import gql from 'graphql-tag';

export default gql`
  query staticPage($_id: ObjID!) {
    staticPage(_id: $_id) {
      _id
      title
      description
      content
      route
      createdAt
      updatedAt
    }
  }
`;
