import React from 'react';
import { Mutation } from '@apollo/react-components';
import uploadImage from 'graphql/uploadImage';

export default (Component) => (
  class withUploadImage extends React.Component {
    render() {
      return (
        <Mutation mutation={uploadImage}>
          {(mutate) => <Component {...this.props} uploadImage={mutate} />}
        </Mutation>
      );
    }
  }
);
