import gql from 'graphql-tag';

export default gql`
  query job($_id: ObjID!) {
    job(_id: $_id) {
      _id
      cost
      description
      internalNotes
      jobNumber
      price
      quantity
      services {
        name
        note
        unitPrice
        quantity
        price
      }
      status
      statusNotes
      title
      type
      unitPrice
      userId
      user {
        firstName
        lastName
        email
        phone
      }
      workOrderId
      promisedAt
      completedAt
      createdAt
      updatedAt
    }
  }
`;
