import gql from 'graphql-tag';

export default gql`
  query formContact($_id: ObjID!) {
    formContact(_id: $_id) {
      _id
      email
      firstName
      lastName
      createdAt
      notes {
        content
        authorId
      }
      customDesignRequests {
        _id
        designs
        productType
        quote {
          _id
        }
        createdAt
      }
      quotes {
        _id
        number
        total
        locked
        emailedAt
        workOrder {
          _id
        }
        customDesignRequestId
        createdAt
      }
      workOrders {
        _id
        number
        status
        user {
          _id
          firstName
          lastName
          email
        }
        total
        locked
        quoteId
      }
    }
  }
`;
