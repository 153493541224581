import React from 'react';
import { Query } from '@apollo/react-components';
import { Loading } from 'components';
import availableProducts from 'graphql/availableProducts';

export default (Component) => {
  class withAvailableProducts extends React.Component {
    render() {
      return (
        <Query query={availableProducts}>
          {({ error, loading, data }) => {
            if (loading) {
              return <Loading/>;
            }

            if (error) {
              return (
                <div>
                  <p>Error :(</p>
                </div>
              );
            }

            return (
              <Component
                {...this.props}
                diamonds={data.diamonds}
                rings={data.rings}
                jewelries={data.jewelries}
              />
            );
          }}
        </Query>
      );
    }
  }

  return withAvailableProducts;
};
