import gql from 'graphql-tag';

export default gql`
  mutation updateUser($_id: ObjID! $input: UpdateUserInput!) {
    updateUser(_id: $_id, input: $input) {
      _id
      updatedAt
    }
  }
`;
