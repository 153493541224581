import React from 'react';
import { Card, CardBody, CardHeader, CardFooter, Form, Container, Col, Alert } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { Button, Footer, FormInputs } from 'components';
// import { Card } from "components/tailwinds";
// import { notify } from 'lib/notify';
import { useLoginWithPasswordMutation } from 'graphql/codegen';
import logoImage from 'vc-logo.svg';
import bgImage from 'assets/img/bg3.jpg';

interface FormData {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const { handleSubmit, register, errors: formError } = useForm<FormData>();
  const [loginWithPassword, { error }] = useLoginWithPasswordMutation();

  // onSubmit handler
  const onSubmit = async (formData: FormData): Promise<any> => {
    // login
    try {
      const { data } = await loginWithPassword({
        variables: {
          email: formData.email,
          password: formData.password
        }
      });
      localStorage.setItem('token', data?.loginWithPassword?.token || '');
      // handle redirect
      const redirect = localStorage.getItem('redirected-from');
      if (redirect) {
        document.location.replace(redirect);
        localStorage.removeItem('redirected-from');
      } else {
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="wrapper wrapper-full-page">
      <div className="full-page section-image">
        <div className="full-page-content">
          <div className="login-page">
            <Container>
              <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Card className="card-login card-plain">
                    <CardHeader>
                      <div className="logo-container">
                        <img src={logoImage} alt="logo" />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <FormInputs
                        inputs={[
                          {
                            label: 'Email',
                            colProps: { xs: 12 },
                            inputProps: {
                              type: 'text',
                              name: 'email',
                              invalid: !!formError.email,
                              error: formError.email?.message,
                              innerRef: register({ required: true })
                            }
                          },
                          {
                            label: 'Password',
                            colProps: { xs: 12 },
                            inputProps: {
                              type: 'password',
                              name: 'password',
                              invalid: !!formError.password,
                              error: formError.password?.message,
                              innerRef: register({ required: true })
                            }
                          }
                        ]}
                      />
                      {error && (
                        <Alert color="danger" style={{ marginTop: '1rem' }}>
                          {error.message.replace('GraphQL error: ', '')}
                        </Alert>
                      )}
                    </CardBody>
                    <CardFooter>
                      <Button color="primary" size="lg" block round type="submit">
                        Login
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        </div>
        <div className="full-page-background" style={{ backgroundImage: `url(${bgImage})` }} />
        <Footer fluid />
      </div>
    </div>
  );
};

export default LoginPage;
