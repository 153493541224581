import React from 'react';
import formatDate from 'date-fns/format';

interface Props {
  date: Date | number;
  format?: string;
}

const DateFormat: React.FC<Props> = ({ date, format = 'MMMM d, yyyy' }) => (
  <span>{formatDate(new Date(date), format)}</span>
);

export default DateFormat;
