import React from 'react';
import { Mutation } from '@apollo/react-components';
import invoiceCreate from 'graphql/invoiceCreate';

export default (Component) => (
  class withInvoiceCreate extends React.Component {
    render() {
      return (
        <Mutation mutation={invoiceCreate}>
          {(mutate) => <Component {...this.props} createInvoice={mutate} />}
        </Mutation>
      );
    }
  }
);
