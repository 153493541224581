import { graphql } from '@apollo/react-hoc';
import gql from 'graphql-tag';

const sendWorkOrderApprovalEmail = gql`
  mutation sendWorkOrderApprovalEmail($input: WorkOrderEmailInput!) {
    sendWorkOrderApprovalEmail(input: $input) {
      result
    }
  }
`;

export default (Component) => graphql(sendWorkOrderApprovalEmail, {
  props: ({ mutate }) => ({
    sendWorkOrderApprovalEmail: (input) => mutate({ variables: { input } })
  })
})(Component);
