/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Table,
  Form,
  Label,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { Mutation } from '@apollo/react-components';
import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import _ from 'lodash';
import Toggle from 'react-toggle';
import Cleave from 'cleave.js/react';
import format from 'date-fns/format';
import {
  faLockOpen,
  faLock,
  faPlus,
  faSave,
  faTrash,
  faPrint,
  faCircle,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { PanelHeader, Button, Icon, FormInputsLegacy, Modal, RightAligned, UserIsInRole } from 'components';
import config from 'config';
import withWorkOrder from 'containers/withWorkOrder';
import withFormContactUpdate from 'containers/withFormContactUpdate';
import withAvailableProducts from 'containers/withAvailableProducts';
import withJobCreate from 'containers/withJobCreate';
import withWorkOrderItemCreate from 'containers/withWorkOrderItemCreate';
import withWorkOrderItemUpdate from 'containers/withWorkOrderItemUpdate';
import withWorkOrderItemDelete from 'containers/withWorkOrderItemDelete';
import withWorkOrderLock from 'containers/withWorkOrderLock';
import withWorkOrderUnlock from 'containers/withWorkOrderUnlock';
import withInvoiceCreate from 'containers/withInvoiceCreate';
import workOrderQuery from 'graphql/workOrder';
import workOrderUpdate from 'graphql/workOrderUpdate';
import paymentDelete from 'graphql/paymentDelete';
import { formatPrice, notify, Alert, multiply, calculateOrderTotals } from 'lib/utils';

const Wrapper = styled.div`
  @media print {
    display: none;
  }
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-around;
`;

class WorkOrder extends React.Component {
  static propTypes = {
    createInvoice: PropTypes.func.isRequired,
    createJob: PropTypes.func.isRequired,
    createWorkOrderItem: PropTypes.func.isRequired,
    deleteWorkOrderItem: PropTypes.func.isRequired,
    diamonds: PropTypes.arrayOf(PropTypes.object),
    history: PropTypes.object.isRequired,
    jewelries: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.object.isRequired,
    refetchWorkOrder: PropTypes.func.isRequired,
    rings: PropTypes.arrayOf(PropTypes.object),
    updateFormContact: PropTypes.func.isRequired,
    updateWorkOrder: PropTypes.func.isRequired,
    updateWorkOrderItem: PropTypes.func.isRequired,
    workOrder: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      availableProducts: {},
      emailDropdownOpen: false,
      locked: props.workOrder && props.workOrder.locked,
      workOrder: props.workOrder
    };
  }

  taxRate = this.props.workOrder.taxPercent || config.taxRate;

  createProductSelectOptions(items) {
    if (!items || !Array.isArray(items) || !items.length) {
      return;
    }
    return items.map(({ _id, name, sku, ...rest }) => ({ label: `${sku} - ${name}`, value: _id, _id, name, ...rest }));
  }

  handleStateChange = (e) => {
    const t = e.target;
    this.setState((state) => _.setWith(_.clone(state), t.name, t.value, _.clone));
  };

  handleLockedStateChange = (locked) =>
    this.props[locked ? 'lockWorkOrder' : 'unlockWorkOrder']({
      variables: {
        _id: this.state.workOrder._id
      },
      refetchQueries: [
        {
          query: workOrderQuery,
          variables: { _id: this.state.workOrder._id }
        }
      ]
    })
      .then(() => {
        notify('success', `Work order ${locked ? 'locked' : 'unlocked'}!`);
        this.setState({ locked });
        if (locked) {
          window.location.reload(); // hard refresh on lock to update WO item stock statuses
        }
      })
      .catch((e) => {
        Alert({
          icon: 'error',
          title: 'Oh no!',
          text: e.message.split('GraphQL error: ')[1]
        });
      });

  handleProductSelectChange = ({ newProductItem }) => {
    let availableProducts;

    if (newProductItem.type === 'Diamond') {
      availableProducts = {
        selectedDiamond: newProductItem,
        selectedRing: { label: '', value: '' },
        selectedJewelry: { label: '', value: '' }
      };
    } else if (newProductItem.type === 'Ring') {
      availableProducts = {
        selectedDiamond: { label: '', value: '' },
        selectedRing: newProductItem,
        selectedJewelry: { label: '', value: '' }
      };
    } else {
      // 'Bracelet', 'Earrings', 'Necklace'
      availableProducts = {
        selectedDiamond: { label: '', value: '' },
        selectedRing: { label: '', value: '' },
        selectedJewelry: newProductItem
      };
    }

    this.setState({
      newProductItem,
      availableProducts
    });
  };

  calculateTotals = (cb) => {
    const totals = calculateOrderTotals(this.state.workOrder);
    this.setState((state) => _.setWith(_.clone(state), 'workOrder', { ...state.workOrder, ...totals }, _.clone), cb);
  };

  handleFormContactUpdate = () => {
    const { user, userId } = this.state.workOrder;

    this.props
      .updateFormContact({
        variables: {
          _id: userId,
          input: _.omit(user, ['_id', 'billingAddress', 'shippingAddress', '__typename'])
        },
        refetchQueries: [
          {
            query: workOrderQuery,
            variables: { _id: this.props.match.params._id }
          }
        ]
      })
      .then(() => notify('success', 'User updated!'))
      .catch((e) => {
        Alert({
          icon: 'error',
          title: 'Oh no!',
          text: e.message.split('GraphQL error: ')[1]
        });
      });
  };

  handleWorkOrderUpdate = async (locked) => {
    const workOrder = _.omit(this.state.workOrder, [
      '_id',
      'user',
      'items',
      'invoiceId',
      'jobs',
      'payments',
      'balance',
      'totalPayments',
      'taxRate',
      'user',
      'createdByUser',
      'isComplete',
      'completedAt',
      'createdAt',
      'updatedAt',
      '__typename'
    ]);

    // update all work order items
    await Promise.all(this.state.workOrder.items.map((item, i) => this.updateWorkOrderItem(i)));

    this.props
      .updateWorkOrder({ _id: this.props.match.params._id, input: workOrder })
      .then(() => {
        if (locked) {
          this.handleLockedStateChange(locked).then(() => this.setState({ locked }));
        }
        notify('success', 'Work Order updated!');
      })
      .catch((e) => {
        Alert({
          icon: 'error',
          title: 'Oh no!',
          text: e.message.split('GraphQL error: ')[1]
        });
      });
  };

  createWorkOrderItem = (newItem) => {
    const items = _.clone(this.state.workOrder.items) || [];

    this.props
      .createWorkOrderItem({
        variables: {
          input: newItem
        },
        refetchQueries: [
          {
            query: workOrderQuery,
            variables: { _id: this.props.workOrder._id }
          }
        ]
      })
      .then(
        ({
          data: {
            createWorkOrderItem: { _id }
          }
        }) => {
          newItem._id = _id;
          newItem.edited = false;
          items.push(newItem);
          this.setState((state) => _.setWith(_.clone(state), 'workOrder.items', items, _.clone));
          notify('success', 'Work order item created and saved!');
          this.calculateTotals();
        }
      )
      .catch((e) => {
        Alert({
          icon: 'error',
          title: 'Oh no!',
          text: e.message.split('GraphQL error: ')[1]
        });
      });
  };

  updateWorkOrderItem = (itemIndex) => {
    const item = _.clone(this.state.workOrder.items)[itemIndex];

    this.props
      .updateWorkOrderItem({
        variables: {
          _id: item._id,
          input: _.omit(item, ['_id', 'edited', 'inStock', 'price', '__typename'])
        },
        refetchQueries: [
          {
            query: workOrderQuery,
            variables: { _id: this.props.workOrder._id }
          }
        ]
      })
      .then(() => {
        this.setState((state) => _.setWith(_.clone(state), `workOrder.items[${itemIndex}].edited`, false, _.clone));
      })
      .catch((e) => {
        Alert({
          icon: 'error',
          title: 'Oh no!',
          text: e.message.split('GraphQL error: ')[1]
        });
      });
  };

  deleteWorkOrderItem = (itemIndex) => {
    const items = _.clone(this.state.workOrder.items);
    const item = items[itemIndex];

    this.props
      .deleteWorkOrderItem({
        variables: {
          _id: item._id
        },
        refetchQueries: [
          {
            query: workOrderQuery,
            variables: { _id: this.props.workOrder._id }
          }
        ]
      })
      .then(() => {
        items.splice(itemIndex, 1);
        this.setState(
          (state) => _.setWith(_.clone(state), 'workOrder.items', items, _.clone),
          () => this.calculateTotals(() => this.handleWorkOrderUpdate())
        );
        notify('success', 'Work order item deleted!');
      })
      .catch((e) => {
        Alert({
          icon: 'error',
          title: 'Oh no!',
          text: e.message.split('GraphQL error: ')[1]
        });
      });
  };

  addProductLineItem = () => {
    const { newProductItem } = this.state;

    this.createWorkOrderItem({
      title: newProductItem.name,
      description: newProductItem.description,
      unitPrice: newProductItem.price,
      quantity: 1,
      productId: newProductItem._id,
      type: newProductItem.type,
      workOrderId: this.props.workOrder._id
    });
  };

  addMiscLineItem = () => {
    this.createWorkOrderItem({
      title: '',
      description: '',
      unitPrice: 0,
      quantity: 1,
      productId: null,
      type: 'Misc',
      workOrderId: this.props.workOrder._id
    });
  };

  createJob = (item) => {
    const { workOrder } = this.state;

    const job = {
      userId: workOrder.userId,
      workOrderId: workOrder._id,
      workOrderItemId: item._id,
      title: item.title,
      description: item.description,
      unitPrice: item.unitPrice,
      quantity: item.quantity,
      price: item.price,
      type: 'special order',
      status: '',
      services: []
    };

    this.props
      .createJob({
        variables: {
          input: job
        },
        refetchQueries: [
          {
            query: workOrderQuery,
            variables: { _id: workOrder._id }
          }
        ]
      })
      .then(({ data: { createJob } }) => {
        this.props.history.push(`/jobs/${createJob._id}`);
      })
      .catch(() => notify('error', 'Job create failed!'));
  };

  createInvoice = () => {
    const { workOrder } = this.state;

    const invoice = {
      userId: workOrder.userId,
      workOrderId: workOrder._id,
      to: workOrder.user.email,
      type: 'work-order'
    };

    this.props
      .createInvoice({
        variables: {
          input: invoice
        },
        refetchQueries: [
          {
            query: workOrderQuery,
            variables: { _id: workOrder._id }
          }
        ]
      })
      .then(({ data: { createInvoice } }) => {
        this.props.history.push(`/invoices/${createInvoice._id}`);
      })
      .catch(() => notify('error', 'Invoice creation failed!'));
  };

  renderJobButton(item) {
    if (item.inStock || item.type === 'Diamond' || item.type === 'Diamond') {
      return <div>In Stock</div>;
    }

    const { workOrder } = this.props;

    if (!Array.isArray(workOrder.jobs) || workOrder.jobs.length < 1) {
      return (
        <Button size="sm" color="primary" onClick={() => this.createJob(item)}>
          Create Job
        </Button>
      );
    }

    const job = workOrder.jobs.find((j) => j.workOrderItemId === item._id);

    if (job) {
      if (job.completedAt) {
        return (
          <div>
            <p>Job is complete.</p>
            <Link to={`/jobs/${job._id}`}>View It</Link>
          </div>
        );
      }

      return (
        <Link to={`/jobs/${job._id}`}>
          <Button size="sm" color="default">
            View Job
          </Button>
        </Link>
      );
    }

    return (
      <Button size="sm" color="primary" onClick={() => this.createJob(item)}>
        Create Job
      </Button>
    );
  }

  render() {
    const { workOrder } = this.props;

    if (!workOrder) {
      return <div>No work order found!</div>;
    }

    return (
      <>
        <Wrapper>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col sm={6}>
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <h5>CUSTOMER DETAILS</h5>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <ul style={{ listStyle: 'none', paddingInlineStart: '.5rem' }}>
                        <li>
                          <strong>Customer:</strong> {workOrder.user.firstName} {workOrder.user.lastName}
                        </li>
                        <li>
                          <strong>Created:</strong> {format(new Date(workOrder.createdAt), 'MMMM d, yyyy')}
                        </li>
                        {workOrder.isComplete && (
                          <li>
                            <strong>Completed:</strong> {format(new Date(workOrder.completedAt), 'MMMM d, yyyy')}
                          </li>
                        )}
                        {workOrder.isComplete && !workOrder.completedAt && this.state.locked ? (
                          <Button color="primary" onClick={this.createInvoice}>
                            Mark Complete & Create Invoice
                          </Button>
                        ) : (
                          !!workOrder.invoiceId && (
                            <Link to={`/invoices/${workOrder.invoiceId}`}>
                              <Button color="primary">View Invoice</Button>
                            </Link>
                          )
                        )}
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={6}>
                <Card>
                  <CardBody>
                    <ActionBar>
                      {this.state.locked && (
                        <>
                          <Link to={`/quoting/work-orders/${this.props.match.params._id}/print`}>
                            <Button color="primary" type="submit">
                              <Icon name={faPrint} /> Print Work Order
                            </Button>
                          </Link>
                          <Link to={`/quoting/work-orders/${workOrder._id}/payments/new`}>
                            <Button color="primary">
                              <Icon name={faCircle} />
                              &nbsp; Record Payment
                            </Button>
                          </Link>
                          <ButtonDropdown
                            isOpen={this.state.emailDropdownOpen}
                            toggle={() => this.setState({ emailDropdownOpen: !this.state.emailDropdownOpen })}
                          >
                            <DropdownToggle caret color="primary">
                              <Icon name={faEnvelope} />
                              &nbsp; Transactional Emails
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() =>
                                  this.props.history.push(`/quoting/work-orders/${workOrder._id}/approval`)}
                              >
                                <Link to={`/quoting/work-orders/${workOrder._id}/approval`}>Work Order Approval</Link>
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.props.history.push(`/quoting/work-orders/${workOrder._id}/shipment-confirmation`)}
                              >
                                <Link to={`/quoting/work-orders/${workOrder._id}/shipment-confirmation`}>
                                  Shipment Confirmation
                                </Link>
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.props.history.push(`/quoting/work-orders/${workOrder._id}/follow-up`)}
                              >
                                <Link to={`/quoting/work-orders/${workOrder._id}/follow-up`}>Post sale follow up</Link>
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </>
                      )}
                      <label htmlFor="lock-switch">
                        <Toggle
                          onChange={(e) => this.handleLockedStateChange(e.target.checked)}
                          defaultChecked={this.state.locked}
                          className="react-switch"
                          id="lock-switch"
                          icons={{
                            checked: <Icon name={faLock} />,
                            unchecked: <Icon name={faLockOpen} />
                          }}
                        />
                      </label>
                    </ActionBar>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md={6}>
                        <h5>WORK ORDER ITEMS</h5>
                      </Col>
                      <Col md={6}>
                        <RightAligned>
                          {!this.state.locked && (
                            <Modal
                              launchButtonText={(
                                <span>
                                  <Icon name={faPlus} /> Product
                                </span>
                              )}
                              submitButtonText="Add Item"
                              onSubmit={this.addProductLineItem}
                            >
                              <Form onSubmit={this.handleSubmit}>
                                <FormInputsLegacy
                                  inputs={[
                                    {
                                      label: 'Diamonds',
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'select',
                                        name: 'diamonds',
                                        options: this.createProductSelectOptions(this.props.diamonds),
                                        value: this.state.availableProducts.selectedDiamond,
                                        onChange: (item) => {
                                          this.handleProductSelectChange({ newProductItem: item });
                                        }
                                      }
                                    },
                                    {
                                      label: 'Rings',
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'select',
                                        name: 'rings',
                                        options: this.createProductSelectOptions(this.props.rings),
                                        value: this.state.availableProducts.selectedRing,
                                        onChange: (item) => {
                                          const product = {
                                            ...item,
                                            description: item.parent.shortDescription?.replace(/\n/g, '<br>')
                                          };
                                          this.handleProductSelectChange({ newProductItem: product });
                                        }
                                      }
                                    },
                                    {
                                      label: 'Jewelry',
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'select',
                                        name: 'jewelries',
                                        options: this.createProductSelectOptions(this.props.jewelries),
                                        value: this.state.availableProducts.selectedJewelry,
                                        onChange: (item) => {
                                          const product = {
                                            ...item,
                                            description: item.parent.shortDescription?.replace(/\n/g, '<br>')
                                          };
                                          this.handleProductSelectChange({ newProductItem: product });
                                        }
                                      }
                                    }
                                  ]}
                                />
                              </Form>
                            </Modal>
                          )}
                          {!this.state.locked && (
                            <Button color="primary" onClick={this.addMiscLineItem}>
                              <Icon name={faPlus} />
                            </Button>
                          )}
                        </RightAligned>
                      </Col>
                      <hr />
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Description</th>
                          <th>Unit Price</th>
                          <th>Quantity</th>
                          <th>Total</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.workOrder.items.map((item, i) =>
                          this.state.locked ? (
                            <tr key={i}>
                              <td>{item.type}</td>
                              <td>
                                {item.productId ? (
                                  <>
                                    <Link
                                      to={`/products/${item.type === 'Diamond' ? 'diamonds' : 'jewelry'}/${
                                        item.productId
                                      }`}
                                    >
                                      {item.title}
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;
                                    <Badge color="default">
                                      {item.inStock || item.type === 'Diamond'
                                        ? 'Reserved from Stock'
                                        : 'Special Order'}
                                    </Badge>
                                  </>
                                ) : (
                                  item.title
                                )}
                                <br />
                                <div className="quill-sourced" dangerouslySetInnerHTML={{ __html: item.description }} />
                              </td>
                              <td>{formatPrice(item.unitPrice)}</td>
                              <td>{item.quantity}</td>
                              <td>{formatPrice(item.price)}</td>
                              <td>{!workOrder.completedAt && this.renderJobButton(item)}</td>
                            </tr>
                          ) : (
                            <tr key={i}>
                              <td>{item.type}</td>
                              <td>
                                <Form>
                                  <FormInputsLegacy
                                    inputs={[
                                      {
                                        colProps: { xs: 12 },
                                        inputProps: {
                                          type: 'text',
                                          name: `workOrder.items[${i}].title`,
                                          defaultValue: item.title,
                                          onChange: (e) => {
                                            this.handleStateChange(e);
                                            this.setState((state) =>
                                              _.setWith(_.clone(state), `workOrder.items[${i}].edited`, true, _.clone)
                                            );
                                          }
                                        }
                                      },
                                      {
                                        colProps: { xs: 12 },
                                        inputProps: {
                                          type: 'wysiwyg',
                                          name: `workOrder.items[${i}].description`,
                                          theme: 'snow',
                                          defaultValue: item.description,
                                          onChange: (val) => {
                                            this.setState((state) =>
                                              _.setWith(
                                                _.clone(state),
                                                `workOrder.items[${i}].description`,
                                                val,
                                                _.clone
                                              )
                                            );
                                            // TODO: the editor triggers an onChange on initial render, so we can't change edited state here
                                            // this.setState((state) => _.setWith(_.clone(state), `workOrder.items[${i}].edited`, true, _.clone));
                                          }
                                        }
                                      }
                                    ]}
                                  />
                                </Form>
                              </td>
                              <td>
                                <Cleave
                                  name={`workOrder.items[${i}].unitPrice`}
                                  value={parseFloat(_.get(this.state, `workOrder.items[${i}].unitPrice`), 10) * 0.01}
                                  className="form-control"
                                  onChange={(e) => {
                                    const { name, rawValue } = e.target;

                                    const unitPrice = Math.round(parseFloat(rawValue) * 100);
                                    const quantity = _.get(this.state, `workOrder.items[${i}].quantity`);
                                    const price = multiply(unitPrice, quantity);

                                    this.setState((state) => _.setWith(_.clone(state), name, unitPrice, _.clone));
                                    this.setState(
                                      (state) =>
                                        _.setWith(_.clone(state), `workOrder.items[${i}].price`, price, _.clone),
                                      () => this.calculateTotals()
                                    );
                                    this.setState((state) =>
                                      _.setWith(_.clone(state), `workOrder.items[${i}].edited`, true, _.clone)
                                    );
                                  }}
                                  options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand',
                                    numeralDecimalScale: 2,
                                    prefix: '$',
                                    rawValueTrimPrefix: true,
                                    numericOnly: true
                                  }}
                                />
                              </td>
                              <td>
                                <Input
                                  name={`workOrder.items[${i}].quantity`}
                                  type="number"
                                  defaultValue={item.quantity}
                                  onChange={(e) => {
                                    const { name, value } = e.target;

                                    const unitPrice = _.get(this.state, `workOrder.items[${i}].unitPrice`);
                                    const quantity = parseFloat(value, 10);
                                    const price = multiply(unitPrice, quantity);

                                    this.setState((state) => _.setWith(_.clone(state), name, quantity, _.clone));
                                    this.setState(
                                      (state) =>
                                        _.setWith(_.clone(state), `workOrder.items[${i}].price`, price, _.clone),
                                      () => this.calculateTotals()
                                    );
                                    this.setState((state) =>
                                      _.setWith(_.clone(state), `workOrder.items[${i}].edited`, true, _.clone)
                                    );
                                  }}
                                />
                              </td>
                              <td>{formatPrice(_.get(this.state, `workOrder.items[${i}].price`))}</td>
                              <td>
                                {!!_.get(this.state, `workOrder.items[${i}].edited`) && (
                                  <Button color="primary" onClick={() => this.updateWorkOrderItem(i)}>
                                    <Icon name={faSave} />
                                  </Button>
                                )}
                                &nbsp;
                                <Button color="danger" onClick={() => this.deleteWorkOrderItem(i)}>
                                  <Icon name={faTrash} />
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                    <hr />
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md={{ size: 3, offset: 9 }}>
                        <Row>
                          <Col sm={4}>
                            <Label>Subtotal</Label>
                          </Col>
                          <Col sm={8}>{formatPrice(_.get(this.state, 'workOrder.subtotal'))}</Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <label htmlFor="tax" style={{ marginTop: '1rem' }}>
                              <p>Tax</p>
                              <Toggle
                                onChange={(e) => {
                                  const hasTax = e.target.checked;
                                  this.setState(
                                    (state) => _.setWith(_.clone(state), 'workOrder.hasTax', hasTax, _.clone),
                                    () => this.calculateTotals()
                                  );
                                }}
                                defaultChecked={!!this.state.workOrder.hasTax}
                                disabled={this.state.locked}
                                className="react-switch"
                                id="tax"
                              />
                            </label>
                          </Col>
                          <Col md={6}>
                            <div style={{ marginTop: '3rem' }}>
                              {!!this.state.workOrder.tax && formatPrice(_.get(this.state, 'workOrder.tax'))}
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: '1.5rem' }}>
                          <Col sm={4}>
                            <Label>Shipping</Label>
                          </Col>
                          <Col sm={8}>
                            {this.state.locked ? (
                              formatPrice(_.get(this.state, 'workOrder.shipping') || 0)
                            ) : (
                              <FormInputsLegacy
                                inputs={[
                                  {
                                    colProps: { xs: 12 },
                                    inputProps: {
                                      type: 'currency',
                                      name: 'shipping',
                                      value: parseFloat(_.get(this.state, 'workOrder.shipping')) / 100,
                                      onChange: (e) => {
                                        const { rawValue } = e.target;
                                        const shipping = Math.round(parseFloat(rawValue) * 100);
                                        this.setState(
                                          (state) => _.setWith(_.clone(state), 'workOrder.shipping', shipping, _.clone),
                                          () => this.calculateTotals()
                                        );
                                      }
                                    }
                                  }
                                ]}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row style={{ marginTop: '1rem' }}>
                          <Col md={4}>
                            <Label>
                              <strong>Total</strong>
                            </Label>
                          </Col>
                          <Col md={6}>
                            <div>
                              <strong>{formatPrice(_.get(this.state, 'workOrder.total'))}</strong>
                            </div>
                          </Col>
                        </Row>
                        {this.state.workOrder.totalPayments > 0 && (
                          <>
                            <Row style={{ marginTop: '1rem' }}>
                              <Col md={4}>
                                <Label>Payments</Label>
                              </Col>
                              <Col md={6}>
                                <div>-{formatPrice(_.get(this.state, 'workOrder.totalPayments'))}</div>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: '1rem' }}>
                              <Col md={4}>
                                <Label>
                                  <strong>Balance</strong>
                                </Label>
                              </Col>
                              <Col md={6}>
                                <div>
                                  <strong>{formatPrice(_.get(this.state, 'workOrder.balance'))}</strong>
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                        <hr />
                        {!this.state.locked && (
                          <Button color="primary" type="submit" onClick={() => this.handleWorkOrderUpdate(true)}>
                            Save and Lock
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle>Payments</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        {!workOrder.payments.length ? (
                          <h4 className="text-center">No payments yet!</h4>
                        ) : (
                          <Table>
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Created By</th>
                                <th>Submitted</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!!workOrder.payments.length &&
                                workOrder.payments.map((p) => (
                                  <tr key={p._id}>
                                    <th>{p.paymentType}</th>
                                    <th>{formatPrice(p.amount)}</th>
                                    <th>{p.createdByUser && p.createdByUser.email}</th>
                                    <th>{format(new Date(p.createdAt), 'MMMM d, yyyy - h:mma')}</th>
                                    <th>
                                      <UserIsInRole roles="admin">
                                        <Mutation
                                          mutation={paymentDelete}
                                          variables={{ _id: p._id }}
                                          onCompleted={() => {
                                            notify(
                                              'success',
                                              `Successfully deleted payment of: ${formatPrice(p.amount)}`
                                            );
                                            this.props.refetchWorkOrder();
                                            setTimeout(() => window.location.reload(), 1500); // fix stale cash
                                          }}
                                          onError={(e) => notify('error', e.message.split('GraphQL error: ')[1])}
                                        >
                                          {(mutate) => (
                                            <Button onClick={mutate} color="danger" size="sm" icon>
                                              <Icon name={faTrash} />
                                            </Button>
                                          )}
                                        </Mutation>
                                      </UserIsInRole>
                                    </th>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Wrapper>

        {/* alert the user if they try to leave the page before saving changes */}
        <Prompt
          when={!!this.state.workOrder.items && !!this.state.workOrder.items.filter(({ edited }) => edited).length}
          message="You have unsaved work order details! Are you sure you want to leave before saving and locking?"
        />
      </>
    );
  }
}

export default compose(
  graphql(workOrderUpdate, {
    props: ({ mutate }) => ({
      updateWorkOrder: (args) =>
        mutate({
          variables: args,
          refetchQueries: [
            {
              query: workOrderQuery,
              variables: { _id: args._id }
            }
          ]
        })
    })
  }),
  withWorkOrderItemCreate,
  withWorkOrderItemUpdate,
  withWorkOrderItemDelete,
  withWorkOrder,
  withWorkOrderLock,
  withWorkOrderUnlock,
  withFormContactUpdate,
  withAvailableProducts,
  withJobCreate,
  withInvoiceCreate
)(WorkOrder);
