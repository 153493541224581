import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from '@apollo/react-components';
import ReactTable from 'react-table-v6';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Form, Modal, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { faEdit, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import matchSorter from 'match-sorter';
import formatDate from 'date-fns/format';

import { PanelHeader, Button, DateFormat, Icon, Loading, FormInputsLegacy, UserIsInRole } from 'components';
import withCreateUser from 'containers/withCreateUser';
import usersQuery from 'graphql/usersQuery';
import userDelete from 'graphql/userDelete';
import { notify, Alert } from 'lib/utils';

const ActionBar = styled.section`
  display: flex;
  justify-content: flex-end;
`;

class Users extends Component {
  static propTypes = {
    createUser: PropTypes.func.isRequired,
    history: PropTypes.object
  };

  state = {
    modalOpen: false,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordAgain: '',
    roles: ['customer'],
    sendEmail: false
  };

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  renderActions(_id) {
    return (
      <div className="actions-right">
        <Button onClick={() => this.props.history.push(`/users/${_id}`)} color="primary" size="sm" icon>
          <Icon name={faEdit} />
        </Button>
        &nbsp;
        <UserIsInRole roles="admin">
          <Mutation
            mutation={userDelete}
            variables={{ _id }}
            refetchQueries={() => [
              {
                query: usersQuery,
                variables: { pagination: { limit: 3000 }, sort: { createdAt: -1 } }
              }
            ]}
            onCompleted={() =>
              Alert('User deleted!', {
                icon: 'success',
                buttons: false,
                timer: 1500
              })
            }
            onError={(e) =>
              Alert('Oh no!', {
                text: e.message.split('GraphQL error: ')[1],
                icon: 'error'
              })
            }
          >
            {(mutate) => (
              <Button
                onClick={() => {
                  Alert({
                    title: 'Are you sure?',
                    text: "There's no going back!",
                    icon: 'warning',
                    dangerMode: true,
                    buttons: {
                      cancel: true,
                      confirm: 'Do it!'
                    }
                  }).then((confirmed) => confirmed && mutate());
                }}
                color="danger"
                size="sm"
                icon
              >
                <Icon name={faTimes} />
              </Button>
            )}
          </Mutation>
          &nbsp;
        </UserIsInRole>
      </div>
    );
  }

  handleFormStateChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCreateUser = (e, { refetch }) => {
    e.preventDefault();
    const { firstName, lastName, email, password, passwordAgain, roles, sendEmail } = this.state;

    if (!firstName || !lastName || !email) {
      return notify('error', 'Name and email fields are required!');
    }

    if (!roles.includes('form-contact')) {
      if (!password || !passwordAgain) {
        return notify('error', 'Both password fields are required!');
      }
      if (password !== passwordAgain) {
        return notify('error', 'Password fields are required for users that aren\'t in the "form-contact" role!');
      }
    }

    this.props
      .createUser({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            password,
            roles,
            sendEmail,
            isInvite: true
          }
        }
      })
      .then(() => {
        refetch();
        this.toggleModal();
      })
      .catch((error) => notify('error', error.toString()));
  };

  render() {
    const columns = [
      {
        Header: 'First Name',
        accessor: 'firstName',
        maxWidth: 100,
        filterMethod: (filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        maxWidth: 100,
        filterMethod: (filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
      },
      {
        Header: 'Email',
        accessor: 'email',
        maxWidth: 240,
        filterMethod: (filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        sortMethod: (a, b) => (a > b ? 1 : -1),
        Cell: ({ value }) => <DateFormat date={value} />,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: [(item) => formatDate(item.createdAt, 'MMMM d, yyyy')] }),
        filterAll: true,
        maxWidth: 150
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
        sortable: false,
        filterable: false
      }
    ];

    return (
      <>
        <PanelHeader size="sm" />
        <Query query={usersQuery} variables={{ pagination: { limit: 10000 }, sort: { createdAt: -1 } }}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) {
              return <p>Error :(</p>;
            }

            const tableData = data.users.map((item) => ({
              ...item,
              actions: this.renderActions(item._id)
            }));

            return (
              <div className="content">
                <Row>
                  <Col xs={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle>
                          <h5>Users</h5>
                        </CardTitle>
                        {/* create user button and modal */}
                        <ActionBar>
                          <Button color="primary" onClick={this.toggleModal}>
                            <span>
                              <Icon name={faPlus} /> Create User
                            </span>
                          </Button>
                          <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
                            <ModalBody>
                              <Form onSubmit={(e) => this.handleCreateUser(e, { refetch })}>
                                <FormInputsLegacy
                                  inputs={[
                                    {
                                      label: 'First Name',
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'text',
                                        name: 'firstName',
                                        defaultValue: '',
                                        onChange: this.handleFormStateChange
                                      }
                                    },
                                    {
                                      label: 'Last Name',
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'text',
                                        name: 'lastName',
                                        defaultValue: '',
                                        onChange: this.handleFormStateChange
                                      }
                                    },
                                    {
                                      label: 'Email',
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'text',
                                        name: 'email',
                                        defaultValue: '',
                                        onChange: this.handleFormStateChange
                                      }
                                    },
                                    {
                                      label: 'Password',
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'password',
                                        name: 'password',
                                        defaultValue: '',
                                        onChange: this.handleFormStateChange
                                      }
                                    },
                                    {
                                      label: 'Password (again)',
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'password',
                                        name: 'passwordAgain',
                                        defaultValue: '',
                                        onChange: this.handleFormStateChange
                                      }
                                    },
                                    {
                                      label: 'Role',
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'select',
                                        name: 'role',
                                        options: [
                                          {
                                            label: 'admin',
                                            value: 'admin'
                                          },
                                          {
                                            label: 'employee',
                                            value: 'employee'
                                          },
                                          {
                                            label: 'customer',
                                            value: 'customer'
                                          },
                                          {
                                            label: 'form-contact',
                                            value: 'form-contact'
                                          }
                                        ],
                                        defaultValue: { label: 'customer', value: 'customer' },
                                        onChange: (role) => this.setState({ roles: [role.value] })
                                      }
                                    }
                                  ]}
                                />
                              </Form>
                              <hr />
                            </ModalBody>
                            <ModalFooter>
                              <Button color="secondary" onClick={this.toggleModal}>
                                Close
                              </Button>
                              <Button color="primary" onClick={(e) => this.handleCreateUser(e, { refetch })}>
                                Save
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </ActionBar>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          data={tableData}
                          filterable
                          columns={columns}
                          defaultPageSize={20}
                          showPaginationBottom
                          className="-striped -highlight"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Query>
      </>
    );
  }
}

export default withCreateUser(Users);
