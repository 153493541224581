import gql from 'graphql-tag';

export default gql`
  mutation updateVendor($where: VendorWhereUniqueInput! $data: VendorUpdateInput!) {
    updateVendor(where: $where, data: $data) {
      id
      updatedAt
    }
  }
`;
