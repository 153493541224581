import gql from 'graphql-tag';

export default gql`
  mutation removeQuote($_id: ObjID!) {
    removeQuote(_id: $_id) {
      n
      ok
    }
  }
`;
