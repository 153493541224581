import React from 'react';
import { FormInputsLegacy } from 'components';

export type ILeadTimeRange = {
  leadTimeFrom?: number;
  leadTimeTo?: number;
}

type Props = {
  leadTimeRange: ILeadTimeRange;
  setLeadTimeRange: (value: React.SetStateAction<ILeadTimeRange>) => void;
};

const LeadTimeRange: React.FC<Props> = (props) => {
  const {
    setLeadTimeRange,
    leadTimeRange
  } = props;

  return (
    <>
      <div className="vendor-settings__header font-weight-bold">Lead time range:</div>
      <FormInputsLegacy
        inputs={[
          {
            colProps: { xs: 3 },
            label: 'Minimum lead time',
            inputProps: {
              style: { width: '100px' },
              type: 'number',
              placeholder: 'Days',
              name: 'leadTimeFrom',
              defaultValue: leadTimeRange.leadTimeFrom,
              onInput(e): void {
                let value: number | null = null;
                if (!isNaN(parseInt(e.target.value, 10))) {
                  value = parseInt(e.target.value, 10);
                }
                setLeadTimeRange({
                  leadTimeFrom: value || 0,
                  leadTimeTo: leadTimeRange.leadTimeTo
                });
              }
            }
          },
          {
            colProps: { xs: 3 },
            label: 'Maximum lead time',
            inputProps: {
              style: { width: '100px' },
              type: 'number',
              placeholder: 'Days',
              name: 'leadTimeTo',
              defaultValue: leadTimeRange.leadTimeTo,
              onInput(e): void {
                let value: number | null = null;
                if (!isNaN(parseInt(e.target.value, 10))) {
                  value = parseInt(e.target.value, 10);
                }
                setLeadTimeRange({
                  leadTimeTo: value || 0,
                  leadTimeFrom: leadTimeRange.leadTimeFrom
                });
              }
            }
          }
        ]}
      />
    </>
  );
};

export default LeadTimeRange;
