import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import Toggle from 'react-toggle';
import toast from 'react-hot-toast';
import { Button, Loading } from 'components';
import { Table, THead, TBody, Th, Td } from 'components/tailwinds/Table';
import { useVendorsQuery, useUpdateVendorMutation, useExportProductsToFileMutation } from 'graphql/codegen';

const Vendors: React.FC = () => {
  const { loading, error, data, refetch } = useVendorsQuery();
  const [updateVendor] = useUpdateVendorMutation();
  const [exportProducts] = useExportProductsToFileMutation();

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  return (
    <>
      <div className="my-4">
        <Row>
          <Col xs={12} sm={{ size: 9, offset: 1 }}>
            <div className="prose">
              <h3>
                Third Party Vendors{' '}
                <span style={{ fontSize: '.8rem', float: 'right' }}>
                  Total vendor diamonds: {data?.totalDiamonds?.toLocaleString()}
                </span>
              </h3>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Table>
                  <THead>
                    <tr>
                      <Th>Enabled</Th>
                      <Th>Name</Th>
                      <Th>Slug</Th>
                      <Th>Source</Th>
                      <Th>Diamonds</Th>
                      <Th />
                    </tr>
                  </THead>
                  <TBody>
                    {data?.vendors.map((v) => (
                      <tr key={v.id}>
                        <Td>
                          <Toggle
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                              const enabled = e.target.checked;
                              updateVendor({
                                variables: {
                                  where: { id: v.id },
                                  data: {
                                    enabled: { set: enabled },
                                    diamonds: {
                                      updateMany: [{ where: {}, data: { enabled: { set: enabled } } }]
                                    }
                                  }
                                }
                              }).then(() => {
                                toast.success(`${v.name} is now ${enabled ? 'enabled' : 'disabled'}.`);
                                refetch();
                              });
                            }}
                            defaultChecked={v.enabled}
                            className="react-switch"
                            icons={false}
                          />
                        </Td>
                        <Td>{v.name}</Td>
                        <Td>{v.slug}</Td>
                        <Td>{v.source}</Td>
                        <Td>{v.diamondsCount}</Td>
                        <Td>
                          <div className="space-x-5">
                            <Link to={`/products/virtual?vendors=${v.slug}`}>
                              <Button color="primary" size="sm" icon>
                                <i className="now-ui-icons objects_diamond" />
                              </Button>
                            </Link>
                            <Link to={`/vendors/${v.id}`}>
                              <Button color="primary" size="sm" icon>
                                <Icon icon={faEdit} />
                              </Button>
                            </Link>
                          </div>
                        </Td>
                      </tr>
                    ))}
                  </TBody>
                </Table>
              </div>
            )}
          </Col>
          <Col xs={12} sm={{ size: 2 }}>
            <Card>
              <CardBody>
                <Button
                  onClick={(): void => {
                    exportProducts();
                    toast.success('Export job started!');
                  }}
                >
                  Trigger RareCarat Export
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Vendors;
