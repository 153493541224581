import React from 'react';
import { Mutation } from '@apollo/react-components';
import workOrderUnlock from 'graphql/workOrderUnlock';

export default (Component) => function withWorkOrderLock(props) {
  return (
    <Mutation mutation={workOrderUnlock}>
      {(mutate) => <Component {...props} unlockWorkOrder={mutate} />}
    </Mutation>
  );
};
