import React from 'react';
import { Card, CardBody, Row, Col, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { ImageUpload, ImageThumbnail } from 'components';
import withImageUpdate from 'containers/withImageUpdate';
import diamondQuery from 'graphql/diamondQuery';
import { notify } from 'lib/utils';

const DiamondImages = ({ diamond, updateImage, refetch }) => (
  <div>
    <ImageUpload
      productType="Diamond"
      productId={diamond.id}
      refetch={refetch}
    />
    <hr />
    <Row>
      <Col md={12}>
        {!!diamond.images.filter((img) => img.category !== '360').length
        && (
        <div>
          <h4>Uploaded Images</h4>
          <Row>
            {diamond.images.filter((img) => img.category !== '360' && img.category !== 'lab').map((image) => (
              <Col md={3} key={image.id}>
                <Card>
                  <CardBody>
                    <ImageThumbnail
                      image={image}
                      refetchQueries={[{
                        query: diamondQuery,
                        variables: { slugOrId: diamond.id }
                      }]}
                    />
                    <Input
                      type="select"
                      defaultValue={image.category}
                      onChange={(e) => {
                        updateImage({
                          variables: {
                            where: { id: image.id },
                            data: { category: { set: e.target.value } }
                          },
                          refetchQueries: [{
                            query: diamondQuery,
                            variables: { slugOrId: diamond.id }
                          }]
                        }).then(() => notify('success', 'Image category successfully updated!'))
                          .catch((err) => notify('error', `Update failed! \n ${err}`));
                      }}
                    >
                      <option value="">Choose a category...</option>
                      <option value="aset">ASET</option>
                      <option value="sarin">Sarin</option>
                      <option value="ideal-scope">Ideal-Scope</option>
                      <option value="arrows">Arrows</option>
                      <option value="hearts">Hearts</option>
                    </Input>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        )}
      </Col>
    </Row>
  </div>
);

DiamondImages.propTypes = {
  diamond: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  updateImage: PropTypes.func.isRequired
};

export default withImageUpdate(DiamondImages);
