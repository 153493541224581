import gql from 'graphql-tag';

export default gql`
  query orders {
    orders {
      _id
      number
      isBankWire
      status
      total
      user {
        _id
        email
        firstName
        lastName
      }
      createdAt
    }
  }
`;
