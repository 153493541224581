import gql from 'graphql-tag';

export default gql`
  mutation updateImage($where: ImageWhereUniqueInput!, $data: ImageUpdateInput!) {
    updateImage(where: $where, data: $data) {
      id
      updatedAt
    }
  }
`;
