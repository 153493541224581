import gql from 'graphql-tag';

export default gql`
  mutation updateCustomDesignRequest($_id: ObjID! $input: CustomDesignRequestUpdateInput!) {
    updateCustomDesignRequest(_id: $_id, input: $input) {
      _id
      updatedAt
    }
  }
`;
