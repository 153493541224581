import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Loading } from 'components';
import { useGeneralInquiriesQuery } from 'graphql/codegen';
import { format } from 'date-fns';

const CustomerInquiries: React.FC = () => {
  const { loading, error, data } = useGeneralInquiriesQuery();

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  return (
    <>
      <div className="my-7">
        <Row>
          <Col xs={12}>
            {loading ? (
              <Loading />
            ) : (
              <div className="p-4">
                <Card className="bg-white">
                  <CardHeader>
                    <div>
                      <h1 className="prose prose-2xl">Customer Inquiries</h1>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th>Submitted</th>
                          <th>First</th>
                          <th>Last</th>
                          <th>Email</th>
                          <th>Message</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {data?.generalInquiries?.map((v) => (
                          <tr key={v._id}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {format(new Date(v.createdAt), 'MMM dd, yyy h:mm:aaa')}
                            </td>
                            <td>{v.firstName}</td>
                            <td>{v.lastName}</td>
                            <td>
                              <Link
                                className="max-w-min"
                                to="#"
                                onClick={(e): void => {
                                  window.location.href = `mailto:${v.email}`;
                                  e.preventDefault();
                                }}
                              >
                                <div className="max-w-xs">{v.email}</div>
                              </Link>
                            </td>
                            <td>
                              <p className="prose p-3">{v.message}</p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CustomerInquiries;
