/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReactTable from 'react-table-v6';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { faPlus, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuid } from 'uuid';

import { PanelHeader, Button, DateFormat, Icon, Loading, UserIsInRole } from 'components';
import diamondsQuery from 'graphql/diamondsQuery';
import removeDiamond from 'graphql/diamondDelete';
import withCreateDiamond from 'containers/withDiamondCreate';
import { formatPrice, Alert, notify } from 'lib/utils';

const ActionBar = styled.section`
  display: flex;
  justify-content: flex-end;
`;

class Diamonds extends Component {
  handleCreateDiamond = (e, { refetch }) => {
    e.preventDefault();
    const skuAndSlug = uuid().replace(/-/g, '');

    this.props.createDiamond({
      autopopulateDescription: true,
      autopopulateMetaDescription: true,
      autopopulateMetaTitle: true,
      carat: 0,
      name: 'New Product',
      sku: skuAndSlug,
      slug: skuAndSlug,
      cost: 0,
      price: 0,
      enabled: false,
      isLabGrown: false,
      meta: {
        create: {
          title: '',
          description: '',
          keywords: ''
        }
      },
      lab: {
        create: {
          name: '',
          reportNumber: ''
        }
      }
    })
      .then(async ({ data: { createDiamond: { id } } }) => {
        await refetch();
        this.props.history.push(`/products/diamonds/${id}`);
      })
      .catch((error) => notify('error', error.toString()));
  }

  renderActions(_id) {
    return (
      <div className="actions-right">
        <Link to={`/products/diamonds/${_id}`}>
          <Button
            color="primary"
            size="sm"
            icon
          >
            <Icon name={faEdit} />
          </Button>
        </Link>
        &nbsp;
        <UserIsInRole roles="admin">
          <Mutation
            mutation={removeDiamond}
            variables={{ _id }}
            refetchQueries={() => ([{
              query: diamondsQuery,
              variables: { where: { vendorId: { equals: null } }, orderBy: { createdAt: 'desc' } }
            }])}
            onCompleted={() => Alert('Diamond deleted!', {
              icon: 'success',
              buttons: false,
              timer: 1500
            })}
            onError={(e) => Alert('Oh no!', {
              text: e.message.split('GraphQL error: ')[1],
              icon: 'error'
            })}
          >
            {(mutate) => (
              <Button
                onClick={() => {
                  Alert({
                    title: 'Are you sure?',
                    text: 'There\'s no going back!',
                    icon: 'warning',
                    dangerMode: true,
                    buttons: {
                      cancel: true,
                      confirm: 'Do it!'
                    }
                  }).then((confirmed) => confirmed && mutate());
                }}
                color="danger"
                size="sm"
                icon
              >
                <Icon name={faTimes} />
              </Button>
            )}
          </Mutation>
          &nbsp;
        </UserIsInRole>
      </div>
    );
  }

  render() {
    const columns = [
      {
        Header: 'SKU',
        accessor: 'sku',
        maxWidth: 140
      },
      {
        Header: 'Carat',
        accessor: 'carat',
        maxWidth: 80
      },
      {
        Header: 'Color',
        accessor: 'color',
        Cell: ({ value }) => value && value.label,
        maxWidth: 70
      },
      {
        Header: 'Clarity',
        accessor: 'clarity',
        Cell: ({ value }) => value && value.label
      },
      {
        Header: 'Shape',
        accessor: 'shape',
        Cell: ({ value }) => value && value.label
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ value }) => value && formatPrice(value),
        sortMethod: (a, b) => (a > b ? 1 : -1),
        maxWidth: 100
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        sortMethod: (a, b) => (a > b ? 1 : -1),
        Cell: ({ value }) => value && <DateFormat date={value} />,
        maxWidth: 150
      },
      {
        Header: 'Qty',
        accessor: 'quantity',
        maxWidth: 50,
        sortable: false,
        filterable: false
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        sortable: false,
        filterable: false
      }
    ];

    return (
      <div>
        <PanelHeader size="sm" />
        <Query query={diamondsQuery} variables={{ where: { vendorId: { equals: null } }, orderBy: { createdAt: 'desc' } }}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) {
              return <p>Error :(</p>;
            }

            const tableData = data.diamonds.map((item) => ({
              ...item,
              actions: this.renderActions(item._id)
            }));

            return (
              <div className="content">
                <Row>
                  <Col xs={12} md={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Diamonds</CardTitle>
                        {/* create product button */}
                        <ActionBar>
                          <Button color="primary" onClick={(e) => this.handleCreateDiamond(e, { refetch })}>
                            <span><Icon name={faPlus} /> Add Diamond</span>
                          </Button>
                        </ActionBar>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          data={tableData}
                          filterable
                          columns={columns}
                          defaultPageSize={20}
                          showPaginationBottom
                          className="-striped -highlight"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

Diamonds.propTypes = {
  createDiamond: PropTypes.func.isRequired,
  history: PropTypes.object
};

export default withCreateDiamond(Diamonds);
