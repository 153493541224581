import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-v6';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Badge } from 'reactstrap';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import matchSorter from 'match-sorter';
import formatDate from 'date-fns/format';

import { PanelHeader, Button, DateFormat, Icon, Loading, UserIsInRole } from 'components';
import jobsQuery from 'graphql/jobs';
import jobDelete from 'graphql/jobDelete';
import { formatPrice, Alert } from 'lib/utils';

class Jobs extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  renderActions(_id) {
    return (
      <div className="actions-right">
        <Link to={`/jobs/${_id}`}>
          <Button
            color="primary"
            size="sm"
            icon
          >
            <Icon name={faEdit}/>
          </Button>
        </Link>
        &nbsp;
        <UserIsInRole roles="admin">
          <Mutation
            mutation={jobDelete}
            variables={{ _id }}
            refetchQueries={() => ([{ query: jobsQuery }])}
            onCompleted={() => Alert('Job deleted!', {
              icon: 'success',
              buttons: false,
              timer: 1500
            })}
            onError={(e) => Alert('Oh no!', {
              text: e.message.split('GraphQL error: ')[1],
              icon: 'error'
            })}
          >
            {(mutate) => (
              <Button
                onClick={() => {
                  Alert({
                    title: 'Are you sure?',
                    text: 'There\'s no going back!',
                    icon: 'warning',
                    dangerMode: true,
                    buttons: {
                      cancel: true,
                      confirm: 'Do it!'
                    }
                  }).then((confirmed) => confirmed && mutate());
                }}
                color="danger"
                size="sm"
                icon
              >
                <Icon name={faTimes}/>
              </Button>
            )}
          </Mutation>
          &nbsp;
        </UserIsInRole>
      </div>
    );
  }

  render() {
    const columns = [
      {
        Header: 'Completed?',
        accessor: 'completedAt',
        Cell: ({ value }) => value ? <Badge color="success">Complete</Badge> : <Badge color="warning">Not Complete</Badge>,
        maxWidth: 140,
        filterable: false
      },
      {
        Header: 'Date Promised',
        accessor: 'promisedAt',
        sortMethod: (a, b) => a > b ? 1 : -1,
        Cell: ({ value }) => value && <DateFormat date={value}/>,
        maxWidth: 150,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: [(item) => formatDate(item.createdAt, 'MMMM d, yyyy')] }),
        filterAll: true
      },
      {
        Header: 'Job ID',
        accessor: '_id',
        maxWidth: 220,
        sortable: false
      },
      {
        Header: 'Customer',
        accessor: 'user',
        Cell: ({ value }) => `${value.firstName} ${value.lastName} (${value.email})`,
        maxWidth: 360,
        sortable: false,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: [(item) => `${item.user.firstName} ${item.user.lastName} ${item.user.email}`] }),
        filterAll: true
      },
      {
        Header: 'Price',
        accessor: 'price',
        sortMethod: (a, b) => a > b ? 1 : -1,
        Cell: ({ value }) => formatPrice(value),
        maxWidth: 100,
        filterable: false
      },
      {
        Header: 'Status',
        accessor: 'status',
        maxWidth: 120,
        filterMethod: (filter, row) =>
          ~String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
        sortable: false,
        filterable: false
      }
    ];

    return (
      <div>
        <PanelHeader size="sm" />
        <Query
          query={jobsQuery}
          variables={{ pagination: { limit: 500 }, sort: { createdAt: -1 } }}
        >
          {({ loading, error, data }) => {

            if (loading) {
              return <Loading/>;
            }

            if (error) {
              return <p>Error :(</p>;
            }

            const tableData = data.jobs.map((item) => ({
              ...item,
              actions: this.renderActions(item._id)
            }));

            return (
              <div className="content">
                <Row>
                  <Col xs={12} md={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Jobs</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          data={tableData}
                          filterable
                          columns={columns}
                          defaultPageSize={10}
                          showPaginationBottom
                          className="-striped -highlight"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Jobs;
