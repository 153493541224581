import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { BulkImageUpload, Button } from 'components';
import withImagesRemove from 'containers/withImagesRemove';
import { notify } from 'lib/utils';

const Diamond360Images = ({ diamond, removeImages, refetch }) => (
  <div>
    <BulkImageUpload
      productType="diamond"
      productId={diamond.id}
      category="360"
      refetch={refetch}
    />
    <hr />
    <Row>
      <Col md={12}>
        {!!diamond.images.filter((img) => img.category === '360').length
        && (
        <div>
          <h4>Uploaded Images</h4>

          <Button
            color="danger"
            onClick={() => {
              // create an array of the image ID's we want to delete
              const imageIds = diamond.images.filter((img) => img.category === '360').map(({ id }) => id);

              // delete them!
              removeImages(imageIds)
                .then(() => {
                  refetch();
                  notify('success', `${imageIds.length} images deleted!`);
                })
                .catch((error) => notify('error', `Upload failed! \n ${error}`));
            }}
          >
            Delete all images
          </Button>

          {diamond.images.filter((img) => img.category === '360').map((image) => (
            <p key={image.id} style={{ marginBottom: '.5rem' }}>
              <a key={image.id} href={image.url} target="_blank" rel="noopener noreferrer">{image.filename}</a>
            </p>
          ))}
        </div>
        )}
      </Col>
    </Row>
  </div>
);

Diamond360Images.propTypes = {
  diamond: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  removeImages: PropTypes.func.isRequired
};

export default withImagesRemove(Diamond360Images);
