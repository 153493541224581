import gql from 'graphql-tag';

export default gql`
  mutation createProduct($data: ProductCreateInput!) {
    createProduct(data: $data) {
      id
      createdAt
    }
  }
`;
