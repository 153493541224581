import React from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';

const sendEmail = gql`
  mutation sendEmail($input: EmailSendInput!) {
    sendEmail(input: $input) {
      result
    }
  }
`;

export default (Component) => (
  class withSendEmail extends React.Component {
    render() {
      return (
        <Mutation mutation={sendEmail}>
          {(mutate) => <Component {...this.props} sendEmail={mutate} />}
        </Mutation>
      );
    }
  }
);
