import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';
import { Loading } from 'components';
import diamondQuery from 'graphql/diamondQuery';

export default (Component) => {
  // eslint-disable-next-line react/prefer-stateless-function
  class withDiamond extends React.Component {
    static propTypes = {
      match: PropTypes.object.isRequired
    }

    render() {
      const parentProps = this.props;
      return (
        <Query query={diamondQuery} variables={{ slugOrId: this.props.match.params._id }}>
          {({ error, loading, data, ...rest }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) {
              return (
                <div>
                  <p>Error :(</p>
                </div>
              );
            }

            return (
              <Component
                {...parentProps}
                data={data}
                {...rest}
              />
            );
          }}
        </Query>
      );
    }
  }

  return withDiamond;
};
