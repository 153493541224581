/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Row, Col, FormGroup, Label, Input, InputGroup, InputGroupAddon } from 'reactstrap';
// InputProps, InputGroupProps, InputGroupAddonProps, LabelProps, FormGroupProps } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactQuill from 'react-quill';
import Cleave from 'cleave.js/react';
import ImageThumbnail from '../Images/ImageThumbnail';
import ImageUpload from './ImageUpload';
import RangeInput from './RangeInput';

// interface Props {
//   addonLeft: any;
//   addonRight: any;
//   formGroupProps: FormGroupProps;
//   inputGroupAddonProps: InputGroupAddonProps;
//   inputGroupProps: InputGroupProps;
//   inputProps: InputProps;
//   label?: string;
//   labelProps?: LabelProps;
// }

interface Props {
  addonLeft: any;
  addonRight: any;
  formGroupProps: any;
  inputGroupAddonProps: any;
  inputGroupProps: any;
  inputProps: any;
  label: any;
  labelProps: any;
}

const FieldGroup: React.FC<Props> = (props) => {
  const [focus, setFocus] = useState(false);
  const { control } = useForm();

  const {
    label,
    addonLeft,
    addonRight,
    formGroupProps,
    labelProps,
    inputProps,
    inputGroupProps,
    inputGroupAddonProps
  } = props;

  let classes = ' ';

  if (inputGroupProps !== undefined) {
    if (inputGroupProps.className !== undefined) {
      classes += `${inputGroupProps.className} `;
    }
  }

  if (addonLeft !== undefined || addonRight !== undefined) {
    return (
      <InputGroup {...inputGroupProps} className={classes + (focus ? 'input-group-focus' : '')}>
        {addonLeft !== undefined ? <InputGroupAddon {...inputGroupAddonProps}>{addonLeft}</InputGroupAddon> : ''}
        <Input {...inputProps} onFocus={(): void => setFocus(true)} onBlur={(): void => setFocus(false)} />
        {addonRight !== undefined ? <InputGroupAddon {...inputGroupAddonProps}>{addonRight}</InputGroupAddon> : ''}
      </InputGroup>
    );
  }

  if (inputProps.type === 'checkbox') {
    return (
      <FormGroup check {...formGroupProps}>
        <Label check>
          <Input key={inputProps.name} type="checkbox" id={`${inputProps.name}`} {...inputProps} /> {label || ''}
          <span className="form-check-sign">
            <span className="check" />
          </span>
        </Label>
      </FormGroup>
    );
  }

  if (inputProps.type === 'radio') {
    return (
      <FormGroup {...formGroupProps}>
        <Label>{label || ''}</Label>
        <br />
        {inputProps.options.map((item, i) => {
          const { label: radioLabel, value } = item;
          const checked = inputProps.defaultValue === value;
          return (
            <div key={i} className="form-check form-check-inline">
              <Input
                key={`${inputProps.name}_${i}`}
                type="radio"
                id={`${inputProps.name}_${i}`}
                className="form-check-input"
                value={value}
                checked={checked}
                {..._.omit(inputProps, ['defaultValue', 'options'])}
              />
              <Label for={`${inputProps.name}_${i}`} {...labelProps}>
                {radioLabel || ''}
              </Label>
            </div>
          );
        })}
      </FormGroup>
    );
  }

  if (inputProps.type === 'select') {
    return (
      <FormGroup {...formGroupProps}>
        {label ? <Label {...labelProps}>{label}</Label> : ''}
        {inputProps.isCreateable ? (
          <Controller
            as={CreatableSelect}
            key={inputProps.name}
            control={inputProps.control || control}
            {...inputProps}
          />
        ) : (
          <Controller as={Select} key={inputProps.name} control={inputProps.control || control} {...inputProps} />
        )}
      </FormGroup>
    );
  }

  // React Range input
  // https://github.com/tajo/react-range
  if (inputProps.type === 'range') {
    return (
      <FormGroup {...formGroupProps}>
        {label ? <Label {...labelProps}>{label}</Label> : ''}
        <RangeInput {...inputProps} />
      </FormGroup>
    );
  }

  // React Quill toolbar config options
  // https://quilljs.com/docs/modules/toolbar/
  if (inputProps.type === 'wysiwyg') {
    return (
      <FormGroup {...formGroupProps}>
        {label ? <Label {...labelProps}>{label}</Label> : ''}
        <ReactQuill
          {...inputProps}
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['link'],
              ['blockquote'],
              [{ color: [] }],
              [{ align: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['image']
              // [{ font: [] }]
            ]
          }}
        />
      </FormGroup>
    );
  }

  if (inputProps.type === 'currency') {
    delete inputProps.type;
    return (
      <FormGroup {...formGroupProps}>
        {label ? <Label {...labelProps}>{label}</Label> : ''}
        <Controller
          key={inputProps.name}
          control={inputProps.control || control}
          render={({ onChange, onBlur, value }): any => (
            <Cleave
              className="form-control"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                numeralDecimalScale: 2,
                prefix: '$',
                rawValueTrimPrefix: true,
                numericOnly: true
              }}
              onChange={(e): void => onChange(e.target.rawValue || 0)}
              onBlur={onBlur}
              value={value}
            />
          )}
          {...inputProps}
        />
      </FormGroup>
    );
  }

  if (inputProps.type === 'imageUpload') {
    delete inputProps.type;
    return inputProps.image ? (
      <ImageThumbnail image={inputProps.image} {...inputProps.thumbnailProps} />
    ) : (
      <FormGroup {...formGroupProps}>
        {label ? <Label {...labelProps}>{label}</Label> : ''}
        <ImageUpload {...inputProps} />
      </FormGroup>
    );
  }

  return (
    <FormGroup {...formGroupProps}>
      {label ? <Label {...labelProps}>{label}</Label> : ''}
      <Input {...inputProps} />
    </FormGroup>
  );
};

const FormInputs: React.FC<{ inputs: any }> = ({ inputs }) => (
  <Row>
    {inputs.map((input, i: number) => (
      <Col key={i} {...input.colProps}>
        <FieldGroup {...input} />
      </Col>
    ))}
  </Row>
);

FormInputs.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.object)
};

export default FormInputs;
