import gql from 'graphql-tag';

export default gql`
  mutation updateStaticPage($_id: ObjID! $input: StaticPageUpdateInput!) {
    updateStaticPage(_id: $_id, input: $input) {
      _id
      updatedAt
    }
  }
`;
