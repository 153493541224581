import React from 'react';
import { Row, Col, Form, FormGroup, Label, Button } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { FormInputs, RightAligned } from 'components';
import { Product, useUpdateProductMutation } from 'graphql/codegen';
import { notify } from 'lib/utils';

interface Props {
  product: Product;
  refetch?: () => void;
}

interface FormData {
  video: {
    code: string;
    title: string;
    source: {
      label: string;
      value: string;
    }
  };
}

const JewelryVideo: React.FC<Props> = ({ product, refetch }) => {
  const { handleSubmit, errors: formErrors, control, register } = useForm<FormData>();

  const [updateProduct, { error: updateProductError }] = useUpdateProductMutation();

  // onSubmit handler
  const onSubmit = (d: FormData): void => {
    updateProduct({
      variables: {
        where: { id: product.id },
        data: {
          video: {
            upsert: {
              create: {
                source: d.video.source.value,
                code: d.video.code,
                title: d.video.title
              },
              update: {
                source: { set: d.video.source.value },
                code: { set: d.video.code },
                title: { set: d.video.title }
              }
            }
          }
        }
      }
    }).then(() => {
      if (refetch) {
        refetch();
      }
      notify('success', 'Video saved!');
    }).catch(() => {
      notify('error', 'Video save error! (see console)');
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {updateProductError && <pre>{JSON.stringify(updateProductError, null, 2)}</pre>}
      <Row>
        <Col md={{ size: 6, offset: 1 }}>
          {formErrors.video?.source
            ? <div style={{ color: 'red', textAlign: 'center' }}>Video source is required!</div> : null}
          {formErrors.video?.code
            ? <div style={{ color: 'red', textAlign: 'center' }}>Video code is required!</div> : null}
          <FormGroup>
            <Label>Video Source</Label>
            <Controller
                name="video.source"
                as={Select}
                control={control}
                options={[{
                  label: '',
                  value: ''
                }, {
                  label: 'Youtube',
                  value: 'Youtube'
                }, {
                  label: 'Vimeo',
                  value: 'Vimeo'
                }, {
                  label: 'MP4',
                  value: 'MP4'
                }]}
                defaultValue={{ label: product.video?.source, value: product.video?.source }}
                rules={{ required: true }}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormInputs
        inputs={[
          {
            label: 'Video Code/URL',
            colProps: {
              md: { size: 6, offset: 1 }
            },
            inputProps: {
              type: 'text',
              name: 'video.code',
              innerRef: register({ required: true }),
              defaultValue: product.video?.code
            }
          },
          {
            label: 'Video Title',
            colProps: {
              md: { size: 6, offset: 1 }
            },
            inputProps: {
              type: 'text',
              name: 'video.title',
              innerRef: register,
              defaultValue: product.video?.title
            }
          }
        ]}
      />
      <Row>
        <Col md={{ size: 6, offset: 1 }}>
          <RightAligned>
            <Button color="primary" type="submit" onSubmit={handleSubmit(onSubmit)}>Save Video</Button>
          </RightAligned>
        </Col>
      </Row>
    </Form>
  );
};

export default JewelryVideo;
