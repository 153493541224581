import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import PrintProvider, { NoPrint } from 'react-easy-print';
import { QueryParamProvider } from 'use-query-params';
import { Toaster } from 'react-hot-toast';
import Dashboard from './layouts/Dashboard';
import config from './config';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/main.css';
import './assets/css/tailwinds.css';
import 'react-toggle/style.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

const hist = createBrowserHistory();

const httpLink = createHttpLink({
  uri: `${config.graphqlUrl}/graphql`
});

// error handling for Apollo Link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // eslint-disable-next-line no-console
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(path)}`
      );
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // eslint-disable-next-line no-undef
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const requestLink = ApolloLink.from([authLink, errorLink, httpLink]);

const isFile = (value: any): boolean =>
  (typeof File !== 'undefined' && value instanceof File) ||
  (Array.isArray(value) && !!value.filter((v) => v instanceof File).length) ||
  (typeof Blob !== 'undefined' && value instanceof Blob);

const isUpload = ({ variables }): boolean => Object.values(variables).some(isFile);

const uploadLink = ApolloLink.from([
  authLink,
  errorLink,
  createUploadLink({
    uri: `${config.graphqlUrl}/graphql`
  })
]);

const client = new ApolloClient({
  link: ApolloLink.split(isUpload, uploadLink, requestLink),
  cache: new InMemoryCache()
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <PrintProvider>
      <NoPrint>
        <Router history={hist}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <Route path="/" component={Dashboard} />
          </QueryParamProvider>
        </Router>
      </NoPrint>
    </PrintProvider>
    <Toaster />
  </ApolloProvider>,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);
