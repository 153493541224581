import gql from 'graphql-tag';

export default gql`
  mutation updateJob($_id: ObjID! $input: JobInput!) {
    updateJob(_id: $_id, input: $input) {
      _id
      updatedAt
    }
  }
`;
