import React from 'react';
import { Mutation } from '@apollo/react-components';
import workOrderItemCreate from 'graphql/workOrderItemCreate';

export default (Component) => (
  class withWorkOrderItemCreate extends React.Component {
    render() {
      return (
        <Mutation mutation={workOrderItemCreate}>
          {(mutate) => <Component {...this.props} createWorkOrderItem={mutate} />}
        </Mutation>
      );
    }
  }
);
