import gql from 'graphql-tag';

export default gql`
  query users($filter: JSON, $sort: JSON, $pagination: JSON) {
    users(filter: $filter, sort: $sort, pagination: $pagination) {
      _id
      firstName
      lastName
      email
      createdAt
    }
  }
`;
