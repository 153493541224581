import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** Id string representation of MongoDB Object Ids */
  ObjID: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type Availability = {
  __typename?: 'Availability';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type AvailabilityCreateNestedOneWithoutProductInput = {
  connect?: Maybe<AvailabilityWhereUniqueInput>;
  connectOrCreate?: Maybe<AvailabilityCreateOrConnectWithoutProductInput>;
  create?: Maybe<AvailabilityCreateWithoutProductInput>;
};

export type AvailabilityCreateOrConnectWithoutProductInput = {
  create: AvailabilityCreateWithoutProductInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type AvailabilityOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type AvailabilityUpdateOneWithoutProductInput = {
  connect?: Maybe<AvailabilityWhereUniqueInput>;
  connectOrCreate?: Maybe<AvailabilityCreateOrConnectWithoutProductInput>;
  create?: Maybe<AvailabilityCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AvailabilityUpdateWithoutProductInput>;
  upsert?: Maybe<AvailabilityUpsertWithoutProductInput>;
};

export type AvailabilityUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AvailabilityUpsertWithoutProductInput = {
  create: AvailabilityCreateWithoutProductInput;
  update: AvailabilityUpdateWithoutProductInput;
};

export type AvailabilityWhereInput = {
  AND?: Maybe<Array<AvailabilityWhereInput>>;
  NOT?: Maybe<Array<AvailabilityWhereInput>>;
  OR?: Maybe<Array<AvailabilityWhereInput>>;
  Product?: Maybe<ProductListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type AvailabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BandType = {
  __typename?: 'BandType';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type BandTypeCreateNestedOneWithoutProductInput = {
  connect?: Maybe<BandTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<BandTypeCreateOrConnectWithoutProductInput>;
  create?: Maybe<BandTypeCreateWithoutProductInput>;
};

export type BandTypeCreateOrConnectWithoutProductInput = {
  create: BandTypeCreateWithoutProductInput;
  where: BandTypeWhereUniqueInput;
};

export type BandTypeCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type BandTypeOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type BandTypeUpdateOneWithoutProductInput = {
  connect?: Maybe<BandTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<BandTypeCreateOrConnectWithoutProductInput>;
  create?: Maybe<BandTypeCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BandTypeUpdateWithoutProductInput>;
  upsert?: Maybe<BandTypeUpsertWithoutProductInput>;
};

export type BandTypeUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type BandTypeUpsertWithoutProductInput = {
  create: BandTypeCreateWithoutProductInput;
  update: BandTypeUpdateWithoutProductInput;
};

export type BandTypeWhereInput = {
  AND?: Maybe<Array<BandTypeWhereInput>>;
  NOT?: Maybe<Array<BandTypeWhereInput>>;
  OR?: Maybe<Array<BandTypeWhereInput>>;
  Product?: Maybe<ProductListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type BandTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type Category = {
  __typename?: 'Category';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  products: Array<Product>;
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type CategoryProductsArgs = {
  after?: Maybe<ProductWhereUniqueInput>;
  before?: Maybe<ProductWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProductOrderByInput>>;
  where?: Maybe<ProductWhereInput>;
};

export type CategoryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  products?: Maybe<ProductCreateNestedManyWithoutCategoriesInput>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryCreateNestedManyWithoutProductsInput = {
  connect?: Maybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CategoryCreateOrConnectWithoutProductsInput>>;
  create?: Maybe<Array<CategoryCreateWithoutProductsInput>>;
};

export type CategoryCreateOrConnectWithoutProductsInput = {
  create: CategoryCreateWithoutProductsInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateWithoutProductsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryListRelationFilter = {
  every?: Maybe<CategoryWhereInput>;
  none?: Maybe<CategoryWhereInput>;
  some?: Maybe<CategoryWhereInput>;
};

export type CategoryOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CategoryScalarWhereInput = {
  AND?: Maybe<Array<CategoryScalarWhereInput>>;
  NOT?: Maybe<Array<CategoryScalarWhereInput>>;
  OR?: Maybe<Array<CategoryScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CategoryUpdateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutCategoriesInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateManyWithoutProductsInput = {
  connect?: Maybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CategoryCreateOrConnectWithoutProductsInput>>;
  create?: Maybe<Array<CategoryCreateWithoutProductsInput>>;
  delete?: Maybe<Array<CategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<CategoryWhereUniqueInput>>;
  set?: Maybe<Array<CategoryWhereUniqueInput>>;
  update?: Maybe<Array<CategoryUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: Maybe<Array<CategoryUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: Maybe<Array<CategoryUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type CategoryUpdateManyWithWhereWithoutProductsInput = {
  data: CategoryUpdateManyMutationInput;
  where: CategoryScalarWhereInput;
};

export type CategoryUpdateWithoutProductsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithWhereUniqueWithoutProductsInput = {
  data: CategoryUpdateWithoutProductsInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpsertWithWhereUniqueWithoutProductsInput = {
  create: CategoryCreateWithoutProductsInput;
  update: CategoryUpdateWithoutProductsInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryWhereInput = {
  AND?: Maybe<Array<CategoryWhereInput>>;
  NOT?: Maybe<Array<CategoryWhereInput>>;
  OR?: Maybe<Array<CategoryWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  products?: Maybe<ProductListRelationFilter>;
  slug?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type Clarity = {
  __typename?: 'Clarity';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ClarityCreateNestedOneWithoutDiamondInput = {
  connect?: Maybe<ClarityWhereUniqueInput>;
  connectOrCreate?: Maybe<ClarityCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<ClarityCreateWithoutDiamondInput>;
};

export type ClarityCreateOrConnectWithoutDiamondInput = {
  create: ClarityCreateWithoutDiamondInput;
  where: ClarityWhereUniqueInput;
};

export type ClarityCreateWithoutDiamondInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ClarityOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ClarityUpdateOneWithoutDiamondInput = {
  connect?: Maybe<ClarityWhereUniqueInput>;
  connectOrCreate?: Maybe<ClarityCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<ClarityCreateWithoutDiamondInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClarityUpdateWithoutDiamondInput>;
  upsert?: Maybe<ClarityUpsertWithoutDiamondInput>;
};

export type ClarityUpdateWithoutDiamondInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ClarityUpsertWithoutDiamondInput = {
  create: ClarityCreateWithoutDiamondInput;
  update: ClarityUpdateWithoutDiamondInput;
};

export type ClarityWhereInput = {
  AND?: Maybe<Array<ClarityWhereInput>>;
  Diamond?: Maybe<DiamondListRelationFilter>;
  NOT?: Maybe<Array<ClarityWhereInput>>;
  OR?: Maybe<Array<ClarityWhereInput>>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type ClarityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Collection = {
  __typename?: 'Collection';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  products: Array<Product>;
  showInCatalog: Scalars['Boolean'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type CollectionProductsArgs = {
  after?: Maybe<ProductWhereUniqueInput>;
  before?: Maybe<ProductWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProductOrderByInput>>;
  where?: Maybe<ProductWhereInput>;
};

export type CollectionCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  products?: Maybe<ProductCreateNestedManyWithoutCollectionsInput>;
  showInCatalog?: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CollectionCreateNestedManyWithoutProductsInput = {
  connect?: Maybe<Array<CollectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CollectionCreateOrConnectWithoutProductsInput>>;
  create?: Maybe<Array<CollectionCreateWithoutProductsInput>>;
};

export type CollectionCreateOrConnectWithoutProductsInput = {
  create: CollectionCreateWithoutProductsInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionCreateWithoutProductsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  showInCatalog?: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CollectionListRelationFilter = {
  every?: Maybe<CollectionWhereInput>;
  none?: Maybe<CollectionWhereInput>;
  some?: Maybe<CollectionWhereInput>;
};

export type CollectionOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  showInCatalog?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CollectionScalarWhereInput = {
  AND?: Maybe<Array<CollectionScalarWhereInput>>;
  NOT?: Maybe<Array<CollectionScalarWhereInput>>;
  OR?: Maybe<Array<CollectionScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  showInCatalog?: Maybe<BoolFilter>;
  slug?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CollectionUpdateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutCollectionsInput>;
  showInCatalog?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CollectionUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  showInCatalog?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CollectionUpdateManyWithoutProductsInput = {
  connect?: Maybe<Array<CollectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CollectionCreateOrConnectWithoutProductsInput>>;
  create?: Maybe<Array<CollectionCreateWithoutProductsInput>>;
  delete?: Maybe<Array<CollectionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CollectionScalarWhereInput>>;
  disconnect?: Maybe<Array<CollectionWhereUniqueInput>>;
  set?: Maybe<Array<CollectionWhereUniqueInput>>;
  update?: Maybe<Array<CollectionUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: Maybe<Array<CollectionUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: Maybe<Array<CollectionUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type CollectionUpdateManyWithWhereWithoutProductsInput = {
  data: CollectionUpdateManyMutationInput;
  where: CollectionScalarWhereInput;
};

export type CollectionUpdateWithoutProductsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  showInCatalog?: Maybe<BoolFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CollectionUpdateWithWhereUniqueWithoutProductsInput = {
  data: CollectionUpdateWithoutProductsInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionUpsertWithWhereUniqueWithoutProductsInput = {
  create: CollectionCreateWithoutProductsInput;
  update: CollectionUpdateWithoutProductsInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionWhereInput = {
  AND?: Maybe<Array<CollectionWhereInput>>;
  NOT?: Maybe<Array<CollectionWhereInput>>;
  OR?: Maybe<Array<CollectionWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  products?: Maybe<ProductListRelationFilter>;
  showInCatalog?: Maybe<BoolFilter>;
  slug?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CollectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type Color = {
  __typename?: 'Color';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ColorCreateNestedOneWithoutDiamondInput = {
  connect?: Maybe<ColorWhereUniqueInput>;
  connectOrCreate?: Maybe<ColorCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<ColorCreateWithoutDiamondInput>;
};

export type ColorCreateOrConnectWithoutDiamondInput = {
  create: ColorCreateWithoutDiamondInput;
  where: ColorWhereUniqueInput;
};

export type ColorCreateWithoutDiamondInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ColorOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ColorUpdateOneWithoutDiamondInput = {
  connect?: Maybe<ColorWhereUniqueInput>;
  connectOrCreate?: Maybe<ColorCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<ColorCreateWithoutDiamondInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ColorUpdateWithoutDiamondInput>;
  upsert?: Maybe<ColorUpsertWithoutDiamondInput>;
};

export type ColorUpdateWithoutDiamondInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ColorUpsertWithoutDiamondInput = {
  create: ColorCreateWithoutDiamondInput;
  update: ColorUpdateWithoutDiamondInput;
};

export type ColorWhereInput = {
  AND?: Maybe<Array<ColorWhereInput>>;
  Diamond?: Maybe<DiamondListRelationFilter>;
  NOT?: Maybe<Array<ColorWhereInput>>;
  OR?: Maybe<Array<ColorWhereInput>>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type ColorWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInvite?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  billingIsSame?: Maybe<Scalars['Boolean']>;
  billingAddress?: Maybe<UserAddressInput>;
  shippingAddress?: Maybe<UserAddressInput>;
};

export type CreditCardPaymentInput = {
  nonce: Scalars['String'];
};

export type Culet = {
  __typename?: 'Culet';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type CuletCreateNestedOneWithoutDiamondInput = {
  connect?: Maybe<CuletWhereUniqueInput>;
  connectOrCreate?: Maybe<CuletCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<CuletCreateWithoutDiamondInput>;
};

export type CuletCreateOrConnectWithoutDiamondInput = {
  create: CuletCreateWithoutDiamondInput;
  where: CuletWhereUniqueInput;
};

export type CuletCreateWithoutDiamondInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type CuletOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type CuletUpdateOneWithoutDiamondInput = {
  connect?: Maybe<CuletWhereUniqueInput>;
  connectOrCreate?: Maybe<CuletCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<CuletCreateWithoutDiamondInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CuletUpdateWithoutDiamondInput>;
  upsert?: Maybe<CuletUpsertWithoutDiamondInput>;
};

export type CuletUpdateWithoutDiamondInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CuletUpsertWithoutDiamondInput = {
  create: CuletCreateWithoutDiamondInput;
  update: CuletUpdateWithoutDiamondInput;
};

export type CuletWhereInput = {
  AND?: Maybe<Array<CuletWhereInput>>;
  Diamond?: Maybe<DiamondListRelationFilter>;
  NOT?: Maybe<Array<CuletWhereInput>>;
  OR?: Maybe<Array<CuletWhereInput>>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type CuletWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CustomDesignRequest = {
  __typename?: 'CustomDesignRequest';
  _id: Scalars['ObjID'];
  userId?: Maybe<Scalars['ObjID']>;
  user?: Maybe<User>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  designs?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  skuOrTitle?: Maybe<Scalars['String']>;
  metal?: Maybe<Scalars['String']>;
  budgetFrom?: Maybe<Scalars['Int']>;
  budgetTo?: Maybe<Scalars['Int']>;
  timeframe?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  caratFrom?: Maybe<Scalars['String']>;
  caratTo?: Maybe<Scalars['String']>;
  clarityFrom?: Maybe<Scalars['String']>;
  clarityTo?: Maybe<Scalars['String']>;
  colorTo?: Maybe<Scalars['String']>;
  colorFrom?: Maybe<Scalars['String']>;
  cut?: Maybe<Scalars['String']>;
  fingerSize?: Maybe<Scalars['Float']>;
  ringType?: Maybe<Scalars['String']>;
  prongs?: Maybe<Scalars['String']>;
  shank?: Maybe<Scalars['String']>;
  shankThickness?: Maybe<Scalars['String']>;
  shape?: Maybe<Scalars['String']>;
  pave?: Maybe<Scalars['String']>;
  quote?: Maybe<Quote>;
  isCenterstoneRequired?: Maybe<Scalars['Boolean']>;
  hasCenterstone?: Maybe<Scalars['Boolean']>;
  isEngravingRequired?: Maybe<Scalars['Boolean']>;
  isFiligreeRequired?: Maybe<Scalars['Boolean']>;
  isPaveRequired?: Maybe<Scalars['Boolean']>;
  ipAddress?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  remoteAddress?: Maybe<Scalars['String']>;
};

export type CustomDesignRequestCreateInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  designs?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  skuOrTitle: Scalars['String'];
  metal?: Maybe<Scalars['String']>;
  budgetFrom?: Maybe<Scalars['Int']>;
  budgetTo?: Maybe<Scalars['Int']>;
  timeframe?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  caratFrom?: Maybe<Scalars['Float']>;
  caratTo?: Maybe<Scalars['Float']>;
  clarityFrom?: Maybe<Scalars['String']>;
  clarityTo?: Maybe<Scalars['String']>;
  colorTo?: Maybe<Scalars['String']>;
  colorFrom?: Maybe<Scalars['String']>;
  cut?: Maybe<Scalars['String']>;
  fingerSize?: Maybe<Scalars['Float']>;
  ringType?: Maybe<Scalars['String']>;
  prongs?: Maybe<Scalars['String']>;
  shank?: Maybe<Scalars['String']>;
  shankThickness?: Maybe<Scalars['String']>;
  shape?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  pave?: Maybe<Scalars['String']>;
  isCenterstoneRequired: Scalars['Boolean'];
  hasCenterstone: Scalars['Boolean'];
  isEngravingRequired?: Maybe<Scalars['Boolean']>;
  isFiligreeRequired?: Maybe<Scalars['Boolean']>;
  isPaveRequired?: Maybe<Scalars['Boolean']>;
};

export type CustomDesignRequestUpdateInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  designs?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  skuOrTitle?: Maybe<Scalars['String']>;
  metal?: Maybe<Scalars['String']>;
  budgetFrom?: Maybe<Scalars['Int']>;
  budgetTo?: Maybe<Scalars['Int']>;
  timeframe?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  caratFrom?: Maybe<Scalars['Float']>;
  caratTo?: Maybe<Scalars['Float']>;
  clarityFrom?: Maybe<Scalars['String']>;
  clarityTo?: Maybe<Scalars['String']>;
  colorTo?: Maybe<Scalars['String']>;
  colorFrom?: Maybe<Scalars['String']>;
  cut?: Maybe<Scalars['String']>;
  fingerSize?: Maybe<Scalars['Float']>;
  ringType?: Maybe<Scalars['String']>;
  prongs?: Maybe<Scalars['String']>;
  shank?: Maybe<Scalars['String']>;
  shankThickness?: Maybe<Scalars['String']>;
  shape?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  pave?: Maybe<Scalars['String']>;
  isCenterstoneRequired?: Maybe<Scalars['Boolean']>;
  hasCenterstone?: Maybe<Scalars['Boolean']>;
  isEngravingRequired?: Maybe<Scalars['Boolean']>;
  isFiligreeRequired?: Maybe<Scalars['Boolean']>;
  isPaveRequired?: Maybe<Scalars['Boolean']>;
};

export type Cut = {
  __typename?: 'Cut';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type CutCreateNestedOneWithoutDiamondInput = {
  connect?: Maybe<CutWhereUniqueInput>;
  connectOrCreate?: Maybe<CutCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<CutCreateWithoutDiamondInput>;
};

export type CutCreateOrConnectWithoutDiamondInput = {
  create: CutCreateWithoutDiamondInput;
  where: CutWhereUniqueInput;
};

export type CutCreateWithoutDiamondInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type CutOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type CutUpdateOneWithoutDiamondInput = {
  connect?: Maybe<CutWhereUniqueInput>;
  connectOrCreate?: Maybe<CutCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<CutCreateWithoutDiamondInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CutUpdateWithoutDiamondInput>;
  upsert?: Maybe<CutUpsertWithoutDiamondInput>;
};

export type CutUpdateWithoutDiamondInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CutUpsertWithoutDiamondInput = {
  create: CutCreateWithoutDiamondInput;
  update: CutUpdateWithoutDiamondInput;
};

export type CutWhereInput = {
  AND?: Maybe<Array<CutWhereInput>>;
  Diamond?: Maybe<DiamondListRelationFilter>;
  NOT?: Maybe<Array<CutWhereInput>>;
  OR?: Maybe<Array<CutWhereInput>>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type CutWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};


export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type Diamond = {
  __typename?: 'Diamond';
  autopopulateDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaTitle?: Maybe<Scalars['Boolean']>;
  carat: Scalars['Float'];
  clarity?: Maybe<Clarity>;
  clarityId?: Maybe<Scalars['String']>;
  color?: Maybe<Color>;
  colorId?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  crownAngle?: Maybe<Scalars['String']>;
  culet?: Maybe<Culet>;
  culetId?: Maybe<Scalars['String']>;
  cut?: Maybe<Cut>;
  cutId?: Maybe<Scalars['String']>;
  depthMM?: Maybe<Scalars['Float']>;
  depthPercent?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fluorescence?: Maybe<Fluorescence>;
  fluorescenceId?: Maybe<Scalars['String']>;
  girdles: Array<Girdle>;
  id: Scalars['String'];
  images: Array<Image>;
  images360IFrameUrl?: Maybe<Scalars['String']>;
  images360VideoUrl?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetail>;
  lengthMM?: Maybe<Scalars['String']>;
  lightPerformance?: Maybe<LightPerformance>;
  lightPerformanceId?: Maybe<Scalars['String']>;
  lowerGirdleFacetPercent?: Maybe<Scalars['String']>;
  meta?: Maybe<MetaData>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  pavilionAngle?: Maybe<Scalars['String']>;
  polish?: Maybe<Polish>;
  polishId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  quantity: Scalars['Float'];
  representativeImage?: Maybe<Scalars['String']>;
  salePrice?: Maybe<Scalars['Int']>;
  shape?: Maybe<DiamondShape>;
  shapeId?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  starLengthPercent?: Maybe<Scalars['String']>;
  symmetry?: Maybe<Symmetry>;
  symmetryId?: Maybe<Scalars['String']>;
  tablePercent?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars['String']>;
  widthMM?: Maybe<Scalars['String']>;
};


export type DiamondImagesArgs = {
  after?: Maybe<ImageWhereUniqueInput>;
  before?: Maybe<ImageWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ImageOrderByInput>>;
  where?: Maybe<ImageWhereInput>;
};

export type DiamondCreateInput = {
  autopopulateDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaTitle?: Maybe<Scalars['Boolean']>;
  carat: Scalars['Float'];
  clarity?: Maybe<ClarityCreateNestedOneWithoutDiamondInput>;
  color?: Maybe<ColorCreateNestedOneWithoutDiamondInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crownAngle?: Maybe<Scalars['String']>;
  culet?: Maybe<CuletCreateNestedOneWithoutDiamondInput>;
  cut?: Maybe<CutCreateNestedOneWithoutDiamondInput>;
  depthMM?: Maybe<Scalars['Float']>;
  depthPercent?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fluorescence?: Maybe<FluorescenceCreateNestedOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleCreateNestedManyWithoutDiamondsInput>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutDiamondInput>;
  images360IFrameUrl?: Maybe<Scalars['String']>;
  images360VideoUrl?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutDiamondInput>;
  lengthMM?: Maybe<Scalars['String']>;
  lightPerformance?: Maybe<LightPerformanceCreateNestedOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<Scalars['String']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutDiamondInput>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  pavilionAngle?: Maybe<Scalars['String']>;
  polish?: Maybe<PolishCreateNestedOneWithoutDiamondInput>;
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Int']>;
  shape?: Maybe<DiamondShapeCreateNestedOneWithoutDiamondInput>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  starLengthPercent?: Maybe<Scalars['String']>;
  symmetry?: Maybe<SymmetryCreateNestedOneWithoutDiamondInput>;
  tablePercent?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<VendorCreateNestedOneWithoutDiamondsInput>;
  widthMM?: Maybe<Scalars['String']>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutDiamondsInput>;
};

export type DiamondCreateManyShapeInput = {
  autopopulateDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaTitle?: Maybe<Scalars['Boolean']>;
  carat: Scalars['Float'];
  clarityId?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crownAngle?: Maybe<Scalars['String']>;
  culetId?: Maybe<Scalars['String']>;
  cutId?: Maybe<Scalars['String']>;
  depthMM?: Maybe<Scalars['Float']>;
  depthPercent?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fluorescenceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  images360IFrameUrl?: Maybe<Scalars['String']>;
  images360VideoUrl?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  lengthMM?: Maybe<Scalars['String']>;
  lightPerformanceId?: Maybe<Scalars['String']>;
  lowerGirdleFacetPercent?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  pavilionAngle?: Maybe<Scalars['String']>;
  polishId?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Int']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  starLengthPercent?: Maybe<Scalars['String']>;
  symmetryId?: Maybe<Scalars['String']>;
  tablePercent?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['String']>;
  widthMM?: Maybe<Scalars['String']>;
};

export type DiamondCreateManyShapeInputEnvelope = {
  data?: Maybe<Array<DiamondCreateManyShapeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DiamondCreateManyVendorInput = {
  autopopulateDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaTitle?: Maybe<Scalars['Boolean']>;
  carat: Scalars['Float'];
  clarityId?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crownAngle?: Maybe<Scalars['String']>;
  culetId?: Maybe<Scalars['String']>;
  cutId?: Maybe<Scalars['String']>;
  depthMM?: Maybe<Scalars['Float']>;
  depthPercent?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fluorescenceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  images360IFrameUrl?: Maybe<Scalars['String']>;
  images360VideoUrl?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  lengthMM?: Maybe<Scalars['String']>;
  lightPerformanceId?: Maybe<Scalars['String']>;
  lowerGirdleFacetPercent?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  pavilionAngle?: Maybe<Scalars['String']>;
  polishId?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Int']>;
  shapeId?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  starLengthPercent?: Maybe<Scalars['String']>;
  symmetryId?: Maybe<Scalars['String']>;
  tablePercent?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  widthMM?: Maybe<Scalars['String']>;
};

export type DiamondCreateManyVendorInputEnvelope = {
  data?: Maybe<Array<DiamondCreateManyVendorInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DiamondCreateNestedManyWithoutShapeInput = {
  connect?: Maybe<Array<DiamondWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DiamondCreateOrConnectWithoutShapeInput>>;
  create?: Maybe<Array<DiamondCreateWithoutShapeInput>>;
  createMany?: Maybe<DiamondCreateManyShapeInputEnvelope>;
};

export type DiamondCreateNestedManyWithoutVendorInput = {
  connect?: Maybe<Array<DiamondWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DiamondCreateOrConnectWithoutVendorInput>>;
  create?: Maybe<Array<DiamondCreateWithoutVendorInput>>;
  createMany?: Maybe<DiamondCreateManyVendorInputEnvelope>;
};

export type DiamondCreateNestedManyWithoutWishlistsInput = {
  connect?: Maybe<Array<DiamondWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DiamondCreateOrConnectWithoutWishlistsInput>>;
  create?: Maybe<Array<DiamondCreateWithoutWishlistsInput>>;
};

export type DiamondCreateNestedOneWithoutImagesInput = {
  connect?: Maybe<DiamondWhereUniqueInput>;
  connectOrCreate?: Maybe<DiamondCreateOrConnectWithoutImagesInput>;
  create?: Maybe<DiamondCreateWithoutImagesInput>;
};

export type DiamondCreateNestedOneWithoutLabInput = {
  connect?: Maybe<DiamondWhereUniqueInput>;
  connectOrCreate?: Maybe<DiamondCreateOrConnectWithoutLabInput>;
  create?: Maybe<DiamondCreateWithoutLabInput>;
};

export type DiamondCreateNestedOneWithoutMetaInput = {
  connect?: Maybe<DiamondWhereUniqueInput>;
  connectOrCreate?: Maybe<DiamondCreateOrConnectWithoutMetaInput>;
  create?: Maybe<DiamondCreateWithoutMetaInput>;
};

export type DiamondCreateOrConnectWithoutImagesInput = {
  create: DiamondCreateWithoutImagesInput;
  where: DiamondWhereUniqueInput;
};

export type DiamondCreateOrConnectWithoutLabInput = {
  create: DiamondCreateWithoutLabInput;
  where: DiamondWhereUniqueInput;
};

export type DiamondCreateOrConnectWithoutMetaInput = {
  create: DiamondCreateWithoutMetaInput;
  where: DiamondWhereUniqueInput;
};

export type DiamondCreateOrConnectWithoutShapeInput = {
  create: DiamondCreateWithoutShapeInput;
  where: DiamondWhereUniqueInput;
};

export type DiamondCreateOrConnectWithoutVendorInput = {
  create: DiamondCreateWithoutVendorInput;
  where: DiamondWhereUniqueInput;
};

export type DiamondCreateOrConnectWithoutWishlistsInput = {
  create: DiamondCreateWithoutWishlistsInput;
  where: DiamondWhereUniqueInput;
};

export type DiamondCreateWithoutImagesInput = {
  autopopulateDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaTitle?: Maybe<Scalars['Boolean']>;
  carat: Scalars['Float'];
  clarity?: Maybe<ClarityCreateNestedOneWithoutDiamondInput>;
  color?: Maybe<ColorCreateNestedOneWithoutDiamondInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crownAngle?: Maybe<Scalars['String']>;
  culet?: Maybe<CuletCreateNestedOneWithoutDiamondInput>;
  cut?: Maybe<CutCreateNestedOneWithoutDiamondInput>;
  depthMM?: Maybe<Scalars['Float']>;
  depthPercent?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fluorescence?: Maybe<FluorescenceCreateNestedOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleCreateNestedManyWithoutDiamondsInput>;
  id?: Maybe<Scalars['String']>;
  images360IFrameUrl?: Maybe<Scalars['String']>;
  images360VideoUrl?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutDiamondInput>;
  lengthMM?: Maybe<Scalars['String']>;
  lightPerformance?: Maybe<LightPerformanceCreateNestedOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<Scalars['String']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutDiamondInput>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  pavilionAngle?: Maybe<Scalars['String']>;
  polish?: Maybe<PolishCreateNestedOneWithoutDiamondInput>;
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Int']>;
  shape?: Maybe<DiamondShapeCreateNestedOneWithoutDiamondInput>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  starLengthPercent?: Maybe<Scalars['String']>;
  symmetry?: Maybe<SymmetryCreateNestedOneWithoutDiamondInput>;
  tablePercent?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<VendorCreateNestedOneWithoutDiamondsInput>;
  widthMM?: Maybe<Scalars['String']>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutDiamondsInput>;
};

export type DiamondCreateWithoutLabInput = {
  autopopulateDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaTitle?: Maybe<Scalars['Boolean']>;
  carat: Scalars['Float'];
  clarity?: Maybe<ClarityCreateNestedOneWithoutDiamondInput>;
  color?: Maybe<ColorCreateNestedOneWithoutDiamondInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crownAngle?: Maybe<Scalars['String']>;
  culet?: Maybe<CuletCreateNestedOneWithoutDiamondInput>;
  cut?: Maybe<CutCreateNestedOneWithoutDiamondInput>;
  depthMM?: Maybe<Scalars['Float']>;
  depthPercent?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fluorescence?: Maybe<FluorescenceCreateNestedOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleCreateNestedManyWithoutDiamondsInput>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutDiamondInput>;
  images360IFrameUrl?: Maybe<Scalars['String']>;
  images360VideoUrl?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  lengthMM?: Maybe<Scalars['String']>;
  lightPerformance?: Maybe<LightPerformanceCreateNestedOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<Scalars['String']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutDiamondInput>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  pavilionAngle?: Maybe<Scalars['String']>;
  polish?: Maybe<PolishCreateNestedOneWithoutDiamondInput>;
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Int']>;
  shape?: Maybe<DiamondShapeCreateNestedOneWithoutDiamondInput>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  starLengthPercent?: Maybe<Scalars['String']>;
  symmetry?: Maybe<SymmetryCreateNestedOneWithoutDiamondInput>;
  tablePercent?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<VendorCreateNestedOneWithoutDiamondsInput>;
  widthMM?: Maybe<Scalars['String']>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutDiamondsInput>;
};

export type DiamondCreateWithoutMetaInput = {
  autopopulateDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaTitle?: Maybe<Scalars['Boolean']>;
  carat: Scalars['Float'];
  clarity?: Maybe<ClarityCreateNestedOneWithoutDiamondInput>;
  color?: Maybe<ColorCreateNestedOneWithoutDiamondInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crownAngle?: Maybe<Scalars['String']>;
  culet?: Maybe<CuletCreateNestedOneWithoutDiamondInput>;
  cut?: Maybe<CutCreateNestedOneWithoutDiamondInput>;
  depthMM?: Maybe<Scalars['Float']>;
  depthPercent?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fluorescence?: Maybe<FluorescenceCreateNestedOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleCreateNestedManyWithoutDiamondsInput>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutDiamondInput>;
  images360IFrameUrl?: Maybe<Scalars['String']>;
  images360VideoUrl?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutDiamondInput>;
  lengthMM?: Maybe<Scalars['String']>;
  lightPerformance?: Maybe<LightPerformanceCreateNestedOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  pavilionAngle?: Maybe<Scalars['String']>;
  polish?: Maybe<PolishCreateNestedOneWithoutDiamondInput>;
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Int']>;
  shape?: Maybe<DiamondShapeCreateNestedOneWithoutDiamondInput>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  starLengthPercent?: Maybe<Scalars['String']>;
  symmetry?: Maybe<SymmetryCreateNestedOneWithoutDiamondInput>;
  tablePercent?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<VendorCreateNestedOneWithoutDiamondsInput>;
  widthMM?: Maybe<Scalars['String']>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutDiamondsInput>;
};

export type DiamondCreateWithoutShapeInput = {
  autopopulateDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaTitle?: Maybe<Scalars['Boolean']>;
  carat: Scalars['Float'];
  clarity?: Maybe<ClarityCreateNestedOneWithoutDiamondInput>;
  color?: Maybe<ColorCreateNestedOneWithoutDiamondInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crownAngle?: Maybe<Scalars['String']>;
  culet?: Maybe<CuletCreateNestedOneWithoutDiamondInput>;
  cut?: Maybe<CutCreateNestedOneWithoutDiamondInput>;
  depthMM?: Maybe<Scalars['Float']>;
  depthPercent?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fluorescence?: Maybe<FluorescenceCreateNestedOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleCreateNestedManyWithoutDiamondsInput>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutDiamondInput>;
  images360IFrameUrl?: Maybe<Scalars['String']>;
  images360VideoUrl?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutDiamondInput>;
  lengthMM?: Maybe<Scalars['String']>;
  lightPerformance?: Maybe<LightPerformanceCreateNestedOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<Scalars['String']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutDiamondInput>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  pavilionAngle?: Maybe<Scalars['String']>;
  polish?: Maybe<PolishCreateNestedOneWithoutDiamondInput>;
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Int']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  starLengthPercent?: Maybe<Scalars['String']>;
  symmetry?: Maybe<SymmetryCreateNestedOneWithoutDiamondInput>;
  tablePercent?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<VendorCreateNestedOneWithoutDiamondsInput>;
  widthMM?: Maybe<Scalars['String']>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutDiamondsInput>;
};

export type DiamondCreateWithoutVendorInput = {
  autopopulateDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaTitle?: Maybe<Scalars['Boolean']>;
  carat: Scalars['Float'];
  clarity?: Maybe<ClarityCreateNestedOneWithoutDiamondInput>;
  color?: Maybe<ColorCreateNestedOneWithoutDiamondInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crownAngle?: Maybe<Scalars['String']>;
  culet?: Maybe<CuletCreateNestedOneWithoutDiamondInput>;
  cut?: Maybe<CutCreateNestedOneWithoutDiamondInput>;
  depthMM?: Maybe<Scalars['Float']>;
  depthPercent?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fluorescence?: Maybe<FluorescenceCreateNestedOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleCreateNestedManyWithoutDiamondsInput>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutDiamondInput>;
  images360IFrameUrl?: Maybe<Scalars['String']>;
  images360VideoUrl?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutDiamondInput>;
  lengthMM?: Maybe<Scalars['String']>;
  lightPerformance?: Maybe<LightPerformanceCreateNestedOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<Scalars['String']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutDiamondInput>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  pavilionAngle?: Maybe<Scalars['String']>;
  polish?: Maybe<PolishCreateNestedOneWithoutDiamondInput>;
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Int']>;
  shape?: Maybe<DiamondShapeCreateNestedOneWithoutDiamondInput>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  starLengthPercent?: Maybe<Scalars['String']>;
  symmetry?: Maybe<SymmetryCreateNestedOneWithoutDiamondInput>;
  tablePercent?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  widthMM?: Maybe<Scalars['String']>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutDiamondsInput>;
};

export type DiamondCreateWithoutWishlistsInput = {
  autopopulateDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaDescription?: Maybe<Scalars['Boolean']>;
  autopopulateMetaTitle?: Maybe<Scalars['Boolean']>;
  carat: Scalars['Float'];
  clarity?: Maybe<ClarityCreateNestedOneWithoutDiamondInput>;
  color?: Maybe<ColorCreateNestedOneWithoutDiamondInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crownAngle?: Maybe<Scalars['String']>;
  culet?: Maybe<CuletCreateNestedOneWithoutDiamondInput>;
  cut?: Maybe<CutCreateNestedOneWithoutDiamondInput>;
  depthMM?: Maybe<Scalars['Float']>;
  depthPercent?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fluorescence?: Maybe<FluorescenceCreateNestedOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleCreateNestedManyWithoutDiamondsInput>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutDiamondInput>;
  images360IFrameUrl?: Maybe<Scalars['String']>;
  images360VideoUrl?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutDiamondInput>;
  lengthMM?: Maybe<Scalars['String']>;
  lightPerformance?: Maybe<LightPerformanceCreateNestedOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<Scalars['String']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutDiamondInput>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  pavilionAngle?: Maybe<Scalars['String']>;
  polish?: Maybe<PolishCreateNestedOneWithoutDiamondInput>;
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Int']>;
  shape?: Maybe<DiamondShapeCreateNestedOneWithoutDiamondInput>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  starLengthPercent?: Maybe<Scalars['String']>;
  symmetry?: Maybe<SymmetryCreateNestedOneWithoutDiamondInput>;
  tablePercent?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<VendorCreateNestedOneWithoutDiamondsInput>;
  widthMM?: Maybe<Scalars['String']>;
};

export type DiamondListRelationFilter = {
  every?: Maybe<DiamondWhereInput>;
  none?: Maybe<DiamondWhereInput>;
  some?: Maybe<DiamondWhereInput>;
};

export type DiamondMetaData = {
  __typename?: 'DiamondMetaData';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
};

export type DiamondOrderByInput = {
  autopopulateDescription?: Maybe<SortOrder>;
  autopopulateMetaDescription?: Maybe<SortOrder>;
  autopopulateMetaTitle?: Maybe<SortOrder>;
  carat?: Maybe<SortOrder>;
  clarityId?: Maybe<SortOrder>;
  colorId?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  crownAngle?: Maybe<SortOrder>;
  culetId?: Maybe<SortOrder>;
  cutId?: Maybe<SortOrder>;
  depthMM?: Maybe<SortOrder>;
  depthPercent?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  fluorescenceId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  images360IFrameUrl?: Maybe<SortOrder>;
  images360VideoUrl?: Maybe<SortOrder>;
  inStock?: Maybe<SortOrder>;
  isLabGrown?: Maybe<SortOrder>;
  lengthMM?: Maybe<SortOrder>;
  lightPerformanceId?: Maybe<SortOrder>;
  lowerGirdleFacetPercent?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  onSale?: Maybe<SortOrder>;
  pavilionAngle?: Maybe<SortOrder>;
  polishId?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  salePrice?: Maybe<SortOrder>;
  shapeId?: Maybe<SortOrder>;
  sku?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  starLengthPercent?: Maybe<SortOrder>;
  symmetryId?: Maybe<SortOrder>;
  tablePercent?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vendorId?: Maybe<SortOrder>;
  widthMM?: Maybe<SortOrder>;
};

export type DiamondOutlierValues = {
  __typename?: 'DiamondOutlierValues';
  maxCarat?: Maybe<Scalars['Float']>;
  maxDepth?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['Float']>;
  maxTable?: Maybe<Scalars['Float']>;
  minCarat?: Maybe<Scalars['Float']>;
  minDepth?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
  minTable?: Maybe<Scalars['Float']>;
};

export type DiamondScalarWhereInput = {
  AND?: Maybe<Array<DiamondScalarWhereInput>>;
  NOT?: Maybe<Array<DiamondScalarWhereInput>>;
  OR?: Maybe<Array<DiamondScalarWhereInput>>;
  autopopulateDescription?: Maybe<BoolNullableFilter>;
  autopopulateMetaDescription?: Maybe<BoolNullableFilter>;
  autopopulateMetaTitle?: Maybe<BoolNullableFilter>;
  carat?: Maybe<FloatFilter>;
  clarityId?: Maybe<StringNullableFilter>;
  colorId?: Maybe<StringNullableFilter>;
  cost?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  crownAngle?: Maybe<StringNullableFilter>;
  culetId?: Maybe<StringNullableFilter>;
  cutId?: Maybe<StringNullableFilter>;
  depthMM?: Maybe<FloatNullableFilter>;
  depthPercent?: Maybe<FloatNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  enabled?: Maybe<BoolNullableFilter>;
  fluorescenceId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  images360IFrameUrl?: Maybe<StringNullableFilter>;
  images360VideoUrl?: Maybe<StringNullableFilter>;
  inStock?: Maybe<BoolNullableFilter>;
  isLabGrown?: Maybe<BoolNullableFilter>;
  lengthMM?: Maybe<StringNullableFilter>;
  lightPerformanceId?: Maybe<StringNullableFilter>;
  lowerGirdleFacetPercent?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  onSale?: Maybe<BoolNullableFilter>;
  pavilionAngle?: Maybe<StringNullableFilter>;
  polishId?: Maybe<StringNullableFilter>;
  price?: Maybe<IntFilter>;
  quantity?: Maybe<FloatFilter>;
  salePrice?: Maybe<IntNullableFilter>;
  shapeId?: Maybe<StringNullableFilter>;
  sku?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  starLengthPercent?: Maybe<StringNullableFilter>;
  symmetryId?: Maybe<StringNullableFilter>;
  tablePercent?: Maybe<FloatNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vendorId?: Maybe<StringNullableFilter>;
  widthMM?: Maybe<StringNullableFilter>;
};

export type DiamondSearchResult = {
  __typename?: 'DiamondSearchResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<Diamond>>>;
};

export type DiamondShape = {
  __typename?: 'DiamondShape';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type DiamondShapeCreateNestedOneWithoutDiamondInput = {
  connect?: Maybe<DiamondShapeWhereUniqueInput>;
  connectOrCreate?: Maybe<DiamondShapeCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<DiamondShapeCreateWithoutDiamondInput>;
};

export type DiamondShapeCreateNestedOneWithoutProductInput = {
  connect?: Maybe<DiamondShapeWhereUniqueInput>;
  connectOrCreate?: Maybe<DiamondShapeCreateOrConnectWithoutProductInput>;
  create?: Maybe<DiamondShapeCreateWithoutProductInput>;
};

export type DiamondShapeCreateOrConnectWithoutDiamondInput = {
  create: DiamondShapeCreateWithoutDiamondInput;
  where: DiamondShapeWhereUniqueInput;
};

export type DiamondShapeCreateOrConnectWithoutProductInput = {
  create: DiamondShapeCreateWithoutProductInput;
  where: DiamondShapeWhereUniqueInput;
};

export type DiamondShapeCreateWithoutDiamondInput = {
  Product?: Maybe<ProductCreateNestedManyWithoutDiamondShapeInput>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type DiamondShapeCreateWithoutProductInput = {
  Diamond?: Maybe<DiamondCreateNestedManyWithoutShapeInput>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type DiamondShapeOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type DiamondShapeUpdateOneWithoutDiamondInput = {
  connect?: Maybe<DiamondShapeWhereUniqueInput>;
  connectOrCreate?: Maybe<DiamondShapeCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<DiamondShapeCreateWithoutDiamondInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DiamondShapeUpdateWithoutDiamondInput>;
  upsert?: Maybe<DiamondShapeUpsertWithoutDiamondInput>;
};

export type DiamondShapeUpdateOneWithoutProductInput = {
  connect?: Maybe<DiamondShapeWhereUniqueInput>;
  connectOrCreate?: Maybe<DiamondShapeCreateOrConnectWithoutProductInput>;
  create?: Maybe<DiamondShapeCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DiamondShapeUpdateWithoutProductInput>;
  upsert?: Maybe<DiamondShapeUpsertWithoutProductInput>;
};

export type DiamondShapeUpdateWithoutDiamondInput = {
  Product?: Maybe<ProductUpdateManyWithoutDiamondShapeInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type DiamondShapeUpdateWithoutProductInput = {
  Diamond?: Maybe<DiamondUpdateManyWithoutShapeInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type DiamondShapeUpsertWithoutDiamondInput = {
  create: DiamondShapeCreateWithoutDiamondInput;
  update: DiamondShapeUpdateWithoutDiamondInput;
};

export type DiamondShapeUpsertWithoutProductInput = {
  create: DiamondShapeCreateWithoutProductInput;
  update: DiamondShapeUpdateWithoutProductInput;
};

export type DiamondShapeWhereInput = {
  AND?: Maybe<Array<DiamondShapeWhereInput>>;
  Diamond?: Maybe<DiamondListRelationFilter>;
  NOT?: Maybe<Array<DiamondShapeWhereInput>>;
  OR?: Maybe<Array<DiamondShapeWhereInput>>;
  Product?: Maybe<ProductListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type DiamondShapeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DiamondUpdateInput = {
  autopopulateDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaTitle?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  clarity?: Maybe<ClarityUpdateOneWithoutDiamondInput>;
  color?: Maybe<ColorUpdateOneWithoutDiamondInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  crownAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  culet?: Maybe<CuletUpdateOneWithoutDiamondInput>;
  cut?: Maybe<CutUpdateOneWithoutDiamondInput>;
  depthMM?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  depthPercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  fluorescence?: Maybe<FluorescenceUpdateOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleUpdateManyWithoutDiamondsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutDiamondInput>;
  images360IFrameUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  images360VideoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inStock?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutDiamondInput>;
  lengthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lightPerformance?: Maybe<LightPerformanceUpdateOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutDiamondInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  pavilionAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  polish?: Maybe<PolishUpdateOneWithoutDiamondInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  salePrice?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shape?: Maybe<DiamondShapeUpdateOneWithoutDiamondInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  starLengthPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  symmetry?: Maybe<SymmetryUpdateOneWithoutDiamondInput>;
  tablePercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vendor?: Maybe<VendorUpdateOneWithoutDiamondsInput>;
  widthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutDiamondsInput>;
};

export type DiamondUpdateManyMutationInput = {
  autopopulateDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaTitle?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  crownAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  depthMM?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  depthPercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images360IFrameUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  images360VideoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inStock?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  lengthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lowerGirdleFacetPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  pavilionAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  salePrice?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  starLengthPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tablePercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  widthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type DiamondUpdateManyWithoutShapeInput = {
  connect?: Maybe<Array<DiamondWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DiamondCreateOrConnectWithoutShapeInput>>;
  create?: Maybe<Array<DiamondCreateWithoutShapeInput>>;
  createMany?: Maybe<DiamondCreateManyShapeInputEnvelope>;
  delete?: Maybe<Array<DiamondWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DiamondScalarWhereInput>>;
  disconnect?: Maybe<Array<DiamondWhereUniqueInput>>;
  set?: Maybe<Array<DiamondWhereUniqueInput>>;
  update?: Maybe<Array<DiamondUpdateWithWhereUniqueWithoutShapeInput>>;
  updateMany?: Maybe<Array<DiamondUpdateManyWithWhereWithoutShapeInput>>;
  upsert?: Maybe<Array<DiamondUpsertWithWhereUniqueWithoutShapeInput>>;
};

export type DiamondUpdateManyWithoutVendorInput = {
  connect?: Maybe<Array<DiamondWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DiamondCreateOrConnectWithoutVendorInput>>;
  create?: Maybe<Array<DiamondCreateWithoutVendorInput>>;
  createMany?: Maybe<DiamondCreateManyVendorInputEnvelope>;
  delete?: Maybe<Array<DiamondWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DiamondScalarWhereInput>>;
  disconnect?: Maybe<Array<DiamondWhereUniqueInput>>;
  set?: Maybe<Array<DiamondWhereUniqueInput>>;
  update?: Maybe<Array<DiamondUpdateWithWhereUniqueWithoutVendorInput>>;
  updateMany?: Maybe<Array<DiamondUpdateManyWithWhereWithoutVendorInput>>;
  upsert?: Maybe<Array<DiamondUpsertWithWhereUniqueWithoutVendorInput>>;
};

export type DiamondUpdateManyWithoutWishlistsInput = {
  connect?: Maybe<Array<DiamondWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DiamondCreateOrConnectWithoutWishlistsInput>>;
  create?: Maybe<Array<DiamondCreateWithoutWishlistsInput>>;
  delete?: Maybe<Array<DiamondWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DiamondScalarWhereInput>>;
  disconnect?: Maybe<Array<DiamondWhereUniqueInput>>;
  set?: Maybe<Array<DiamondWhereUniqueInput>>;
  update?: Maybe<Array<DiamondUpdateWithWhereUniqueWithoutWishlistsInput>>;
  updateMany?: Maybe<Array<DiamondUpdateManyWithWhereWithoutWishlistsInput>>;
  upsert?: Maybe<Array<DiamondUpsertWithWhereUniqueWithoutWishlistsInput>>;
};

export type DiamondUpdateManyWithWhereWithoutShapeInput = {
  data: DiamondUpdateManyMutationInput;
  where: DiamondScalarWhereInput;
};

export type DiamondUpdateManyWithWhereWithoutVendorInput = {
  data: DiamondUpdateManyMutationInput;
  where: DiamondScalarWhereInput;
};

export type DiamondUpdateManyWithWhereWithoutWishlistsInput = {
  data: DiamondUpdateManyMutationInput;
  where: DiamondScalarWhereInput;
};

export type DiamondUpdateOneWithoutImagesInput = {
  connect?: Maybe<DiamondWhereUniqueInput>;
  connectOrCreate?: Maybe<DiamondCreateOrConnectWithoutImagesInput>;
  create?: Maybe<DiamondCreateWithoutImagesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DiamondUpdateWithoutImagesInput>;
  upsert?: Maybe<DiamondUpsertWithoutImagesInput>;
};

export type DiamondUpdateOneWithoutLabInput = {
  connect?: Maybe<DiamondWhereUniqueInput>;
  connectOrCreate?: Maybe<DiamondCreateOrConnectWithoutLabInput>;
  create?: Maybe<DiamondCreateWithoutLabInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DiamondUpdateWithoutLabInput>;
  upsert?: Maybe<DiamondUpsertWithoutLabInput>;
};

export type DiamondUpdateOneWithoutMetaInput = {
  connect?: Maybe<DiamondWhereUniqueInput>;
  connectOrCreate?: Maybe<DiamondCreateOrConnectWithoutMetaInput>;
  create?: Maybe<DiamondCreateWithoutMetaInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DiamondUpdateWithoutMetaInput>;
  upsert?: Maybe<DiamondUpsertWithoutMetaInput>;
};

export type DiamondUpdateWithoutImagesInput = {
  autopopulateDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaTitle?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  clarity?: Maybe<ClarityUpdateOneWithoutDiamondInput>;
  color?: Maybe<ColorUpdateOneWithoutDiamondInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  crownAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  culet?: Maybe<CuletUpdateOneWithoutDiamondInput>;
  cut?: Maybe<CutUpdateOneWithoutDiamondInput>;
  depthMM?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  depthPercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  fluorescence?: Maybe<FluorescenceUpdateOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleUpdateManyWithoutDiamondsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images360IFrameUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  images360VideoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inStock?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutDiamondInput>;
  lengthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lightPerformance?: Maybe<LightPerformanceUpdateOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutDiamondInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  pavilionAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  polish?: Maybe<PolishUpdateOneWithoutDiamondInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  salePrice?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shape?: Maybe<DiamondShapeUpdateOneWithoutDiamondInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  starLengthPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  symmetry?: Maybe<SymmetryUpdateOneWithoutDiamondInput>;
  tablePercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vendor?: Maybe<VendorUpdateOneWithoutDiamondsInput>;
  widthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutDiamondsInput>;
};

export type DiamondUpdateWithoutLabInput = {
  autopopulateDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaTitle?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  clarity?: Maybe<ClarityUpdateOneWithoutDiamondInput>;
  color?: Maybe<ColorUpdateOneWithoutDiamondInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  crownAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  culet?: Maybe<CuletUpdateOneWithoutDiamondInput>;
  cut?: Maybe<CutUpdateOneWithoutDiamondInput>;
  depthMM?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  depthPercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  fluorescence?: Maybe<FluorescenceUpdateOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleUpdateManyWithoutDiamondsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutDiamondInput>;
  images360IFrameUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  images360VideoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inStock?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  lengthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lightPerformance?: Maybe<LightPerformanceUpdateOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutDiamondInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  pavilionAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  polish?: Maybe<PolishUpdateOneWithoutDiamondInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  salePrice?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shape?: Maybe<DiamondShapeUpdateOneWithoutDiamondInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  starLengthPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  symmetry?: Maybe<SymmetryUpdateOneWithoutDiamondInput>;
  tablePercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vendor?: Maybe<VendorUpdateOneWithoutDiamondsInput>;
  widthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutDiamondsInput>;
};

export type DiamondUpdateWithoutMetaInput = {
  autopopulateDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaTitle?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  clarity?: Maybe<ClarityUpdateOneWithoutDiamondInput>;
  color?: Maybe<ColorUpdateOneWithoutDiamondInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  crownAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  culet?: Maybe<CuletUpdateOneWithoutDiamondInput>;
  cut?: Maybe<CutUpdateOneWithoutDiamondInput>;
  depthMM?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  depthPercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  fluorescence?: Maybe<FluorescenceUpdateOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleUpdateManyWithoutDiamondsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutDiamondInput>;
  images360IFrameUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  images360VideoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inStock?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutDiamondInput>;
  lengthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lightPerformance?: Maybe<LightPerformanceUpdateOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  pavilionAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  polish?: Maybe<PolishUpdateOneWithoutDiamondInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  salePrice?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shape?: Maybe<DiamondShapeUpdateOneWithoutDiamondInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  starLengthPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  symmetry?: Maybe<SymmetryUpdateOneWithoutDiamondInput>;
  tablePercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vendor?: Maybe<VendorUpdateOneWithoutDiamondsInput>;
  widthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutDiamondsInput>;
};

export type DiamondUpdateWithoutShapeInput = {
  autopopulateDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaTitle?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  clarity?: Maybe<ClarityUpdateOneWithoutDiamondInput>;
  color?: Maybe<ColorUpdateOneWithoutDiamondInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  crownAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  culet?: Maybe<CuletUpdateOneWithoutDiamondInput>;
  cut?: Maybe<CutUpdateOneWithoutDiamondInput>;
  depthMM?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  depthPercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  fluorescence?: Maybe<FluorescenceUpdateOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleUpdateManyWithoutDiamondsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutDiamondInput>;
  images360IFrameUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  images360VideoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inStock?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutDiamondInput>;
  lengthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lightPerformance?: Maybe<LightPerformanceUpdateOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutDiamondInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  pavilionAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  polish?: Maybe<PolishUpdateOneWithoutDiamondInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  salePrice?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  starLengthPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  symmetry?: Maybe<SymmetryUpdateOneWithoutDiamondInput>;
  tablePercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vendor?: Maybe<VendorUpdateOneWithoutDiamondsInput>;
  widthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutDiamondsInput>;
};

export type DiamondUpdateWithoutVendorInput = {
  autopopulateDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaTitle?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  clarity?: Maybe<ClarityUpdateOneWithoutDiamondInput>;
  color?: Maybe<ColorUpdateOneWithoutDiamondInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  crownAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  culet?: Maybe<CuletUpdateOneWithoutDiamondInput>;
  cut?: Maybe<CutUpdateOneWithoutDiamondInput>;
  depthMM?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  depthPercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  fluorescence?: Maybe<FluorescenceUpdateOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleUpdateManyWithoutDiamondsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutDiamondInput>;
  images360IFrameUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  images360VideoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inStock?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutDiamondInput>;
  lengthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lightPerformance?: Maybe<LightPerformanceUpdateOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutDiamondInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  pavilionAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  polish?: Maybe<PolishUpdateOneWithoutDiamondInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  salePrice?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shape?: Maybe<DiamondShapeUpdateOneWithoutDiamondInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  starLengthPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  symmetry?: Maybe<SymmetryUpdateOneWithoutDiamondInput>;
  tablePercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  widthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutDiamondsInput>;
};

export type DiamondUpdateWithoutWishlistsInput = {
  autopopulateDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaDescription?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  autopopulateMetaTitle?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  clarity?: Maybe<ClarityUpdateOneWithoutDiamondInput>;
  color?: Maybe<ColorUpdateOneWithoutDiamondInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  crownAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  culet?: Maybe<CuletUpdateOneWithoutDiamondInput>;
  cut?: Maybe<CutUpdateOneWithoutDiamondInput>;
  depthMM?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  depthPercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  fluorescence?: Maybe<FluorescenceUpdateOneWithoutDiamondInput>;
  girdles?: Maybe<GirdleUpdateManyWithoutDiamondsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutDiamondInput>;
  images360IFrameUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  images360VideoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inStock?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutDiamondInput>;
  lengthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lightPerformance?: Maybe<LightPerformanceUpdateOneWithoutDiamondInput>;
  lowerGirdleFacetPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutDiamondInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  pavilionAngle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  polish?: Maybe<PolishUpdateOneWithoutDiamondInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  salePrice?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shape?: Maybe<DiamondShapeUpdateOneWithoutDiamondInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  starLengthPercent?: Maybe<NullableStringFieldUpdateOperationsInput>;
  symmetry?: Maybe<SymmetryUpdateOneWithoutDiamondInput>;
  tablePercent?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vendor?: Maybe<VendorUpdateOneWithoutDiamondsInput>;
  widthMM?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type DiamondUpdateWithWhereUniqueWithoutShapeInput = {
  data: DiamondUpdateWithoutShapeInput;
  where: DiamondWhereUniqueInput;
};

export type DiamondUpdateWithWhereUniqueWithoutVendorInput = {
  data: DiamondUpdateWithoutVendorInput;
  where: DiamondWhereUniqueInput;
};

export type DiamondUpdateWithWhereUniqueWithoutWishlistsInput = {
  data: DiamondUpdateWithoutWishlistsInput;
  where: DiamondWhereUniqueInput;
};

export type DiamondUpsertWithoutImagesInput = {
  create: DiamondCreateWithoutImagesInput;
  update: DiamondUpdateWithoutImagesInput;
};

export type DiamondUpsertWithoutLabInput = {
  create: DiamondCreateWithoutLabInput;
  update: DiamondUpdateWithoutLabInput;
};

export type DiamondUpsertWithoutMetaInput = {
  create: DiamondCreateWithoutMetaInput;
  update: DiamondUpdateWithoutMetaInput;
};

export type DiamondUpsertWithWhereUniqueWithoutShapeInput = {
  create: DiamondCreateWithoutShapeInput;
  update: DiamondUpdateWithoutShapeInput;
  where: DiamondWhereUniqueInput;
};

export type DiamondUpsertWithWhereUniqueWithoutVendorInput = {
  create: DiamondCreateWithoutVendorInput;
  update: DiamondUpdateWithoutVendorInput;
  where: DiamondWhereUniqueInput;
};

export type DiamondUpsertWithWhereUniqueWithoutWishlistsInput = {
  create: DiamondCreateWithoutWishlistsInput;
  update: DiamondUpdateWithoutWishlistsInput;
  where: DiamondWhereUniqueInput;
};

export type DiamondWhereInput = {
  AND?: Maybe<Array<DiamondWhereInput>>;
  NOT?: Maybe<Array<DiamondWhereInput>>;
  OR?: Maybe<Array<DiamondWhereInput>>;
  autopopulateDescription?: Maybe<BoolNullableFilter>;
  autopopulateMetaDescription?: Maybe<BoolNullableFilter>;
  autopopulateMetaTitle?: Maybe<BoolNullableFilter>;
  carat?: Maybe<FloatFilter>;
  clarity?: Maybe<ClarityWhereInput>;
  clarityId?: Maybe<StringNullableFilter>;
  color?: Maybe<ColorWhereInput>;
  colorId?: Maybe<StringNullableFilter>;
  cost?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  crownAngle?: Maybe<StringNullableFilter>;
  culet?: Maybe<CuletWhereInput>;
  culetId?: Maybe<StringNullableFilter>;
  cut?: Maybe<CutWhereInput>;
  cutId?: Maybe<StringNullableFilter>;
  depthMM?: Maybe<FloatNullableFilter>;
  depthPercent?: Maybe<FloatNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  enabled?: Maybe<BoolNullableFilter>;
  fluorescence?: Maybe<FluorescenceWhereInput>;
  fluorescenceId?: Maybe<StringNullableFilter>;
  girdles?: Maybe<GirdleListRelationFilter>;
  id?: Maybe<StringFilter>;
  images?: Maybe<ImageListRelationFilter>;
  images360IFrameUrl?: Maybe<StringNullableFilter>;
  images360VideoUrl?: Maybe<StringNullableFilter>;
  inStock?: Maybe<BoolNullableFilter>;
  isLabGrown?: Maybe<BoolNullableFilter>;
  lab?: Maybe<LabDetailWhereInput>;
  lengthMM?: Maybe<StringNullableFilter>;
  lightPerformance?: Maybe<LightPerformanceWhereInput>;
  lightPerformanceId?: Maybe<StringNullableFilter>;
  lowerGirdleFacetPercent?: Maybe<StringNullableFilter>;
  meta?: Maybe<MetaDataWhereInput>;
  name?: Maybe<StringNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  onSale?: Maybe<BoolNullableFilter>;
  pavilionAngle?: Maybe<StringNullableFilter>;
  polish?: Maybe<PolishWhereInput>;
  polishId?: Maybe<StringNullableFilter>;
  price?: Maybe<IntFilter>;
  quantity?: Maybe<FloatFilter>;
  salePrice?: Maybe<IntNullableFilter>;
  shape?: Maybe<DiamondShapeWhereInput>;
  shapeId?: Maybe<StringNullableFilter>;
  sku?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  starLengthPercent?: Maybe<StringNullableFilter>;
  symmetry?: Maybe<SymmetryWhereInput>;
  symmetryId?: Maybe<StringNullableFilter>;
  tablePercent?: Maybe<FloatNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vendor?: Maybe<VendorWhereInput>;
  vendorId?: Maybe<StringNullableFilter>;
  widthMM?: Maybe<StringNullableFilter>;
  wishlists?: Maybe<WishlistListRelationFilter>;
};

export type DiamondWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type Email = {
  __typename?: 'Email';
  _id: Scalars['ObjID'];
  body?: Maybe<Scalars['String']>;
  from: Scalars['String'];
  hasEmail?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['ObjID']>;
  message_replaced?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ObjID']>;
  paymentType?: Maybe<Scalars['String']>;
  shippingCompanyId?: Maybe<Scalars['ObjID']>;
  subject: Scalars['String'];
  template?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to: Scalars['String'];
  trackingNumber?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  userId?: Maybe<Scalars['ObjID']>;
  workOrderId?: Maybe<Scalars['ObjID']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailInput = {
  body?: Maybe<Scalars['String']>;
  from: Scalars['String'];
  hasEmail?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['ObjID']>;
  message_replaced?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ObjID']>;
  paymentType?: Maybe<Scalars['String']>;
  shippingCompanyId?: Maybe<Scalars['ObjID']>;
  subject: Scalars['String'];
  template?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to: Scalars['String'];
  trackingNumber?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  userId?: Maybe<Scalars['ObjID']>;
  workOrderId?: Maybe<Scalars['ObjID']>;
};

export type EmailSendInput = {
  data?: Maybe<Scalars['JSON']>;
  from?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['ObjID']>;
  orderId?: Maybe<Scalars['ObjID']>;
  subject: Scalars['String'];
  template?: Maybe<Scalars['String']>;
  to: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjID']>;
  workOrderId?: Maybe<Scalars['ObjID']>;
};

export type EnumVendorSourceFieldUpdateOperationsInput = {
  set?: Maybe<VendorSource>;
};

export type EnumVendorSourceFilter = {
  equals?: Maybe<VendorSource>;
  in?: Maybe<Array<VendorSource>>;
  not?: Maybe<NestedEnumVendorSourceFilter>;
  notIn?: Maybe<Array<VendorSource>>;
};

export type Faq = {
  __typename?: 'FAQ';
  _id: Scalars['ObjID'];
  category?: Maybe<Scalars['String']>;
  question: Scalars['String'];
  answer: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FaqInput = {
  category?: Maybe<Scalars['String']>;
  question: Scalars['String'];
  answer: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  set?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type Fluorescence = {
  __typename?: 'Fluorescence';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type FluorescenceCreateNestedOneWithoutDiamondInput = {
  connect?: Maybe<FluorescenceWhereUniqueInput>;
  connectOrCreate?: Maybe<FluorescenceCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<FluorescenceCreateWithoutDiamondInput>;
};

export type FluorescenceCreateOrConnectWithoutDiamondInput = {
  create: FluorescenceCreateWithoutDiamondInput;
  where: FluorescenceWhereUniqueInput;
};

export type FluorescenceCreateWithoutDiamondInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type FluorescenceOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type FluorescenceUpdateOneWithoutDiamondInput = {
  connect?: Maybe<FluorescenceWhereUniqueInput>;
  connectOrCreate?: Maybe<FluorescenceCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<FluorescenceCreateWithoutDiamondInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FluorescenceUpdateWithoutDiamondInput>;
  upsert?: Maybe<FluorescenceUpsertWithoutDiamondInput>;
};

export type FluorescenceUpdateWithoutDiamondInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type FluorescenceUpsertWithoutDiamondInput = {
  create: FluorescenceCreateWithoutDiamondInput;
  update: FluorescenceUpdateWithoutDiamondInput;
};

export type FluorescenceWhereInput = {
  AND?: Maybe<Array<FluorescenceWhereInput>>;
  Diamond?: Maybe<DiamondListRelationFilter>;
  NOT?: Maybe<Array<FluorescenceWhereInput>>;
  OR?: Maybe<Array<FluorescenceWhereInput>>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type FluorescenceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type FormContact = {
  __typename?: 'FormContact';
  _id: Scalars['ObjID'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjID']>;
  notes?: Maybe<Array<Maybe<FormContactNotes>>>;
  customDesignRequests?: Maybe<Array<Maybe<CustomDesignRequest>>>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  workOrders?: Maybe<Array<Maybe<WorkOrder>>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FormContactInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<FormContactNotesInput>>>;
};

export type FormContactNotes = {
  __typename?: 'FormContactNotes';
  authorId?: Maybe<Scalars['ObjID']>;
  content?: Maybe<Scalars['String']>;
};

export type FormContactNotesInput = {
  authorId?: Maybe<Scalars['ObjID']>;
  content?: Maybe<Scalars['String']>;
};

export type GeneralInquiry = {
  __typename?: 'GeneralInquiry';
  _id: Scalars['ObjID'];
  fullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjID']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type GeneralInquiryCreateInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  recaptcha: Scalars['String'];
};

export type GeneralInquiryUpdateInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Girdle = {
  __typename?: 'Girdle';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type GirdleCreateNestedManyWithoutDiamondsInput = {
  connect?: Maybe<Array<GirdleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GirdleCreateOrConnectWithoutDiamondsInput>>;
  create?: Maybe<Array<GirdleCreateWithoutDiamondsInput>>;
};

export type GirdleCreateOrConnectWithoutDiamondsInput = {
  create: GirdleCreateWithoutDiamondsInput;
  where: GirdleWhereUniqueInput;
};

export type GirdleCreateWithoutDiamondsInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type GirdleListRelationFilter = {
  every?: Maybe<GirdleWhereInput>;
  none?: Maybe<GirdleWhereInput>;
  some?: Maybe<GirdleWhereInput>;
};

export type GirdleOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type GirdleScalarWhereInput = {
  AND?: Maybe<Array<GirdleScalarWhereInput>>;
  NOT?: Maybe<Array<GirdleScalarWhereInput>>;
  OR?: Maybe<Array<GirdleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type GirdleUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type GirdleUpdateManyWithoutDiamondsInput = {
  connect?: Maybe<Array<GirdleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GirdleCreateOrConnectWithoutDiamondsInput>>;
  create?: Maybe<Array<GirdleCreateWithoutDiamondsInput>>;
  delete?: Maybe<Array<GirdleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<GirdleScalarWhereInput>>;
  disconnect?: Maybe<Array<GirdleWhereUniqueInput>>;
  set?: Maybe<Array<GirdleWhereUniqueInput>>;
  update?: Maybe<Array<GirdleUpdateWithWhereUniqueWithoutDiamondsInput>>;
  updateMany?: Maybe<Array<GirdleUpdateManyWithWhereWithoutDiamondsInput>>;
  upsert?: Maybe<Array<GirdleUpsertWithWhereUniqueWithoutDiamondsInput>>;
};

export type GirdleUpdateManyWithWhereWithoutDiamondsInput = {
  data: GirdleUpdateManyMutationInput;
  where: GirdleScalarWhereInput;
};

export type GirdleUpdateWithoutDiamondsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type GirdleUpdateWithWhereUniqueWithoutDiamondsInput = {
  data: GirdleUpdateWithoutDiamondsInput;
  where: GirdleWhereUniqueInput;
};

export type GirdleUpsertWithWhereUniqueWithoutDiamondsInput = {
  create: GirdleCreateWithoutDiamondsInput;
  update: GirdleUpdateWithoutDiamondsInput;
  where: GirdleWhereUniqueInput;
};

export type GirdleWhereInput = {
  AND?: Maybe<Array<GirdleWhereInput>>;
  NOT?: Maybe<Array<GirdleWhereInput>>;
  OR?: Maybe<Array<GirdleWhereInput>>;
  diamonds?: Maybe<DiamondListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type GirdleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  diamond?: Maybe<Diamond>;
  diamondId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mimetype?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  storage: Scalars['String'];
  storageUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars['String']>;
};

export type ImageCreateInput = {
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  diamond?: Maybe<DiamondCreateNestedOneWithoutImagesInput>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  product?: Maybe<ProductCreateNestedOneWithoutImagesInput>;
  productType?: Maybe<Scalars['String']>;
  storage: Scalars['String'];
  storageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  vendor?: Maybe<VendorCreateNestedOneWithoutImagesInput>;
};

export type ImageCreateManyDiamondInput = {
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  storage: Scalars['String'];
  storageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  vendorId?: Maybe<Scalars['String']>;
};

export type ImageCreateManyDiamondInputEnvelope = {
  data?: Maybe<Array<ImageCreateManyDiamondInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ImageCreateManyProductInput = {
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  diamondId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  storage: Scalars['String'];
  storageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  vendorId?: Maybe<Scalars['String']>;
};

export type ImageCreateManyProductInputEnvelope = {
  data?: Maybe<Array<ImageCreateManyProductInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ImageCreateManyVendorInput = {
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  diamondId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  storage: Scalars['String'];
  storageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type ImageCreateManyVendorInputEnvelope = {
  data?: Maybe<Array<ImageCreateManyVendorInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ImageCreateNestedManyWithoutDiamondInput = {
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutDiamondInput>>;
  create?: Maybe<Array<ImageCreateWithoutDiamondInput>>;
  createMany?: Maybe<ImageCreateManyDiamondInputEnvelope>;
};

export type ImageCreateNestedManyWithoutProductInput = {
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutProductInput>>;
  create?: Maybe<Array<ImageCreateWithoutProductInput>>;
  createMany?: Maybe<ImageCreateManyProductInputEnvelope>;
};

export type ImageCreateNestedManyWithoutVendorInput = {
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutVendorInput>>;
  create?: Maybe<Array<ImageCreateWithoutVendorInput>>;
  createMany?: Maybe<ImageCreateManyVendorInputEnvelope>;
};

export type ImageCreateOrConnectWithoutDiamondInput = {
  create: ImageCreateWithoutDiamondInput;
  where: ImageWhereUniqueInput;
};

export type ImageCreateOrConnectWithoutProductInput = {
  create: ImageCreateWithoutProductInput;
  where: ImageWhereUniqueInput;
};

export type ImageCreateOrConnectWithoutVendorInput = {
  create: ImageCreateWithoutVendorInput;
  where: ImageWhereUniqueInput;
};

export type ImageCreateWithoutDiamondInput = {
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  product?: Maybe<ProductCreateNestedOneWithoutImagesInput>;
  productType?: Maybe<Scalars['String']>;
  storage: Scalars['String'];
  storageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  vendor?: Maybe<VendorCreateNestedOneWithoutImagesInput>;
};

export type ImageCreateWithoutProductInput = {
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  diamond?: Maybe<DiamondCreateNestedOneWithoutImagesInput>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  storage: Scalars['String'];
  storageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  vendor?: Maybe<VendorCreateNestedOneWithoutImagesInput>;
};

export type ImageCreateWithoutVendorInput = {
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  diamond?: Maybe<DiamondCreateNestedOneWithoutImagesInput>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  product?: Maybe<ProductCreateNestedOneWithoutImagesInput>;
  productType?: Maybe<Scalars['String']>;
  storage: Scalars['String'];
  storageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type ImageListRelationFilter = {
  every?: Maybe<ImageWhereInput>;
  none?: Maybe<ImageWhereInput>;
  some?: Maybe<ImageWhereInput>;
};

export type ImageOrderByInput = {
  category?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  diamondId?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mimetype?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  pageId?: Maybe<SortOrder>;
  path?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  productType?: Maybe<SortOrder>;
  storage?: Maybe<SortOrder>;
  storageUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  vendorId?: Maybe<SortOrder>;
};

export type ImageScalarWhereInput = {
  AND?: Maybe<Array<ImageScalarWhereInput>>;
  NOT?: Maybe<Array<ImageScalarWhereInput>>;
  OR?: Maybe<Array<ImageScalarWhereInput>>;
  category?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  diamondId?: Maybe<StringNullableFilter>;
  filename?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  mimetype?: Maybe<StringNullableFilter>;
  order?: Maybe<IntNullableFilter>;
  pageId?: Maybe<StringNullableFilter>;
  path?: Maybe<StringNullableFilter>;
  productId?: Maybe<StringNullableFilter>;
  productType?: Maybe<StringNullableFilter>;
  storage?: Maybe<StringFilter>;
  storageUrl?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  vendorId?: Maybe<StringNullableFilter>;
};

export type ImageUpdateInput = {
  category?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  diamond?: Maybe<DiamondUpdateOneWithoutImagesInput>;
  filename?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mimetype?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  pageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  path?: Maybe<NullableStringFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutImagesInput>;
  productType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  storage?: Maybe<StringFieldUpdateOperationsInput>;
  storageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
  vendor?: Maybe<VendorUpdateOneWithoutImagesInput>;
};

export type ImageUpdateManyMutationInput = {
  category?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  filename?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mimetype?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  pageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  path?: Maybe<NullableStringFieldUpdateOperationsInput>;
  productType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  storage?: Maybe<StringFieldUpdateOperationsInput>;
  storageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ImageUpdateManyWithoutDiamondInput = {
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutDiamondInput>>;
  create?: Maybe<Array<ImageCreateWithoutDiamondInput>>;
  createMany?: Maybe<ImageCreateManyDiamondInputEnvelope>;
  delete?: Maybe<Array<ImageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ImageScalarWhereInput>>;
  disconnect?: Maybe<Array<ImageWhereUniqueInput>>;
  set?: Maybe<Array<ImageWhereUniqueInput>>;
  update?: Maybe<Array<ImageUpdateWithWhereUniqueWithoutDiamondInput>>;
  updateMany?: Maybe<Array<ImageUpdateManyWithWhereWithoutDiamondInput>>;
  upsert?: Maybe<Array<ImageUpsertWithWhereUniqueWithoutDiamondInput>>;
};

export type ImageUpdateManyWithoutProductInput = {
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutProductInput>>;
  create?: Maybe<Array<ImageCreateWithoutProductInput>>;
  createMany?: Maybe<ImageCreateManyProductInputEnvelope>;
  delete?: Maybe<Array<ImageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ImageScalarWhereInput>>;
  disconnect?: Maybe<Array<ImageWhereUniqueInput>>;
  set?: Maybe<Array<ImageWhereUniqueInput>>;
  update?: Maybe<Array<ImageUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<ImageUpdateManyWithWhereWithoutProductInput>>;
  upsert?: Maybe<Array<ImageUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ImageUpdateManyWithoutVendorInput = {
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutVendorInput>>;
  create?: Maybe<Array<ImageCreateWithoutVendorInput>>;
  createMany?: Maybe<ImageCreateManyVendorInputEnvelope>;
  delete?: Maybe<Array<ImageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ImageScalarWhereInput>>;
  disconnect?: Maybe<Array<ImageWhereUniqueInput>>;
  set?: Maybe<Array<ImageWhereUniqueInput>>;
  update?: Maybe<Array<ImageUpdateWithWhereUniqueWithoutVendorInput>>;
  updateMany?: Maybe<Array<ImageUpdateManyWithWhereWithoutVendorInput>>;
  upsert?: Maybe<Array<ImageUpsertWithWhereUniqueWithoutVendorInput>>;
};

export type ImageUpdateManyWithWhereWithoutDiamondInput = {
  data: ImageUpdateManyMutationInput;
  where: ImageScalarWhereInput;
};

export type ImageUpdateManyWithWhereWithoutProductInput = {
  data: ImageUpdateManyMutationInput;
  where: ImageScalarWhereInput;
};

export type ImageUpdateManyWithWhereWithoutVendorInput = {
  data: ImageUpdateManyMutationInput;
  where: ImageScalarWhereInput;
};

export type ImageUpdateWithoutDiamondInput = {
  category?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  filename?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mimetype?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  pageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  path?: Maybe<NullableStringFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutImagesInput>;
  productType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  storage?: Maybe<StringFieldUpdateOperationsInput>;
  storageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
  vendor?: Maybe<VendorUpdateOneWithoutImagesInput>;
};

export type ImageUpdateWithoutProductInput = {
  category?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  diamond?: Maybe<DiamondUpdateOneWithoutImagesInput>;
  filename?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mimetype?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  pageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  path?: Maybe<NullableStringFieldUpdateOperationsInput>;
  productType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  storage?: Maybe<StringFieldUpdateOperationsInput>;
  storageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
  vendor?: Maybe<VendorUpdateOneWithoutImagesInput>;
};

export type ImageUpdateWithoutVendorInput = {
  category?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  diamond?: Maybe<DiamondUpdateOneWithoutImagesInput>;
  filename?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mimetype?: Maybe<NullableStringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  pageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  path?: Maybe<NullableStringFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutImagesInput>;
  productType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  storage?: Maybe<StringFieldUpdateOperationsInput>;
  storageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ImageUpdateWithWhereUniqueWithoutDiamondInput = {
  data: ImageUpdateWithoutDiamondInput;
  where: ImageWhereUniqueInput;
};

export type ImageUpdateWithWhereUniqueWithoutProductInput = {
  data: ImageUpdateWithoutProductInput;
  where: ImageWhereUniqueInput;
};

export type ImageUpdateWithWhereUniqueWithoutVendorInput = {
  data: ImageUpdateWithoutVendorInput;
  where: ImageWhereUniqueInput;
};

export type ImageUploadAttributesInput = {
  category?: Maybe<Scalars['String']>;
  diamondId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
};

export type ImageUpsertWithWhereUniqueWithoutDiamondInput = {
  create: ImageCreateWithoutDiamondInput;
  update: ImageUpdateWithoutDiamondInput;
  where: ImageWhereUniqueInput;
};

export type ImageUpsertWithWhereUniqueWithoutProductInput = {
  create: ImageCreateWithoutProductInput;
  update: ImageUpdateWithoutProductInput;
  where: ImageWhereUniqueInput;
};

export type ImageUpsertWithWhereUniqueWithoutVendorInput = {
  create: ImageCreateWithoutVendorInput;
  update: ImageUpdateWithoutVendorInput;
  where: ImageWhereUniqueInput;
};

export type ImageWhereInput = {
  AND?: Maybe<Array<ImageWhereInput>>;
  NOT?: Maybe<Array<ImageWhereInput>>;
  OR?: Maybe<Array<ImageWhereInput>>;
  category?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  diamond?: Maybe<DiamondWhereInput>;
  diamondId?: Maybe<StringNullableFilter>;
  filename?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  mimetype?: Maybe<StringNullableFilter>;
  order?: Maybe<IntNullableFilter>;
  pageId?: Maybe<StringNullableFilter>;
  path?: Maybe<StringNullableFilter>;
  product?: Maybe<ProductWhereInput>;
  productId?: Maybe<StringNullableFilter>;
  productType?: Maybe<StringNullableFilter>;
  storage?: Maybe<StringFilter>;
  storageUrl?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  vendor?: Maybe<VendorWhereInput>;
  vendorId?: Maybe<StringNullableFilter>;
};

export type ImageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  _id: Scalars['ObjID'];
  balance?: Maybe<Scalars['Int']>;
  body?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<Email>>>;
  from?: Maybe<Scalars['String']>;
  items: Array<Maybe<WorkOrderItem>>;
  note?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ObjID']>;
  order?: Maybe<Order>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  subject: Scalars['String'];
  shipping?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  totalPayments?: Maybe<Scalars['Int']>;
  terms?: Maybe<Scalars['String']>;
  to: Scalars['String'];
  type: Scalars['String'];
  userId?: Maybe<Scalars['ObjID']>;
  user?: Maybe<User>;
  workOrderId?: Maybe<Scalars['ObjID']>;
  workOrder?: Maybe<WorkOrder>;
  lockedAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ObjID']>;
  createdByUser?: Maybe<User>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['ObjID']>;
  updatedByUser?: Maybe<User>;
};

export type InvoiceInput = {
  body?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ObjID']>;
  subject?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjID']>;
  workOrderId?: Maybe<Scalars['ObjID']>;
};

export type Job = {
  __typename?: 'Job';
  _id: Scalars['ObjID'];
  cost?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  jobNumber?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  statusNotes?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  userId: Scalars['ObjID'];
  user: User;
  workOrder?: Maybe<WorkOrder>;
  workOrderId: Scalars['ObjID'];
  workOrderItem?: Maybe<WorkOrderItem>;
  workOrderItemId?: Maybe<Scalars['ObjID']>;
  services?: Maybe<Array<Maybe<JobService>>>;
  promisedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ObjID']>;
  createdByUser?: Maybe<User>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['ObjID']>;
  updatedByUser?: Maybe<User>;
};

export type JobInput = {
  cost?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  jobNumber?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  services?: Maybe<Array<Maybe<JobServiceInput>>>;
  status?: Maybe<Scalars['String']>;
  statusNotes?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['ObjID']>;
  workOrderId?: Maybe<Scalars['ObjID']>;
  workOrderItemId?: Maybe<Scalars['ObjID']>;
  promisedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
};

export type JobService = {
  __typename?: 'JobService';
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export type JobServiceInput = {
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};


export type LabDetail = {
  __typename?: 'LabDetail';
  certificate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reportNumber?: Maybe<Scalars['String']>;
};

export type LabDetailCreateNestedOneWithoutDiamondInput = {
  connect?: Maybe<LabDetailWhereUniqueInput>;
  connectOrCreate?: Maybe<LabDetailCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<LabDetailCreateWithoutDiamondInput>;
};

export type LabDetailCreateNestedOneWithoutProductInput = {
  connect?: Maybe<LabDetailWhereUniqueInput>;
  connectOrCreate?: Maybe<LabDetailCreateOrConnectWithoutProductInput>;
  create?: Maybe<LabDetailCreateWithoutProductInput>;
};

export type LabDetailCreateOrConnectWithoutDiamondInput = {
  create: LabDetailCreateWithoutDiamondInput;
  where: LabDetailWhereUniqueInput;
};

export type LabDetailCreateOrConnectWithoutProductInput = {
  create: LabDetailCreateWithoutProductInput;
  where: LabDetailWhereUniqueInput;
};

export type LabDetailCreateWithoutDiamondInput = {
  certificate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product?: Maybe<ProductCreateNestedOneWithoutLabInput>;
  reportNumber?: Maybe<Scalars['String']>;
};

export type LabDetailCreateWithoutProductInput = {
  certificate?: Maybe<Scalars['String']>;
  diamond?: Maybe<DiamondCreateNestedOneWithoutLabInput>;
  id?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reportNumber?: Maybe<Scalars['String']>;
};

export type LabDetailInput = {
  name?: Maybe<Scalars['String']>;
  certificate?: Maybe<Scalars['String']>;
  reportNumber?: Maybe<Scalars['String']>;
};

export type LabDetailUpdateOneWithoutDiamondInput = {
  connect?: Maybe<LabDetailWhereUniqueInput>;
  connectOrCreate?: Maybe<LabDetailCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<LabDetailCreateWithoutDiamondInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LabDetailUpdateWithoutDiamondInput>;
  upsert?: Maybe<LabDetailUpsertWithoutDiamondInput>;
};

export type LabDetailUpdateOneWithoutProductInput = {
  connect?: Maybe<LabDetailWhereUniqueInput>;
  connectOrCreate?: Maybe<LabDetailCreateOrConnectWithoutProductInput>;
  create?: Maybe<LabDetailCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LabDetailUpdateWithoutProductInput>;
  upsert?: Maybe<LabDetailUpsertWithoutProductInput>;
};

export type LabDetailUpdateWithoutDiamondInput = {
  certificate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutLabInput>;
  reportNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type LabDetailUpdateWithoutProductInput = {
  certificate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  diamond?: Maybe<DiamondUpdateOneWithoutLabInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imageUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  reportNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type LabDetailUpsertWithoutDiamondInput = {
  create: LabDetailCreateWithoutDiamondInput;
  update: LabDetailUpdateWithoutDiamondInput;
};

export type LabDetailUpsertWithoutProductInput = {
  create: LabDetailCreateWithoutProductInput;
  update: LabDetailUpdateWithoutProductInput;
};

export type LabDetailWhereInput = {
  AND?: Maybe<Array<LabDetailWhereInput>>;
  NOT?: Maybe<Array<LabDetailWhereInput>>;
  OR?: Maybe<Array<LabDetailWhereInput>>;
  certificate?: Maybe<StringNullableFilter>;
  diamond?: Maybe<DiamondWhereInput>;
  diamondId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  imageUrl?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  product?: Maybe<ProductWhereInput>;
  productId?: Maybe<StringNullableFilter>;
  reportNumber?: Maybe<StringNullableFilter>;
};

export type LabDetailWhereUniqueInput = {
  diamondId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type LightPerformance = {
  __typename?: 'LightPerformance';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type LightPerformanceCreateNestedOneWithoutDiamondInput = {
  connect?: Maybe<LightPerformanceWhereUniqueInput>;
  connectOrCreate?: Maybe<LightPerformanceCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<LightPerformanceCreateWithoutDiamondInput>;
};

export type LightPerformanceCreateOrConnectWithoutDiamondInput = {
  create: LightPerformanceCreateWithoutDiamondInput;
  where: LightPerformanceWhereUniqueInput;
};

export type LightPerformanceCreateWithoutDiamondInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type LightPerformanceOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type LightPerformanceUpdateOneWithoutDiamondInput = {
  connect?: Maybe<LightPerformanceWhereUniqueInput>;
  connectOrCreate?: Maybe<LightPerformanceCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<LightPerformanceCreateWithoutDiamondInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LightPerformanceUpdateWithoutDiamondInput>;
  upsert?: Maybe<LightPerformanceUpsertWithoutDiamondInput>;
};

export type LightPerformanceUpdateWithoutDiamondInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type LightPerformanceUpsertWithoutDiamondInput = {
  create: LightPerformanceCreateWithoutDiamondInput;
  update: LightPerformanceUpdateWithoutDiamondInput;
};

export type LightPerformanceWhereInput = {
  AND?: Maybe<Array<LightPerformanceWhereInput>>;
  Diamond?: Maybe<DiamondListRelationFilter>;
  NOT?: Maybe<Array<LightPerformanceWhereInput>>;
  OR?: Maybe<Array<LightPerformanceWhereInput>>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type LightPerformanceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type LoginToken = {
  __typename?: 'LoginToken';
  token: Scalars['String'];
};

export type MetaData = {
  __typename?: 'MetaData';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MetaDataCreateNestedOneWithoutDiamondInput = {
  connect?: Maybe<MetaDataWhereUniqueInput>;
  connectOrCreate?: Maybe<MetaDataCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<MetaDataCreateWithoutDiamondInput>;
};

export type MetaDataCreateNestedOneWithoutProductInput = {
  connect?: Maybe<MetaDataWhereUniqueInput>;
  connectOrCreate?: Maybe<MetaDataCreateOrConnectWithoutProductInput>;
  create?: Maybe<MetaDataCreateWithoutProductInput>;
};

export type MetaDataCreateOrConnectWithoutDiamondInput = {
  create: MetaDataCreateWithoutDiamondInput;
  where: MetaDataWhereUniqueInput;
};

export type MetaDataCreateOrConnectWithoutProductInput = {
  create: MetaDataCreateWithoutProductInput;
  where: MetaDataWhereUniqueInput;
};

export type MetaDataCreateWithoutDiamondInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  product?: Maybe<ProductCreateNestedOneWithoutMetaInput>;
  title?: Maybe<Scalars['String']>;
};

export type MetaDataCreateWithoutProductInput = {
  description?: Maybe<Scalars['String']>;
  diamond?: Maybe<DiamondCreateNestedOneWithoutMetaInput>;
  id?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MetaDataInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
};

export type MetaDataUpdateOneWithoutDiamondInput = {
  connect?: Maybe<MetaDataWhereUniqueInput>;
  connectOrCreate?: Maybe<MetaDataCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<MetaDataCreateWithoutDiamondInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<MetaDataUpdateWithoutDiamondInput>;
  upsert?: Maybe<MetaDataUpsertWithoutDiamondInput>;
};

export type MetaDataUpdateOneWithoutProductInput = {
  connect?: Maybe<MetaDataWhereUniqueInput>;
  connectOrCreate?: Maybe<MetaDataCreateOrConnectWithoutProductInput>;
  create?: Maybe<MetaDataCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<MetaDataUpdateWithoutProductInput>;
  upsert?: Maybe<MetaDataUpsertWithoutProductInput>;
};

export type MetaDataUpdateWithoutDiamondInput = {
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutMetaInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type MetaDataUpdateWithoutProductInput = {
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  diamond?: Maybe<DiamondUpdateOneWithoutMetaInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keywords?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type MetaDataUpsertWithoutDiamondInput = {
  create: MetaDataCreateWithoutDiamondInput;
  update: MetaDataUpdateWithoutDiamondInput;
};

export type MetaDataUpsertWithoutProductInput = {
  create: MetaDataCreateWithoutProductInput;
  update: MetaDataUpdateWithoutProductInput;
};

export type MetaDataWhereInput = {
  AND?: Maybe<Array<MetaDataWhereInput>>;
  NOT?: Maybe<Array<MetaDataWhereInput>>;
  OR?: Maybe<Array<MetaDataWhereInput>>;
  description?: Maybe<StringNullableFilter>;
  diamond?: Maybe<DiamondWhereInput>;
  diamondId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  keywords?: Maybe<StringNullableFilter>;
  product?: Maybe<ProductWhereInput>;
  productId?: Maybe<StringNullableFilter>;
  title?: Maybe<StringNullableFilter>;
};

export type MetaDataWhereUniqueInput = {
  diamondId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type Metal = {
  __typename?: 'Metal';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type MetalCreateNestedOneWithoutProductInput = {
  connect?: Maybe<MetalWhereUniqueInput>;
  connectOrCreate?: Maybe<MetalCreateOrConnectWithoutProductInput>;
  create?: Maybe<MetalCreateWithoutProductInput>;
};

export type MetalCreateOrConnectWithoutProductInput = {
  create: MetalCreateWithoutProductInput;
  where: MetalWhereUniqueInput;
};

export type MetalCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type MetalOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type MetalUpdateOneWithoutProductInput = {
  connect?: Maybe<MetalWhereUniqueInput>;
  connectOrCreate?: Maybe<MetalCreateOrConnectWithoutProductInput>;
  create?: Maybe<MetalCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<MetalUpdateWithoutProductInput>;
  upsert?: Maybe<MetalUpsertWithoutProductInput>;
};

export type MetalUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type MetalUpsertWithoutProductInput = {
  create: MetalCreateWithoutProductInput;
  update: MetalUpdateWithoutProductInput;
};

export type MetalWhereInput = {
  AND?: Maybe<Array<MetalWhereInput>>;
  NOT?: Maybe<Array<MetalWhereInput>>;
  OR?: Maybe<Array<MetalWhereInput>>;
  Product?: Maybe<ProductListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type MetalWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCategory: Category;
  createCollection: Collection;
  createDiamond: Diamond;
  createImage: Image;
  createOptionSet: OptionSet;
  createProduct: Product;
  createVendor: Vendor;
  deleteCategory?: Maybe<Category>;
  deleteCollection?: Maybe<Collection>;
  deleteDiamond?: Maybe<Diamond>;
  deleteImage?: Maybe<Image>;
  deleteImages?: Maybe<Array<Maybe<Image>>>;
  deleteOptionSet?: Maybe<OptionSet>;
  deleteOptionSets: AffectedRowsOutput;
  deleteProduct?: Maybe<Product>;
  deleteProducts: AffectedRowsOutput;
  deleteVendor?: Maybe<Vendor>;
  emailListSignup?: Maybe<Scalars['Boolean']>;
  emailWishlist?: Maybe<Scalars['Boolean']>;
  shareProduct?: Maybe<Scalars['Boolean']>;
  updateCategory?: Maybe<Category>;
  updateCollection?: Maybe<Collection>;
  updateDiamond?: Maybe<Diamond>;
  updateImage?: Maybe<Image>;
  updateImages: AffectedRowsOutput;
  updateOptionSet?: Maybe<OptionSet>;
  updateOptionSets: AffectedRowsOutput;
  updateProduct?: Maybe<Product>;
  updateProducts: AffectedRowsOutput;
  updateVendor?: Maybe<Vendor>;
  updateWishlist: Wishlist;
  uploadImage?: Maybe<Image>;
  uploadImages?: Maybe<Array<Maybe<Image>>>;
  createCustomDesignRequest?: Maybe<CustomDesignRequest>;
  updateCustomDesignRequest?: Maybe<CustomDesignRequest>;
  removeCustomDesignRequest?: Maybe<RemoveResult>;
  sendEmail?: Maybe<SuccessResult>;
  createEmail?: Maybe<Email>;
  updateEmail?: Maybe<Email>;
  removeEmail?: Maybe<Scalars['Boolean']>;
  createFAQ?: Maybe<Faq>;
  updateFAQ?: Maybe<Faq>;
  removeFAQ?: Maybe<Scalars['Boolean']>;
  createFormContact?: Maybe<FormContact>;
  updateFormContact?: Maybe<FormContact>;
  removeFormContact?: Maybe<RemoveResult>;
  createGeneralInquiry?: Maybe<GeneralInquiry>;
  updateGeneralInquiry?: Maybe<GeneralInquiry>;
  removeGeneralInquiry?: Maybe<Scalars['Boolean']>;
  createInvoice?: Maybe<Invoice>;
  updateInvoice?: Maybe<Invoice>;
  removeInvoice?: Maybe<Scalars['Boolean']>;
  sendInvoice?: Maybe<Invoice>;
  createJob?: Maybe<Job>;
  updateJob?: Maybe<Job>;
  removeJob?: Maybe<RemoveResult>;
  exportProductsToFile?: Maybe<Scalars['Boolean']>;
  createOrder?: Maybe<Order>;
  updateOrder?: Maybe<Order>;
  removeOrder?: Maybe<RemoveResult>;
  sendOrderShipmentEmail?: Maybe<SuccessResult>;
  createPayment?: Maybe<Payment>;
  updatePayment?: Maybe<Payment>;
  removePayment?: Maybe<RemoveResult>;
  sendPaymentReceivedEmail?: Maybe<SuccessResult>;
  createPaymentType?: Maybe<PaymentType>;
  updatePaymentType?: Maybe<PaymentType>;
  removePaymentType?: Maybe<RemoveResult>;
  createPermission?: Maybe<Permission>;
  updatePermission?: Maybe<Permission>;
  removePermission?: Maybe<Scalars['Boolean']>;
  createQuote?: Maybe<Quote>;
  updateQuote?: Maybe<Quote>;
  removeQuote?: Maybe<RemoveResult>;
  sendQuote?: Maybe<Quote>;
  createReferral?: Maybe<Referral>;
  updateReferral?: Maybe<Referral>;
  removeReferral?: Maybe<Scalars['Boolean']>;
  createRole?: Maybe<Role>;
  updateRole?: Maybe<Role>;
  removeRole?: Maybe<Scalars['Boolean']>;
  setSetting?: Maybe<Setting>;
  unsetSetting?: Maybe<RemoveResult>;
  createSnippet?: Maybe<Snippet>;
  updateSnippet?: Maybe<Snippet>;
  removeSnippet?: Maybe<Scalars['Boolean']>;
  createStaticPage?: Maybe<StaticPage>;
  updateStaticPage?: Maybe<StaticPage>;
  removeStaticPage?: Maybe<Scalars['Boolean']>;
  createSubscriber?: Maybe<Subscriber>;
  updateSubscriber?: Maybe<Subscriber>;
  removeSubscriber?: Maybe<Scalars['Boolean']>;
  createTestimonial?: Maybe<Testimonial>;
  updateTestimonial?: Maybe<Testimonial>;
  removeTestimonial?: Maybe<Scalars['Boolean']>;
  loginWithPassword?: Maybe<LoginToken>;
  changePassword?: Maybe<SuccessResult>;
  forgotPassword?: Maybe<SuccessResult>;
  resetPassword?: Maybe<SuccessResult>;
  validatePasswordToken?: Maybe<SuccessResult>;
  createUser?: Maybe<User>;
  updateUser?: Maybe<User>;
  removeUser?: Maybe<RemoveResult>;
  buyVirtualProducts?: Maybe<Scalars['JSON']>;
  createWorkOrder?: Maybe<WorkOrder>;
  updateWorkOrder?: Maybe<WorkOrder>;
  removeWorkOrder?: Maybe<RemoveResult>;
  lockWorkOrder?: Maybe<WorkOrder>;
  unlockWorkOrder?: Maybe<WorkOrder>;
  sendWorkOrderApprovalEmail?: Maybe<SuccessResult>;
  sendWorkOrderShipmentEmail?: Maybe<SuccessResult>;
  sendWorkOrderFollowupEmail?: Maybe<SuccessResult>;
  createWorkOrderItem?: Maybe<WorkOrderItem>;
  updateWorkOrderItem?: Maybe<WorkOrderItem>;
  removeWorkOrderItem?: Maybe<RemoveResult>;
};


export type MutationCreateCategoryArgs = {
  data: CategoryCreateInput;
};


export type MutationCreateCollectionArgs = {
  data: CollectionCreateInput;
};


export type MutationCreateDiamondArgs = {
  data: DiamondCreateInput;
};


export type MutationCreateImageArgs = {
  data: ImageCreateInput;
};


export type MutationCreateOptionSetArgs = {
  data: OptionSetCreateInput;
};


export type MutationCreateProductArgs = {
  data: ProductCreateInput;
};


export type MutationCreateVendorArgs = {
  data: VendorCreateInput;
};


export type MutationDeleteCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type MutationDeleteCollectionArgs = {
  where: CollectionWhereUniqueInput;
};


export type MutationDeleteDiamondArgs = {
  where: DiamondWhereUniqueInput;
};


export type MutationDeleteImageArgs = {
  id: Scalars['String'];
};


export type MutationDeleteImagesArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteOptionSetArgs = {
  where: OptionSetWhereUniqueInput;
};


export type MutationDeleteOptionSetsArgs = {
  where?: Maybe<OptionSetWhereInput>;
};


export type MutationDeleteProductArgs = {
  where: ProductWhereUniqueInput;
};


export type MutationDeleteProductsArgs = {
  where?: Maybe<ProductWhereInput>;
};


export type MutationDeleteVendorArgs = {
  where: VendorWhereUniqueInput;
};


export type MutationEmailListSignupArgs = {
  email: Scalars['String'];
};


export type MutationEmailWishlistArgs = {
  email: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};


export type MutationShareProductArgs = {
  input: ShareProductInput;
};


export type MutationUpdateCategoryArgs = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationUpdateCollectionArgs = {
  data: CollectionUpdateInput;
  where: CollectionWhereUniqueInput;
};


export type MutationUpdateDiamondArgs = {
  data: DiamondUpdateInput;
  where: DiamondWhereUniqueInput;
};


export type MutationUpdateImageArgs = {
  data: ImageUpdateInput;
  where: ImageWhereUniqueInput;
};


export type MutationUpdateImagesArgs = {
  data: ImageUpdateManyMutationInput;
  where?: Maybe<ImageWhereInput>;
};


export type MutationUpdateOptionSetArgs = {
  data: OptionSetUpdateInput;
  where: OptionSetWhereUniqueInput;
};


export type MutationUpdateOptionSetsArgs = {
  data: OptionSetUpdateManyMutationInput;
  where?: Maybe<OptionSetWhereInput>;
};


export type MutationUpdateProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpdateProductsArgs = {
  data: ProductUpdateManyMutationInput;
  where?: Maybe<ProductWhereInput>;
};


export type MutationUpdateVendorArgs = {
  data: VendorUpdateInput;
  where: VendorWhereUniqueInput;
};


export type MutationUpdateWishlistArgs = {
  create: WishlistCreateInput;
  update: WishlistUpdateInput;
  where: WishlistWhereUniqueInput;
};


export type MutationUploadImageArgs = {
  data: ImageUploadAttributesInput;
  file: Scalars['Upload'];
};


export type MutationUploadImagesArgs = {
  data: ImageUploadAttributesInput;
  files: Array<Maybe<Scalars['Upload']>>;
};


export type MutationCreateCustomDesignRequestArgs = {
  input: CustomDesignRequestCreateInput;
};


export type MutationUpdateCustomDesignRequestArgs = {
  _id: Scalars['ObjID'];
  input: CustomDesignRequestUpdateInput;
};


export type MutationRemoveCustomDesignRequestArgs = {
  _id: Scalars['ObjID'];
};


export type MutationSendEmailArgs = {
  input: EmailSendInput;
};


export type MutationCreateEmailArgs = {
  input: EmailInput;
};


export type MutationUpdateEmailArgs = {
  _id: Scalars['ObjID'];
  input: EmailInput;
};


export type MutationRemoveEmailArgs = {
  _id: Scalars['ObjID'];
};


export type MutationCreateFaqArgs = {
  input: FaqInput;
};


export type MutationUpdateFaqArgs = {
  _id: Scalars['ObjID'];
  input: FaqInput;
};


export type MutationRemoveFaqArgs = {
  _id: Scalars['ObjID'];
};


export type MutationCreateFormContactArgs = {
  input: CreateUserInput;
};


export type MutationUpdateFormContactArgs = {
  _id: Scalars['ObjID'];
  input: UpdateUserInput;
};


export type MutationRemoveFormContactArgs = {
  _id: Scalars['ObjID'];
};


export type MutationCreateGeneralInquiryArgs = {
  input: GeneralInquiryCreateInput;
};


export type MutationUpdateGeneralInquiryArgs = {
  _id: Scalars['ObjID'];
  input: GeneralInquiryUpdateInput;
};


export type MutationRemoveGeneralInquiryArgs = {
  _id: Scalars['ObjID'];
};


export type MutationCreateInvoiceArgs = {
  input: InvoiceInput;
};


export type MutationUpdateInvoiceArgs = {
  _id: Scalars['ObjID'];
  input: InvoiceInput;
};


export type MutationRemoveInvoiceArgs = {
  _id: Scalars['ObjID'];
};


export type MutationSendInvoiceArgs = {
  _id: Scalars['ObjID'];
};


export type MutationCreateJobArgs = {
  input: JobInput;
};


export type MutationUpdateJobArgs = {
  _id: Scalars['ObjID'];
  input: JobInput;
};


export type MutationRemoveJobArgs = {
  _id: Scalars['ObjID'];
};


export type MutationExportProductsToFileArgs = {
  name: Scalars['String'];
};


export type MutationCreateOrderArgs = {
  input: OrderCreateInput;
};


export type MutationUpdateOrderArgs = {
  _id: Scalars['ObjID'];
  input: OrderUpdateInput;
};


export type MutationRemoveOrderArgs = {
  _id: Scalars['ObjID'];
};


export type MutationSendOrderShipmentEmailArgs = {
  input: OrderEmailInput;
};


export type MutationCreatePaymentArgs = {
  input: PaymentCreateInput;
};


export type MutationUpdatePaymentArgs = {
  _id: Scalars['ObjID'];
  input: PaymentUpdateInput;
};


export type MutationRemovePaymentArgs = {
  _id: Scalars['ObjID'];
};


export type MutationSendPaymentReceivedEmailArgs = {
  input: PaymentReceivedEmailInput;
};


export type MutationCreatePaymentTypeArgs = {
  input: PaymentTypeInput;
};


export type MutationUpdatePaymentTypeArgs = {
  _id: Scalars['ObjID'];
  input: PaymentTypeInput;
};


export type MutationRemovePaymentTypeArgs = {
  _id: Scalars['ObjID'];
};


export type MutationCreatePermissionArgs = {
  input: PermissionInput;
};


export type MutationUpdatePermissionArgs = {
  _id: Scalars['ObjID'];
  input: PermissionInput;
};


export type MutationRemovePermissionArgs = {
  _id: Scalars['ObjID'];
};


export type MutationCreateQuoteArgs = {
  input: QuoteInput;
};


export type MutationUpdateQuoteArgs = {
  _id: Scalars['ObjID'];
  input: QuoteInput;
};


export type MutationRemoveQuoteArgs = {
  _id: Scalars['ObjID'];
};


export type MutationSendQuoteArgs = {
  _id: Scalars['ObjID'];
};


export type MutationCreateReferralArgs = {
  input: ReferralInput;
};


export type MutationUpdateReferralArgs = {
  _id: Scalars['ObjID'];
  input: ReferralInput;
};


export type MutationRemoveReferralArgs = {
  _id: Scalars['ObjID'];
};


export type MutationCreateRoleArgs = {
  input: RoleCreateInput;
};


export type MutationUpdateRoleArgs = {
  _id: Scalars['ObjID'];
  input: RoleUpdateInput;
};


export type MutationRemoveRoleArgs = {
  _id: Scalars['ObjID'];
};


export type MutationSetSettingArgs = {
  key: Scalars['String'];
  value: Scalars['String'];
};


export type MutationUnsetSettingArgs = {
  _id?: Maybe<Scalars['ObjID']>;
  key?: Maybe<Scalars['String']>;
};


export type MutationCreateSnippetArgs = {
  input: SnippetInput;
};


export type MutationUpdateSnippetArgs = {
  _id: Scalars['ObjID'];
  input: SnippetInput;
};


export type MutationRemoveSnippetArgs = {
  _id: Scalars['ObjID'];
};


export type MutationCreateStaticPageArgs = {
  input: StaticPageCreateInput;
};


export type MutationUpdateStaticPageArgs = {
  _id: Scalars['ObjID'];
  input: StaticPageUpdateInput;
};


export type MutationRemoveStaticPageArgs = {
  _id: Scalars['ObjID'];
};


export type MutationCreateSubscriberArgs = {
  input: SubscriberInput;
};


export type MutationUpdateSubscriberArgs = {
  _id: Scalars['ObjID'];
  input: SubscriberInput;
};


export type MutationRemoveSubscriberArgs = {
  _id: Scalars['ObjID'];
};


export type MutationCreateTestimonialArgs = {
  input: TestimonialInput;
};


export type MutationUpdateTestimonialArgs = {
  _id: Scalars['ObjID'];
  input: TestimonialInput;
};


export type MutationRemoveTestimonialArgs = {
  _id: Scalars['ObjID'];
};


export type MutationLoginWithPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
  userId?: Maybe<Scalars['ObjID']>;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};


export type MutationValidatePasswordTokenArgs = {
  token: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  _id: Scalars['ObjID'];
  input: UpdateUserInput;
};


export type MutationRemoveUserArgs = {
  _id: Scalars['ObjID'];
};


export type MutationBuyVirtualProductsArgs = {
  productIds: Array<Scalars['String']>;
};


export type MutationCreateWorkOrderArgs = {
  input: WorkOrderInput;
};


export type MutationUpdateWorkOrderArgs = {
  _id: Scalars['ObjID'];
  input: WorkOrderInput;
};


export type MutationRemoveWorkOrderArgs = {
  _id: Scalars['ObjID'];
};


export type MutationLockWorkOrderArgs = {
  _id: Scalars['ObjID'];
};


export type MutationUnlockWorkOrderArgs = {
  _id: Scalars['ObjID'];
};


export type MutationSendWorkOrderApprovalEmailArgs = {
  input: WorkOrderEmailInput;
};


export type MutationSendWorkOrderShipmentEmailArgs = {
  input: WorkOrderEmailInput;
};


export type MutationSendWorkOrderFollowupEmailArgs = {
  input: WorkOrderEmailInput;
};


export type MutationCreateWorkOrderItemArgs = {
  input: WorkOrderItemInput;
};


export type MutationUpdateWorkOrderItemArgs = {
  _id: Scalars['ObjID'];
  input: WorkOrderItemInput;
};


export type MutationRemoveWorkOrderItemArgs = {
  _id: Scalars['ObjID'];
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumVendorSourceFilter = {
  equals?: Maybe<VendorSource>;
  in?: Maybe<Array<VendorSource>>;
  not?: Maybe<NestedEnumVendorSourceFilter>;
  notIn?: Maybe<Array<VendorSource>>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  set?: Maybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};


export type Option = {
  __typename?: 'Option';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  optionSet: OptionSet;
  products: Array<Product>;
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type OptionProductsArgs = {
  after?: Maybe<ProductWhereUniqueInput>;
  before?: Maybe<ProductWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProductOrderByInput>>;
  where?: Maybe<ProductWhereInput>;
};

export type OptionCreateManyOptionSetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OptionCreateManyOptionSetInputEnvelope = {
  data?: Maybe<Array<OptionCreateManyOptionSetInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OptionCreateNestedManyWithoutOptionSetInput = {
  connect?: Maybe<Array<OptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OptionCreateOrConnectWithoutOptionSetInput>>;
  create?: Maybe<Array<OptionCreateWithoutOptionSetInput>>;
  createMany?: Maybe<OptionCreateManyOptionSetInputEnvelope>;
};

export type OptionCreateNestedManyWithoutProductsInput = {
  connect?: Maybe<Array<OptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OptionCreateOrConnectWithoutProductsInput>>;
  create?: Maybe<Array<OptionCreateWithoutProductsInput>>;
};

export type OptionCreateOrConnectWithoutOptionSetInput = {
  create: OptionCreateWithoutOptionSetInput;
  where: OptionWhereUniqueInput;
};

export type OptionCreateOrConnectWithoutProductsInput = {
  create: OptionCreateWithoutProductsInput;
  where: OptionWhereUniqueInput;
};

export type OptionCreateWithoutOptionSetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  products?: Maybe<ProductCreateNestedManyWithoutOptionsInput>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OptionCreateWithoutProductsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  optionSet: OptionSetCreateNestedOneWithoutOptionsInput;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OptionListRelationFilter = {
  every?: Maybe<OptionWhereInput>;
  none?: Maybe<OptionWhereInput>;
  some?: Maybe<OptionWhereInput>;
};

export type OptionOptionSetIdNameCompoundUniqueInput = {
  name: Scalars['String'];
  optionSetId: Scalars['String'];
};

export type OptionOptionSetIdSlugCompoundUniqueInput = {
  optionSetId: Scalars['String'];
  slug: Scalars['String'];
};

export type OptionOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  optionSetId?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OptionScalarWhereInput = {
  AND?: Maybe<Array<OptionScalarWhereInput>>;
  NOT?: Maybe<Array<OptionScalarWhereInput>>;
  OR?: Maybe<Array<OptionScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  optionSetId?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OptionSet = {
  __typename?: 'OptionSet';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  options: Array<Option>;
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type OptionSetOptionsArgs = {
  after?: Maybe<OptionWhereUniqueInput>;
  before?: Maybe<OptionWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OptionOrderByInput>>;
  where?: Maybe<OptionWhereInput>;
};

export type OptionSetCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  options?: Maybe<OptionCreateNestedManyWithoutOptionSetInput>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OptionSetCreateNestedOneWithoutOptionsInput = {
  connect?: Maybe<OptionSetWhereUniqueInput>;
  connectOrCreate?: Maybe<OptionSetCreateOrConnectWithoutOptionsInput>;
  create?: Maybe<OptionSetCreateWithoutOptionsInput>;
};

export type OptionSetCreateOrConnectWithoutOptionsInput = {
  create: OptionSetCreateWithoutOptionsInput;
  where: OptionSetWhereUniqueInput;
};

export type OptionSetCreateWithoutOptionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OptionSetOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OptionSetUpdateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  options?: Maybe<OptionUpdateManyWithoutOptionSetInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OptionSetUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OptionSetUpdateOneRequiredWithoutOptionsInput = {
  connect?: Maybe<OptionSetWhereUniqueInput>;
  connectOrCreate?: Maybe<OptionSetCreateOrConnectWithoutOptionsInput>;
  create?: Maybe<OptionSetCreateWithoutOptionsInput>;
  update?: Maybe<OptionSetUpdateWithoutOptionsInput>;
  upsert?: Maybe<OptionSetUpsertWithoutOptionsInput>;
};

export type OptionSetUpdateWithoutOptionsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OptionSetUpsertWithoutOptionsInput = {
  create: OptionSetCreateWithoutOptionsInput;
  update: OptionSetUpdateWithoutOptionsInput;
};

export type OptionSetWhereInput = {
  AND?: Maybe<Array<OptionSetWhereInput>>;
  NOT?: Maybe<Array<OptionSetWhereInput>>;
  OR?: Maybe<Array<OptionSetWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  options?: Maybe<OptionListRelationFilter>;
  slug?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OptionSetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type OptionUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OptionUpdateManyWithoutOptionSetInput = {
  connect?: Maybe<Array<OptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OptionCreateOrConnectWithoutOptionSetInput>>;
  create?: Maybe<Array<OptionCreateWithoutOptionSetInput>>;
  createMany?: Maybe<OptionCreateManyOptionSetInputEnvelope>;
  delete?: Maybe<Array<OptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OptionScalarWhereInput>>;
  disconnect?: Maybe<Array<OptionWhereUniqueInput>>;
  set?: Maybe<Array<OptionWhereUniqueInput>>;
  update?: Maybe<Array<OptionUpdateWithWhereUniqueWithoutOptionSetInput>>;
  updateMany?: Maybe<Array<OptionUpdateManyWithWhereWithoutOptionSetInput>>;
  upsert?: Maybe<Array<OptionUpsertWithWhereUniqueWithoutOptionSetInput>>;
};

export type OptionUpdateManyWithoutProductsInput = {
  connect?: Maybe<Array<OptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OptionCreateOrConnectWithoutProductsInput>>;
  create?: Maybe<Array<OptionCreateWithoutProductsInput>>;
  delete?: Maybe<Array<OptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OptionScalarWhereInput>>;
  disconnect?: Maybe<Array<OptionWhereUniqueInput>>;
  set?: Maybe<Array<OptionWhereUniqueInput>>;
  update?: Maybe<Array<OptionUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: Maybe<Array<OptionUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: Maybe<Array<OptionUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type OptionUpdateManyWithWhereWithoutOptionSetInput = {
  data: OptionUpdateManyMutationInput;
  where: OptionScalarWhereInput;
};

export type OptionUpdateManyWithWhereWithoutProductsInput = {
  data: OptionUpdateManyMutationInput;
  where: OptionScalarWhereInput;
};

export type OptionUpdateWithoutOptionSetInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutOptionsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OptionUpdateWithoutProductsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  optionSet?: Maybe<OptionSetUpdateOneRequiredWithoutOptionsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OptionUpdateWithWhereUniqueWithoutOptionSetInput = {
  data: OptionUpdateWithoutOptionSetInput;
  where: OptionWhereUniqueInput;
};

export type OptionUpdateWithWhereUniqueWithoutProductsInput = {
  data: OptionUpdateWithoutProductsInput;
  where: OptionWhereUniqueInput;
};

export type OptionUpsertWithWhereUniqueWithoutOptionSetInput = {
  create: OptionCreateWithoutOptionSetInput;
  update: OptionUpdateWithoutOptionSetInput;
  where: OptionWhereUniqueInput;
};

export type OptionUpsertWithWhereUniqueWithoutProductsInput = {
  create: OptionCreateWithoutProductsInput;
  update: OptionUpdateWithoutProductsInput;
  where: OptionWhereUniqueInput;
};

export type OptionWhereInput = {
  AND?: Maybe<Array<OptionWhereInput>>;
  NOT?: Maybe<Array<OptionWhereInput>>;
  OR?: Maybe<Array<OptionWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  optionSet?: Maybe<OptionSetWhereInput>;
  optionSetId?: Maybe<StringFilter>;
  products?: Maybe<ProductListRelationFilter>;
  slug?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  optionSetId_name?: Maybe<OptionOptionSetIdNameCompoundUniqueInput>;
  optionSetId_slug?: Maybe<OptionOptionSetIdSlugCompoundUniqueInput>;
};

export type Order = {
  __typename?: 'Order';
  _id: Scalars['ObjID'];
  customerNote?: Maybe<Scalars['String']>;
  hasTax?: Maybe<Scalars['Boolean']>;
  hasCCFee?: Maybe<Scalars['Boolean']>;
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars['ObjID']>;
  isBankWire?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<OrderItem>>>;
  number?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Int']>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  paymentMethod?: Maybe<Scalars['String']>;
  referral?: Maybe<Referral>;
  referralId?: Maybe<Scalars['ObjID']>;
  shipping?: Maybe<Scalars['Int']>;
  shippingAddress?: Maybe<OrderAddress>;
  billingAddress?: Maybe<OrderAddress>;
  shipments?: Maybe<Array<Maybe<Shipment>>>;
  status?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalPayments?: Maybe<Scalars['Int']>;
  transactionId?: Maybe<Scalars['ObjID']>;
  userId: Scalars['ObjID'];
  user?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ObjID']>;
  createdByUser?: Maybe<User>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['ObjID']>;
  updatedByUser?: Maybe<User>;
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type OrderAddressInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type OrderCreateInput = {
  billingAddress: OrderAddressInput;
  shippingAddress: OrderAddressInput;
  billingIsSame: Scalars['Boolean'];
  invoiceId?: Maybe<Scalars['ObjID']>;
  items: Array<OrderItemInput>;
  hasCCFee: Scalars['Boolean'];
  payment: OrderPaymentInput;
  paymentMethod?: Maybe<Scalars['String']>;
  referralId?: Maybe<Scalars['ObjID']>;
  customerNote?: Maybe<Scalars['String']>;
};

export type OrderEmailInput = {
  data?: Maybe<Scalars['JSON']>;
  from?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  template?: Maybe<Scalars['String']>;
  to: Scalars['String'];
  orderId: Scalars['ObjID'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  title?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['String']>;
  representativeImage?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  fingerSize?: Maybe<Scalars['String']>;
  returnedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemInput = {
  title: Scalars['String'];
  unitPrice: Scalars['Int'];
  quantity: Scalars['Float'];
  productId: Scalars['String'];
  sku: Scalars['String'];
  type: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  fingerSize?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['String']>;
};

export type OrderItemProduct = {
  __typename?: 'OrderItemProduct';
  _id: Scalars['ObjID'];
  description?: Maybe<Scalars['String']>;
  representativeImage?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OrderPaymentInput = {
  amount: Scalars['Int'];
  creditCard?: Maybe<CreditCardPaymentInput>;
  paymentMethod: Scalars['String'];
};

export type OrderUpdateInput = {
  hasCCFee?: Maybe<Scalars['Boolean']>;
  hasTax?: Maybe<Scalars['Boolean']>;
  invoiceId?: Maybe<Scalars['ObjID']>;
  isBankWire?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<OrderItemInput>>>;
  referralId?: Maybe<Scalars['ObjID']>;
  shipping?: Maybe<Scalars['Int']>;
  shippingAddress?: Maybe<OrderAddressInput>;
  billingAddress?: Maybe<OrderAddressInput>;
  status?: Maybe<Scalars['String']>;
  payment?: Maybe<OrderPaymentInput>;
  paymentMethod?: Maybe<Scalars['String']>;
  customerNote?: Maybe<Scalars['String']>;
};

export type Pave = {
  __typename?: 'Pave';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type PaveCreateNestedOneWithoutProductInput = {
  connect?: Maybe<PaveWhereUniqueInput>;
  connectOrCreate?: Maybe<PaveCreateOrConnectWithoutProductInput>;
  create?: Maybe<PaveCreateWithoutProductInput>;
};

export type PaveCreateOrConnectWithoutProductInput = {
  create: PaveCreateWithoutProductInput;
  where: PaveWhereUniqueInput;
};

export type PaveCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type PaveDiamondType = {
  __typename?: 'PaveDiamondType';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type PaveDiamondTypeCreateNestedOneWithoutProductInput = {
  connect?: Maybe<PaveDiamondTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PaveDiamondTypeCreateOrConnectWithoutProductInput>;
  create?: Maybe<PaveDiamondTypeCreateWithoutProductInput>;
};

export type PaveDiamondTypeCreateOrConnectWithoutProductInput = {
  create: PaveDiamondTypeCreateWithoutProductInput;
  where: PaveDiamondTypeWhereUniqueInput;
};

export type PaveDiamondTypeCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type PaveDiamondTypeOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type PaveDiamondTypeUpdateOneWithoutProductInput = {
  connect?: Maybe<PaveDiamondTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PaveDiamondTypeCreateOrConnectWithoutProductInput>;
  create?: Maybe<PaveDiamondTypeCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PaveDiamondTypeUpdateWithoutProductInput>;
  upsert?: Maybe<PaveDiamondTypeUpsertWithoutProductInput>;
};

export type PaveDiamondTypeUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PaveDiamondTypeUpsertWithoutProductInput = {
  create: PaveDiamondTypeCreateWithoutProductInput;
  update: PaveDiamondTypeUpdateWithoutProductInput;
};

export type PaveDiamondTypeWhereInput = {
  AND?: Maybe<Array<PaveDiamondTypeWhereInput>>;
  NOT?: Maybe<Array<PaveDiamondTypeWhereInput>>;
  OR?: Maybe<Array<PaveDiamondTypeWhereInput>>;
  Product?: Maybe<ProductListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type PaveDiamondTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PaveOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type PaveUpdateOneWithoutProductInput = {
  connect?: Maybe<PaveWhereUniqueInput>;
  connectOrCreate?: Maybe<PaveCreateOrConnectWithoutProductInput>;
  create?: Maybe<PaveCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PaveUpdateWithoutProductInput>;
  upsert?: Maybe<PaveUpsertWithoutProductInput>;
};

export type PaveUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PaveUpsertWithoutProductInput = {
  create: PaveCreateWithoutProductInput;
  update: PaveUpdateWithoutProductInput;
};

export type PaveWhereInput = {
  AND?: Maybe<Array<PaveWhereInput>>;
  NOT?: Maybe<Array<PaveWhereInput>>;
  OR?: Maybe<Array<PaveWhereInput>>;
  Product?: Maybe<ProductListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type PaveWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  _id: Scalars['ObjID'];
  amount?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['ObjID']>;
  createdByUser?: Maybe<User>;
  invoiceId?: Maybe<Scalars['ObjID']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['ObjID']>;
  workOrder?: Maybe<WorkOrder>;
  workOrderId?: Maybe<Scalars['ObjID']>;
  paymentType?: Maybe<Scalars['String']>;
  paymentTypeId?: Maybe<Scalars['ObjID']>;
  status?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjID']>;
  user?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['ObjID']>;
  updatedByUser?: Maybe<User>;
};

export type PaymentCreateInput = {
  amount: Scalars['Int'];
  invoiceId?: Maybe<Scalars['ObjID']>;
  orderId?: Maybe<Scalars['ObjID']>;
  paymentTypeId: Scalars['ObjID'];
  status?: Maybe<Scalars['String']>;
  workOrderId?: Maybe<Scalars['ObjID']>;
  userId: Scalars['ObjID'];
};

export type PaymentReceivedEmailInput = {
  to?: Maybe<Scalars['String']>;
  paymentId: Scalars['ObjID'];
};

export type PaymentType = {
  __typename?: 'PaymentType';
  _id: Scalars['ObjID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentTypeInput = {
  name?: Maybe<Scalars['String']>;
};

export type PaymentUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  invoiceId?: Maybe<Scalars['ObjID']>;
  orderId?: Maybe<Scalars['ObjID']>;
  paymentTypeId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  workOrderId?: Maybe<Scalars['ObjID']>;
  userId?: Maybe<Scalars['ObjID']>;
};

export type Permission = {
  __typename?: 'Permission';
  _id: Scalars['ObjID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PermissionInput = {
  name: Scalars['String'];
};

export type Polish = {
  __typename?: 'Polish';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type PolishCreateNestedOneWithoutDiamondInput = {
  connect?: Maybe<PolishWhereUniqueInput>;
  connectOrCreate?: Maybe<PolishCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<PolishCreateWithoutDiamondInput>;
};

export type PolishCreateOrConnectWithoutDiamondInput = {
  create: PolishCreateWithoutDiamondInput;
  where: PolishWhereUniqueInput;
};

export type PolishCreateWithoutDiamondInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type PolishOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type PolishUpdateOneWithoutDiamondInput = {
  connect?: Maybe<PolishWhereUniqueInput>;
  connectOrCreate?: Maybe<PolishCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<PolishCreateWithoutDiamondInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PolishUpdateWithoutDiamondInput>;
  upsert?: Maybe<PolishUpsertWithoutDiamondInput>;
};

export type PolishUpdateWithoutDiamondInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PolishUpsertWithoutDiamondInput = {
  create: PolishCreateWithoutDiamondInput;
  update: PolishUpdateWithoutDiamondInput;
};

export type PolishWhereInput = {
  AND?: Maybe<Array<PolishWhereInput>>;
  Diamond?: Maybe<DiamondListRelationFilter>;
  NOT?: Maybe<Array<PolishWhereInput>>;
  OR?: Maybe<Array<PolishWhereInput>>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type PolishWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  allowCheckout: Scalars['Boolean'];
  availability?: Maybe<Availability>;
  availabilityId?: Maybe<Scalars['String']>;
  bandType?: Maybe<BandType>;
  bandTypeId?: Maybe<Scalars['String']>;
  carat: Scalars['Float'];
  categories: Array<Category>;
  collections: Array<Collection>;
  cost?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  diamondShape?: Maybe<DiamondShape>;
  diamondShapeId?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  fingerSize?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  images: Array<Image>;
  inStock?: Maybe<Scalars['Boolean']>;
  isBand: Scalars['Boolean'];
  isDefaultVariant: Scalars['Boolean'];
  isEngagementRing: Scalars['Boolean'];
  isHandFabricated: Scalars['Boolean'];
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing: Scalars['Boolean'];
  lab?: Maybe<LabDetail>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  meta?: Maybe<MetaData>;
  metal?: Maybe<Metal>;
  metalId?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  options: Array<Option>;
  parent?: Maybe<Product>;
  parentId?: Maybe<Scalars['String']>;
  pave?: Maybe<Pave>;
  paveDiamondType?: Maybe<PaveDiamondType>;
  paveDiamondTypeId?: Maybe<Scalars['String']>;
  paveId?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  productType?: Maybe<ProductType>;
  productTypeId?: Maybe<Scalars['String']>;
  prong?: Maybe<Prong>;
  prongId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  representativeImage?: Maybe<Scalars['String']>;
  ringType?: Maybe<RingType>;
  ringTypeId?: Maybe<Scalars['String']>;
  salePrice?: Maybe<Scalars['Float']>;
  shank?: Maybe<Shank>;
  shankId?: Maybe<Scalars['String']>;
  shankThickness?: Maybe<ShankThickness>;
  shankThicknessId?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  variants: Array<Product>;
  video?: Maybe<Video>;
};


export type ProductCategoriesArgs = {
  after?: Maybe<CategoryWhereUniqueInput>;
  before?: Maybe<CategoryWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CategoryOrderByInput>>;
  where?: Maybe<CategoryWhereInput>;
};


export type ProductCollectionsArgs = {
  after?: Maybe<CollectionWhereUniqueInput>;
  before?: Maybe<CollectionWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CollectionOrderByInput>>;
  where?: Maybe<CollectionWhereInput>;
};


export type ProductImagesArgs = {
  after?: Maybe<ImageWhereUniqueInput>;
  before?: Maybe<ImageWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ImageOrderByInput>>;
  where?: Maybe<ImageWhereInput>;
};


export type ProductOptionsArgs = {
  after?: Maybe<OptionWhereUniqueInput>;
  before?: Maybe<OptionWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OptionOrderByInput>>;
  where?: Maybe<OptionWhereInput>;
};


export type ProductVariantsArgs = {
  after?: Maybe<ProductWhereUniqueInput>;
  before?: Maybe<ProductWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProductOrderByInput>>;
  where?: Maybe<ProductWhereInput>;
};

export type ProductCreateInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutProductInput>;
  bandType?: Maybe<BandTypeCreateNestedOneWithoutProductInput>;
  carat?: Maybe<Scalars['Float']>;
  categories?: Maybe<CategoryCreateNestedManyWithoutProductsInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutProductsInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  diamondShape?: Maybe<DiamondShapeCreateNestedOneWithoutProductInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutProductInput>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutProductInput>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutProductInput>;
  metal?: Maybe<MetalCreateNestedOneWithoutProductInput>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  options?: Maybe<OptionCreateNestedManyWithoutProductsInput>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  pave?: Maybe<PaveCreateNestedOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeCreateNestedOneWithoutProductInput>;
  price: Scalars['Int'];
  productType?: Maybe<ProductTypeCreateNestedOneWithoutProductInput>;
  prong?: Maybe<ProngCreateNestedOneWithoutProductInput>;
  quantity?: Maybe<Scalars['Float']>;
  ringType?: Maybe<RingTypeCreateNestedOneWithoutProductInput>;
  salePrice?: Maybe<Scalars['Float']>;
  shank?: Maybe<ShankCreateNestedOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessCreateNestedOneWithoutProductInput>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  video?: Maybe<VideoCreateNestedOneWithoutProductInput>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutProductsInput>;
};

export type ProductCreateManyDiamondShapeInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availabilityId?: Maybe<Scalars['String']>;
  bandTypeId?: Maybe<Scalars['String']>;
  carat?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  metalId?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['String']>;
  paveDiamondTypeId?: Maybe<Scalars['String']>;
  paveId?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  productTypeId?: Maybe<Scalars['String']>;
  prongId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  ringTypeId?: Maybe<Scalars['String']>;
  salePrice?: Maybe<Scalars['Float']>;
  shankId?: Maybe<Scalars['String']>;
  shankThicknessId?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCreateManyDiamondShapeInputEnvelope = {
  data?: Maybe<Array<ProductCreateManyDiamondShapeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateManyParentInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availabilityId?: Maybe<Scalars['String']>;
  bandTypeId?: Maybe<Scalars['String']>;
  carat?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  diamondShapeId?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  metalId?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  paveDiamondTypeId?: Maybe<Scalars['String']>;
  paveId?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  productTypeId?: Maybe<Scalars['String']>;
  prongId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  ringTypeId?: Maybe<Scalars['String']>;
  salePrice?: Maybe<Scalars['Float']>;
  shankId?: Maybe<Scalars['String']>;
  shankThicknessId?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCreateManyParentInputEnvelope = {
  data?: Maybe<Array<ProductCreateManyParentInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateNestedManyWithoutCategoriesInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutCategoriesInput>>;
  create?: Maybe<Array<ProductCreateWithoutCategoriesInput>>;
};

export type ProductCreateNestedManyWithoutCollectionsInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutCollectionsInput>>;
  create?: Maybe<Array<ProductCreateWithoutCollectionsInput>>;
};

export type ProductCreateNestedManyWithoutDiamondShapeInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutDiamondShapeInput>>;
  create?: Maybe<Array<ProductCreateWithoutDiamondShapeInput>>;
  createMany?: Maybe<ProductCreateManyDiamondShapeInputEnvelope>;
};

export type ProductCreateNestedManyWithoutOptionsInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutOptionsInput>>;
  create?: Maybe<Array<ProductCreateWithoutOptionsInput>>;
};

export type ProductCreateNestedManyWithoutParentInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<ProductCreateWithoutParentInput>>;
  createMany?: Maybe<ProductCreateManyParentInputEnvelope>;
};

export type ProductCreateNestedManyWithoutWishlistsInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutWishlistsInput>>;
  create?: Maybe<Array<ProductCreateWithoutWishlistsInput>>;
};

export type ProductCreateNestedOneWithoutImagesInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutImagesInput>;
  create?: Maybe<ProductCreateWithoutImagesInput>;
};

export type ProductCreateNestedOneWithoutLabInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutLabInput>;
  create?: Maybe<ProductCreateWithoutLabInput>;
};

export type ProductCreateNestedOneWithoutMetaInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutMetaInput>;
  create?: Maybe<ProductCreateWithoutMetaInput>;
};

export type ProductCreateNestedOneWithoutVariantsInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutVariantsInput>;
  create?: Maybe<ProductCreateWithoutVariantsInput>;
};

export type ProductCreateOrConnectWithoutCategoriesInput = {
  create: ProductCreateWithoutCategoriesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutCollectionsInput = {
  create: ProductCreateWithoutCollectionsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutDiamondShapeInput = {
  create: ProductCreateWithoutDiamondShapeInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutImagesInput = {
  create: ProductCreateWithoutImagesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutLabInput = {
  create: ProductCreateWithoutLabInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutMetaInput = {
  create: ProductCreateWithoutMetaInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutOptionsInput = {
  create: ProductCreateWithoutOptionsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutParentInput = {
  create: ProductCreateWithoutParentInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutVariantsInput = {
  create: ProductCreateWithoutVariantsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutWishlistsInput = {
  create: ProductCreateWithoutWishlistsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutCategoriesInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutProductInput>;
  bandType?: Maybe<BandTypeCreateNestedOneWithoutProductInput>;
  carat?: Maybe<Scalars['Float']>;
  collections?: Maybe<CollectionCreateNestedManyWithoutProductsInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  diamondShape?: Maybe<DiamondShapeCreateNestedOneWithoutProductInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutProductInput>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutProductInput>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutProductInput>;
  metal?: Maybe<MetalCreateNestedOneWithoutProductInput>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  options?: Maybe<OptionCreateNestedManyWithoutProductsInput>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  pave?: Maybe<PaveCreateNestedOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeCreateNestedOneWithoutProductInput>;
  price: Scalars['Int'];
  productType?: Maybe<ProductTypeCreateNestedOneWithoutProductInput>;
  prong?: Maybe<ProngCreateNestedOneWithoutProductInput>;
  quantity?: Maybe<Scalars['Float']>;
  ringType?: Maybe<RingTypeCreateNestedOneWithoutProductInput>;
  salePrice?: Maybe<Scalars['Float']>;
  shank?: Maybe<ShankCreateNestedOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessCreateNestedOneWithoutProductInput>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  video?: Maybe<VideoCreateNestedOneWithoutProductInput>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutProductsInput>;
};

export type ProductCreateWithoutCollectionsInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutProductInput>;
  bandType?: Maybe<BandTypeCreateNestedOneWithoutProductInput>;
  carat?: Maybe<Scalars['Float']>;
  categories?: Maybe<CategoryCreateNestedManyWithoutProductsInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  diamondShape?: Maybe<DiamondShapeCreateNestedOneWithoutProductInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutProductInput>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutProductInput>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutProductInput>;
  metal?: Maybe<MetalCreateNestedOneWithoutProductInput>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  options?: Maybe<OptionCreateNestedManyWithoutProductsInput>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  pave?: Maybe<PaveCreateNestedOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeCreateNestedOneWithoutProductInput>;
  price: Scalars['Int'];
  productType?: Maybe<ProductTypeCreateNestedOneWithoutProductInput>;
  prong?: Maybe<ProngCreateNestedOneWithoutProductInput>;
  quantity?: Maybe<Scalars['Float']>;
  ringType?: Maybe<RingTypeCreateNestedOneWithoutProductInput>;
  salePrice?: Maybe<Scalars['Float']>;
  shank?: Maybe<ShankCreateNestedOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessCreateNestedOneWithoutProductInput>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  video?: Maybe<VideoCreateNestedOneWithoutProductInput>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutProductsInput>;
};

export type ProductCreateWithoutDiamondShapeInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutProductInput>;
  bandType?: Maybe<BandTypeCreateNestedOneWithoutProductInput>;
  carat?: Maybe<Scalars['Float']>;
  categories?: Maybe<CategoryCreateNestedManyWithoutProductsInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutProductsInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutProductInput>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutProductInput>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutProductInput>;
  metal?: Maybe<MetalCreateNestedOneWithoutProductInput>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  options?: Maybe<OptionCreateNestedManyWithoutProductsInput>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  pave?: Maybe<PaveCreateNestedOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeCreateNestedOneWithoutProductInput>;
  price: Scalars['Int'];
  productType?: Maybe<ProductTypeCreateNestedOneWithoutProductInput>;
  prong?: Maybe<ProngCreateNestedOneWithoutProductInput>;
  quantity?: Maybe<Scalars['Float']>;
  ringType?: Maybe<RingTypeCreateNestedOneWithoutProductInput>;
  salePrice?: Maybe<Scalars['Float']>;
  shank?: Maybe<ShankCreateNestedOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessCreateNestedOneWithoutProductInput>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  video?: Maybe<VideoCreateNestedOneWithoutProductInput>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutProductsInput>;
};

export type ProductCreateWithoutImagesInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutProductInput>;
  bandType?: Maybe<BandTypeCreateNestedOneWithoutProductInput>;
  carat?: Maybe<Scalars['Float']>;
  categories?: Maybe<CategoryCreateNestedManyWithoutProductsInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutProductsInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  diamondShape?: Maybe<DiamondShapeCreateNestedOneWithoutProductInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutProductInput>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutProductInput>;
  metal?: Maybe<MetalCreateNestedOneWithoutProductInput>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  options?: Maybe<OptionCreateNestedManyWithoutProductsInput>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  pave?: Maybe<PaveCreateNestedOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeCreateNestedOneWithoutProductInput>;
  price: Scalars['Int'];
  productType?: Maybe<ProductTypeCreateNestedOneWithoutProductInput>;
  prong?: Maybe<ProngCreateNestedOneWithoutProductInput>;
  quantity?: Maybe<Scalars['Float']>;
  ringType?: Maybe<RingTypeCreateNestedOneWithoutProductInput>;
  salePrice?: Maybe<Scalars['Float']>;
  shank?: Maybe<ShankCreateNestedOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessCreateNestedOneWithoutProductInput>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  video?: Maybe<VideoCreateNestedOneWithoutProductInput>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutProductsInput>;
};

export type ProductCreateWithoutLabInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutProductInput>;
  bandType?: Maybe<BandTypeCreateNestedOneWithoutProductInput>;
  carat?: Maybe<Scalars['Float']>;
  categories?: Maybe<CategoryCreateNestedManyWithoutProductsInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutProductsInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  diamondShape?: Maybe<DiamondShapeCreateNestedOneWithoutProductInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutProductInput>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutProductInput>;
  metal?: Maybe<MetalCreateNestedOneWithoutProductInput>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  options?: Maybe<OptionCreateNestedManyWithoutProductsInput>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  pave?: Maybe<PaveCreateNestedOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeCreateNestedOneWithoutProductInput>;
  price: Scalars['Int'];
  productType?: Maybe<ProductTypeCreateNestedOneWithoutProductInput>;
  prong?: Maybe<ProngCreateNestedOneWithoutProductInput>;
  quantity?: Maybe<Scalars['Float']>;
  ringType?: Maybe<RingTypeCreateNestedOneWithoutProductInput>;
  salePrice?: Maybe<Scalars['Float']>;
  shank?: Maybe<ShankCreateNestedOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessCreateNestedOneWithoutProductInput>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  video?: Maybe<VideoCreateNestedOneWithoutProductInput>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutProductsInput>;
};

export type ProductCreateWithoutMetaInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutProductInput>;
  bandType?: Maybe<BandTypeCreateNestedOneWithoutProductInput>;
  carat?: Maybe<Scalars['Float']>;
  categories?: Maybe<CategoryCreateNestedManyWithoutProductsInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutProductsInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  diamondShape?: Maybe<DiamondShapeCreateNestedOneWithoutProductInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutProductInput>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutProductInput>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  metal?: Maybe<MetalCreateNestedOneWithoutProductInput>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  options?: Maybe<OptionCreateNestedManyWithoutProductsInput>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  pave?: Maybe<PaveCreateNestedOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeCreateNestedOneWithoutProductInput>;
  price: Scalars['Int'];
  productType?: Maybe<ProductTypeCreateNestedOneWithoutProductInput>;
  prong?: Maybe<ProngCreateNestedOneWithoutProductInput>;
  quantity?: Maybe<Scalars['Float']>;
  ringType?: Maybe<RingTypeCreateNestedOneWithoutProductInput>;
  salePrice?: Maybe<Scalars['Float']>;
  shank?: Maybe<ShankCreateNestedOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessCreateNestedOneWithoutProductInput>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  video?: Maybe<VideoCreateNestedOneWithoutProductInput>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutProductsInput>;
};

export type ProductCreateWithoutOptionsInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutProductInput>;
  bandType?: Maybe<BandTypeCreateNestedOneWithoutProductInput>;
  carat?: Maybe<Scalars['Float']>;
  categories?: Maybe<CategoryCreateNestedManyWithoutProductsInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutProductsInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  diamondShape?: Maybe<DiamondShapeCreateNestedOneWithoutProductInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutProductInput>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutProductInput>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutProductInput>;
  metal?: Maybe<MetalCreateNestedOneWithoutProductInput>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  pave?: Maybe<PaveCreateNestedOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeCreateNestedOneWithoutProductInput>;
  price: Scalars['Int'];
  productType?: Maybe<ProductTypeCreateNestedOneWithoutProductInput>;
  prong?: Maybe<ProngCreateNestedOneWithoutProductInput>;
  quantity?: Maybe<Scalars['Float']>;
  ringType?: Maybe<RingTypeCreateNestedOneWithoutProductInput>;
  salePrice?: Maybe<Scalars['Float']>;
  shank?: Maybe<ShankCreateNestedOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessCreateNestedOneWithoutProductInput>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  video?: Maybe<VideoCreateNestedOneWithoutProductInput>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutProductsInput>;
};

export type ProductCreateWithoutParentInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutProductInput>;
  bandType?: Maybe<BandTypeCreateNestedOneWithoutProductInput>;
  carat?: Maybe<Scalars['Float']>;
  categories?: Maybe<CategoryCreateNestedManyWithoutProductsInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutProductsInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  diamondShape?: Maybe<DiamondShapeCreateNestedOneWithoutProductInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutProductInput>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutProductInput>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutProductInput>;
  metal?: Maybe<MetalCreateNestedOneWithoutProductInput>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  options?: Maybe<OptionCreateNestedManyWithoutProductsInput>;
  pave?: Maybe<PaveCreateNestedOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeCreateNestedOneWithoutProductInput>;
  price: Scalars['Int'];
  productType?: Maybe<ProductTypeCreateNestedOneWithoutProductInput>;
  prong?: Maybe<ProngCreateNestedOneWithoutProductInput>;
  quantity?: Maybe<Scalars['Float']>;
  ringType?: Maybe<RingTypeCreateNestedOneWithoutProductInput>;
  salePrice?: Maybe<Scalars['Float']>;
  shank?: Maybe<ShankCreateNestedOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessCreateNestedOneWithoutProductInput>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  video?: Maybe<VideoCreateNestedOneWithoutProductInput>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutProductsInput>;
};

export type ProductCreateWithoutVariantsInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutProductInput>;
  bandType?: Maybe<BandTypeCreateNestedOneWithoutProductInput>;
  carat?: Maybe<Scalars['Float']>;
  categories?: Maybe<CategoryCreateNestedManyWithoutProductsInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutProductsInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  diamondShape?: Maybe<DiamondShapeCreateNestedOneWithoutProductInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutProductInput>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutProductInput>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutProductInput>;
  metal?: Maybe<MetalCreateNestedOneWithoutProductInput>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  options?: Maybe<OptionCreateNestedManyWithoutProductsInput>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  pave?: Maybe<PaveCreateNestedOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeCreateNestedOneWithoutProductInput>;
  price: Scalars['Int'];
  productType?: Maybe<ProductTypeCreateNestedOneWithoutProductInput>;
  prong?: Maybe<ProngCreateNestedOneWithoutProductInput>;
  quantity?: Maybe<Scalars['Float']>;
  ringType?: Maybe<RingTypeCreateNestedOneWithoutProductInput>;
  salePrice?: Maybe<Scalars['Float']>;
  shank?: Maybe<ShankCreateNestedOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessCreateNestedOneWithoutProductInput>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  video?: Maybe<VideoCreateNestedOneWithoutProductInput>;
  wishlists?: Maybe<WishlistCreateNestedManyWithoutProductsInput>;
};

export type ProductCreateWithoutWishlistsInput = {
  allowCheckout?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutProductInput>;
  bandType?: Maybe<BandTypeCreateNestedOneWithoutProductInput>;
  carat?: Maybe<Scalars['Float']>;
  categories?: Maybe<CategoryCreateNestedManyWithoutProductsInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutProductsInput>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  diamondShape?: Maybe<DiamondShapeCreateNestedOneWithoutProductInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  fingerSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutProductInput>;
  isBand?: Maybe<Scalars['Boolean']>;
  isDefaultVariant?: Maybe<Scalars['Boolean']>;
  isEngagementRing?: Maybe<Scalars['Boolean']>;
  isHandFabricated?: Maybe<Scalars['Boolean']>;
  isLabGrown?: Maybe<Scalars['Boolean']>;
  isRightHandRing?: Maybe<Scalars['Boolean']>;
  lab?: Maybe<LabDetailCreateNestedOneWithoutProductInput>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  meta?: Maybe<MetaDataCreateNestedOneWithoutProductInput>;
  metal?: Maybe<MetalCreateNestedOneWithoutProductInput>;
  modelName?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  onSale?: Maybe<Scalars['Boolean']>;
  options?: Maybe<OptionCreateNestedManyWithoutProductsInput>;
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  pave?: Maybe<PaveCreateNestedOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeCreateNestedOneWithoutProductInput>;
  price: Scalars['Int'];
  productType?: Maybe<ProductTypeCreateNestedOneWithoutProductInput>;
  prong?: Maybe<ProngCreateNestedOneWithoutProductInput>;
  quantity?: Maybe<Scalars['Float']>;
  ringType?: Maybe<RingTypeCreateNestedOneWithoutProductInput>;
  salePrice?: Maybe<Scalars['Float']>;
  shank?: Maybe<ShankCreateNestedOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessCreateNestedOneWithoutProductInput>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
  video?: Maybe<VideoCreateNestedOneWithoutProductInput>;
};

export type ProductListRelationFilter = {
  every?: Maybe<ProductWhereInput>;
  none?: Maybe<ProductWhereInput>;
  some?: Maybe<ProductWhereInput>;
};

export type ProductOrderByInput = {
  allowCheckout?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
  bandTypeId?: Maybe<SortOrder>;
  carat?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  diamondShapeId?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  fingerSize?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isBand?: Maybe<SortOrder>;
  isDefaultVariant?: Maybe<SortOrder>;
  isEngagementRing?: Maybe<SortOrder>;
  isHandFabricated?: Maybe<SortOrder>;
  isLabGrown?: Maybe<SortOrder>;
  isRightHandRing?: Maybe<SortOrder>;
  leadTimeFrom?: Maybe<SortOrder>;
  leadTimeTo?: Maybe<SortOrder>;
  metalId?: Maybe<SortOrder>;
  modelName?: Maybe<SortOrder>;
  modelNumber?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  onSale?: Maybe<SortOrder>;
  parentId?: Maybe<SortOrder>;
  paveDiamondTypeId?: Maybe<SortOrder>;
  paveId?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  productTypeId?: Maybe<SortOrder>;
  prongId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  ringTypeId?: Maybe<SortOrder>;
  salePrice?: Maybe<SortOrder>;
  shankId?: Maybe<SortOrder>;
  shankThicknessId?: Maybe<SortOrder>;
  shortDescription?: Maybe<SortOrder>;
  sku?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ProductScalarWhereInput = {
  AND?: Maybe<Array<ProductScalarWhereInput>>;
  NOT?: Maybe<Array<ProductScalarWhereInput>>;
  OR?: Maybe<Array<ProductScalarWhereInput>>;
  allowCheckout?: Maybe<BoolFilter>;
  availabilityId?: Maybe<StringNullableFilter>;
  bandTypeId?: Maybe<StringNullableFilter>;
  carat?: Maybe<FloatFilter>;
  cost?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  diamondShapeId?: Maybe<StringNullableFilter>;
  enabled?: Maybe<BoolFilter>;
  fingerSize?: Maybe<FloatNullableFilter>;
  id?: Maybe<StringFilter>;
  isBand?: Maybe<BoolFilter>;
  isDefaultVariant?: Maybe<BoolFilter>;
  isEngagementRing?: Maybe<BoolFilter>;
  isHandFabricated?: Maybe<BoolFilter>;
  isLabGrown?: Maybe<BoolNullableFilter>;
  isRightHandRing?: Maybe<BoolFilter>;
  leadTimeFrom?: Maybe<IntNullableFilter>;
  leadTimeTo?: Maybe<IntNullableFilter>;
  metalId?: Maybe<StringNullableFilter>;
  modelName?: Maybe<StringNullableFilter>;
  modelNumber?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  onSale?: Maybe<BoolNullableFilter>;
  parentId?: Maybe<StringNullableFilter>;
  paveDiamondTypeId?: Maybe<StringNullableFilter>;
  paveId?: Maybe<StringNullableFilter>;
  price?: Maybe<IntFilter>;
  productTypeId?: Maybe<StringNullableFilter>;
  prongId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<FloatFilter>;
  ringTypeId?: Maybe<StringNullableFilter>;
  salePrice?: Maybe<FloatNullableFilter>;
  shankId?: Maybe<StringNullableFilter>;
  shankThicknessId?: Maybe<StringNullableFilter>;
  shortDescription?: Maybe<StringNullableFilter>;
  sku?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  type?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ProductSearchResult = {
  __typename?: 'ProductSearchResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<Product>>>;
};

export type ProductType = {
  __typename?: 'ProductType';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ProductTypeCreateNestedOneWithoutProductInput = {
  connect?: Maybe<ProductTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductTypeCreateOrConnectWithoutProductInput>;
  create?: Maybe<ProductTypeCreateWithoutProductInput>;
};

export type ProductTypeCreateOrConnectWithoutProductInput = {
  create: ProductTypeCreateWithoutProductInput;
  where: ProductTypeWhereUniqueInput;
};

export type ProductTypeCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ProductTypeOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ProductTypeUpdateOneWithoutProductInput = {
  connect?: Maybe<ProductTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductTypeCreateOrConnectWithoutProductInput>;
  create?: Maybe<ProductTypeCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProductTypeUpdateWithoutProductInput>;
  upsert?: Maybe<ProductTypeUpsertWithoutProductInput>;
};

export type ProductTypeUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ProductTypeUpsertWithoutProductInput = {
  create: ProductTypeCreateWithoutProductInput;
  update: ProductTypeUpdateWithoutProductInput;
};

export type ProductTypeWhereInput = {
  AND?: Maybe<Array<ProductTypeWhereInput>>;
  NOT?: Maybe<Array<ProductTypeWhereInput>>;
  OR?: Maybe<Array<ProductTypeWhereInput>>;
  Product?: Maybe<ProductListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type ProductTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ProductUpdateInput = {
  allowCheckout?: Maybe<BoolFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutProductInput>;
  bandType?: Maybe<BandTypeUpdateOneWithoutProductInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  categories?: Maybe<CategoryUpdateManyWithoutProductsInput>;
  collections?: Maybe<CollectionUpdateManyWithoutProductsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  diamondShape?: Maybe<DiamondShapeUpdateOneWithoutProductInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fingerSize?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutProductInput>;
  isBand?: Maybe<BoolFieldUpdateOperationsInput>;
  isDefaultVariant?: Maybe<BoolFieldUpdateOperationsInput>;
  isEngagementRing?: Maybe<BoolFieldUpdateOperationsInput>;
  isHandFabricated?: Maybe<BoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isRightHandRing?: Maybe<BoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutProductInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutProductInput>;
  metal?: Maybe<MetalUpdateOneWithoutProductInput>;
  modelName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modelNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  options?: Maybe<OptionUpdateManyWithoutProductsInput>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsInput>;
  pave?: Maybe<PaveUpdateOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeUpdateOneWithoutProductInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  productType?: Maybe<ProductTypeUpdateOneWithoutProductInput>;
  prong?: Maybe<ProngUpdateOneWithoutProductInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  ringType?: Maybe<RingTypeUpdateOneWithoutProductInput>;
  salePrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  shank?: Maybe<ShankUpdateOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessUpdateOneWithoutProductInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentInput>;
  video?: Maybe<VideoUpdateOneWithoutProductInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutProductsInput>;
};

export type ProductUpdateManyMutationInput = {
  allowCheckout?: Maybe<BoolFieldUpdateOperationsInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fingerSize?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isBand?: Maybe<BoolFieldUpdateOperationsInput>;
  isDefaultVariant?: Maybe<BoolFieldUpdateOperationsInput>;
  isEngagementRing?: Maybe<BoolFieldUpdateOperationsInput>;
  isHandFabricated?: Maybe<BoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isRightHandRing?: Maybe<BoolFieldUpdateOperationsInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  modelName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modelNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  salePrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductUpdateManyWithoutCategoriesInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutCategoriesInput>>;
  create?: Maybe<Array<ProductCreateWithoutCategoriesInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutCategoriesInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutCategoriesInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutCategoriesInput>>;
};

export type ProductUpdateManyWithoutCollectionsInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutCollectionsInput>>;
  create?: Maybe<Array<ProductCreateWithoutCollectionsInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutCollectionsInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutCollectionsInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutCollectionsInput>>;
};

export type ProductUpdateManyWithoutDiamondShapeInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutDiamondShapeInput>>;
  create?: Maybe<Array<ProductCreateWithoutDiamondShapeInput>>;
  createMany?: Maybe<ProductCreateManyDiamondShapeInputEnvelope>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutDiamondShapeInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutDiamondShapeInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutDiamondShapeInput>>;
};

export type ProductUpdateManyWithoutOptionsInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutOptionsInput>>;
  create?: Maybe<Array<ProductCreateWithoutOptionsInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutOptionsInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutOptionsInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutOptionsInput>>;
};

export type ProductUpdateManyWithoutParentInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<ProductCreateWithoutParentInput>>;
  createMany?: Maybe<ProductCreateManyParentInputEnvelope>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutParentInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutParentInput>>;
};

export type ProductUpdateManyWithoutWishlistsInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutWishlistsInput>>;
  create?: Maybe<Array<ProductCreateWithoutWishlistsInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutWishlistsInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutWishlistsInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutWishlistsInput>>;
};

export type ProductUpdateManyWithWhereWithoutCategoriesInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutCollectionsInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutDiamondShapeInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutOptionsInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutParentInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutWishlistsInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateOneWithoutImagesInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutImagesInput>;
  create?: Maybe<ProductCreateWithoutImagesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProductUpdateWithoutImagesInput>;
  upsert?: Maybe<ProductUpsertWithoutImagesInput>;
};

export type ProductUpdateOneWithoutLabInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutLabInput>;
  create?: Maybe<ProductCreateWithoutLabInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProductUpdateWithoutLabInput>;
  upsert?: Maybe<ProductUpsertWithoutLabInput>;
};

export type ProductUpdateOneWithoutMetaInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutMetaInput>;
  create?: Maybe<ProductCreateWithoutMetaInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProductUpdateWithoutMetaInput>;
  upsert?: Maybe<ProductUpsertWithoutMetaInput>;
};

export type ProductUpdateOneWithoutVariantsInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutVariantsInput>;
  create?: Maybe<ProductCreateWithoutVariantsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProductUpdateWithoutVariantsInput>;
  upsert?: Maybe<ProductUpsertWithoutVariantsInput>;
};

export type ProductUpdateWithoutCategoriesInput = {
  allowCheckout?: Maybe<BoolFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutProductInput>;
  bandType?: Maybe<BandTypeUpdateOneWithoutProductInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  collections?: Maybe<CollectionUpdateManyWithoutProductsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  diamondShape?: Maybe<DiamondShapeUpdateOneWithoutProductInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fingerSize?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutProductInput>;
  isBand?: Maybe<BoolFieldUpdateOperationsInput>;
  isDefaultVariant?: Maybe<BoolFieldUpdateOperationsInput>;
  isEngagementRing?: Maybe<BoolFieldUpdateOperationsInput>;
  isHandFabricated?: Maybe<BoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isRightHandRing?: Maybe<BoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutProductInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutProductInput>;
  metal?: Maybe<MetalUpdateOneWithoutProductInput>;
  modelName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modelNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  options?: Maybe<OptionUpdateManyWithoutProductsInput>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsInput>;
  pave?: Maybe<PaveUpdateOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeUpdateOneWithoutProductInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  productType?: Maybe<ProductTypeUpdateOneWithoutProductInput>;
  prong?: Maybe<ProngUpdateOneWithoutProductInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  ringType?: Maybe<RingTypeUpdateOneWithoutProductInput>;
  salePrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  shank?: Maybe<ShankUpdateOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessUpdateOneWithoutProductInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentInput>;
  video?: Maybe<VideoUpdateOneWithoutProductInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutProductsInput>;
};

export type ProductUpdateWithoutCollectionsInput = {
  allowCheckout?: Maybe<BoolFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutProductInput>;
  bandType?: Maybe<BandTypeUpdateOneWithoutProductInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  categories?: Maybe<CategoryUpdateManyWithoutProductsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  diamondShape?: Maybe<DiamondShapeUpdateOneWithoutProductInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fingerSize?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutProductInput>;
  isBand?: Maybe<BoolFieldUpdateOperationsInput>;
  isDefaultVariant?: Maybe<BoolFieldUpdateOperationsInput>;
  isEngagementRing?: Maybe<BoolFieldUpdateOperationsInput>;
  isHandFabricated?: Maybe<BoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isRightHandRing?: Maybe<BoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutProductInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutProductInput>;
  metal?: Maybe<MetalUpdateOneWithoutProductInput>;
  modelName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modelNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  options?: Maybe<OptionUpdateManyWithoutProductsInput>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsInput>;
  pave?: Maybe<PaveUpdateOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeUpdateOneWithoutProductInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  productType?: Maybe<ProductTypeUpdateOneWithoutProductInput>;
  prong?: Maybe<ProngUpdateOneWithoutProductInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  ringType?: Maybe<RingTypeUpdateOneWithoutProductInput>;
  salePrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  shank?: Maybe<ShankUpdateOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessUpdateOneWithoutProductInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentInput>;
  video?: Maybe<VideoUpdateOneWithoutProductInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutProductsInput>;
};

export type ProductUpdateWithoutDiamondShapeInput = {
  allowCheckout?: Maybe<BoolFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutProductInput>;
  bandType?: Maybe<BandTypeUpdateOneWithoutProductInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  categories?: Maybe<CategoryUpdateManyWithoutProductsInput>;
  collections?: Maybe<CollectionUpdateManyWithoutProductsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fingerSize?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutProductInput>;
  isBand?: Maybe<BoolFieldUpdateOperationsInput>;
  isDefaultVariant?: Maybe<BoolFieldUpdateOperationsInput>;
  isEngagementRing?: Maybe<BoolFieldUpdateOperationsInput>;
  isHandFabricated?: Maybe<BoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isRightHandRing?: Maybe<BoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutProductInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutProductInput>;
  metal?: Maybe<MetalUpdateOneWithoutProductInput>;
  modelName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modelNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  options?: Maybe<OptionUpdateManyWithoutProductsInput>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsInput>;
  pave?: Maybe<PaveUpdateOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeUpdateOneWithoutProductInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  productType?: Maybe<ProductTypeUpdateOneWithoutProductInput>;
  prong?: Maybe<ProngUpdateOneWithoutProductInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  ringType?: Maybe<RingTypeUpdateOneWithoutProductInput>;
  salePrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  shank?: Maybe<ShankUpdateOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessUpdateOneWithoutProductInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentInput>;
  video?: Maybe<VideoUpdateOneWithoutProductInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutProductsInput>;
};

export type ProductUpdateWithoutImagesInput = {
  allowCheckout?: Maybe<BoolFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutProductInput>;
  bandType?: Maybe<BandTypeUpdateOneWithoutProductInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  categories?: Maybe<CategoryUpdateManyWithoutProductsInput>;
  collections?: Maybe<CollectionUpdateManyWithoutProductsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  diamondShape?: Maybe<DiamondShapeUpdateOneWithoutProductInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fingerSize?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isBand?: Maybe<BoolFieldUpdateOperationsInput>;
  isDefaultVariant?: Maybe<BoolFieldUpdateOperationsInput>;
  isEngagementRing?: Maybe<BoolFieldUpdateOperationsInput>;
  isHandFabricated?: Maybe<BoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isRightHandRing?: Maybe<BoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutProductInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutProductInput>;
  metal?: Maybe<MetalUpdateOneWithoutProductInput>;
  modelName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modelNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  options?: Maybe<OptionUpdateManyWithoutProductsInput>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsInput>;
  pave?: Maybe<PaveUpdateOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeUpdateOneWithoutProductInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  productType?: Maybe<ProductTypeUpdateOneWithoutProductInput>;
  prong?: Maybe<ProngUpdateOneWithoutProductInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  ringType?: Maybe<RingTypeUpdateOneWithoutProductInput>;
  salePrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  shank?: Maybe<ShankUpdateOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessUpdateOneWithoutProductInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentInput>;
  video?: Maybe<VideoUpdateOneWithoutProductInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutProductsInput>;
};

export type ProductUpdateWithoutLabInput = {
  allowCheckout?: Maybe<BoolFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutProductInput>;
  bandType?: Maybe<BandTypeUpdateOneWithoutProductInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  categories?: Maybe<CategoryUpdateManyWithoutProductsInput>;
  collections?: Maybe<CollectionUpdateManyWithoutProductsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  diamondShape?: Maybe<DiamondShapeUpdateOneWithoutProductInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fingerSize?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutProductInput>;
  isBand?: Maybe<BoolFieldUpdateOperationsInput>;
  isDefaultVariant?: Maybe<BoolFieldUpdateOperationsInput>;
  isEngagementRing?: Maybe<BoolFieldUpdateOperationsInput>;
  isHandFabricated?: Maybe<BoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isRightHandRing?: Maybe<BoolFieldUpdateOperationsInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutProductInput>;
  metal?: Maybe<MetalUpdateOneWithoutProductInput>;
  modelName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modelNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  options?: Maybe<OptionUpdateManyWithoutProductsInput>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsInput>;
  pave?: Maybe<PaveUpdateOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeUpdateOneWithoutProductInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  productType?: Maybe<ProductTypeUpdateOneWithoutProductInput>;
  prong?: Maybe<ProngUpdateOneWithoutProductInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  ringType?: Maybe<RingTypeUpdateOneWithoutProductInput>;
  salePrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  shank?: Maybe<ShankUpdateOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessUpdateOneWithoutProductInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentInput>;
  video?: Maybe<VideoUpdateOneWithoutProductInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutProductsInput>;
};

export type ProductUpdateWithoutMetaInput = {
  allowCheckout?: Maybe<BoolFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutProductInput>;
  bandType?: Maybe<BandTypeUpdateOneWithoutProductInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  categories?: Maybe<CategoryUpdateManyWithoutProductsInput>;
  collections?: Maybe<CollectionUpdateManyWithoutProductsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  diamondShape?: Maybe<DiamondShapeUpdateOneWithoutProductInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fingerSize?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutProductInput>;
  isBand?: Maybe<BoolFieldUpdateOperationsInput>;
  isDefaultVariant?: Maybe<BoolFieldUpdateOperationsInput>;
  isEngagementRing?: Maybe<BoolFieldUpdateOperationsInput>;
  isHandFabricated?: Maybe<BoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isRightHandRing?: Maybe<BoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutProductInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  metal?: Maybe<MetalUpdateOneWithoutProductInput>;
  modelName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modelNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  options?: Maybe<OptionUpdateManyWithoutProductsInput>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsInput>;
  pave?: Maybe<PaveUpdateOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeUpdateOneWithoutProductInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  productType?: Maybe<ProductTypeUpdateOneWithoutProductInput>;
  prong?: Maybe<ProngUpdateOneWithoutProductInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  ringType?: Maybe<RingTypeUpdateOneWithoutProductInput>;
  salePrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  shank?: Maybe<ShankUpdateOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessUpdateOneWithoutProductInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentInput>;
  video?: Maybe<VideoUpdateOneWithoutProductInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutProductsInput>;
};

export type ProductUpdateWithoutOptionsInput = {
  allowCheckout?: Maybe<BoolFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutProductInput>;
  bandType?: Maybe<BandTypeUpdateOneWithoutProductInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  categories?: Maybe<CategoryUpdateManyWithoutProductsInput>;
  collections?: Maybe<CollectionUpdateManyWithoutProductsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  diamondShape?: Maybe<DiamondShapeUpdateOneWithoutProductInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fingerSize?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutProductInput>;
  isBand?: Maybe<BoolFieldUpdateOperationsInput>;
  isDefaultVariant?: Maybe<BoolFieldUpdateOperationsInput>;
  isEngagementRing?: Maybe<BoolFieldUpdateOperationsInput>;
  isHandFabricated?: Maybe<BoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isRightHandRing?: Maybe<BoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutProductInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutProductInput>;
  metal?: Maybe<MetalUpdateOneWithoutProductInput>;
  modelName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modelNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsInput>;
  pave?: Maybe<PaveUpdateOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeUpdateOneWithoutProductInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  productType?: Maybe<ProductTypeUpdateOneWithoutProductInput>;
  prong?: Maybe<ProngUpdateOneWithoutProductInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  ringType?: Maybe<RingTypeUpdateOneWithoutProductInput>;
  salePrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  shank?: Maybe<ShankUpdateOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessUpdateOneWithoutProductInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentInput>;
  video?: Maybe<VideoUpdateOneWithoutProductInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutProductsInput>;
};

export type ProductUpdateWithoutParentInput = {
  allowCheckout?: Maybe<BoolFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutProductInput>;
  bandType?: Maybe<BandTypeUpdateOneWithoutProductInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  categories?: Maybe<CategoryUpdateManyWithoutProductsInput>;
  collections?: Maybe<CollectionUpdateManyWithoutProductsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  diamondShape?: Maybe<DiamondShapeUpdateOneWithoutProductInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fingerSize?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutProductInput>;
  isBand?: Maybe<BoolFieldUpdateOperationsInput>;
  isDefaultVariant?: Maybe<BoolFieldUpdateOperationsInput>;
  isEngagementRing?: Maybe<BoolFieldUpdateOperationsInput>;
  isHandFabricated?: Maybe<BoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isRightHandRing?: Maybe<BoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutProductInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutProductInput>;
  metal?: Maybe<MetalUpdateOneWithoutProductInput>;
  modelName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modelNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  options?: Maybe<OptionUpdateManyWithoutProductsInput>;
  pave?: Maybe<PaveUpdateOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeUpdateOneWithoutProductInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  productType?: Maybe<ProductTypeUpdateOneWithoutProductInput>;
  prong?: Maybe<ProngUpdateOneWithoutProductInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  ringType?: Maybe<RingTypeUpdateOneWithoutProductInput>;
  salePrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  shank?: Maybe<ShankUpdateOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessUpdateOneWithoutProductInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentInput>;
  video?: Maybe<VideoUpdateOneWithoutProductInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutProductsInput>;
};

export type ProductUpdateWithoutVariantsInput = {
  allowCheckout?: Maybe<BoolFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutProductInput>;
  bandType?: Maybe<BandTypeUpdateOneWithoutProductInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  categories?: Maybe<CategoryUpdateManyWithoutProductsInput>;
  collections?: Maybe<CollectionUpdateManyWithoutProductsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  diamondShape?: Maybe<DiamondShapeUpdateOneWithoutProductInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fingerSize?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutProductInput>;
  isBand?: Maybe<BoolFieldUpdateOperationsInput>;
  isDefaultVariant?: Maybe<BoolFieldUpdateOperationsInput>;
  isEngagementRing?: Maybe<BoolFieldUpdateOperationsInput>;
  isHandFabricated?: Maybe<BoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isRightHandRing?: Maybe<BoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutProductInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutProductInput>;
  metal?: Maybe<MetalUpdateOneWithoutProductInput>;
  modelName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modelNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  options?: Maybe<OptionUpdateManyWithoutProductsInput>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsInput>;
  pave?: Maybe<PaveUpdateOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeUpdateOneWithoutProductInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  productType?: Maybe<ProductTypeUpdateOneWithoutProductInput>;
  prong?: Maybe<ProngUpdateOneWithoutProductInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  ringType?: Maybe<RingTypeUpdateOneWithoutProductInput>;
  salePrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  shank?: Maybe<ShankUpdateOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessUpdateOneWithoutProductInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  video?: Maybe<VideoUpdateOneWithoutProductInput>;
  wishlists?: Maybe<WishlistUpdateManyWithoutProductsInput>;
};

export type ProductUpdateWithoutWishlistsInput = {
  allowCheckout?: Maybe<BoolFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutProductInput>;
  bandType?: Maybe<BandTypeUpdateOneWithoutProductInput>;
  carat?: Maybe<FloatFieldUpdateOperationsInput>;
  categories?: Maybe<CategoryUpdateManyWithoutProductsInput>;
  collections?: Maybe<CollectionUpdateManyWithoutProductsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  diamondShape?: Maybe<DiamondShapeUpdateOneWithoutProductInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  fingerSize?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutProductInput>;
  isBand?: Maybe<BoolFieldUpdateOperationsInput>;
  isDefaultVariant?: Maybe<BoolFieldUpdateOperationsInput>;
  isEngagementRing?: Maybe<BoolFieldUpdateOperationsInput>;
  isHandFabricated?: Maybe<BoolFieldUpdateOperationsInput>;
  isLabGrown?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isRightHandRing?: Maybe<BoolFieldUpdateOperationsInput>;
  lab?: Maybe<LabDetailUpdateOneWithoutProductInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  meta?: Maybe<MetaDataUpdateOneWithoutProductInput>;
  metal?: Maybe<MetalUpdateOneWithoutProductInput>;
  modelName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modelNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  onSale?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  options?: Maybe<OptionUpdateManyWithoutProductsInput>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsInput>;
  pave?: Maybe<PaveUpdateOneWithoutProductInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeUpdateOneWithoutProductInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  productType?: Maybe<ProductTypeUpdateOneWithoutProductInput>;
  prong?: Maybe<ProngUpdateOneWithoutProductInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  ringType?: Maybe<RingTypeUpdateOneWithoutProductInput>;
  salePrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  shank?: Maybe<ShankUpdateOneWithoutProductInput>;
  shankThickness?: Maybe<ShankThicknessUpdateOneWithoutProductInput>;
  shortDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  variants?: Maybe<ProductUpdateManyWithoutParentInput>;
  video?: Maybe<VideoUpdateOneWithoutProductInput>;
};

export type ProductUpdateWithWhereUniqueWithoutCategoriesInput = {
  data: ProductUpdateWithoutCategoriesInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutCollectionsInput = {
  data: ProductUpdateWithoutCollectionsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutDiamondShapeInput = {
  data: ProductUpdateWithoutDiamondShapeInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutOptionsInput = {
  data: ProductUpdateWithoutOptionsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutParentInput = {
  data: ProductUpdateWithoutParentInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutWishlistsInput = {
  data: ProductUpdateWithoutWishlistsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithoutImagesInput = {
  create: ProductCreateWithoutImagesInput;
  update: ProductUpdateWithoutImagesInput;
};

export type ProductUpsertWithoutLabInput = {
  create: ProductCreateWithoutLabInput;
  update: ProductUpdateWithoutLabInput;
};

export type ProductUpsertWithoutMetaInput = {
  create: ProductCreateWithoutMetaInput;
  update: ProductUpdateWithoutMetaInput;
};

export type ProductUpsertWithoutVariantsInput = {
  create: ProductCreateWithoutVariantsInput;
  update: ProductUpdateWithoutVariantsInput;
};

export type ProductUpsertWithWhereUniqueWithoutCategoriesInput = {
  create: ProductCreateWithoutCategoriesInput;
  update: ProductUpdateWithoutCategoriesInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutCollectionsInput = {
  create: ProductCreateWithoutCollectionsInput;
  update: ProductUpdateWithoutCollectionsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutDiamondShapeInput = {
  create: ProductCreateWithoutDiamondShapeInput;
  update: ProductUpdateWithoutDiamondShapeInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutOptionsInput = {
  create: ProductCreateWithoutOptionsInput;
  update: ProductUpdateWithoutOptionsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutParentInput = {
  create: ProductCreateWithoutParentInput;
  update: ProductUpdateWithoutParentInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutWishlistsInput = {
  create: ProductCreateWithoutWishlistsInput;
  update: ProductUpdateWithoutWishlistsInput;
  where: ProductWhereUniqueInput;
};

export type ProductVideo = {
  __typename?: 'ProductVideo';
  source?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ProductVideoInput = {
  source?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ProductWhereInput = {
  AND?: Maybe<Array<ProductWhereInput>>;
  NOT?: Maybe<Array<ProductWhereInput>>;
  OR?: Maybe<Array<ProductWhereInput>>;
  allowCheckout?: Maybe<BoolFilter>;
  availability?: Maybe<AvailabilityWhereInput>;
  availabilityId?: Maybe<StringNullableFilter>;
  bandType?: Maybe<BandTypeWhereInput>;
  bandTypeId?: Maybe<StringNullableFilter>;
  carat?: Maybe<FloatFilter>;
  categories?: Maybe<CategoryListRelationFilter>;
  collections?: Maybe<CollectionListRelationFilter>;
  cost?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  diamondShape?: Maybe<DiamondShapeWhereInput>;
  diamondShapeId?: Maybe<StringNullableFilter>;
  enabled?: Maybe<BoolFilter>;
  fingerSize?: Maybe<FloatNullableFilter>;
  id?: Maybe<StringFilter>;
  images?: Maybe<ImageListRelationFilter>;
  isBand?: Maybe<BoolFilter>;
  isDefaultVariant?: Maybe<BoolFilter>;
  isEngagementRing?: Maybe<BoolFilter>;
  isHandFabricated?: Maybe<BoolFilter>;
  isLabGrown?: Maybe<BoolNullableFilter>;
  isRightHandRing?: Maybe<BoolFilter>;
  lab?: Maybe<LabDetailWhereInput>;
  leadTimeFrom?: Maybe<IntNullableFilter>;
  leadTimeTo?: Maybe<IntNullableFilter>;
  meta?: Maybe<MetaDataWhereInput>;
  metal?: Maybe<MetalWhereInput>;
  metalId?: Maybe<StringNullableFilter>;
  modelName?: Maybe<StringNullableFilter>;
  modelNumber?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  onSale?: Maybe<BoolNullableFilter>;
  options?: Maybe<OptionListRelationFilter>;
  parent?: Maybe<ProductWhereInput>;
  parentId?: Maybe<StringNullableFilter>;
  pave?: Maybe<PaveWhereInput>;
  paveDiamondType?: Maybe<PaveDiamondTypeWhereInput>;
  paveDiamondTypeId?: Maybe<StringNullableFilter>;
  paveId?: Maybe<StringNullableFilter>;
  price?: Maybe<IntFilter>;
  productType?: Maybe<ProductTypeWhereInput>;
  productTypeId?: Maybe<StringNullableFilter>;
  prong?: Maybe<ProngWhereInput>;
  prongId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<FloatFilter>;
  ringType?: Maybe<RingTypeWhereInput>;
  ringTypeId?: Maybe<StringNullableFilter>;
  salePrice?: Maybe<FloatNullableFilter>;
  shank?: Maybe<ShankWhereInput>;
  shankId?: Maybe<StringNullableFilter>;
  shankThickness?: Maybe<ShankThicknessWhereInput>;
  shankThicknessId?: Maybe<StringNullableFilter>;
  shortDescription?: Maybe<StringNullableFilter>;
  sku?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  type?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  variants?: Maybe<ProductListRelationFilter>;
  video?: Maybe<VideoWhereInput>;
  wishlists?: Maybe<WishlistListRelationFilter>;
};

export type ProductWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type Prong = {
  __typename?: 'Prong';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ProngCreateNestedOneWithoutProductInput = {
  connect?: Maybe<ProngWhereUniqueInput>;
  connectOrCreate?: Maybe<ProngCreateOrConnectWithoutProductInput>;
  create?: Maybe<ProngCreateWithoutProductInput>;
};

export type ProngCreateOrConnectWithoutProductInput = {
  create: ProngCreateWithoutProductInput;
  where: ProngWhereUniqueInput;
};

export type ProngCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ProngOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ProngUpdateOneWithoutProductInput = {
  connect?: Maybe<ProngWhereUniqueInput>;
  connectOrCreate?: Maybe<ProngCreateOrConnectWithoutProductInput>;
  create?: Maybe<ProngCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProngUpdateWithoutProductInput>;
  upsert?: Maybe<ProngUpsertWithoutProductInput>;
};

export type ProngUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ProngUpsertWithoutProductInput = {
  create: ProngCreateWithoutProductInput;
  update: ProngUpdateWithoutProductInput;
};

export type ProngWhereInput = {
  AND?: Maybe<Array<ProngWhereInput>>;
  NOT?: Maybe<Array<ProngWhereInput>>;
  OR?: Maybe<Array<ProngWhereInput>>;
  Product?: Maybe<ProductListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type ProngWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  availabilities: Array<Availability>;
  bandTypes: Array<BandType>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  clarities: Array<Clarity>;
  collection?: Maybe<Collection>;
  collections: Array<Collection>;
  colors: Array<Color>;
  culets: Array<Culet>;
  cuts: Array<Cut>;
  diamond?: Maybe<Diamond>;
  diamondOutliers?: Maybe<DiamondOutlierValues>;
  diamondShapes: Array<DiamondShape>;
  diamonds: Array<Diamond>;
  diamondsCount?: Maybe<Scalars['Int']>;
  fluorescences: Array<Fluorescence>;
  girdles: Array<Girdle>;
  images: Array<Image>;
  lightPerformances: Array<LightPerformance>;
  metals: Array<Metal>;
  optionSets: Array<OptionSet>;
  paveDiamondTypes: Array<PaveDiamondType>;
  paves: Array<Pave>;
  polishes: Array<Polish>;
  product?: Maybe<Product>;
  productSearch?: Maybe<ProductSearchResult>;
  productTypes: Array<ProductType>;
  products: Array<Product>;
  productsCount?: Maybe<Scalars['Int']>;
  prongs: Array<Prong>;
  ringTypes: Array<RingType>;
  shankThicknesses: Array<ShankThickness>;
  shanks: Array<Shank>;
  symmetries: Array<Symmetry>;
  vendor?: Maybe<Vendor>;
  vendors: Array<Vendor>;
  wishlist?: Maybe<Wishlist>;
  wishlists: Array<Wishlist>;
  customDesignRequests?: Maybe<Array<CustomDesignRequest>>;
  customDesignRequest?: Maybe<CustomDesignRequest>;
  emails?: Maybe<Array<Email>>;
  email?: Maybe<Email>;
  faqs?: Maybe<Array<Faq>>;
  faq?: Maybe<Faq>;
  formContacts?: Maybe<Array<FormContact>>;
  formContact?: Maybe<FormContact>;
  generalInquiries?: Maybe<Array<GeneralInquiry>>;
  generalInquiry?: Maybe<GeneralInquiry>;
  invoices?: Maybe<Array<Invoice>>;
  invoice?: Maybe<Invoice>;
  invoiceEmail?: Maybe<RenderedEmailResult>;
  jobs?: Maybe<Array<Job>>;
  job?: Maybe<Job>;
  orders?: Maybe<Array<Order>>;
  order?: Maybe<Order>;
  payments?: Maybe<Array<Payment>>;
  payment?: Maybe<Order>;
  paymentTypes?: Maybe<Array<PaymentType>>;
  paymentType?: Maybe<PaymentType>;
  permissions?: Maybe<Array<Permission>>;
  permission?: Maybe<Permission>;
  quotes?: Maybe<Array<Quote>>;
  quote?: Maybe<Quote>;
  quoteEmail?: Maybe<RenderedEmailResult>;
  referrals?: Maybe<Array<Referral>>;
  referral?: Maybe<Referral>;
  proposeSimilarDiamonds?: Maybe<Array<Maybe<Diamond>>>;
  proposeRingsForDiamond?: Maybe<Array<Maybe<Product>>>;
  proposeSimilarJewelry?: Maybe<Array<Maybe<Product>>>;
  roles?: Maybe<Array<Role>>;
  role?: Maybe<Role>;
  invoiceSearch?: Maybe<Array<Invoice>>;
  settings?: Maybe<Array<Setting>>;
  setting?: Maybe<Setting>;
  snippets?: Maybe<Array<Snippet>>;
  snippet?: Maybe<Snippet>;
  staticPages?: Maybe<Array<StaticPage>>;
  staticPage?: Maybe<StaticPage>;
  subscribers?: Maybe<Array<Subscriber>>;
  subscriber?: Maybe<Subscriber>;
  template?: Maybe<Template>;
  testimonials?: Maybe<Array<Testimonial>>;
  testimonial?: Maybe<Testimonial>;
  users?: Maybe<Array<User>>;
  user?: Maybe<User>;
  currentUser?: Maybe<User>;
  virtualProducts?: Maybe<VirtualProductResults>;
  virtualProduct?: Maybe<VirtualProduct>;
  workOrders?: Maybe<Array<WorkOrder>>;
  workOrder?: Maybe<WorkOrder>;
  workOrderEmail?: Maybe<RenderedEmailResult>;
  workOrderItems?: Maybe<Array<WorkOrderItem>>;
  workOrderItem?: Maybe<WorkOrderItem>;
};


export type QueryAvailabilitiesArgs = {
  orderBy?: Maybe<Array<AvailabilityOrderByInput>>;
  where?: Maybe<AvailabilityWhereInput>;
};


export type QueryBandTypesArgs = {
  orderBy?: Maybe<Array<BandTypeOrderByInput>>;
  where?: Maybe<BandTypeWhereInput>;
};


export type QueryCategoriesArgs = {
  after?: Maybe<CategoryWhereUniqueInput>;
  before?: Maybe<CategoryWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CategoryOrderByInput>>;
  where?: Maybe<CategoryWhereInput>;
};


export type QueryCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type QueryClaritiesArgs = {
  orderBy?: Maybe<Array<ClarityOrderByInput>>;
  where?: Maybe<ClarityWhereInput>;
};


export type QueryCollectionArgs = {
  where: CollectionWhereUniqueInput;
};


export type QueryCollectionsArgs = {
  after?: Maybe<CollectionWhereUniqueInput>;
  before?: Maybe<CollectionWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CollectionOrderByInput>>;
  where?: Maybe<CollectionWhereInput>;
};


export type QueryColorsArgs = {
  orderBy?: Maybe<Array<ColorOrderByInput>>;
  where?: Maybe<ColorWhereInput>;
};


export type QueryCuletsArgs = {
  orderBy?: Maybe<Array<CuletOrderByInput>>;
  where?: Maybe<CuletWhereInput>;
};


export type QueryCutsArgs = {
  orderBy?: Maybe<Array<CutOrderByInput>>;
  where?: Maybe<CutWhereInput>;
};


export type QueryDiamondArgs = {
  slugOrId: Scalars['String'];
};


export type QueryDiamondShapesArgs = {
  orderBy?: Maybe<Array<DiamondShapeOrderByInput>>;
  where?: Maybe<DiamondShapeWhereInput>;
};


export type QueryDiamondsArgs = {
  after?: Maybe<DiamondWhereUniqueInput>;
  before?: Maybe<DiamondWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<DiamondOrderByInput>>;
  where?: Maybe<DiamondWhereInput>;
};


export type QueryDiamondsCountArgs = {
  where?: Maybe<DiamondWhereInput>;
};


export type QueryFluorescencesArgs = {
  orderBy?: Maybe<Array<FluorescenceOrderByInput>>;
  where?: Maybe<FluorescenceWhereInput>;
};


export type QueryGirdlesArgs = {
  orderBy?: Maybe<Array<GirdleOrderByInput>>;
  where?: Maybe<GirdleWhereInput>;
};


export type QueryImagesArgs = {
  after?: Maybe<ImageWhereUniqueInput>;
  before?: Maybe<ImageWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ImageOrderByInput>>;
  where?: Maybe<ImageWhereInput>;
};


export type QueryLightPerformancesArgs = {
  orderBy?: Maybe<Array<LightPerformanceOrderByInput>>;
  where?: Maybe<LightPerformanceWhereInput>;
};


export type QueryMetalsArgs = {
  orderBy?: Maybe<Array<MetalOrderByInput>>;
  where?: Maybe<MetalWhereInput>;
};


export type QueryOptionSetsArgs = {
  after?: Maybe<OptionSetWhereUniqueInput>;
  before?: Maybe<OptionSetWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OptionSetOrderByInput>>;
  where?: Maybe<OptionSetWhereInput>;
};


export type QueryPaveDiamondTypesArgs = {
  orderBy?: Maybe<Array<PaveDiamondTypeOrderByInput>>;
  where?: Maybe<PaveDiamondTypeWhereInput>;
};


export type QueryPavesArgs = {
  orderBy?: Maybe<Array<PaveOrderByInput>>;
  where?: Maybe<PaveWhereInput>;
};


export type QueryPolishesArgs = {
  orderBy?: Maybe<Array<PolishOrderByInput>>;
  where?: Maybe<PolishWhereInput>;
};


export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryProductSearchArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  terms: Scalars['String'];
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryProductTypesArgs = {
  orderBy?: Maybe<Array<ProductTypeOrderByInput>>;
  where?: Maybe<ProductTypeWhereInput>;
};


export type QueryProductsArgs = {
  after?: Maybe<ProductWhereUniqueInput>;
  before?: Maybe<ProductWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProductOrderByInput>>;
  where?: Maybe<ProductWhereInput>;
};


export type QueryProductsCountArgs = {
  where?: Maybe<ProductWhereInput>;
};


export type QueryProngsArgs = {
  orderBy?: Maybe<Array<ProngOrderByInput>>;
  where?: Maybe<ProngWhereInput>;
};


export type QueryRingTypesArgs = {
  orderBy?: Maybe<Array<RingTypeOrderByInput>>;
  where?: Maybe<RingTypeWhereInput>;
};


export type QueryShankThicknessesArgs = {
  orderBy?: Maybe<Array<ShankThicknessOrderByInput>>;
  where?: Maybe<ShankThicknessWhereInput>;
};


export type QueryShanksArgs = {
  orderBy?: Maybe<Array<ShankOrderByInput>>;
  where?: Maybe<ShankWhereInput>;
};


export type QuerySymmetriesArgs = {
  orderBy?: Maybe<Array<SymmetryOrderByInput>>;
  where?: Maybe<SymmetryWhereInput>;
};


export type QueryVendorArgs = {
  where: VendorWhereUniqueInput;
};


export type QueryVendorsArgs = {
  after?: Maybe<VendorWhereUniqueInput>;
  before?: Maybe<VendorWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<VendorOrderByInput>>;
  where?: Maybe<VendorWhereInput>;
};


export type QueryWishlistArgs = {
  where: WishlistWhereUniqueInput;
};


export type QueryWishlistsArgs = {
  after?: Maybe<WishlistWhereUniqueInput>;
  before?: Maybe<WishlistWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<WishlistOrderByInput>>;
  where?: Maybe<WishlistWhereInput>;
};


export type QueryCustomDesignRequestArgs = {
  _id?: Maybe<Scalars['ObjID']>;
  userId?: Maybe<Scalars['ObjID']>;
};


export type QueryEmailsArgs = {
  invoiceId?: Maybe<Scalars['ObjID']>;
  workOrderId?: Maybe<Scalars['ObjID']>;
  quoteId?: Maybe<Scalars['ObjID']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjID']>;
};


export type QueryEmailArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryFaqsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryFaqArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryFormContactArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryGeneralInquiriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
  userId?: Maybe<Scalars['ObjID']>;
};


export type QueryGeneralInquiryArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryInvoicesArgs = {
  userId?: Maybe<Scalars['ObjID']>;
};


export type QueryInvoiceArgs = {
  _id: Scalars['ObjID'];
};


export type QueryInvoiceEmailArgs = {
  _id: Scalars['ObjID'];
};


export type QueryJobsArgs = {
  workOrderId?: Maybe<Scalars['ObjID']>;
  userId?: Maybe<Scalars['ObjID']>;
};


export type QueryJobArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryOrdersArgs = {
  filter?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryOrderArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryPaymentsArgs = {
  orderId?: Maybe<Scalars['ObjID']>;
  workOrderId?: Maybe<Scalars['ObjID']>;
  userId?: Maybe<Scalars['ObjID']>;
};


export type QueryPaymentArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryPaymentTypesArgs = {
  filter?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryPaymentTypeArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryPermissionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryPermissionArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryQuotesArgs = {
  customDesignRequestId?: Maybe<Scalars['ObjID']>;
  userId?: Maybe<Scalars['ObjID']>;
};


export type QueryQuoteArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryQuoteEmailArgs = {
  _id: Scalars['ObjID'];
};


export type QueryReferralsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryReferralArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryProposeSimilarDiamondsArgs = {
  diamondShapeId?: Maybe<Scalars['String']>;
  excludeId?: Maybe<Scalars['String']>;
  carat?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryProposeRingsForDiamondArgs = {
  diamondShapeId?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryProposeSimilarJewelryArgs = {
  productId?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryRolesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryRoleArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryInvoiceSearchArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};


export type QuerySettingArgs = {
  _id?: Maybe<Scalars['ObjID']>;
  key?: Maybe<Scalars['String']>;
};


export type QuerySnippetsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QuerySnippetArgs = {
  _id?: Maybe<Scalars['ObjID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryStaticPageArgs = {
  _id?: Maybe<Scalars['ObjID']>;
  route?: Maybe<Scalars['String']>;
};


export type QuerySubscribersArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QuerySubscriberArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryTemplateArgs = {
  name: Scalars['String'];
};


export type QueryTestimonialsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryTestimonialArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryUsersArgs = {
  filter?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryUserArgs = {
  _id: Scalars['ObjID'];
};


export type QueryVirtualProductsArgs = {
  where?: Maybe<VirtualProductsWhereInput>;
  limit?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
};


export type QueryVirtualProductArgs = {
  id: Scalars['String'];
};


export type QueryWorkOrdersArgs = {
  quoteId?: Maybe<Scalars['ObjID']>;
  workOrderInvoiceId?: Maybe<Scalars['ObjID']>;
  userId?: Maybe<Scalars['ObjID']>;
};


export type QueryWorkOrderArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type QueryWorkOrderEmailArgs = {
  _id: Scalars['ObjID'];
};


export type QueryWorkOrderItemsArgs = {
  quoteId?: Maybe<Scalars['ObjID']>;
  workOrderId?: Maybe<Scalars['ObjID']>;
  userId?: Maybe<Scalars['ObjID']>;
};


export type QueryWorkOrderItemArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Quote = {
  __typename?: 'Quote';
  _id: Scalars['ObjID'];
  customDesignRequestId?: Maybe<Scalars['ObjID']>;
  customDesignRequest?: Maybe<CustomDesignRequest>;
  description?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<Email>>>;
  items?: Maybe<Array<Maybe<QuoteItem>>>;
  locked?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Int']>;
  shipping?: Maybe<Scalars['Int']>;
  hasTax?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  userId: Scalars['ObjID'];
  user: User;
  emailedAt?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  workOrder?: Maybe<WorkOrder>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ObjID']>;
  createdByUser?: Maybe<User>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['ObjID']>;
  updatedByUser?: Maybe<User>;
};

export type QuoteInput = {
  customDesignRequestId?: Maybe<Scalars['ObjID']>;
  description?: Maybe<Scalars['String']>;
  emailedAt?: Maybe<Scalars['DateTime']>;
  hasTax?: Maybe<Scalars['Boolean']>;
  shipping?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<QuoteItemInput>>;
  locked?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['ObjID']>;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type QuoteItem = {
  __typename?: 'QuoteItem';
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type QuoteItemInput = {
  description?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Int']>;
};

export type Referral = {
  __typename?: 'Referral';
  _id: Scalars['ObjID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ReferralInput = {
  name: Scalars['String'];
};

export type RemoveResult = {
  __typename?: 'RemoveResult';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Int']>;
};

export type RenderedEmailResult = {
  __typename?: 'RenderedEmailResult';
  html?: Maybe<Scalars['String']>;
};

export type RingType = {
  __typename?: 'RingType';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type RingTypeCreateNestedOneWithoutProductInput = {
  connect?: Maybe<RingTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<RingTypeCreateOrConnectWithoutProductInput>;
  create?: Maybe<RingTypeCreateWithoutProductInput>;
};

export type RingTypeCreateOrConnectWithoutProductInput = {
  create: RingTypeCreateWithoutProductInput;
  where: RingTypeWhereUniqueInput;
};

export type RingTypeCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type RingTypeOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type RingTypeUpdateOneWithoutProductInput = {
  connect?: Maybe<RingTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<RingTypeCreateOrConnectWithoutProductInput>;
  create?: Maybe<RingTypeCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<RingTypeUpdateWithoutProductInput>;
  upsert?: Maybe<RingTypeUpsertWithoutProductInput>;
};

export type RingTypeUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type RingTypeUpsertWithoutProductInput = {
  create: RingTypeCreateWithoutProductInput;
  update: RingTypeUpdateWithoutProductInput;
};

export type RingTypeWhereInput = {
  AND?: Maybe<Array<RingTypeWhereInput>>;
  NOT?: Maybe<Array<RingTypeWhereInput>>;
  OR?: Maybe<Array<RingTypeWhereInput>>;
  Product?: Maybe<ProductListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type RingTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  _id: Scalars['ObjID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Scalars['ObjID']>>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type RoleCreateInput = {
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Scalars['ObjID']>>>;
};

export type RoleUpdateInput = {
  permissions?: Maybe<Array<Maybe<Scalars['ObjID']>>>;
};

export type Setting = {
  __typename?: 'Setting';
  _id: Scalars['ObjID'];
  key: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ObjID']>;
  createdByUser?: Maybe<User>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['ObjID']>;
  updatedByUser?: Maybe<User>;
};

export type Shank = {
  __typename?: 'Shank';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ShankCreateNestedOneWithoutProductInput = {
  connect?: Maybe<ShankWhereUniqueInput>;
  connectOrCreate?: Maybe<ShankCreateOrConnectWithoutProductInput>;
  create?: Maybe<ShankCreateWithoutProductInput>;
};

export type ShankCreateOrConnectWithoutProductInput = {
  create: ShankCreateWithoutProductInput;
  where: ShankWhereUniqueInput;
};

export type ShankCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ShankOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ShankThickness = {
  __typename?: 'ShankThickness';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ShankThicknessCreateNestedOneWithoutProductInput = {
  connect?: Maybe<ShankThicknessWhereUniqueInput>;
  connectOrCreate?: Maybe<ShankThicknessCreateOrConnectWithoutProductInput>;
  create?: Maybe<ShankThicknessCreateWithoutProductInput>;
};

export type ShankThicknessCreateOrConnectWithoutProductInput = {
  create: ShankThicknessCreateWithoutProductInput;
  where: ShankThicknessWhereUniqueInput;
};

export type ShankThicknessCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ShankThicknessOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ShankThicknessUpdateOneWithoutProductInput = {
  connect?: Maybe<ShankThicknessWhereUniqueInput>;
  connectOrCreate?: Maybe<ShankThicknessCreateOrConnectWithoutProductInput>;
  create?: Maybe<ShankThicknessCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ShankThicknessUpdateWithoutProductInput>;
  upsert?: Maybe<ShankThicknessUpsertWithoutProductInput>;
};

export type ShankThicknessUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ShankThicknessUpsertWithoutProductInput = {
  create: ShankThicknessCreateWithoutProductInput;
  update: ShankThicknessUpdateWithoutProductInput;
};

export type ShankThicknessWhereInput = {
  AND?: Maybe<Array<ShankThicknessWhereInput>>;
  NOT?: Maybe<Array<ShankThicknessWhereInput>>;
  OR?: Maybe<Array<ShankThicknessWhereInput>>;
  Product?: Maybe<ProductListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type ShankThicknessWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ShankUpdateOneWithoutProductInput = {
  connect?: Maybe<ShankWhereUniqueInput>;
  connectOrCreate?: Maybe<ShankCreateOrConnectWithoutProductInput>;
  create?: Maybe<ShankCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ShankUpdateWithoutProductInput>;
  upsert?: Maybe<ShankUpsertWithoutProductInput>;
};

export type ShankUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ShankUpsertWithoutProductInput = {
  create: ShankCreateWithoutProductInput;
  update: ShankUpdateWithoutProductInput;
};

export type ShankWhereInput = {
  AND?: Maybe<Array<ShankWhereInput>>;
  NOT?: Maybe<Array<ShankWhereInput>>;
  OR?: Maybe<Array<ShankWhereInput>>;
  Product?: Maybe<ProductListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type ShankWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ShareProductInput = {
  isDiamond: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  recipientEmail: Scalars['String'];
  recipientName: Scalars['String'];
  senderEmail: Scalars['String'];
};

export type Shipment = {
  __typename?: 'Shipment';
  shippingCompany?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type Snippet = {
  __typename?: 'Snippet';
  _id: Scalars['ObjID'];
  slug: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SnippetInput = {
  slug?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StaticPage = {
  __typename?: 'StaticPage';
  _id: Scalars['ObjID'];
  route: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  template: Scalars['String'];
  meta?: Maybe<MetaData>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type StaticPageCreateInput = {
  route: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  meta?: Maybe<MetaDataInput>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type StaticPageUpdateInput = {
  route?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  meta?: Maybe<MetaDataInput>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type Subscriber = {
  __typename?: 'Subscriber';
  _id: Scalars['ObjID'];
  email: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjID']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SubscriberInput = {
  email?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjID']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  userCreated?: Maybe<User>;
  userUpdated?: Maybe<User>;
  userRemoved?: Maybe<Scalars['ObjID']>;
  customDesignRequestCreated?: Maybe<CustomDesignRequest>;
  customDesignRequestUpdated?: Maybe<CustomDesignRequest>;
  customDesignRequestRemoved?: Maybe<Scalars['ObjID']>;
  emailCreated?: Maybe<Email>;
  emailUpdated?: Maybe<Email>;
  emailRemoved?: Maybe<Scalars['ObjID']>;
  faqCreated?: Maybe<Faq>;
  faqUpdated?: Maybe<Faq>;
  faqRemoved?: Maybe<Scalars['ObjID']>;
  formContactCreated?: Maybe<FormContact>;
  formContactUpdated?: Maybe<FormContact>;
  formContactRemoved?: Maybe<Scalars['ObjID']>;
  invoiceCreated?: Maybe<Invoice>;
  invoiceUpdated?: Maybe<Invoice>;
  invoiceRemoved?: Maybe<Scalars['ObjID']>;
  orderCreated?: Maybe<Order>;
  orderUpdated?: Maybe<Order>;
  orderRemoved?: Maybe<Scalars['ObjID']>;
  paymentCreated?: Maybe<Payment>;
  paymentUpdated?: Maybe<Payment>;
  paymentRemoved?: Maybe<Scalars['ObjID']>;
  paymentTypeCreated?: Maybe<PaymentType>;
  paymentTypeUpdated?: Maybe<PaymentType>;
  paymentTypeRemoved?: Maybe<Scalars['ObjID']>;
  permissionCreated?: Maybe<Permission>;
  permissionUpdated?: Maybe<Permission>;
  permissionRemoved?: Maybe<Scalars['ObjID']>;
  referralCreated?: Maybe<Referral>;
  referralUpdated?: Maybe<Referral>;
  referralRemoved?: Maybe<Scalars['ObjID']>;
  roleCreated?: Maybe<Role>;
  roleUpdated?: Maybe<Role>;
  roleRemoved?: Maybe<Scalars['ObjID']>;
  settingCreated?: Maybe<Setting>;
  settingUpdated?: Maybe<Setting>;
  settingRemoved?: Maybe<Scalars['ObjID']>;
  snippetCreated?: Maybe<Snippet>;
  snippetUpdated?: Maybe<Snippet>;
  snippetRemoved?: Maybe<Scalars['ObjID']>;
  staticPageCreated?: Maybe<StaticPage>;
  staticPageUpdated?: Maybe<StaticPage>;
  staticPageRemoved?: Maybe<Scalars['ObjID']>;
  subscriberCreated?: Maybe<Subscriber>;
  subscriberUpdated?: Maybe<Subscriber>;
  subscriberRemoved?: Maybe<Scalars['ObjID']>;
  testimonialCreated?: Maybe<Testimonial>;
  testimonialUpdated?: Maybe<Testimonial>;
  testimonialRemoved?: Maybe<Scalars['ObjID']>;
  workOrderItemCreated?: Maybe<WorkOrderItem>;
  workOrderItemUpdated?: Maybe<WorkOrderItem>;
  workOrderItemRemoved?: Maybe<RemoveResult>;
};


export type SubscriptionUserCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionUserUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionUserRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionCustomDesignRequestCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionCustomDesignRequestUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionCustomDesignRequestRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionEmailCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionEmailUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionEmailRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionFaqCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionFaqUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionFaqRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionFormContactCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionFormContactUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionFormContactRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionInvoiceCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionInvoiceUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionInvoiceRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionOrderCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionOrderUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionOrderRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionPaymentCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionPaymentUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionPaymentRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionPaymentTypeCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionPaymentTypeUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionPaymentTypeRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionPermissionCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionPermissionUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionPermissionRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionReferralCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionReferralUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionReferralRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionRoleCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionRoleUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionRoleRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionSettingCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
  key?: Maybe<Scalars['String']>;
};


export type SubscriptionSettingUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
  key?: Maybe<Scalars['String']>;
};


export type SubscriptionSettingRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
  key?: Maybe<Scalars['String']>;
};


export type SubscriptionSnippetCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionSnippetUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionSnippetRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionStaticPageCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionStaticPageUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionStaticPageRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionSubscriberCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionSubscriberUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionSubscriberRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionTestimonialCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionTestimonialUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionTestimonialRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionWorkOrderItemCreatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionWorkOrderItemUpdatedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};


export type SubscriptionWorkOrderItemRemovedArgs = {
  _id?: Maybe<Scalars['ObjID']>;
};

export type SuccessResult = {
  __typename?: 'SuccessResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type Symmetry = {
  __typename?: 'Symmetry';
  id: Scalars['String'];
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type SymmetryCreateNestedOneWithoutDiamondInput = {
  connect?: Maybe<SymmetryWhereUniqueInput>;
  connectOrCreate?: Maybe<SymmetryCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<SymmetryCreateWithoutDiamondInput>;
};

export type SymmetryCreateOrConnectWithoutDiamondInput = {
  create: SymmetryCreateWithoutDiamondInput;
  where: SymmetryWhereUniqueInput;
};

export type SymmetryCreateWithoutDiamondInput = {
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type SymmetryOrderByInput = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type SymmetryUpdateOneWithoutDiamondInput = {
  connect?: Maybe<SymmetryWhereUniqueInput>;
  connectOrCreate?: Maybe<SymmetryCreateOrConnectWithoutDiamondInput>;
  create?: Maybe<SymmetryCreateWithoutDiamondInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<SymmetryUpdateWithoutDiamondInput>;
  upsert?: Maybe<SymmetryUpsertWithoutDiamondInput>;
};

export type SymmetryUpdateWithoutDiamondInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  label?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SymmetryUpsertWithoutDiamondInput = {
  create: SymmetryCreateWithoutDiamondInput;
  update: SymmetryUpdateWithoutDiamondInput;
};

export type SymmetryWhereInput = {
  AND?: Maybe<Array<SymmetryWhereInput>>;
  Diamond?: Maybe<DiamondListRelationFilter>;
  NOT?: Maybe<Array<SymmetryWhereInput>>;
  OR?: Maybe<Array<SymmetryWhereInput>>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  value?: Maybe<StringFilter>;
};

export type SymmetryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Template = {
  __typename?: 'Template';
  content: Scalars['String'];
};

export type Testimonial = {
  __typename?: 'Testimonial';
  _id: Scalars['ObjID'];
  name: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjID']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TestimonialInput = {
  name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjID']>;
};

export type UpdateUserInput = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingIsSame?: Maybe<Scalars['Boolean']>;
  billingAddress?: Maybe<UserAddressInput>;
  shippingAddress?: Maybe<UserAddressInput>;
};


export type User = {
  __typename?: 'User';
  _id: Scalars['ObjID'];
  email: Scalars['String'];
  password: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<UserAddress>;
  shippingAddress?: Maybe<UserAddress>;
  billingIsSame?: Maybe<Scalars['Boolean']>;
  customDesignRequests?: Maybe<Array<Maybe<CustomDesignRequest>>>;
  orders?: Maybe<Array<Maybe<Order>>>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  workOrders?: Maybe<Array<Maybe<WorkOrder>>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserAddress = {
  __typename?: 'UserAddress';
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  isCopy?: Maybe<Scalars['Boolean']>;
};

export type UserAddressInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
};

export type Vendor = {
  __typename?: 'Vendor';
  createdAt: Scalars['DateTime'];
  diamonds: Array<Diamond>;
  diamondsCount?: Maybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  markups: Array<VendorMarkup>;
  name: Scalars['String'];
  slug: Scalars['String'];
  source: VendorSource;
  updatedAt: Scalars['DateTime'];
};


export type VendorDiamondsArgs = {
  after?: Maybe<DiamondWhereUniqueInput>;
  before?: Maybe<DiamondWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<DiamondOrderByInput>>;
  where?: Maybe<DiamondWhereInput>;
};


export type VendorMarkupsArgs = {
  after?: Maybe<VendorMarkupWhereUniqueInput>;
  before?: Maybe<VendorMarkupWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<VendorMarkupOrderByInput>>;
  where?: Maybe<VendorMarkupWhereInput>;
};

export type VendorCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  diamonds?: Maybe<DiamondCreateNestedManyWithoutVendorInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutVendorInput>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  markups?: Maybe<VendorMarkupCreateNestedManyWithoutVendorInput>;
  name: Scalars['String'];
  slug: Scalars['String'];
  source: VendorSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VendorCreateNestedOneWithoutDiamondsInput = {
  connect?: Maybe<VendorWhereUniqueInput>;
  connectOrCreate?: Maybe<VendorCreateOrConnectWithoutDiamondsInput>;
  create?: Maybe<VendorCreateWithoutDiamondsInput>;
};

export type VendorCreateNestedOneWithoutImagesInput = {
  connect?: Maybe<VendorWhereUniqueInput>;
  connectOrCreate?: Maybe<VendorCreateOrConnectWithoutImagesInput>;
  create?: Maybe<VendorCreateWithoutImagesInput>;
};

export type VendorCreateOrConnectWithoutDiamondsInput = {
  create: VendorCreateWithoutDiamondsInput;
  where: VendorWhereUniqueInput;
};

export type VendorCreateOrConnectWithoutImagesInput = {
  create: VendorCreateWithoutImagesInput;
  where: VendorWhereUniqueInput;
};

export type VendorCreateWithoutDiamondsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<ImageCreateNestedManyWithoutVendorInput>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  markups?: Maybe<VendorMarkupCreateNestedManyWithoutVendorInput>;
  name: Scalars['String'];
  slug: Scalars['String'];
  source: VendorSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VendorCreateWithoutImagesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  diamonds?: Maybe<DiamondCreateNestedManyWithoutVendorInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leadTimeFrom?: Maybe<Scalars['Int']>;
  leadTimeTo?: Maybe<Scalars['Int']>;
  markups?: Maybe<VendorMarkupCreateNestedManyWithoutVendorInput>;
  name: Scalars['String'];
  slug: Scalars['String'];
  source: VendorSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VendorMarkup = {
  __typename?: 'VendorMarkup';
  from: Scalars['Int'];
  id: Scalars['String'];
  markupPercent: Scalars['Float'];
  to?: Maybe<Scalars['Int']>;
};

export type VendorMarkupCreateManyVendorInput = {
  from: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  markupPercent: Scalars['Float'];
  to?: Maybe<Scalars['Int']>;
};

export type VendorMarkupCreateManyVendorInputEnvelope = {
  data?: Maybe<Array<VendorMarkupCreateManyVendorInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VendorMarkupCreateNestedManyWithoutVendorInput = {
  connect?: Maybe<Array<VendorMarkupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VendorMarkupCreateOrConnectWithoutVendorInput>>;
  create?: Maybe<Array<VendorMarkupCreateWithoutVendorInput>>;
  createMany?: Maybe<VendorMarkupCreateManyVendorInputEnvelope>;
};

export type VendorMarkupCreateOrConnectWithoutVendorInput = {
  create: VendorMarkupCreateWithoutVendorInput;
  where: VendorMarkupWhereUniqueInput;
};

export type VendorMarkupCreateWithoutVendorInput = {
  from: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  markupPercent: Scalars['Float'];
  to?: Maybe<Scalars['Int']>;
};

export type VendorMarkupListRelationFilter = {
  every?: Maybe<VendorMarkupWhereInput>;
  none?: Maybe<VendorMarkupWhereInput>;
  some?: Maybe<VendorMarkupWhereInput>;
};

export type VendorMarkupOrderByInput = {
  from?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  markupPercent?: Maybe<SortOrder>;
  to?: Maybe<SortOrder>;
  vendorId?: Maybe<SortOrder>;
};

export type VendorMarkupScalarWhereInput = {
  AND?: Maybe<Array<VendorMarkupScalarWhereInput>>;
  NOT?: Maybe<Array<VendorMarkupScalarWhereInput>>;
  OR?: Maybe<Array<VendorMarkupScalarWhereInput>>;
  from?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  markupPercent?: Maybe<FloatFilter>;
  to?: Maybe<IntNullableFilter>;
  vendorId?: Maybe<StringNullableFilter>;
};

export type VendorMarkupUpdateManyMutationInput = {
  from?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  markupPercent?: Maybe<FloatFieldUpdateOperationsInput>;
  to?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type VendorMarkupUpdateManyWithoutVendorInput = {
  connect?: Maybe<Array<VendorMarkupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VendorMarkupCreateOrConnectWithoutVendorInput>>;
  create?: Maybe<Array<VendorMarkupCreateWithoutVendorInput>>;
  createMany?: Maybe<VendorMarkupCreateManyVendorInputEnvelope>;
  delete?: Maybe<Array<VendorMarkupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VendorMarkupScalarWhereInput>>;
  disconnect?: Maybe<Array<VendorMarkupWhereUniqueInput>>;
  set?: Maybe<Array<VendorMarkupWhereUniqueInput>>;
  update?: Maybe<Array<VendorMarkupUpdateWithWhereUniqueWithoutVendorInput>>;
  updateMany?: Maybe<Array<VendorMarkupUpdateManyWithWhereWithoutVendorInput>>;
  upsert?: Maybe<Array<VendorMarkupUpsertWithWhereUniqueWithoutVendorInput>>;
};

export type VendorMarkupUpdateManyWithWhereWithoutVendorInput = {
  data: VendorMarkupUpdateManyMutationInput;
  where: VendorMarkupScalarWhereInput;
};

export type VendorMarkupUpdateWithoutVendorInput = {
  from?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  markupPercent?: Maybe<FloatFieldUpdateOperationsInput>;
  to?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type VendorMarkupUpdateWithWhereUniqueWithoutVendorInput = {
  data: VendorMarkupUpdateWithoutVendorInput;
  where: VendorMarkupWhereUniqueInput;
};

export type VendorMarkupUpsertWithWhereUniqueWithoutVendorInput = {
  create: VendorMarkupCreateWithoutVendorInput;
  update: VendorMarkupUpdateWithoutVendorInput;
  where: VendorMarkupWhereUniqueInput;
};

export type VendorMarkupWhereInput = {
  AND?: Maybe<Array<VendorMarkupWhereInput>>;
  NOT?: Maybe<Array<VendorMarkupWhereInput>>;
  OR?: Maybe<Array<VendorMarkupWhereInput>>;
  Vendor?: Maybe<VendorWhereInput>;
  from?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  markupPercent?: Maybe<FloatFilter>;
  to?: Maybe<IntNullableFilter>;
  vendorId?: Maybe<StringNullableFilter>;
};

export type VendorMarkupWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VendorOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  leadTimeFrom?: Maybe<SortOrder>;
  leadTimeTo?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum VendorSource {
  Jbbrothers = 'jbbrothers',
  Vdb = 'vdb',
  Nivoda = 'nivoda'
}

export type VendorUpdateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  diamonds?: Maybe<DiamondUpdateManyWithoutVendorInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutVendorInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  markups?: Maybe<VendorMarkupUpdateManyWithoutVendorInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<EnumVendorSourceFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VendorUpdateOneWithoutDiamondsInput = {
  connect?: Maybe<VendorWhereUniqueInput>;
  connectOrCreate?: Maybe<VendorCreateOrConnectWithoutDiamondsInput>;
  create?: Maybe<VendorCreateWithoutDiamondsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VendorUpdateWithoutDiamondsInput>;
  upsert?: Maybe<VendorUpsertWithoutDiamondsInput>;
};

export type VendorUpdateOneWithoutImagesInput = {
  connect?: Maybe<VendorWhereUniqueInput>;
  connectOrCreate?: Maybe<VendorCreateOrConnectWithoutImagesInput>;
  create?: Maybe<VendorCreateWithoutImagesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VendorUpdateWithoutImagesInput>;
  upsert?: Maybe<VendorUpsertWithoutImagesInput>;
};

export type VendorUpdateWithoutDiamondsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  images?: Maybe<ImageUpdateManyWithoutVendorInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  markups?: Maybe<VendorMarkupUpdateManyWithoutVendorInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<EnumVendorSourceFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VendorUpdateWithoutImagesInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  diamonds?: Maybe<DiamondUpdateManyWithoutVendorInput>;
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  leadTimeFrom?: Maybe<NullableIntFieldUpdateOperationsInput>;
  leadTimeTo?: Maybe<NullableIntFieldUpdateOperationsInput>;
  markups?: Maybe<VendorMarkupUpdateManyWithoutVendorInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<EnumVendorSourceFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VendorUpsertWithoutDiamondsInput = {
  create: VendorCreateWithoutDiamondsInput;
  update: VendorUpdateWithoutDiamondsInput;
};

export type VendorUpsertWithoutImagesInput = {
  create: VendorCreateWithoutImagesInput;
  update: VendorUpdateWithoutImagesInput;
};

export type VendorWhereInput = {
  AND?: Maybe<Array<VendorWhereInput>>;
  NOT?: Maybe<Array<VendorWhereInput>>;
  OR?: Maybe<Array<VendorWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  diamonds?: Maybe<DiamondListRelationFilter>;
  enabled?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  images?: Maybe<ImageListRelationFilter>;
  leadTimeFrom?: Maybe<IntNullableFilter>;
  leadTimeTo?: Maybe<IntNullableFilter>;
  markups?: Maybe<VendorMarkupListRelationFilter>;
  name?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  source?: Maybe<EnumVendorSourceFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VendorWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type Video = {
  __typename?: 'Video';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  source: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type VideoCreateNestedOneWithoutProductInput = {
  connect?: Maybe<VideoWhereUniqueInput>;
  connectOrCreate?: Maybe<VideoCreateOrConnectWithoutProductInput>;
  create?: Maybe<VideoCreateWithoutProductInput>;
};

export type VideoCreateOrConnectWithoutProductInput = {
  create: VideoCreateWithoutProductInput;
  where: VideoWhereUniqueInput;
};

export type VideoCreateWithoutProductInput = {
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VideoUpdateOneWithoutProductInput = {
  connect?: Maybe<VideoWhereUniqueInput>;
  connectOrCreate?: Maybe<VideoCreateOrConnectWithoutProductInput>;
  create?: Maybe<VideoCreateWithoutProductInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VideoUpdateWithoutProductInput>;
  upsert?: Maybe<VideoUpsertWithoutProductInput>;
};

export type VideoUpdateWithoutProductInput = {
  code?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VideoUpsertWithoutProductInput = {
  create: VideoCreateWithoutProductInput;
  update: VideoUpdateWithoutProductInput;
};

export type VideoWhereInput = {
  AND?: Maybe<Array<VideoWhereInput>>;
  NOT?: Maybe<Array<VideoWhereInput>>;
  OR?: Maybe<Array<VideoWhereInput>>;
  code?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  product?: Maybe<ProductWhereInput>;
  productId?: Maybe<StringNullableFilter>;
  source?: Maybe<StringFilter>;
  title?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VideoWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type VirtualProduct = {
  __typename?: 'VirtualProduct';
  _id?: Maybe<Scalars['ObjID']>;
  id: Scalars['String'];
  carat: Scalars['Float'];
  clarity?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cost: Scalars['Int'];
  crownAngle?: Maybe<Scalars['Float']>;
  culet?: Maybe<Scalars['String']>;
  cut?: Maybe<Scalars['String']>;
  depthMM?: Maybe<Scalars['Float']>;
  depthPercent?: Maybe<Scalars['Float']>;
  fluorescence?: Maybe<Scalars['String']>;
  girdle?: Maybe<Scalars['Float']>;
  images: Array<Scalars['String']>;
  images360?: Maybe<Scalars['String']>;
  lab?: Maybe<Scalars['String']>;
  labReport?: Maybe<Scalars['String']>;
  lengthMM?: Maybe<Scalars['Float']>;
  lightPerformance?: Maybe<Scalars['String']>;
  measurements?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pavilionAngle?: Maybe<Scalars['Float']>;
  polish?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  shape?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  symmetry?: Maybe<Scalars['String']>;
  tablePercent?: Maybe<Scalars['Float']>;
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars['ObjID']>;
  widthMM?: Maybe<Scalars['String']>;
};

export type VirtualProductResults = {
  __typename?: 'VirtualProductResults';
  results: Array<Maybe<VirtualProduct>>;
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  hasNext: Scalars['Boolean'];
  count: Scalars['Int'];
};

export type VirtualProductsWhereInput = {
  caratFrom?: Maybe<Scalars['Float']>;
  caratTo?: Maybe<Scalars['Float']>;
  priceFrom?: Maybe<Scalars['Int']>;
  priceTo?: Maybe<Scalars['Int']>;
  color?: Maybe<Array<Maybe<Scalars['String']>>>;
  lab?: Maybe<Array<Maybe<Scalars['String']>>>;
  purity?: Maybe<Array<Maybe<Scalars['String']>>>;
  shape?: Maybe<Array<Maybe<Scalars['String']>>>;
  sku?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Wishlist = {
  __typename?: 'Wishlist';
  createdAt: Scalars['DateTime'];
  diamonds: Array<Diamond>;
  id: Scalars['String'];
  isPublic: Scalars['Boolean'];
  owner?: Maybe<WishlistOwner>;
  products: Array<Product>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};


export type WishlistDiamondsArgs = {
  after?: Maybe<DiamondWhereUniqueInput>;
  before?: Maybe<DiamondWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<DiamondOrderByInput>>;
  where?: Maybe<DiamondWhereInput>;
};


export type WishlistProductsArgs = {
  after?: Maybe<ProductWhereUniqueInput>;
  before?: Maybe<ProductWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProductOrderByInput>>;
  where?: Maybe<ProductWhereInput>;
};

export type WishlistCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  diamonds?: Maybe<DiamondCreateNestedManyWithoutWishlistsInput>;
  id?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  products?: Maybe<ProductCreateNestedManyWithoutWishlistsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type WishlistCreateNestedManyWithoutDiamondsInput = {
  connect?: Maybe<Array<WishlistWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WishlistCreateOrConnectWithoutDiamondsInput>>;
  create?: Maybe<Array<WishlistCreateWithoutDiamondsInput>>;
};

export type WishlistCreateNestedManyWithoutProductsInput = {
  connect?: Maybe<Array<WishlistWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WishlistCreateOrConnectWithoutProductsInput>>;
  create?: Maybe<Array<WishlistCreateWithoutProductsInput>>;
};

export type WishlistCreateOrConnectWithoutDiamondsInput = {
  create: WishlistCreateWithoutDiamondsInput;
  where: WishlistWhereUniqueInput;
};

export type WishlistCreateOrConnectWithoutProductsInput = {
  create: WishlistCreateWithoutProductsInput;
  where: WishlistWhereUniqueInput;
};

export type WishlistCreateWithoutDiamondsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  products?: Maybe<ProductCreateNestedManyWithoutWishlistsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type WishlistCreateWithoutProductsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  diamonds?: Maybe<DiamondCreateNestedManyWithoutWishlistsInput>;
  id?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type WishlistListRelationFilter = {
  every?: Maybe<WishlistWhereInput>;
  none?: Maybe<WishlistWhereInput>;
  some?: Maybe<WishlistWhereInput>;
};

export type WishlistOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isPublic?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type WishlistOwner = {
  __typename?: 'WishlistOwner';
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type WishlistScalarWhereInput = {
  AND?: Maybe<Array<WishlistScalarWhereInput>>;
  NOT?: Maybe<Array<WishlistScalarWhereInput>>;
  OR?: Maybe<Array<WishlistScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isPublic?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
};

export type WishlistUpdateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  diamonds?: Maybe<DiamondUpdateManyWithoutWishlistsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutWishlistsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type WishlistUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type WishlistUpdateManyWithoutDiamondsInput = {
  connect?: Maybe<Array<WishlistWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WishlistCreateOrConnectWithoutDiamondsInput>>;
  create?: Maybe<Array<WishlistCreateWithoutDiamondsInput>>;
  delete?: Maybe<Array<WishlistWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WishlistScalarWhereInput>>;
  disconnect?: Maybe<Array<WishlistWhereUniqueInput>>;
  set?: Maybe<Array<WishlistWhereUniqueInput>>;
  update?: Maybe<Array<WishlistUpdateWithWhereUniqueWithoutDiamondsInput>>;
  updateMany?: Maybe<Array<WishlistUpdateManyWithWhereWithoutDiamondsInput>>;
  upsert?: Maybe<Array<WishlistUpsertWithWhereUniqueWithoutDiamondsInput>>;
};

export type WishlistUpdateManyWithoutProductsInput = {
  connect?: Maybe<Array<WishlistWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WishlistCreateOrConnectWithoutProductsInput>>;
  create?: Maybe<Array<WishlistCreateWithoutProductsInput>>;
  delete?: Maybe<Array<WishlistWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WishlistScalarWhereInput>>;
  disconnect?: Maybe<Array<WishlistWhereUniqueInput>>;
  set?: Maybe<Array<WishlistWhereUniqueInput>>;
  update?: Maybe<Array<WishlistUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: Maybe<Array<WishlistUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: Maybe<Array<WishlistUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type WishlistUpdateManyWithWhereWithoutDiamondsInput = {
  data: WishlistUpdateManyMutationInput;
  where: WishlistScalarWhereInput;
};

export type WishlistUpdateManyWithWhereWithoutProductsInput = {
  data: WishlistUpdateManyMutationInput;
  where: WishlistScalarWhereInput;
};

export type WishlistUpdateWithoutDiamondsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutWishlistsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type WishlistUpdateWithoutProductsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  diamonds?: Maybe<DiamondUpdateManyWithoutWishlistsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type WishlistUpdateWithWhereUniqueWithoutDiamondsInput = {
  data: WishlistUpdateWithoutDiamondsInput;
  where: WishlistWhereUniqueInput;
};

export type WishlistUpdateWithWhereUniqueWithoutProductsInput = {
  data: WishlistUpdateWithoutProductsInput;
  where: WishlistWhereUniqueInput;
};

export type WishlistUpsertWithWhereUniqueWithoutDiamondsInput = {
  create: WishlistCreateWithoutDiamondsInput;
  update: WishlistUpdateWithoutDiamondsInput;
  where: WishlistWhereUniqueInput;
};

export type WishlistUpsertWithWhereUniqueWithoutProductsInput = {
  create: WishlistCreateWithoutProductsInput;
  update: WishlistUpdateWithoutProductsInput;
  where: WishlistWhereUniqueInput;
};

export type WishlistWhereInput = {
  AND?: Maybe<Array<WishlistWhereInput>>;
  NOT?: Maybe<Array<WishlistWhereInput>>;
  OR?: Maybe<Array<WishlistWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  diamonds?: Maybe<DiamondListRelationFilter>;
  id?: Maybe<StringFilter>;
  isPublic?: Maybe<BoolFilter>;
  products?: Maybe<ProductListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
};

export type WishlistWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type WorkOrder = {
  __typename?: 'WorkOrder';
  _id: Scalars['ObjID'];
  hasTax?: Maybe<Scalars['Boolean']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<WorkOrderItem>>>;
  jobs?: Maybe<Array<Maybe<Job>>>;
  locked?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  balance?: Maybe<Scalars['Int']>;
  quoteId?: Maybe<Scalars['ObjID']>;
  quote?: Maybe<Quote>;
  shipments?: Maybe<Array<Maybe<Shipment>>>;
  shipping?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  totalPayments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ObjID']>;
  user?: Maybe<User>;
  invoiceId?: Maybe<Scalars['ObjID']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ObjID'];
  createdByUser: User;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['ObjID']>;
  updatedByUser?: Maybe<User>;
};

export type WorkOrderEmailInput = {
  data?: Maybe<Scalars['JSON']>;
  from?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  template?: Maybe<Scalars['String']>;
  to: Scalars['String'];
  workOrderId: Scalars['ObjID'];
};

export type WorkOrderInput = {
  hasTax?: Maybe<Scalars['Boolean']>;
  locked?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  quoteId?: Maybe<Scalars['ObjID']>;
  shipping?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ObjID']>;
};

export type WorkOrderItem = {
  __typename?: 'WorkOrderItem';
  _id: Scalars['ObjID'];
  description?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productType?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  returnedAt?: Maybe<Scalars['DateTime']>;
  tax?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ObjID']>;
  workOrderId: Scalars['ObjID'];
  workOrder: WorkOrder;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['ObjID']>;
  createdByUser?: Maybe<User>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['ObjID']>;
  updatedByUser?: Maybe<User>;
};

export type WorkOrderItemInput = {
  description?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  returnedAt?: Maybe<Scalars['DateTime']>;
  tax?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Int']>;
  workOrderId?: Maybe<Scalars['ObjID']>;
};

export type AvailableProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableProductsQuery = (
  { __typename?: 'Query' }
  & { diamonds: Array<(
    { __typename?: 'Diamond' }
    & Pick<Diamond, 'id' | 'sku' | 'name' | 'description' | 'price' | 'quantity' | 'type'>
    & { _id: Diamond['id'] }
    & { meta?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'description'>
    )> }
  )>, rings: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'sku' | 'name' | 'description' | 'shortDescription' | 'price' | 'quantity' | 'type'>
    & { _id: Product['id'] }
    & { meta?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'description'>
    )>, parent?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'shortDescription'>
    )> }
  )>, jewelries: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'sku' | 'name' | 'description' | 'shortDescription' | 'price' | 'quantity' | 'type'>
    & { _id: Product['id'] }
    & { meta?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'description'>
    )>, parent?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'shortDescription'>
    )> }
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'email' | 'firstName' | 'lastName' | 'roles' | 'phone'>
  )> }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'email' | 'firstName' | 'lastName' | 'roles' | 'createdAt'>
  )> }
);

export type CustomDesignRequestQueryVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type CustomDesignRequestQuery = (
  { __typename?: 'Query' }
  & { customDesignRequest?: Maybe<(
    { __typename?: 'CustomDesignRequest' }
    & Pick<CustomDesignRequest, '_id' | 'userId' | 'designs' | 'productType' | 'metal' | 'budgetFrom' | 'budgetTo' | 'timeframe' | 'comments' | 'caratFrom' | 'caratTo' | 'clarityFrom' | 'clarityTo' | 'colorFrom' | 'colorTo' | 'cut' | 'fingerSize' | 'ringType' | 'prongs' | 'shank' | 'shankThickness' | 'shape' | 'skuOrTitle' | 'pave' | 'isCenterstoneRequired' | 'isEngravingRequired' | 'isPaveRequired' | 'isFiligreeRequired' | 'status' | 'createdAt' | 'updatedAt' | 'remoteAddress'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'phone'>
    )>, quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, '_id'>
    )> }
  )> }
);

export type RemoveCustomDesignRequestMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type RemoveCustomDesignRequestMutation = (
  { __typename?: 'Mutation' }
  & { removeCustomDesignRequest?: Maybe<(
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'n' | 'ok'>
  )> }
);

export type UpdateCustomDesignRequestMutationVariables = Exact<{
  _id: Scalars['ObjID'];
  input: CustomDesignRequestUpdateInput;
}>;


export type UpdateCustomDesignRequestMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomDesignRequest?: Maybe<(
    { __typename?: 'CustomDesignRequest' }
    & Pick<CustomDesignRequest, '_id' | 'updatedAt'>
  )> }
);

export type CustomDesignRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomDesignRequestsQuery = (
  { __typename?: 'Query' }
  & { customDesignRequests?: Maybe<Array<(
    { __typename?: 'CustomDesignRequest' }
    & Pick<CustomDesignRequest, '_id' | 'email' | 'firstName' | 'lastName' | 'status' | 'createdAt'>
  )>> }
);

export type DiamondAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type DiamondAttributesQuery = (
  { __typename?: 'Query' }
  & { clarities: Array<(
    { __typename?: 'Clarity' }
    & Pick<Clarity, 'id' | 'label' | 'order'>
    & { _id: Clarity['id'] }
  )>, colors: Array<(
    { __typename?: 'Color' }
    & Pick<Color, 'id' | 'label' | 'order'>
    & { _id: Color['id'] }
  )>, culets: Array<(
    { __typename?: 'Culet' }
    & Pick<Culet, 'id' | 'label' | 'order'>
    & { _id: Culet['id'] }
  )>, cuts: Array<(
    { __typename?: 'Cut' }
    & Pick<Cut, 'id' | 'label' | 'order'>
    & { _id: Cut['id'] }
  )>, fluorescences: Array<(
    { __typename?: 'Fluorescence' }
    & Pick<Fluorescence, 'id' | 'label' | 'order'>
    & { _id: Fluorescence['id'] }
  )>, girdles: Array<(
    { __typename?: 'Girdle' }
    & Pick<Girdle, 'id' | 'label' | 'order'>
    & { _id: Girdle['id'] }
  )>, lightPerformances: Array<(
    { __typename?: 'LightPerformance' }
    & Pick<LightPerformance, 'id' | 'label' | 'order'>
    & { _id: LightPerformance['id'] }
  )>, polishes: Array<(
    { __typename?: 'Polish' }
    & Pick<Polish, 'id' | 'label' | 'order'>
    & { _id: Polish['id'] }
  )>, diamondShapes: Array<(
    { __typename?: 'DiamondShape' }
    & Pick<DiamondShape, 'id' | 'label' | 'order'>
    & { _id: DiamondShape['id'] }
  )>, symmetries: Array<(
    { __typename?: 'Symmetry' }
    & Pick<Symmetry, 'id' | 'label' | 'order'>
    & { _id: Symmetry['id'] }
  )> }
);

export type CreateDiamondMutationVariables = Exact<{
  data: DiamondCreateInput;
}>;


export type CreateDiamondMutation = (
  { __typename?: 'Mutation' }
  & { createDiamond: (
    { __typename?: 'Diamond' }
    & Pick<Diamond, 'id' | 'createdAt'>
    & { _id: Diamond['id'] }
  ) }
);

export type RemoveDiamondMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveDiamondMutation = (
  { __typename?: 'Mutation' }
  & { deleteDiamond?: Maybe<(
    { __typename?: 'Diamond' }
    & Pick<Diamond, 'id'>
  )> }
);

export type DescriptiveFieldsQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type DescriptiveFieldsQuery = (
  { __typename?: 'Query' }
  & { diamond?: Maybe<(
    { __typename?: 'Diamond' }
    & Pick<Diamond, 'description'>
    & { metaDescription?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'description'>
    )>, metaTitle?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'title'>
    )> }
  )> }
);

export type DiamondQueryVariables = Exact<{
  slugOrId: Scalars['String'];
}>;


export type DiamondQuery = (
  { __typename?: 'Query' }
  & { clarities: Array<(
    { __typename?: 'Clarity' }
    & Pick<Clarity, 'id' | 'label' | 'order'>
    & { _id: Clarity['id'] }
  )>, colors: Array<(
    { __typename?: 'Color' }
    & Pick<Color, 'id' | 'label' | 'order'>
    & { _id: Color['id'] }
  )>, culets: Array<(
    { __typename?: 'Culet' }
    & Pick<Culet, 'id' | 'label' | 'order'>
    & { _id: Culet['id'] }
  )>, cuts: Array<(
    { __typename?: 'Cut' }
    & Pick<Cut, 'id' | 'label' | 'order'>
    & { _id: Cut['id'] }
  )>, fluorescences: Array<(
    { __typename?: 'Fluorescence' }
    & Pick<Fluorescence, 'id' | 'label' | 'order'>
    & { _id: Fluorescence['id'] }
  )>, girdles: Array<(
    { __typename?: 'Girdle' }
    & Pick<Girdle, 'id' | 'label' | 'order'>
    & { _id: Girdle['id'] }
  )>, lightPerformances: Array<(
    { __typename?: 'LightPerformance' }
    & Pick<LightPerformance, 'id' | 'label' | 'order'>
    & { _id: LightPerformance['id'] }
  )>, polishes: Array<(
    { __typename?: 'Polish' }
    & Pick<Polish, 'id' | 'label' | 'order'>
    & { _id: Polish['id'] }
  )>, diamondShapes: Array<(
    { __typename?: 'DiamondShape' }
    & Pick<DiamondShape, 'id' | 'label' | 'order'>
    & { _id: DiamondShape['id'] }
  )>, symmetries: Array<(
    { __typename?: 'Symmetry' }
    & Pick<Symmetry, 'id' | 'label' | 'order'>
    & { _id: Symmetry['id'] }
  )>, diamond?: Maybe<(
    { __typename?: 'Diamond' }
    & Pick<Diamond, 'id' | 'autopopulateDescription' | 'autopopulateMetaDescription' | 'autopopulateMetaTitle' | 'carat' | 'clarityId' | 'colorId' | 'cost' | 'createdAt' | 'crownAngle' | 'culetId' | 'cutId' | 'description' | 'depthMM' | 'depthPercent' | 'enabled' | 'fluorescenceId' | 'images360IFrameUrl' | 'lengthMM' | 'lightPerformanceId' | 'lowerGirdleFacetPercent' | 'name' | 'isLabGrown' | 'notes' | 'onSale' | 'pavilionAngle' | 'polishId' | 'price' | 'quantity' | 'salePrice' | 'shapeId' | 'sku' | 'slug' | 'starLengthPercent' | 'symmetryId' | 'tablePercent' | 'vendorId' | 'widthMM' | 'updatedAt'>
    & { _id: Diamond['id'] }
    & { clarity?: Maybe<(
      { __typename?: 'Clarity' }
      & Pick<Clarity, 'id' | 'label' | 'value'>
    )>, color?: Maybe<(
      { __typename?: 'Color' }
      & Pick<Color, 'id' | 'label' | 'value'>
    )>, culet?: Maybe<(
      { __typename?: 'Culet' }
      & Pick<Culet, 'id' | 'label' | 'value'>
    )>, cut?: Maybe<(
      { __typename?: 'Cut' }
      & Pick<Cut, 'id' | 'label' | 'value'>
    )>, fluorescence?: Maybe<(
      { __typename?: 'Fluorescence' }
      & Pick<Fluorescence, 'id' | 'label' | 'value'>
    )>, girdles: Array<(
      { __typename?: 'Girdle' }
      & Pick<Girdle, 'id' | 'label' | 'value'>
    )>, images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'category' | 'filename' | 'path' | 'storageUrl' | 'url'>
    )>, lab?: Maybe<(
      { __typename?: 'LabDetail' }
      & Pick<LabDetail, 'name' | 'certificate' | 'reportNumber'>
    )>, lightPerformance?: Maybe<(
      { __typename?: 'LightPerformance' }
      & Pick<LightPerformance, 'id' | 'label' | 'value'>
    )>, meta?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'title' | 'description' | 'keywords'>
    )>, polish?: Maybe<(
      { __typename?: 'Polish' }
      & Pick<Polish, 'id' | 'label' | 'value'>
    )>, shape?: Maybe<(
      { __typename?: 'DiamondShape' }
      & Pick<DiamondShape, 'id' | 'label' | 'value'>
    )>, symmetry?: Maybe<(
      { __typename?: 'Symmetry' }
      & Pick<Symmetry, 'id' | 'label' | 'value'>
    )>, vendor?: Maybe<(
      { __typename?: 'Vendor' }
      & Pick<Vendor, 'name'>
    )> }
  )> }
);

export type UpdateDiamondMutationVariables = Exact<{
  where: DiamondWhereUniqueInput;
  data: DiamondUpdateInput;
}>;


export type UpdateDiamondMutation = (
  { __typename?: 'Mutation' }
  & { updateDiamond?: Maybe<(
    { __typename?: 'Diamond' }
    & Pick<Diamond, 'id' | 'updatedAt'>
  )> }
);

export type DiamondsQueryVariables = Exact<{
  where?: Maybe<DiamondWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<DiamondWhereUniqueInput>;
  before?: Maybe<DiamondWhereUniqueInput>;
  orderBy?: Maybe<Array<DiamondOrderByInput> | DiamondOrderByInput>;
}>;


export type DiamondsQuery = (
  { __typename?: 'Query' }
  & { count: Query['diamondsCount'] }
  & { diamonds: Array<(
    { __typename?: 'Diamond' }
    & Pick<Diamond, 'id' | 'carat' | 'cost' | 'createdAt' | 'crownAngle' | 'name' | 'price' | 'quantity' | 'sku'>
    & { _id: Diamond['id'] }
    & { clarity?: Maybe<(
      { __typename?: 'Clarity' }
      & Pick<Clarity, 'id' | 'label'>
    )>, color?: Maybe<(
      { __typename?: 'Color' }
      & Pick<Color, 'id' | 'label'>
    )>, culet?: Maybe<(
      { __typename?: 'Culet' }
      & Pick<Culet, 'id' | 'label'>
    )>, cut?: Maybe<(
      { __typename?: 'Cut' }
      & Pick<Cut, 'id' | 'label'>
    )>, lab?: Maybe<(
      { __typename?: 'LabDetail' }
      & Pick<LabDetail, 'name' | 'reportNumber'>
    )>, shape?: Maybe<(
      { __typename?: 'DiamondShape' }
      & Pick<DiamondShape, 'id' | 'label'>
    )> }
  )> }
);

export type EmailsQueryVariables = Exact<{
  workOrderId?: Maybe<Scalars['ObjID']>;
  quoteId?: Maybe<Scalars['ObjID']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjID']>;
}>;


export type EmailsQuery = (
  { __typename?: 'Query' }
  & { emails?: Maybe<Array<(
    { __typename?: 'Email' }
    & Pick<Email, '_id' | 'body' | 'from' | 'hasEmail' | 'html' | 'invoiceId' | 'message' | 'orderId' | 'paymentType' | 'shippingCompanyId' | 'subject' | 'template' | 'text' | 'to' | 'trackingNumber' | 'type' | 'userId' | 'workOrderId' | 'sentAt' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type FormContactQueryVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type FormContactQuery = (
  { __typename?: 'Query' }
  & { formContact?: Maybe<(
    { __typename?: 'FormContact' }
    & Pick<FormContact, '_id' | 'email' | 'firstName' | 'lastName' | 'createdAt'>
    & { notes?: Maybe<Array<Maybe<(
      { __typename?: 'FormContactNotes' }
      & Pick<FormContactNotes, 'content' | 'authorId'>
    )>>>, customDesignRequests?: Maybe<Array<Maybe<(
      { __typename?: 'CustomDesignRequest' }
      & Pick<CustomDesignRequest, '_id' | 'designs' | 'productType' | 'createdAt'>
      & { quote?: Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, '_id'>
      )> }
    )>>>, quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, '_id' | 'number' | 'total' | 'locked' | 'emailedAt' | 'customDesignRequestId' | 'createdAt'>
      & { workOrder?: Maybe<(
        { __typename?: 'WorkOrder' }
        & Pick<WorkOrder, '_id'>
      )> }
    )>>>, workOrders?: Maybe<Array<Maybe<(
      { __typename?: 'WorkOrder' }
      & Pick<WorkOrder, '_id' | 'number' | 'status' | 'total' | 'locked' | 'quoteId'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName' | 'email'>
      )> }
    )>>> }
  )> }
);

export type RemoveFormContactMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type RemoveFormContactMutation = (
  { __typename?: 'Mutation' }
  & { removeFormContact?: Maybe<(
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'n' | 'ok'>
  )> }
);

export type UpdateFormContactMutationVariables = Exact<{
  _id: Scalars['ObjID'];
  input: UpdateUserInput;
}>;


export type UpdateFormContactMutation = (
  { __typename?: 'Mutation' }
  & { updateFormContact?: Maybe<(
    { __typename?: 'FormContact' }
    & Pick<FormContact, '_id' | 'updatedAt'>
  )> }
);

export type FormContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type FormContactsQuery = (
  { __typename?: 'Query' }
  & { formContacts?: Maybe<Array<(
    { __typename?: 'FormContact' }
    & Pick<FormContact, '_id' | 'email' | 'firstName' | 'lastName' | 'createdAt'>
    & { customDesignRequests?: Maybe<Array<Maybe<(
      { __typename?: 'CustomDesignRequest' }
      & Pick<CustomDesignRequest, '_id'>
    )>>>, quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, '_id'>
    )>>>, workOrders?: Maybe<Array<Maybe<(
      { __typename?: 'WorkOrder' }
      & Pick<WorkOrder, '_id'>
    )>>> }
  )>> }
);

export type GeneralInquiriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GeneralInquiriesQuery = (
  { __typename?: 'Query' }
  & { generalInquiries?: Maybe<Array<(
    { __typename?: 'GeneralInquiry' }
    & Pick<GeneralInquiry, '_id' | 'firstName' | 'lastName' | 'email' | 'message' | 'createdAt'>
  )>> }
);

export type RemoveImageMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveImageMutation = (
  { __typename?: 'Mutation' }
  & { deleteImage?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id'>
  )> }
);

export type UpdateImageMutationVariables = Exact<{
  where: ImageWhereUniqueInput;
  data: ImageUpdateInput;
}>;


export type UpdateImageMutation = (
  { __typename?: 'Mutation' }
  & { updateImage?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'updatedAt'>
  )> }
);

export type RemoveImagesMutationVariables = Exact<{
  input: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveImagesMutation = (
  { __typename?: 'Mutation' }
  & { deleteImages?: Maybe<Array<Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id'>
  )>>> }
);

export type UpdateImagesMutationVariables = Exact<{
  where?: Maybe<ImageWhereInput>;
  data: ImageUpdateManyMutationInput;
}>;


export type UpdateImagesMutation = (
  { __typename?: 'Mutation' }
  & { updateImages: (
    { __typename?: 'AffectedRowsOutput' }
    & Pick<AffectedRowsOutput, 'count'>
  ) }
);

export type CreateInvoiceMutationVariables = Exact<{
  input: InvoiceInput;
}>;


export type CreateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, '_id'>
  )> }
);

export type InvoiceQueryVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type InvoiceQuery = (
  { __typename?: 'Query' }
  & { invoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, '_id' | 'balance' | 'body' | 'note' | 'number' | 'subject' | 'subtotal' | 'tax' | 'terms' | 'to' | 'total' | 'totalPayments' | 'type' | 'userId' | 'orderId' | 'workOrderId' | 'sentAt' | 'createdAt' | 'updatedAt'>
    & { emails?: Maybe<Array<Maybe<(
      { __typename?: 'Email' }
      & Pick<Email, '_id' | 'body' | 'from' | 'hasEmail' | 'html' | 'invoiceId' | 'message' | 'orderId' | 'paymentType' | 'shippingCompanyId' | 'subject' | 'template' | 'text' | 'to' | 'trackingNumber' | 'type' | 'userId' | 'workOrderId' | 'sentAt' | 'createdAt' | 'updatedAt'>
    )>>>, items: Array<Maybe<(
      { __typename?: 'WorkOrderItem' }
      & Pick<WorkOrderItem, 'title' | 'unitPrice' | 'quantity' | 'price'>
    )>>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'phone'>
    )>, createdByUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    )>, order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, '_id' | 'tax' | 'shipping' | 'total'>
    )>, workOrder?: Maybe<(
      { __typename?: 'WorkOrder' }
      & Pick<WorkOrder, '_id' | 'completedAt' | 'quoteId' | 'subtotal' | 'tax' | 'shipping' | 'total'>
    )> }
  )> }
);

export type SendInvoiceMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type SendInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { sendInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, '_id'>
  )> }
);

export type UpdateInvoiceMutationVariables = Exact<{
  _id: Scalars['ObjID'];
  input: InvoiceInput;
}>;


export type UpdateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, '_id' | 'updatedAt'>
  )> }
);

export type ProductsQueryVariables = Exact<{
  where?: Maybe<ProductWhereInput>;
  after?: Maybe<ProductWhereUniqueInput>;
  before?: Maybe<ProductWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProductOrderByInput> | ProductOrderByInput>;
}>;


export type ProductsQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'createdAt' | 'modelNumber' | 'enabled' | 'name' | 'price' | 'quantity' | 'sku' | 'type'>
    & { _id: Product['id'] }
    & { variants: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id'>
    )> }
  )> }
);

export type CreateProductMutationVariables = Exact<{
  data: ProductCreateInput;
}>;


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'createdAt'>
  ) }
);

export type RemoveJewelryMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveJewelryMutation = (
  { __typename?: 'Mutation' }
  & { deleteProduct?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id'>
  )> }
);

export type JewelryQueryVariables = Exact<{
  where: ProductWhereUniqueInput;
}>;


export type JewelryQuery = (
  { __typename?: 'Query' }
  & { availabilities: Array<(
    { __typename?: 'Availability' }
    & Pick<Availability, 'id' | 'label' | 'order'>
    & { _id: Availability['id'] }
  )>, diamondShapes: Array<(
    { __typename?: 'DiamondShape' }
    & Pick<DiamondShape, 'id' | 'label' | 'order'>
    & { _id: DiamondShape['id'] }
  )>, metals: Array<(
    { __typename?: 'Metal' }
    & Pick<Metal, 'id' | 'label' | 'order'>
    & { _id: Metal['id'] }
  )>, paves: Array<(
    { __typename?: 'Pave' }
    & Pick<Pave, 'id' | 'label' | 'order'>
    & { _id: Pave['id'] }
  )>, paveDiamondTypes: Array<(
    { __typename?: 'PaveDiamondType' }
    & Pick<PaveDiamondType, 'id' | 'label' | 'order'>
    & { _id: PaveDiamondType['id'] }
  )>, productTypes: Array<(
    { __typename?: 'ProductType' }
    & Pick<ProductType, 'id' | 'label' | 'order'>
    & { _id: ProductType['id'] }
  )>, prongs: Array<(
    { __typename?: 'Prong' }
    & Pick<Prong, 'id' | 'label' | 'order'>
    & { _id: Prong['id'] }
  )>, ringTypes: Array<(
    { __typename?: 'RingType' }
    & Pick<RingType, 'id' | 'label' | 'order'>
    & { _id: RingType['id'] }
  )>, bandTypes: Array<(
    { __typename?: 'BandType' }
    & Pick<BandType, 'id' | 'label' | 'order'>
    & { _id: BandType['id'] }
  )>, shanks: Array<(
    { __typename?: 'Shank' }
    & Pick<Shank, 'id' | 'label' | 'order'>
    & { _id: Shank['id'] }
  )>, shankThicknesses: Array<(
    { __typename?: 'ShankThickness' }
    & Pick<ShankThickness, 'id' | 'label' | 'value'>
    & { _id: ShankThickness['id'] }
  )>, optionSets: Array<(
    { __typename?: 'OptionSet' }
    & Pick<OptionSet, 'id' | 'name' | 'slug'>
    & { options: Array<(
      { __typename?: 'Option' }
      & Pick<Option, 'id' | 'name' | 'slug'>
    )> }
  )>, jewelry?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'allowCheckout' | 'carat' | 'cost' | 'description' | 'diamondShapeId' | 'enabled' | 'fingerSize' | 'isDefaultVariant' | 'isEngagementRing' | 'isHandFabricated' | 'isRightHandRing' | 'isBand' | 'isLabGrown' | 'leadTimeFrom' | 'leadTimeTo' | 'metalId' | 'modelName' | 'modelNumber' | 'name' | 'notes' | 'parentId' | 'paveId' | 'paveDiamondTypeId' | 'price' | 'productTypeId' | 'prongId' | 'quantity' | 'ringTypeId' | 'bandTypeId' | 'salePrice' | 'shankId' | 'shankThicknessId' | 'sku' | 'shortDescription' | 'slug' | 'type' | 'createdAt' | 'updatedAt'>
    & { _id: Product['id'] }
    & { availability?: Maybe<(
      { __typename?: 'Availability' }
      & Pick<Availability, 'value'>
    )>, categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'slug' | 'createdAt' | 'updatedAt'>
    )>, collections: Array<(
      { __typename?: 'Collection' }
      & Pick<Collection, 'id' | 'name' | 'slug' | 'createdAt' | 'updatedAt'>
    )>, diamondShape?: Maybe<(
      { __typename?: 'DiamondShape' }
      & Pick<DiamondShape, 'label' | 'value'>
      & { _id: DiamondShape['id'] }
    )>, images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'category' | 'filename' | 'order' | 'path' | 'storageUrl' | 'url'>
      & { _id: Image['id'] }
    )>, lab?: Maybe<(
      { __typename?: 'LabDetail' }
      & Pick<LabDetail, 'name' | 'certificate' | 'reportNumber'>
    )>, metal?: Maybe<(
      { __typename?: 'Metal' }
      & Pick<Metal, 'label' | 'value'>
      & { _id: Metal['id'] }
    )>, options: Array<(
      { __typename?: 'Option' }
      & Pick<Option, 'id' | 'name' | 'slug'>
      & { optionSet: (
        { __typename?: 'OptionSet' }
        & Pick<OptionSet, 'id' | 'name' | 'slug'>
      ) }
    )>, pave?: Maybe<(
      { __typename?: 'Pave' }
      & Pick<Pave, 'id' | 'label' | 'value'>
      & { _id: Pave['id'] }
    )>, paveDiamondType?: Maybe<(
      { __typename?: 'PaveDiamondType' }
      & Pick<PaveDiamondType, 'id' | 'label' | 'value'>
      & { _id: PaveDiamondType['id'] }
    )>, productType?: Maybe<(
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'id' | 'label' | 'value'>
      & { _id: ProductType['id'] }
    )>, prong?: Maybe<(
      { __typename?: 'Prong' }
      & Pick<Prong, 'id' | 'label' | 'value'>
      & { _id: Prong['id'] }
    )>, ringType?: Maybe<(
      { __typename?: 'RingType' }
      & Pick<RingType, 'id' | 'label' | 'value'>
      & { _id: RingType['id'] }
    )>, bandType?: Maybe<(
      { __typename?: 'BandType' }
      & Pick<BandType, 'id' | 'label' | 'value'>
      & { _id: BandType['id'] }
    )>, shank?: Maybe<(
      { __typename?: 'Shank' }
      & Pick<Shank, 'id' | 'label' | 'value'>
      & { _id: Shank['id'] }
    )>, shankThickness?: Maybe<(
      { __typename?: 'ShankThickness' }
      & Pick<ShankThickness, 'id' | 'label' | 'value'>
      & { _id: ShankThickness['id'] }
    )>, meta?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'title' | 'description' | 'keywords'>
    )>, variants: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'sku' | 'quantity' | 'price' | 'isDefaultVariant' | 'isLabGrown' | 'parentId' | 'representativeImage'>
      & { options: Array<(
        { __typename?: 'Option' }
        & Pick<Option, 'id' | 'name' | 'slug'>
        & { optionSet: (
          { __typename?: 'OptionSet' }
          & Pick<OptionSet, 'id' | 'name' | 'slug'>
        ) }
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'url'>
      )> }
    )>, video?: Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'title' | 'code' | 'source'>
    )> }
  )> }
);

export type UpdateJewelryMutationVariables = Exact<{
  where: ProductWhereUniqueInput;
  data: ProductUpdateInput;
}>;


export type UpdateJewelryMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'updatedAt'>
  )> }
);

export type JobQueryVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type JobQuery = (
  { __typename?: 'Query' }
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, '_id' | 'cost' | 'description' | 'internalNotes' | 'jobNumber' | 'price' | 'quantity' | 'status' | 'statusNotes' | 'title' | 'type' | 'unitPrice' | 'userId' | 'workOrderId' | 'promisedAt' | 'completedAt' | 'createdAt' | 'updatedAt'>
    & { services?: Maybe<Array<Maybe<(
      { __typename?: 'JobService' }
      & Pick<JobService, 'name' | 'note' | 'unitPrice' | 'quantity' | 'price'>
    )>>>, user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'phone'>
    ) }
  )> }
);

export type CreateJobMutationVariables = Exact<{
  input: JobInput;
}>;


export type CreateJobMutation = (
  { __typename?: 'Mutation' }
  & { createJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, '_id'>
  )> }
);

export type RemoveJobMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type RemoveJobMutation = (
  { __typename?: 'Mutation' }
  & { removeJob?: Maybe<(
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'n' | 'ok'>
  )> }
);

export type UpdateJobMutationVariables = Exact<{
  _id: Scalars['ObjID'];
  input: JobInput;
}>;


export type UpdateJobMutation = (
  { __typename?: 'Mutation' }
  & { updateJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, '_id' | 'updatedAt'>
  )> }
);

export type JobsQueryVariables = Exact<{
  workOrderId?: Maybe<Scalars['ObjID']>;
  userId?: Maybe<Scalars['ObjID']>;
}>;


export type JobsQuery = (
  { __typename?: 'Query' }
  & { jobs?: Maybe<Array<(
    { __typename?: 'Job' }
    & Pick<Job, '_id' | 'cost' | 'description' | 'userId' | 'internalNotes' | 'jobNumber' | 'price' | 'quantity' | 'status' | 'statusNotes' | 'title' | 'type' | 'unitPrice' | 'workOrderId' | 'promisedAt' | 'completedAt' | 'createdAt' | 'updatedAt'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    ) }
  )>> }
);

export type LoginWithPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginWithPasswordMutation = (
  { __typename?: 'Mutation' }
  & { loginWithPassword?: Maybe<(
    { __typename?: 'LoginToken' }
    & Pick<LoginToken, 'token'>
  )> }
);

export type RemoveOrderMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type RemoveOrderMutation = (
  { __typename?: 'Mutation' }
  & { removeOrder?: Maybe<(
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'n' | 'ok'>
  )> }
);

export type OrderQueryVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type OrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, '_id' | 'number' | 'isBankWire' | 'status' | 'total' | 'totalPayments' | 'tax' | 'hasCCFee' | 'balance' | 'subtotal' | 'shipping' | 'userId' | 'invoiceId' | 'createdAt' | 'updatedAt'>
    & { referral?: Maybe<(
      { __typename?: 'Referral' }
      & Pick<Referral, '_id' | 'name'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'firstName' | 'lastName' | 'email'>
      & { billingAddress?: Maybe<(
        { __typename?: 'UserAddress' }
        & Pick<UserAddress, 'phone'>
      )>, shippingAddress?: Maybe<(
        { __typename?: 'UserAddress' }
        & Pick<UserAddress, 'address1' | 'city' | 'state' | 'country' | 'phone'>
      )> }
    )>, items?: Maybe<Array<Maybe<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'title' | 'sku' | 'type' | 'notes' | 'quantity' | 'price' | 'productId' | 'vendorId'>
    )>>> }
  )> }
);

export type UpdateOrderMutationVariables = Exact<{
  _id: Scalars['ObjID'];
  input: OrderUpdateInput;
}>;


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, '_id' | 'updatedAt'>
  )> }
);

export type InvoiceSearchQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type InvoiceSearchQuery = (
  { __typename?: 'Query' }
  & { invoiceSearch?: Maybe<Array<(
    { __typename: 'Invoice' }
    & Pick<Invoice, '_id' | 'userId' | 'workOrderId' | 'orderId' | 'shipping' | 'tax' | 'createdAt'>
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
      & { shippingAddress?: Maybe<(
        { __typename: 'UserAddress' }
        & Pick<UserAddress, 'state'>
      )> }
    )>, items: Array<Maybe<(
      { __typename: 'WorkOrderItem' }
      & Pick<WorkOrderItem, 'productId' | 'title' | 'unitPrice' | 'quantity' | 'price' | 'createdAt'>
    )>>, order?: Maybe<(
      { __typename: 'Order' }
      & { shippingAddress?: Maybe<(
        { __typename: 'OrderAddress' }
        & Pick<OrderAddress, 'state'>
      )> }
    )> }
  )>> }
);

export type OrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { orders?: Maybe<Array<(
    { __typename?: 'Order' }
    & Pick<Order, '_id' | 'number' | 'isBankWire' | 'status' | 'total' | 'createdAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'email' | 'firstName' | 'lastName'>
    )> }
  )>> }
);

export type RemovePaymentMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type RemovePaymentMutation = (
  { __typename?: 'Mutation' }
  & { removePayment?: Maybe<(
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'n' | 'ok'>
  )> }
);

export type PaymentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentTypesQuery = (
  { __typename?: 'Query' }
  & { paymentTypes?: Maybe<Array<(
    { __typename?: 'PaymentType' }
    & Pick<PaymentType, '_id' | 'name'>
  )>> }
);

export type PaymentsQueryVariables = Exact<{
  orderId?: Maybe<Scalars['ObjID']>;
  workOrderId?: Maybe<Scalars['ObjID']>;
  userId?: Maybe<Scalars['ObjID']>;
}>;


export type PaymentsQuery = (
  { __typename?: 'Query' }
  & { payments?: Maybe<Array<(
    { __typename?: 'Payment' }
    & Pick<Payment, '_id' | 'amount' | 'paymentType' | 'tax' | 'userId' | 'createdAt' | 'updatedAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
    )> }
  )>> }
);

export type ProductAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductAttributesQuery = (
  { __typename?: 'Query' }
  & { clarities: Array<(
    { __typename?: 'Clarity' }
    & Pick<Clarity, 'id' | 'label' | 'value'>
  )>, colors: Array<(
    { __typename?: 'Color' }
    & Pick<Color, 'id' | 'label' | 'value'>
  )>, culets: Array<(
    { __typename?: 'Culet' }
    & Pick<Culet, 'id' | 'label' | 'value'>
  )>, cuts: Array<(
    { __typename?: 'Cut' }
    & Pick<Cut, 'id' | 'label' | 'value'>
  )>, fluorescences: Array<(
    { __typename?: 'Fluorescence' }
    & Pick<Fluorescence, 'id' | 'label' | 'value'>
  )>, girdles: Array<(
    { __typename?: 'Girdle' }
    & Pick<Girdle, 'id' | 'label' | 'value'>
  )>, lightPerformances: Array<(
    { __typename?: 'LightPerformance' }
    & Pick<LightPerformance, 'id' | 'label' | 'value'>
  )>, paves: Array<(
    { __typename?: 'Pave' }
    & Pick<Pave, 'id' | 'label' | 'value'>
  )>, polishes: Array<(
    { __typename?: 'Polish' }
    & Pick<Polish, 'id' | 'label' | 'value'>
  )>, symmetries: Array<(
    { __typename?: 'Symmetry' }
    & Pick<Symmetry, 'id' | 'label' | 'value'>
  )>, metals: Array<(
    { __typename?: 'Metal' }
    & Pick<Metal, 'id' | 'value' | 'label' | 'order'>
  )>, ringTypes: Array<(
    { __typename?: 'RingType' }
    & Pick<RingType, 'id' | 'value' | 'label' | 'order'>
  )>, bandTypes: Array<(
    { __typename?: 'BandType' }
    & Pick<BandType, 'id' | 'value' | 'label' | 'order'>
  )>, diamondShapes: Array<(
    { __typename?: 'DiamondShape' }
    & Pick<DiamondShape, 'id' | 'label' | 'order' | 'value'>
  )> }
);

export type ProductTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductTypesQuery = (
  { __typename?: 'Query' }
  & { productTypes: Array<(
    { __typename?: 'ProductType' }
    & Pick<ProductType, 'id' | 'label' | 'order'>
    & { _id: ProductType['id'] }
  )> }
);

export type UpdateProductMutationVariables = Exact<{
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
}>;


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'updatedAt'>
  )> }
);

export type CollectionsQueryVariables = Exact<{
  where?: Maybe<CollectionWhereInput>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<CollectionWhereUniqueInput>;
  orderBy?: Maybe<Array<CollectionOrderByInput> | CollectionOrderByInput>;
}>;


export type CollectionsQuery = (
  { __typename?: 'Query' }
  & { collections: Array<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id' | 'name' | 'slug' | 'description' | 'showInCatalog' | 'createdAt' | 'updatedAt'>
    & { products: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id'>
    )> }
  )> }
);

export type CollectionQueryVariables = Exact<{
  where: CollectionWhereUniqueInput;
}>;


export type CollectionQuery = (
  { __typename?: 'Query' }
  & { collection?: Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id' | 'name' | 'slug' | 'description' | 'showInCatalog' | 'createdAt' | 'updatedAt'>
    & { products: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'sku' | 'name' | 'representativeImage'>
    )> }
  )> }
);

export type CreateCollectionMutationVariables = Exact<{
  data: CollectionCreateInput;
}>;


export type CreateCollectionMutation = (
  { __typename?: 'Mutation' }
  & { createCollection: (
    { __typename?: 'Collection' }
    & Pick<Collection, 'id'>
  ) }
);

export type UpdateCollectionMutationVariables = Exact<{
  where: CollectionWhereUniqueInput;
  data: CollectionUpdateInput;
}>;


export type UpdateCollectionMutation = (
  { __typename?: 'Mutation' }
  & { updateCollection?: Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id'>
  )> }
);

export type DeleteCollectionMutationVariables = Exact<{
  where: CollectionWhereUniqueInput;
}>;


export type DeleteCollectionMutation = (
  { __typename?: 'Mutation' }
  & { deleteCollection?: Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id'>
  )> }
);

export type CreateCategoryMutationVariables = Exact<{
  data: CategoryCreateInput;
}>;


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory: (
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  ) }
);

export type UpdateCategoryMutationVariables = Exact<{
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateInput;
}>;


export type UpdateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  )> }
);

export type DeleteCategoryMutationVariables = Exact<{
  where: CategoryWhereUniqueInput;
}>;


export type DeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteCategory?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  )> }
);

export type ExportProductsToFileMutationVariables = Exact<{ [key: string]: never; }>;


export type ExportProductsToFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportProductsToFile'>
);

export type UpdateProductsMutationVariables = Exact<{
  where?: Maybe<ProductWhereInput>;
  data: ProductUpdateManyMutationInput;
}>;


export type UpdateProductsMutation = (
  { __typename?: 'Mutation' }
  & { updateProducts: (
    { __typename?: 'AffectedRowsOutput' }
    & Pick<AffectedRowsOutput, 'count'>
  ) }
);

export type DeleteProductsMutationVariables = Exact<{
  where: ProductWhereInput;
}>;


export type DeleteProductsMutation = (
  { __typename?: 'Mutation' }
  & { deleteProducts: (
    { __typename?: 'AffectedRowsOutput' }
    & Pick<AffectedRowsOutput, 'count'>
  ) }
);

export type QuoteQueryVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type QuoteQuery = (
  { __typename?: 'Query' }
  & { quote?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, '_id' | 'userId' | 'number' | 'description' | 'hasTax' | 'tax' | 'shipping' | 'subtotal' | 'total' | 'locked' | 'validUntil' | 'createdAt' | 'updatedAt'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'phone'>
    ), items?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteItem' }
      & Pick<QuoteItem, 'title' | 'description' | 'unitPrice' | 'price' | 'quantity' | 'productId' | 'type'>
    )>>>, workOrder?: Maybe<(
      { __typename?: 'WorkOrder' }
      & Pick<WorkOrder, '_id' | 'createdAt'>
    )>, emails?: Maybe<Array<Maybe<(
      { __typename?: 'Email' }
      & Pick<Email, '_id' | 'sentAt' | 'createdAt' | 'updatedAt'>
    )>>>, createdByUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    )> }
  )> }
);

export type CreateQuoteMutationVariables = Exact<{
  input: QuoteInput;
}>;


export type CreateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { createQuote?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, '_id'>
  )> }
);

export type RemoveQuoteMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type RemoveQuoteMutation = (
  { __typename?: 'Mutation' }
  & { removeQuote?: Maybe<(
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'n' | 'ok'>
  )> }
);

export type UpdateQuoteMutationVariables = Exact<{
  _id: Scalars['ObjID'];
  input: QuoteInput;
}>;


export type UpdateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { updateQuote?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, '_id' | 'updatedAt'>
  )> }
);

export type QuotesQueryVariables = Exact<{ [key: string]: never; }>;


export type QuotesQuery = (
  { __typename?: 'Query' }
  & { quotes?: Maybe<Array<(
    { __typename?: 'Quote' }
    & Pick<Quote, '_id' | 'number' | 'emailedAt' | 'total' | 'createdAt'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    ), customDesignRequest?: Maybe<(
      { __typename?: 'CustomDesignRequest' }
      & Pick<CustomDesignRequest, '_id'>
    )>, workOrder?: Maybe<(
      { __typename?: 'WorkOrder' }
      & Pick<WorkOrder, '_id'>
    )> }
  )>> }
);

export type SendQuoteMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type SendQuoteMutation = (
  { __typename?: 'Mutation' }
  & { sendQuote?: Maybe<(
    { __typename?: 'Quote' }
    & Pick<Quote, '_id' | 'emailedAt' | 'updatedAt'>
  )> }
);

export type StaticPageQueryVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type StaticPageQuery = (
  { __typename?: 'Query' }
  & { staticPage?: Maybe<(
    { __typename?: 'StaticPage' }
    & Pick<StaticPage, '_id' | 'title' | 'description' | 'content' | 'route' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdateStaticPageMutationVariables = Exact<{
  _id: Scalars['ObjID'];
  input: StaticPageUpdateInput;
}>;


export type UpdateStaticPageMutation = (
  { __typename?: 'Mutation' }
  & { updateStaticPage?: Maybe<(
    { __typename?: 'StaticPage' }
    & Pick<StaticPage, '_id' | 'updatedAt'>
  )> }
);

export type StaticPagesQueryVariables = Exact<{ [key: string]: never; }>;


export type StaticPagesQuery = (
  { __typename?: 'Query' }
  & { staticPages?: Maybe<Array<(
    { __typename?: 'StaticPage' }
    & Pick<StaticPage, '_id' | 'title' | 'content' | 'route' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type UploadImageMutationVariables = Exact<{
  data: ImageUploadAttributesInput;
  file: Scalars['Upload'];
}>;


export type UploadImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadImage?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'url'>
  )> }
);

export type UploadImagesMutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
  data: ImageUploadAttributesInput;
}>;


export type UploadImagesMutation = (
  { __typename?: 'Mutation' }
  & { uploadImages?: Maybe<Array<Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'filename' | 'url'>
  )>>> }
);

export type RemoveUserMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & { removeUser?: Maybe<(
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'n' | 'ok'>
  )> }
);

export type UserQueryVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'email' | 'firstName' | 'lastName' | 'roles' | 'billingIsSame' | 'createdAt' | 'updatedAt'>
    & { billingAddress?: Maybe<(
      { __typename?: 'UserAddress' }
      & Pick<UserAddress, 'firstName' | 'lastName' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'country' | 'phone'>
    )>, shippingAddress?: Maybe<(
      { __typename?: 'UserAddress' }
      & Pick<UserAddress, 'firstName' | 'lastName' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'country' | 'phone'>
    )>, customDesignRequests?: Maybe<Array<Maybe<(
      { __typename?: 'CustomDesignRequest' }
      & Pick<CustomDesignRequest, '_id' | 'designs' | 'productType' | 'createdAt'>
      & { quote?: Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, '_id'>
      )> }
    )>>>, quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, '_id' | 'number' | 'total' | 'locked' | 'emailedAt' | 'customDesignRequestId' | 'createdAt'>
      & { workOrder?: Maybe<(
        { __typename?: 'WorkOrder' }
        & Pick<WorkOrder, '_id'>
      )> }
    )>>>, workOrders?: Maybe<Array<Maybe<(
      { __typename?: 'WorkOrder' }
      & Pick<WorkOrder, '_id' | 'number' | 'status' | 'total' | 'locked' | 'quoteId'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName' | 'email'>
      )> }
    )>>>, orders?: Maybe<Array<Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, '_id' | 'status' | 'total' | 'createdAt'>
    )>>> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  _id: Scalars['ObjID'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'updatedAt'>
  )> }
);

export type UsersQueryVariables = Exact<{
  filter?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<Scalars['JSON']>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'createdAt'>
  )>> }
);

export type VendorQueryVariables = Exact<{
  where: VendorWhereUniqueInput;
}>;


export type VendorQuery = (
  { __typename?: 'Query' }
  & { vendor?: Maybe<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id' | 'name' | 'slug' | 'source' | 'enabled' | 'diamondsCount' | 'leadTimeFrom' | 'leadTimeTo' | 'createdAt' | 'updatedAt'>
    & { markups: Array<(
      { __typename?: 'VendorMarkup' }
      & Pick<VendorMarkup, 'id' | 'from' | 'to' | 'markupPercent'>
    )> }
  )> }
);

export type UpdateVendorMutationVariables = Exact<{
  where: VendorWhereUniqueInput;
  data: VendorUpdateInput;
}>;


export type UpdateVendorMutation = (
  { __typename?: 'Mutation' }
  & { updateVendor?: Maybe<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id' | 'updatedAt'>
  )> }
);

export type VendorsQueryVariables = Exact<{
  where?: Maybe<VendorWhereInput>;
}>;


export type VendorsQuery = (
  { __typename?: 'Query' }
  & { totalDiamonds: Query['diamondsCount'] }
  & { vendors: Array<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id' | 'name' | 'slug' | 'source' | 'enabled' | 'diamondsCount' | 'createdAt' | 'updatedAt'>
  )> }
);

export type VirtualProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type VirtualProductQuery = (
  { __typename?: 'Query' }
  & { virtualProduct?: Maybe<(
    { __typename?: 'VirtualProduct' }
    & Pick<VirtualProduct, 'id' | 'carat' | 'clarity' | 'color' | 'cost' | 'crownAngle' | 'culet' | 'cut' | 'depthMM' | 'depthPercent' | 'fluorescence' | 'girdle' | 'images' | 'images360' | 'lab' | 'lengthMM' | 'lightPerformance' | 'measurements' | 'name' | 'pavilionAngle' | 'polish' | 'price' | 'shape' | 'sku' | 'slug' | 'symmetry' | 'tablePercent' | 'widthMM'>
    & { vendor?: Maybe<(
      { __typename?: 'Vendor' }
      & Pick<Vendor, 'id' | 'name'>
    )> }
  )> }
);

export type VirtualProductsQueryVariables = Exact<{
  where?: Maybe<VirtualProductsWhereInput>;
  limit?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
}>;


export type VirtualProductsQuery = (
  { __typename?: 'Query' }
  & { virtualProducts?: Maybe<(
    { __typename?: 'VirtualProductResults' }
    & Pick<VirtualProductResults, 'hasNext' | 'next' | 'previous' | 'count'>
    & { results: Array<Maybe<(
      { __typename?: 'VirtualProduct' }
      & Pick<VirtualProduct, '_id' | 'id' | 'carat' | 'clarity' | 'color' | 'cost' | 'crownAngle' | 'culet' | 'cut' | 'depthMM' | 'depthPercent' | 'fluorescence' | 'girdle' | 'images' | 'images360' | 'lab' | 'lengthMM' | 'lightPerformance' | 'measurements' | 'name' | 'pavilionAngle' | 'polish' | 'price' | 'shape' | 'sku' | 'slug' | 'symmetry' | 'tablePercent' | 'widthMM'>
    )>> }
  )> }
);

export type WorkOrderQueryVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type WorkOrderQuery = (
  { __typename?: 'Query' }
  & { workOrder?: Maybe<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, '_id' | 'userId' | 'number' | 'invoiceId' | 'hasTax' | 'tax' | 'shipping' | 'subtotal' | 'total' | 'totalPayments' | 'balance' | 'locked' | 'isComplete' | 'completedAt' | 'createdAt' | 'updatedAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'phone'>
      & { shippingAddress?: Maybe<(
        { __typename?: 'UserAddress' }
        & Pick<UserAddress, 'name' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'country' | 'phone' | 'isCopy'>
      )>, billingAddress?: Maybe<(
        { __typename?: 'UserAddress' }
        & Pick<UserAddress, 'name' | 'address1' | 'address2' | 'city' | 'state' | 'zip' | 'country' | 'phone' | 'isCopy'>
      )> }
    )>, items?: Maybe<Array<Maybe<(
      { __typename?: 'WorkOrderItem' }
      & Pick<WorkOrderItem, '_id' | 'title' | 'description' | 'inStock' | 'unitPrice' | 'price' | 'quantity' | 'productId' | 'type'>
    )>>>, jobs?: Maybe<Array<Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'workOrderItemId' | 'completedAt'>
    )>>>, payments?: Maybe<Array<Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, '_id' | 'amount' | 'paymentType' | 'status' | 'tax' | 'taxPercent' | 'createdAt'>
      & { createdByUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, '_id' | 'email'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, '_id' | 'email'>
      )> }
    )>>>, createdByUser: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ) }
  )> }
);

export type CreateWorkOrderMutationVariables = Exact<{
  input: WorkOrderInput;
}>;


export type CreateWorkOrderMutation = (
  { __typename?: 'Mutation' }
  & { createWorkOrder?: Maybe<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, '_id'>
  )> }
);

export type RemoveWorkOrderMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type RemoveWorkOrderMutation = (
  { __typename?: 'Mutation' }
  & { removeWorkOrder?: Maybe<(
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'n' | 'ok'>
  )> }
);

export type CreateWorkOrderItemMutationVariables = Exact<{
  input: WorkOrderItemInput;
}>;


export type CreateWorkOrderItemMutation = (
  { __typename?: 'Mutation' }
  & { createWorkOrderItem?: Maybe<(
    { __typename?: 'WorkOrderItem' }
    & Pick<WorkOrderItem, '_id'>
  )> }
);

export type RemoveWorkOrderItemMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type RemoveWorkOrderItemMutation = (
  { __typename?: 'Mutation' }
  & { removeWorkOrderItem?: Maybe<(
    { __typename?: 'RemoveResult' }
    & Pick<RemoveResult, 'n' | 'ok'>
  )> }
);

export type UpdateWorkOrderItemMutationVariables = Exact<{
  _id: Scalars['ObjID'];
  input: WorkOrderItemInput;
}>;


export type UpdateWorkOrderItemMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkOrderItem?: Maybe<(
    { __typename?: 'WorkOrderItem' }
    & Pick<WorkOrderItem, '_id' | 'updatedAt'>
  )> }
);

export type LockWorkOrderMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type LockWorkOrderMutation = (
  { __typename?: 'Mutation' }
  & { lockWorkOrder?: Maybe<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, '_id' | 'locked' | 'updatedAt'>
  )> }
);

export type UnlockWorkOrderMutationVariables = Exact<{
  _id: Scalars['ObjID'];
}>;


export type UnlockWorkOrderMutation = (
  { __typename?: 'Mutation' }
  & { unlockWorkOrder?: Maybe<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, '_id' | 'locked' | 'updatedAt'>
  )> }
);

export type UpdateWorkOrderMutationVariables = Exact<{
  _id: Scalars['ObjID'];
  input: WorkOrderInput;
}>;


export type UpdateWorkOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkOrder?: Maybe<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, '_id' | 'updatedAt'>
  )> }
);

export type WorkOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkOrdersQuery = (
  { __typename?: 'Query' }
  & { workOrders?: Maybe<Array<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, '_id' | 'number' | 'total' | 'isComplete' | 'completedAt' | 'createdAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'firstName' | 'lastName' | 'email'>
    )>, quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, '_id'>
    )>, payments?: Maybe<Array<Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, '_id'>
    )>>> }
  )>> }
);


export const AvailableProductsDocument = gql`
    query availableProducts {
  diamonds(where: {vendorId: {equals: null}}) {
    _id: id
    id
    sku
    name
    description
    price
    quantity
    type
    meta {
      description
    }
  }
  rings: products(where: {type: {equals: "Ring"}, parentId: {not: null}}) {
    _id: id
    id
    sku
    name
    description
    shortDescription
    price
    quantity
    type
    meta {
      description
    }
    parent {
      shortDescription
    }
  }
  jewelries: products(where: {type: {in: ["Earrings", "Bracelet", "Necklace"]}, parentId: {not: null}}) {
    _id: id
    id
    sku
    name
    description
    shortDescription
    price
    quantity
    type
    meta {
      description
    }
    parent {
      shortDescription
    }
  }
}
    `;
export type AvailableProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableProductsQuery, AvailableProductsQueryVariables>, 'query'>;

    export const AvailableProductsComponent = (props: AvailableProductsComponentProps) => (
      <ApolloReactComponents.Query<AvailableProductsQuery, AvailableProductsQueryVariables> query={AvailableProductsDocument} {...props} />
    );
    
export type AvailableProductsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AvailableProductsQuery, AvailableProductsQueryVariables>
    } & TChildProps;
export function withAvailableProducts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AvailableProductsQuery,
  AvailableProductsQueryVariables,
  AvailableProductsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AvailableProductsQuery, AvailableProductsQueryVariables, AvailableProductsProps<TChildProps, TDataName>>(AvailableProductsDocument, {
      alias: 'availableProducts',
      ...operationOptions
    });
};

/**
 * __useAvailableProductsQuery__
 *
 * To run a query within a React component, call `useAvailableProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableProductsQuery, AvailableProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableProductsQuery, AvailableProductsQueryVariables>(AvailableProductsDocument, baseOptions);
      }
export function useAvailableProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableProductsQuery, AvailableProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableProductsQuery, AvailableProductsQueryVariables>(AvailableProductsDocument, baseOptions);
        }
export type AvailableProductsQueryHookResult = ReturnType<typeof useAvailableProductsQuery>;
export type AvailableProductsLazyQueryHookResult = ReturnType<typeof useAvailableProductsLazyQuery>;
export type AvailableProductsQueryResult = ApolloReactCommon.QueryResult<AvailableProductsQuery, AvailableProductsQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    _id
    email
    firstName
    lastName
    roles
    phone
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;
export type CreateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserMutation, CreateUserMutationVariables>, 'mutation'>;

    export const CreateUserComponent = (props: CreateUserComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserMutation, CreateUserMutationVariables> mutation={CreateUserDocument} {...props} />
    );
    
export type CreateUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>
    } & TChildProps;
export function withCreateUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateUserMutation,
  CreateUserMutationVariables,
  CreateUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateUserMutation, CreateUserMutationVariables, CreateUserProps<TChildProps, TDataName>>(CreateUserDocument, {
      alias: 'createUser',
      ...operationOptions
    });
};

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    _id
    email
    firstName
    lastName
    roles
    createdAt
  }
}
    `;
export type CurrentUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentUserQuery, CurrentUserQueryVariables>, 'query'>;

    export const CurrentUserComponent = (props: CurrentUserComponentProps) => (
      <ApolloReactComponents.Query<CurrentUserQuery, CurrentUserQueryVariables> query={CurrentUserDocument} {...props} />
    );
    
export type CurrentUserProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CurrentUserQuery, CurrentUserQueryVariables>
    } & TChildProps;
export function withCurrentUser<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  CurrentUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CurrentUserQuery, CurrentUserQueryVariables, CurrentUserProps<TChildProps, TDataName>>(CurrentUserDocument, {
      alias: 'currentUser',
      ...operationOptions
    });
};

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const CustomDesignRequestDocument = gql`
    query customDesignRequest($_id: ObjID!) {
  customDesignRequest(_id: $_id) {
    _id
    userId
    user {
      _id
      firstName
      lastName
      email
      phone
    }
    quote {
      _id
    }
    designs
    productType
    metal
    budgetFrom
    budgetTo
    timeframe
    comments
    caratFrom
    caratTo
    clarityFrom
    clarityTo
    colorFrom
    colorTo
    cut
    fingerSize
    ringType
    prongs
    shank
    shankThickness
    shape
    skuOrTitle
    pave
    isCenterstoneRequired
    isEngravingRequired
    isPaveRequired
    isFiligreeRequired
    isCenterstoneRequired
    status
    createdAt
    updatedAt
    remoteAddress
  }
}
    `;
export type CustomDesignRequestComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomDesignRequestQuery, CustomDesignRequestQueryVariables>, 'query'> & ({ variables: CustomDesignRequestQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CustomDesignRequestComponent = (props: CustomDesignRequestComponentProps) => (
      <ApolloReactComponents.Query<CustomDesignRequestQuery, CustomDesignRequestQueryVariables> query={CustomDesignRequestDocument} {...props} />
    );
    
export type CustomDesignRequestProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CustomDesignRequestQuery, CustomDesignRequestQueryVariables>
    } & TChildProps;
export function withCustomDesignRequest<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CustomDesignRequestQuery,
  CustomDesignRequestQueryVariables,
  CustomDesignRequestProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CustomDesignRequestQuery, CustomDesignRequestQueryVariables, CustomDesignRequestProps<TChildProps, TDataName>>(CustomDesignRequestDocument, {
      alias: 'customDesignRequest',
      ...operationOptions
    });
};

/**
 * __useCustomDesignRequestQuery__
 *
 * To run a query within a React component, call `useCustomDesignRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomDesignRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomDesignRequestQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useCustomDesignRequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomDesignRequestQuery, CustomDesignRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomDesignRequestQuery, CustomDesignRequestQueryVariables>(CustomDesignRequestDocument, baseOptions);
      }
export function useCustomDesignRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomDesignRequestQuery, CustomDesignRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomDesignRequestQuery, CustomDesignRequestQueryVariables>(CustomDesignRequestDocument, baseOptions);
        }
export type CustomDesignRequestQueryHookResult = ReturnType<typeof useCustomDesignRequestQuery>;
export type CustomDesignRequestLazyQueryHookResult = ReturnType<typeof useCustomDesignRequestLazyQuery>;
export type CustomDesignRequestQueryResult = ApolloReactCommon.QueryResult<CustomDesignRequestQuery, CustomDesignRequestQueryVariables>;
export const RemoveCustomDesignRequestDocument = gql`
    mutation removeCustomDesignRequest($_id: ObjID!) {
  removeCustomDesignRequest(_id: $_id) {
    n
    ok
  }
}
    `;
export type RemoveCustomDesignRequestMutationFn = ApolloReactCommon.MutationFunction<RemoveCustomDesignRequestMutation, RemoveCustomDesignRequestMutationVariables>;
export type RemoveCustomDesignRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveCustomDesignRequestMutation, RemoveCustomDesignRequestMutationVariables>, 'mutation'>;

    export const RemoveCustomDesignRequestComponent = (props: RemoveCustomDesignRequestComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveCustomDesignRequestMutation, RemoveCustomDesignRequestMutationVariables> mutation={RemoveCustomDesignRequestDocument} {...props} />
    );
    
export type RemoveCustomDesignRequestProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveCustomDesignRequestMutation, RemoveCustomDesignRequestMutationVariables>
    } & TChildProps;
export function withRemoveCustomDesignRequest<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveCustomDesignRequestMutation,
  RemoveCustomDesignRequestMutationVariables,
  RemoveCustomDesignRequestProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveCustomDesignRequestMutation, RemoveCustomDesignRequestMutationVariables, RemoveCustomDesignRequestProps<TChildProps, TDataName>>(RemoveCustomDesignRequestDocument, {
      alias: 'removeCustomDesignRequest',
      ...operationOptions
    });
};

/**
 * __useRemoveCustomDesignRequestMutation__
 *
 * To run a mutation, you first call `useRemoveCustomDesignRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomDesignRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomDesignRequestMutation, { data, loading, error }] = useRemoveCustomDesignRequestMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveCustomDesignRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCustomDesignRequestMutation, RemoveCustomDesignRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCustomDesignRequestMutation, RemoveCustomDesignRequestMutationVariables>(RemoveCustomDesignRequestDocument, baseOptions);
      }
export type RemoveCustomDesignRequestMutationHookResult = ReturnType<typeof useRemoveCustomDesignRequestMutation>;
export type RemoveCustomDesignRequestMutationResult = ApolloReactCommon.MutationResult<RemoveCustomDesignRequestMutation>;
export type RemoveCustomDesignRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCustomDesignRequestMutation, RemoveCustomDesignRequestMutationVariables>;
export const UpdateCustomDesignRequestDocument = gql`
    mutation updateCustomDesignRequest($_id: ObjID!, $input: CustomDesignRequestUpdateInput!) {
  updateCustomDesignRequest(_id: $_id, input: $input) {
    _id
    updatedAt
  }
}
    `;
export type UpdateCustomDesignRequestMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomDesignRequestMutation, UpdateCustomDesignRequestMutationVariables>;
export type UpdateCustomDesignRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomDesignRequestMutation, UpdateCustomDesignRequestMutationVariables>, 'mutation'>;

    export const UpdateCustomDesignRequestComponent = (props: UpdateCustomDesignRequestComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomDesignRequestMutation, UpdateCustomDesignRequestMutationVariables> mutation={UpdateCustomDesignRequestDocument} {...props} />
    );
    
export type UpdateCustomDesignRequestProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateCustomDesignRequestMutation, UpdateCustomDesignRequestMutationVariables>
    } & TChildProps;
export function withUpdateCustomDesignRequest<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCustomDesignRequestMutation,
  UpdateCustomDesignRequestMutationVariables,
  UpdateCustomDesignRequestProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCustomDesignRequestMutation, UpdateCustomDesignRequestMutationVariables, UpdateCustomDesignRequestProps<TChildProps, TDataName>>(UpdateCustomDesignRequestDocument, {
      alias: 'updateCustomDesignRequest',
      ...operationOptions
    });
};

/**
 * __useUpdateCustomDesignRequestMutation__
 *
 * To run a mutation, you first call `useUpdateCustomDesignRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomDesignRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomDesignRequestMutation, { data, loading, error }] = useUpdateCustomDesignRequestMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomDesignRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomDesignRequestMutation, UpdateCustomDesignRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomDesignRequestMutation, UpdateCustomDesignRequestMutationVariables>(UpdateCustomDesignRequestDocument, baseOptions);
      }
export type UpdateCustomDesignRequestMutationHookResult = ReturnType<typeof useUpdateCustomDesignRequestMutation>;
export type UpdateCustomDesignRequestMutationResult = ApolloReactCommon.MutationResult<UpdateCustomDesignRequestMutation>;
export type UpdateCustomDesignRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomDesignRequestMutation, UpdateCustomDesignRequestMutationVariables>;
export const CustomDesignRequestsDocument = gql`
    query customDesignRequests {
  customDesignRequests {
    _id
    email
    firstName
    lastName
    status
    createdAt
  }
}
    `;
export type CustomDesignRequestsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomDesignRequestsQuery, CustomDesignRequestsQueryVariables>, 'query'>;

    export const CustomDesignRequestsComponent = (props: CustomDesignRequestsComponentProps) => (
      <ApolloReactComponents.Query<CustomDesignRequestsQuery, CustomDesignRequestsQueryVariables> query={CustomDesignRequestsDocument} {...props} />
    );
    
export type CustomDesignRequestsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CustomDesignRequestsQuery, CustomDesignRequestsQueryVariables>
    } & TChildProps;
export function withCustomDesignRequests<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CustomDesignRequestsQuery,
  CustomDesignRequestsQueryVariables,
  CustomDesignRequestsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CustomDesignRequestsQuery, CustomDesignRequestsQueryVariables, CustomDesignRequestsProps<TChildProps, TDataName>>(CustomDesignRequestsDocument, {
      alias: 'customDesignRequests',
      ...operationOptions
    });
};

/**
 * __useCustomDesignRequestsQuery__
 *
 * To run a query within a React component, call `useCustomDesignRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomDesignRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomDesignRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomDesignRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomDesignRequestsQuery, CustomDesignRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomDesignRequestsQuery, CustomDesignRequestsQueryVariables>(CustomDesignRequestsDocument, baseOptions);
      }
export function useCustomDesignRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomDesignRequestsQuery, CustomDesignRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomDesignRequestsQuery, CustomDesignRequestsQueryVariables>(CustomDesignRequestsDocument, baseOptions);
        }
export type CustomDesignRequestsQueryHookResult = ReturnType<typeof useCustomDesignRequestsQuery>;
export type CustomDesignRequestsLazyQueryHookResult = ReturnType<typeof useCustomDesignRequestsLazyQuery>;
export type CustomDesignRequestsQueryResult = ApolloReactCommon.QueryResult<CustomDesignRequestsQuery, CustomDesignRequestsQueryVariables>;
export const DiamondAttributesDocument = gql`
    query diamondAttributes {
  clarities {
    _id: id
    id
    label
    order
  }
  colors {
    _id: id
    id
    label
    order
  }
  culets {
    _id: id
    id
    label
    order
  }
  cuts {
    _id: id
    id
    label
    order
  }
  fluorescences {
    _id: id
    id
    label
    order
  }
  girdles {
    _id: id
    id
    label
    order
  }
  lightPerformances {
    _id: id
    id
    label
    order
  }
  polishes {
    _id: id
    id
    label
    order
  }
  diamondShapes {
    _id: id
    id
    label
    order
  }
  symmetries {
    _id: id
    id
    label
    order
  }
}
    `;
export type DiamondAttributesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DiamondAttributesQuery, DiamondAttributesQueryVariables>, 'query'>;

    export const DiamondAttributesComponent = (props: DiamondAttributesComponentProps) => (
      <ApolloReactComponents.Query<DiamondAttributesQuery, DiamondAttributesQueryVariables> query={DiamondAttributesDocument} {...props} />
    );
    
export type DiamondAttributesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DiamondAttributesQuery, DiamondAttributesQueryVariables>
    } & TChildProps;
export function withDiamondAttributes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DiamondAttributesQuery,
  DiamondAttributesQueryVariables,
  DiamondAttributesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DiamondAttributesQuery, DiamondAttributesQueryVariables, DiamondAttributesProps<TChildProps, TDataName>>(DiamondAttributesDocument, {
      alias: 'diamondAttributes',
      ...operationOptions
    });
};

/**
 * __useDiamondAttributesQuery__
 *
 * To run a query within a React component, call `useDiamondAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiamondAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiamondAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDiamondAttributesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiamondAttributesQuery, DiamondAttributesQueryVariables>) {
        return ApolloReactHooks.useQuery<DiamondAttributesQuery, DiamondAttributesQueryVariables>(DiamondAttributesDocument, baseOptions);
      }
export function useDiamondAttributesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiamondAttributesQuery, DiamondAttributesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiamondAttributesQuery, DiamondAttributesQueryVariables>(DiamondAttributesDocument, baseOptions);
        }
export type DiamondAttributesQueryHookResult = ReturnType<typeof useDiamondAttributesQuery>;
export type DiamondAttributesLazyQueryHookResult = ReturnType<typeof useDiamondAttributesLazyQuery>;
export type DiamondAttributesQueryResult = ApolloReactCommon.QueryResult<DiamondAttributesQuery, DiamondAttributesQueryVariables>;
export const CreateDiamondDocument = gql`
    mutation createDiamond($data: DiamondCreateInput!) {
  createDiamond(data: $data) {
    _id: id
    id
    createdAt
  }
}
    `;
export type CreateDiamondMutationFn = ApolloReactCommon.MutationFunction<CreateDiamondMutation, CreateDiamondMutationVariables>;
export type CreateDiamondComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateDiamondMutation, CreateDiamondMutationVariables>, 'mutation'>;

    export const CreateDiamondComponent = (props: CreateDiamondComponentProps) => (
      <ApolloReactComponents.Mutation<CreateDiamondMutation, CreateDiamondMutationVariables> mutation={CreateDiamondDocument} {...props} />
    );
    
export type CreateDiamondProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateDiamondMutation, CreateDiamondMutationVariables>
    } & TChildProps;
export function withCreateDiamond<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateDiamondMutation,
  CreateDiamondMutationVariables,
  CreateDiamondProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateDiamondMutation, CreateDiamondMutationVariables, CreateDiamondProps<TChildProps, TDataName>>(CreateDiamondDocument, {
      alias: 'createDiamond',
      ...operationOptions
    });
};

/**
 * __useCreateDiamondMutation__
 *
 * To run a mutation, you first call `useCreateDiamondMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiamondMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiamondMutation, { data, loading, error }] = useCreateDiamondMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDiamondMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDiamondMutation, CreateDiamondMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDiamondMutation, CreateDiamondMutationVariables>(CreateDiamondDocument, baseOptions);
      }
export type CreateDiamondMutationHookResult = ReturnType<typeof useCreateDiamondMutation>;
export type CreateDiamondMutationResult = ApolloReactCommon.MutationResult<CreateDiamondMutation>;
export type CreateDiamondMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDiamondMutation, CreateDiamondMutationVariables>;
export const RemoveDiamondDocument = gql`
    mutation removeDiamond($_id: String!) {
  deleteDiamond(where: {id: $_id}) {
    id
  }
}
    `;
export type RemoveDiamondMutationFn = ApolloReactCommon.MutationFunction<RemoveDiamondMutation, RemoveDiamondMutationVariables>;
export type RemoveDiamondComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveDiamondMutation, RemoveDiamondMutationVariables>, 'mutation'>;

    export const RemoveDiamondComponent = (props: RemoveDiamondComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveDiamondMutation, RemoveDiamondMutationVariables> mutation={RemoveDiamondDocument} {...props} />
    );
    
export type RemoveDiamondProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveDiamondMutation, RemoveDiamondMutationVariables>
    } & TChildProps;
export function withRemoveDiamond<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveDiamondMutation,
  RemoveDiamondMutationVariables,
  RemoveDiamondProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveDiamondMutation, RemoveDiamondMutationVariables, RemoveDiamondProps<TChildProps, TDataName>>(RemoveDiamondDocument, {
      alias: 'removeDiamond',
      ...operationOptions
    });
};

/**
 * __useRemoveDiamondMutation__
 *
 * To run a mutation, you first call `useRemoveDiamondMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDiamondMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDiamondMutation, { data, loading, error }] = useRemoveDiamondMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveDiamondMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveDiamondMutation, RemoveDiamondMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveDiamondMutation, RemoveDiamondMutationVariables>(RemoveDiamondDocument, baseOptions);
      }
export type RemoveDiamondMutationHookResult = ReturnType<typeof useRemoveDiamondMutation>;
export type RemoveDiamondMutationResult = ApolloReactCommon.MutationResult<RemoveDiamondMutation>;
export type RemoveDiamondMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveDiamondMutation, RemoveDiamondMutationVariables>;
export const DescriptiveFieldsDocument = gql`
    query descriptiveFields($_id: String!) {
  diamond(slugOrId: $_id) {
    description
    metaDescription: meta {
      description
    }
    metaTitle: meta {
      title
    }
  }
}
    `;
export type DescriptiveFieldsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DescriptiveFieldsQuery, DescriptiveFieldsQueryVariables>, 'query'> & ({ variables: DescriptiveFieldsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DescriptiveFieldsComponent = (props: DescriptiveFieldsComponentProps) => (
      <ApolloReactComponents.Query<DescriptiveFieldsQuery, DescriptiveFieldsQueryVariables> query={DescriptiveFieldsDocument} {...props} />
    );
    
export type DescriptiveFieldsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DescriptiveFieldsQuery, DescriptiveFieldsQueryVariables>
    } & TChildProps;
export function withDescriptiveFields<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DescriptiveFieldsQuery,
  DescriptiveFieldsQueryVariables,
  DescriptiveFieldsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DescriptiveFieldsQuery, DescriptiveFieldsQueryVariables, DescriptiveFieldsProps<TChildProps, TDataName>>(DescriptiveFieldsDocument, {
      alias: 'descriptiveFields',
      ...operationOptions
    });
};

/**
 * __useDescriptiveFieldsQuery__
 *
 * To run a query within a React component, call `useDescriptiveFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDescriptiveFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDescriptiveFieldsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useDescriptiveFieldsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DescriptiveFieldsQuery, DescriptiveFieldsQueryVariables>) {
        return ApolloReactHooks.useQuery<DescriptiveFieldsQuery, DescriptiveFieldsQueryVariables>(DescriptiveFieldsDocument, baseOptions);
      }
export function useDescriptiveFieldsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DescriptiveFieldsQuery, DescriptiveFieldsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DescriptiveFieldsQuery, DescriptiveFieldsQueryVariables>(DescriptiveFieldsDocument, baseOptions);
        }
export type DescriptiveFieldsQueryHookResult = ReturnType<typeof useDescriptiveFieldsQuery>;
export type DescriptiveFieldsLazyQueryHookResult = ReturnType<typeof useDescriptiveFieldsLazyQuery>;
export type DescriptiveFieldsQueryResult = ApolloReactCommon.QueryResult<DescriptiveFieldsQuery, DescriptiveFieldsQueryVariables>;
export const DiamondDocument = gql`
    query diamond($slugOrId: String!) {
  clarities {
    _id: id
    id
    label
    order
  }
  colors {
    _id: id
    id
    label
    order
  }
  culets {
    _id: id
    id
    label
    order
  }
  cuts {
    _id: id
    id
    label
    order
  }
  fluorescences {
    _id: id
    id
    label
    order
  }
  girdles {
    _id: id
    id
    label
    order
  }
  lightPerformances {
    _id: id
    id
    label
    order
  }
  polishes {
    _id: id
    id
    label
    order
  }
  diamondShapes {
    _id: id
    id
    label
    order
  }
  symmetries {
    _id: id
    id
    label
    order
  }
  diamond(slugOrId: $slugOrId) {
    _id: id
    id
    autopopulateDescription
    autopopulateMetaDescription
    autopopulateMetaTitle
    carat
    clarityId
    clarity {
      id
      label
      value
    }
    colorId
    color {
      id
      label
      value
    }
    cost
    createdAt
    crownAngle
    culetId
    culet {
      id
      label
      value
    }
    cutId
    cut {
      id
      label
      value
    }
    description
    depthMM
    depthPercent
    enabled
    fluorescenceId
    fluorescence {
      id
      label
      value
    }
    girdles {
      id
      label
      value
    }
    images {
      id
      category
      filename
      path
      storageUrl
      url
    }
    images360IFrameUrl
    lab {
      name
      certificate
      reportNumber
    }
    lengthMM
    lightPerformanceId
    lightPerformance {
      id
      label
      value
    }
    lowerGirdleFacetPercent
    meta {
      title
      description
      keywords
    }
    name
    isLabGrown
    notes
    onSale
    pavilionAngle
    polishId
    polish {
      id
      label
      value
    }
    price
    quantity
    salePrice
    shapeId
    shape {
      id
      label
      value
    }
    sku
    slug
    starLengthPercent
    symmetryId
    symmetry {
      id
      label
      value
    }
    tablePercent
    vendorId
    vendor {
      name
    }
    widthMM
    updatedAt
  }
}
    `;
export type DiamondComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DiamondQuery, DiamondQueryVariables>, 'query'> & ({ variables: DiamondQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DiamondComponent = (props: DiamondComponentProps) => (
      <ApolloReactComponents.Query<DiamondQuery, DiamondQueryVariables> query={DiamondDocument} {...props} />
    );
    
export type DiamondProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DiamondQuery, DiamondQueryVariables>
    } & TChildProps;
export function withDiamond<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DiamondQuery,
  DiamondQueryVariables,
  DiamondProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DiamondQuery, DiamondQueryVariables, DiamondProps<TChildProps, TDataName>>(DiamondDocument, {
      alias: 'diamond',
      ...operationOptions
    });
};

/**
 * __useDiamondQuery__
 *
 * To run a query within a React component, call `useDiamondQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiamondQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiamondQuery({
 *   variables: {
 *      slugOrId: // value for 'slugOrId'
 *   },
 * });
 */
export function useDiamondQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiamondQuery, DiamondQueryVariables>) {
        return ApolloReactHooks.useQuery<DiamondQuery, DiamondQueryVariables>(DiamondDocument, baseOptions);
      }
export function useDiamondLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiamondQuery, DiamondQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiamondQuery, DiamondQueryVariables>(DiamondDocument, baseOptions);
        }
export type DiamondQueryHookResult = ReturnType<typeof useDiamondQuery>;
export type DiamondLazyQueryHookResult = ReturnType<typeof useDiamondLazyQuery>;
export type DiamondQueryResult = ApolloReactCommon.QueryResult<DiamondQuery, DiamondQueryVariables>;
export const UpdateDiamondDocument = gql`
    mutation updateDiamond($where: DiamondWhereUniqueInput!, $data: DiamondUpdateInput!) {
  updateDiamond(where: $where, data: $data) {
    id
    updatedAt
  }
}
    `;
export type UpdateDiamondMutationFn = ApolloReactCommon.MutationFunction<UpdateDiamondMutation, UpdateDiamondMutationVariables>;
export type UpdateDiamondComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateDiamondMutation, UpdateDiamondMutationVariables>, 'mutation'>;

    export const UpdateDiamondComponent = (props: UpdateDiamondComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateDiamondMutation, UpdateDiamondMutationVariables> mutation={UpdateDiamondDocument} {...props} />
    );
    
export type UpdateDiamondProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateDiamondMutation, UpdateDiamondMutationVariables>
    } & TChildProps;
export function withUpdateDiamond<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateDiamondMutation,
  UpdateDiamondMutationVariables,
  UpdateDiamondProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateDiamondMutation, UpdateDiamondMutationVariables, UpdateDiamondProps<TChildProps, TDataName>>(UpdateDiamondDocument, {
      alias: 'updateDiamond',
      ...operationOptions
    });
};

/**
 * __useUpdateDiamondMutation__
 *
 * To run a mutation, you first call `useUpdateDiamondMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiamondMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiamondMutation, { data, loading, error }] = useUpdateDiamondMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDiamondMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDiamondMutation, UpdateDiamondMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDiamondMutation, UpdateDiamondMutationVariables>(UpdateDiamondDocument, baseOptions);
      }
export type UpdateDiamondMutationHookResult = ReturnType<typeof useUpdateDiamondMutation>;
export type UpdateDiamondMutationResult = ApolloReactCommon.MutationResult<UpdateDiamondMutation>;
export type UpdateDiamondMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDiamondMutation, UpdateDiamondMutationVariables>;
export const DiamondsDocument = gql`
    query diamonds($where: DiamondWhereInput, $first: Int, $last: Int, $after: DiamondWhereUniqueInput, $before: DiamondWhereUniqueInput, $orderBy: [DiamondOrderByInput!]) {
  count: diamondsCount(where: $where)
  diamonds(where: $where, first: $first, last: $last, after: $after, before: $before, orderBy: $orderBy) {
    _id: id
    id
    carat
    clarity {
      id
      label
    }
    color {
      id
      label
    }
    cost
    createdAt
    crownAngle
    culet {
      id
      label
    }
    cut {
      id
      label
    }
    cut {
      id
      label
    }
    lab {
      name
      reportNumber
    }
    name
    price
    quantity
    shape {
      id
      label
    }
    sku
  }
}
    `;
export type DiamondsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DiamondsQuery, DiamondsQueryVariables>, 'query'>;

    export const DiamondsComponent = (props: DiamondsComponentProps) => (
      <ApolloReactComponents.Query<DiamondsQuery, DiamondsQueryVariables> query={DiamondsDocument} {...props} />
    );
    
export type DiamondsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DiamondsQuery, DiamondsQueryVariables>
    } & TChildProps;
export function withDiamonds<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DiamondsQuery,
  DiamondsQueryVariables,
  DiamondsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DiamondsQuery, DiamondsQueryVariables, DiamondsProps<TChildProps, TDataName>>(DiamondsDocument, {
      alias: 'diamonds',
      ...operationOptions
    });
};

/**
 * __useDiamondsQuery__
 *
 * To run a query within a React component, call `useDiamondsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiamondsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiamondsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDiamondsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiamondsQuery, DiamondsQueryVariables>) {
        return ApolloReactHooks.useQuery<DiamondsQuery, DiamondsQueryVariables>(DiamondsDocument, baseOptions);
      }
export function useDiamondsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiamondsQuery, DiamondsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiamondsQuery, DiamondsQueryVariables>(DiamondsDocument, baseOptions);
        }
export type DiamondsQueryHookResult = ReturnType<typeof useDiamondsQuery>;
export type DiamondsLazyQueryHookResult = ReturnType<typeof useDiamondsLazyQuery>;
export type DiamondsQueryResult = ApolloReactCommon.QueryResult<DiamondsQuery, DiamondsQueryVariables>;
export const EmailsDocument = gql`
    query emails($workOrderId: ObjID, $quoteId: ObjID, $type: String, $userId: ObjID) {
  emails(workOrderId: $workOrderId, quoteId: $quoteId, type: $type, userId: $userId) {
    _id
    body
    from
    hasEmail
    html
    invoiceId
    message
    orderId
    paymentType
    shippingCompanyId
    subject
    template
    text
    to
    trackingNumber
    type
    userId
    workOrderId
    sentAt
    createdAt
    updatedAt
  }
}
    `;
export type EmailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmailsQuery, EmailsQueryVariables>, 'query'>;

    export const EmailsComponent = (props: EmailsComponentProps) => (
      <ApolloReactComponents.Query<EmailsQuery, EmailsQueryVariables> query={EmailsDocument} {...props} />
    );
    
export type EmailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EmailsQuery, EmailsQueryVariables>
    } & TChildProps;
export function withEmails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EmailsQuery,
  EmailsQueryVariables,
  EmailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EmailsQuery, EmailsQueryVariables, EmailsProps<TChildProps, TDataName>>(EmailsDocument, {
      alias: 'emails',
      ...operationOptions
    });
};

/**
 * __useEmailsQuery__
 *
 * To run a query within a React component, call `useEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailsQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *      quoteId: // value for 'quoteId'
 *      type: // value for 'type'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEmailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmailsQuery, EmailsQueryVariables>) {
        return ApolloReactHooks.useQuery<EmailsQuery, EmailsQueryVariables>(EmailsDocument, baseOptions);
      }
export function useEmailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmailsQuery, EmailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmailsQuery, EmailsQueryVariables>(EmailsDocument, baseOptions);
        }
export type EmailsQueryHookResult = ReturnType<typeof useEmailsQuery>;
export type EmailsLazyQueryHookResult = ReturnType<typeof useEmailsLazyQuery>;
export type EmailsQueryResult = ApolloReactCommon.QueryResult<EmailsQuery, EmailsQueryVariables>;
export const FormContactDocument = gql`
    query formContact($_id: ObjID!) {
  formContact(_id: $_id) {
    _id
    email
    firstName
    lastName
    createdAt
    notes {
      content
      authorId
    }
    customDesignRequests {
      _id
      designs
      productType
      quote {
        _id
      }
      createdAt
    }
    quotes {
      _id
      number
      total
      locked
      emailedAt
      workOrder {
        _id
      }
      customDesignRequestId
      createdAt
    }
    workOrders {
      _id
      number
      status
      user {
        _id
        firstName
        lastName
        email
      }
      total
      locked
      quoteId
    }
  }
}
    `;
export type FormContactComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FormContactQuery, FormContactQueryVariables>, 'query'> & ({ variables: FormContactQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FormContactComponent = (props: FormContactComponentProps) => (
      <ApolloReactComponents.Query<FormContactQuery, FormContactQueryVariables> query={FormContactDocument} {...props} />
    );
    
export type FormContactProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FormContactQuery, FormContactQueryVariables>
    } & TChildProps;
export function withFormContact<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FormContactQuery,
  FormContactQueryVariables,
  FormContactProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FormContactQuery, FormContactQueryVariables, FormContactProps<TChildProps, TDataName>>(FormContactDocument, {
      alias: 'formContact',
      ...operationOptions
    });
};

/**
 * __useFormContactQuery__
 *
 * To run a query within a React component, call `useFormContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormContactQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useFormContactQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormContactQuery, FormContactQueryVariables>) {
        return ApolloReactHooks.useQuery<FormContactQuery, FormContactQueryVariables>(FormContactDocument, baseOptions);
      }
export function useFormContactLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormContactQuery, FormContactQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FormContactQuery, FormContactQueryVariables>(FormContactDocument, baseOptions);
        }
export type FormContactQueryHookResult = ReturnType<typeof useFormContactQuery>;
export type FormContactLazyQueryHookResult = ReturnType<typeof useFormContactLazyQuery>;
export type FormContactQueryResult = ApolloReactCommon.QueryResult<FormContactQuery, FormContactQueryVariables>;
export const RemoveFormContactDocument = gql`
    mutation removeFormContact($_id: ObjID!) {
  removeFormContact(_id: $_id) {
    n
    ok
  }
}
    `;
export type RemoveFormContactMutationFn = ApolloReactCommon.MutationFunction<RemoveFormContactMutation, RemoveFormContactMutationVariables>;
export type RemoveFormContactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveFormContactMutation, RemoveFormContactMutationVariables>, 'mutation'>;

    export const RemoveFormContactComponent = (props: RemoveFormContactComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveFormContactMutation, RemoveFormContactMutationVariables> mutation={RemoveFormContactDocument} {...props} />
    );
    
export type RemoveFormContactProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveFormContactMutation, RemoveFormContactMutationVariables>
    } & TChildProps;
export function withRemoveFormContact<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveFormContactMutation,
  RemoveFormContactMutationVariables,
  RemoveFormContactProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveFormContactMutation, RemoveFormContactMutationVariables, RemoveFormContactProps<TChildProps, TDataName>>(RemoveFormContactDocument, {
      alias: 'removeFormContact',
      ...operationOptions
    });
};

/**
 * __useRemoveFormContactMutation__
 *
 * To run a mutation, you first call `useRemoveFormContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFormContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFormContactMutation, { data, loading, error }] = useRemoveFormContactMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveFormContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFormContactMutation, RemoveFormContactMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveFormContactMutation, RemoveFormContactMutationVariables>(RemoveFormContactDocument, baseOptions);
      }
export type RemoveFormContactMutationHookResult = ReturnType<typeof useRemoveFormContactMutation>;
export type RemoveFormContactMutationResult = ApolloReactCommon.MutationResult<RemoveFormContactMutation>;
export type RemoveFormContactMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFormContactMutation, RemoveFormContactMutationVariables>;
export const UpdateFormContactDocument = gql`
    mutation updateFormContact($_id: ObjID!, $input: UpdateUserInput!) {
  updateFormContact(_id: $_id, input: $input) {
    _id
    updatedAt
  }
}
    `;
export type UpdateFormContactMutationFn = ApolloReactCommon.MutationFunction<UpdateFormContactMutation, UpdateFormContactMutationVariables>;
export type UpdateFormContactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateFormContactMutation, UpdateFormContactMutationVariables>, 'mutation'>;

    export const UpdateFormContactComponent = (props: UpdateFormContactComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateFormContactMutation, UpdateFormContactMutationVariables> mutation={UpdateFormContactDocument} {...props} />
    );
    
export type UpdateFormContactProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateFormContactMutation, UpdateFormContactMutationVariables>
    } & TChildProps;
export function withUpdateFormContact<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateFormContactMutation,
  UpdateFormContactMutationVariables,
  UpdateFormContactProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateFormContactMutation, UpdateFormContactMutationVariables, UpdateFormContactProps<TChildProps, TDataName>>(UpdateFormContactDocument, {
      alias: 'updateFormContact',
      ...operationOptions
    });
};

/**
 * __useUpdateFormContactMutation__
 *
 * To run a mutation, you first call `useUpdateFormContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormContactMutation, { data, loading, error }] = useUpdateFormContactMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFormContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFormContactMutation, UpdateFormContactMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFormContactMutation, UpdateFormContactMutationVariables>(UpdateFormContactDocument, baseOptions);
      }
export type UpdateFormContactMutationHookResult = ReturnType<typeof useUpdateFormContactMutation>;
export type UpdateFormContactMutationResult = ApolloReactCommon.MutationResult<UpdateFormContactMutation>;
export type UpdateFormContactMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFormContactMutation, UpdateFormContactMutationVariables>;
export const FormContactsDocument = gql`
    query formContacts {
  formContacts {
    _id
    email
    firstName
    lastName
    customDesignRequests {
      _id
    }
    quotes {
      _id
    }
    workOrders {
      _id
    }
    createdAt
  }
}
    `;
export type FormContactsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FormContactsQuery, FormContactsQueryVariables>, 'query'>;

    export const FormContactsComponent = (props: FormContactsComponentProps) => (
      <ApolloReactComponents.Query<FormContactsQuery, FormContactsQueryVariables> query={FormContactsDocument} {...props} />
    );
    
export type FormContactsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FormContactsQuery, FormContactsQueryVariables>
    } & TChildProps;
export function withFormContacts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FormContactsQuery,
  FormContactsQueryVariables,
  FormContactsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FormContactsQuery, FormContactsQueryVariables, FormContactsProps<TChildProps, TDataName>>(FormContactsDocument, {
      alias: 'formContacts',
      ...operationOptions
    });
};

/**
 * __useFormContactsQuery__
 *
 * To run a query within a React component, call `useFormContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormContactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormContactsQuery, FormContactsQueryVariables>) {
        return ApolloReactHooks.useQuery<FormContactsQuery, FormContactsQueryVariables>(FormContactsDocument, baseOptions);
      }
export function useFormContactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormContactsQuery, FormContactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FormContactsQuery, FormContactsQueryVariables>(FormContactsDocument, baseOptions);
        }
export type FormContactsQueryHookResult = ReturnType<typeof useFormContactsQuery>;
export type FormContactsLazyQueryHookResult = ReturnType<typeof useFormContactsLazyQuery>;
export type FormContactsQueryResult = ApolloReactCommon.QueryResult<FormContactsQuery, FormContactsQueryVariables>;
export const GeneralInquiriesDocument = gql`
    query generalInquiries {
  generalInquiries {
    _id
    firstName
    lastName
    email
    message
    createdAt
  }
}
    `;
export type GeneralInquiriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GeneralInquiriesQuery, GeneralInquiriesQueryVariables>, 'query'>;

    export const GeneralInquiriesComponent = (props: GeneralInquiriesComponentProps) => (
      <ApolloReactComponents.Query<GeneralInquiriesQuery, GeneralInquiriesQueryVariables> query={GeneralInquiriesDocument} {...props} />
    );
    
export type GeneralInquiriesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GeneralInquiriesQuery, GeneralInquiriesQueryVariables>
    } & TChildProps;
export function withGeneralInquiries<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GeneralInquiriesQuery,
  GeneralInquiriesQueryVariables,
  GeneralInquiriesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GeneralInquiriesQuery, GeneralInquiriesQueryVariables, GeneralInquiriesProps<TChildProps, TDataName>>(GeneralInquiriesDocument, {
      alias: 'generalInquiries',
      ...operationOptions
    });
};

/**
 * __useGeneralInquiriesQuery__
 *
 * To run a query within a React component, call `useGeneralInquiriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneralInquiriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneralInquiriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeneralInquiriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GeneralInquiriesQuery, GeneralInquiriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GeneralInquiriesQuery, GeneralInquiriesQueryVariables>(GeneralInquiriesDocument, baseOptions);
      }
export function useGeneralInquiriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GeneralInquiriesQuery, GeneralInquiriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GeneralInquiriesQuery, GeneralInquiriesQueryVariables>(GeneralInquiriesDocument, baseOptions);
        }
export type GeneralInquiriesQueryHookResult = ReturnType<typeof useGeneralInquiriesQuery>;
export type GeneralInquiriesLazyQueryHookResult = ReturnType<typeof useGeneralInquiriesLazyQuery>;
export type GeneralInquiriesQueryResult = ApolloReactCommon.QueryResult<GeneralInquiriesQuery, GeneralInquiriesQueryVariables>;
export const RemoveImageDocument = gql`
    mutation removeImage($id: String!) {
  deleteImage(id: $id) {
    id
  }
}
    `;
export type RemoveImageMutationFn = ApolloReactCommon.MutationFunction<RemoveImageMutation, RemoveImageMutationVariables>;
export type RemoveImageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveImageMutation, RemoveImageMutationVariables>, 'mutation'>;

    export const RemoveImageComponent = (props: RemoveImageComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveImageMutation, RemoveImageMutationVariables> mutation={RemoveImageDocument} {...props} />
    );
    
export type RemoveImageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveImageMutation, RemoveImageMutationVariables>
    } & TChildProps;
export function withRemoveImage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveImageMutation,
  RemoveImageMutationVariables,
  RemoveImageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveImageMutation, RemoveImageMutationVariables, RemoveImageProps<TChildProps, TDataName>>(RemoveImageDocument, {
      alias: 'removeImage',
      ...operationOptions
    });
};

/**
 * __useRemoveImageMutation__
 *
 * To run a mutation, you first call `useRemoveImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeImageMutation, { data, loading, error }] = useRemoveImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveImageMutation, RemoveImageMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveImageMutation, RemoveImageMutationVariables>(RemoveImageDocument, baseOptions);
      }
export type RemoveImageMutationHookResult = ReturnType<typeof useRemoveImageMutation>;
export type RemoveImageMutationResult = ApolloReactCommon.MutationResult<RemoveImageMutation>;
export type RemoveImageMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveImageMutation, RemoveImageMutationVariables>;
export const UpdateImageDocument = gql`
    mutation updateImage($where: ImageWhereUniqueInput!, $data: ImageUpdateInput!) {
  updateImage(where: $where, data: $data) {
    id
    updatedAt
  }
}
    `;
export type UpdateImageMutationFn = ApolloReactCommon.MutationFunction<UpdateImageMutation, UpdateImageMutationVariables>;
export type UpdateImageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateImageMutation, UpdateImageMutationVariables>, 'mutation'>;

    export const UpdateImageComponent = (props: UpdateImageComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateImageMutation, UpdateImageMutationVariables> mutation={UpdateImageDocument} {...props} />
    );
    
export type UpdateImageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateImageMutation, UpdateImageMutationVariables>
    } & TChildProps;
export function withUpdateImage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateImageMutation,
  UpdateImageMutationVariables,
  UpdateImageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateImageMutation, UpdateImageMutationVariables, UpdateImageProps<TChildProps, TDataName>>(UpdateImageDocument, {
      alias: 'updateImage',
      ...operationOptions
    });
};

/**
 * __useUpdateImageMutation__
 *
 * To run a mutation, you first call `useUpdateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImageMutation, { data, loading, error }] = useUpdateImageMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateImageMutation, UpdateImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateImageMutation, UpdateImageMutationVariables>(UpdateImageDocument, baseOptions);
      }
export type UpdateImageMutationHookResult = ReturnType<typeof useUpdateImageMutation>;
export type UpdateImageMutationResult = ApolloReactCommon.MutationResult<UpdateImageMutation>;
export type UpdateImageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateImageMutation, UpdateImageMutationVariables>;
export const RemoveImagesDocument = gql`
    mutation removeImages($input: [String!]!) {
  deleteImages(ids: $input) {
    id
  }
}
    `;
export type RemoveImagesMutationFn = ApolloReactCommon.MutationFunction<RemoveImagesMutation, RemoveImagesMutationVariables>;
export type RemoveImagesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveImagesMutation, RemoveImagesMutationVariables>, 'mutation'>;

    export const RemoveImagesComponent = (props: RemoveImagesComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveImagesMutation, RemoveImagesMutationVariables> mutation={RemoveImagesDocument} {...props} />
    );
    
export type RemoveImagesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveImagesMutation, RemoveImagesMutationVariables>
    } & TChildProps;
export function withRemoveImages<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveImagesMutation,
  RemoveImagesMutationVariables,
  RemoveImagesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveImagesMutation, RemoveImagesMutationVariables, RemoveImagesProps<TChildProps, TDataName>>(RemoveImagesDocument, {
      alias: 'removeImages',
      ...operationOptions
    });
};

/**
 * __useRemoveImagesMutation__
 *
 * To run a mutation, you first call `useRemoveImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeImagesMutation, { data, loading, error }] = useRemoveImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveImagesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveImagesMutation, RemoveImagesMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveImagesMutation, RemoveImagesMutationVariables>(RemoveImagesDocument, baseOptions);
      }
export type RemoveImagesMutationHookResult = ReturnType<typeof useRemoveImagesMutation>;
export type RemoveImagesMutationResult = ApolloReactCommon.MutationResult<RemoveImagesMutation>;
export type RemoveImagesMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveImagesMutation, RemoveImagesMutationVariables>;
export const UpdateImagesDocument = gql`
    mutation updateImages($where: ImageWhereInput, $data: ImageUpdateManyMutationInput!) {
  updateImages(where: $where, data: $data) {
    count
  }
}
    `;
export type UpdateImagesMutationFn = ApolloReactCommon.MutationFunction<UpdateImagesMutation, UpdateImagesMutationVariables>;
export type UpdateImagesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateImagesMutation, UpdateImagesMutationVariables>, 'mutation'>;

    export const UpdateImagesComponent = (props: UpdateImagesComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateImagesMutation, UpdateImagesMutationVariables> mutation={UpdateImagesDocument} {...props} />
    );
    
export type UpdateImagesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateImagesMutation, UpdateImagesMutationVariables>
    } & TChildProps;
export function withUpdateImages<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateImagesMutation,
  UpdateImagesMutationVariables,
  UpdateImagesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateImagesMutation, UpdateImagesMutationVariables, UpdateImagesProps<TChildProps, TDataName>>(UpdateImagesDocument, {
      alias: 'updateImages',
      ...operationOptions
    });
};

/**
 * __useUpdateImagesMutation__
 *
 * To run a mutation, you first call `useUpdateImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImagesMutation, { data, loading, error }] = useUpdateImagesMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateImagesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateImagesMutation, UpdateImagesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateImagesMutation, UpdateImagesMutationVariables>(UpdateImagesDocument, baseOptions);
      }
export type UpdateImagesMutationHookResult = ReturnType<typeof useUpdateImagesMutation>;
export type UpdateImagesMutationResult = ApolloReactCommon.MutationResult<UpdateImagesMutation>;
export type UpdateImagesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateImagesMutation, UpdateImagesMutationVariables>;
export const CreateInvoiceDocument = gql`
    mutation createInvoice($input: InvoiceInput!) {
  createInvoice(input: $input) {
    _id
  }
}
    `;
export type CreateInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export type CreateInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>, 'mutation'>;

    export const CreateInvoiceComponent = (props: CreateInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInvoiceMutation, CreateInvoiceMutationVariables> mutation={CreateInvoiceDocument} {...props} />
    );
    
export type CreateInvoiceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>
    } & TChildProps;
export function withCreateInvoice<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables,
  CreateInvoiceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateInvoiceMutation, CreateInvoiceMutationVariables, CreateInvoiceProps<TChildProps, TDataName>>(CreateInvoiceDocument, {
      alias: 'createInvoice',
      ...operationOptions
    });
};

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, baseOptions);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const InvoiceDocument = gql`
    query invoice($_id: ObjID!) {
  invoice(_id: $_id) {
    _id
    balance
    body
    emails {
      _id
      body
      from
      hasEmail
      html
      invoiceId
      message
      orderId
      paymentType
      shippingCompanyId
      subject
      template
      text
      to
      trackingNumber
      type
      userId
      workOrderId
      sentAt
      createdAt
      updatedAt
    }
    items {
      title
      unitPrice
      quantity
      price
    }
    note
    number
    subject
    subtotal
    tax
    terms
    to
    total
    totalPayments
    type
    userId
    user {
      firstName
      lastName
      email
      phone
    }
    createdByUser {
      firstName
      lastName
    }
    orderId
    order {
      _id
      tax
      shipping
      total
    }
    workOrderId
    workOrder {
      _id
      completedAt
      quoteId
      subtotal
      tax
      shipping
      total
    }
    sentAt
    createdAt
    updatedAt
  }
}
    `;
export type InvoiceComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InvoiceQuery, InvoiceQueryVariables>, 'query'> & ({ variables: InvoiceQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InvoiceComponent = (props: InvoiceComponentProps) => (
      <ApolloReactComponents.Query<InvoiceQuery, InvoiceQueryVariables> query={InvoiceDocument} {...props} />
    );
    
export type InvoiceProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InvoiceQuery, InvoiceQueryVariables>
    } & TChildProps;
export function withInvoice<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InvoiceQuery,
  InvoiceQueryVariables,
  InvoiceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, InvoiceQuery, InvoiceQueryVariables, InvoiceProps<TChildProps, TDataName>>(InvoiceDocument, {
      alias: 'invoice',
      ...operationOptions
    });
};

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, baseOptions);
      }
export function useInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, baseOptions);
        }
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceQueryResult = ApolloReactCommon.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const SendInvoiceDocument = gql`
    mutation sendInvoice($_id: ObjID!) {
  sendInvoice(_id: $_id) {
    _id
  }
}
    `;
export type SendInvoiceMutationFn = ApolloReactCommon.MutationFunction<SendInvoiceMutation, SendInvoiceMutationVariables>;
export type SendInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendInvoiceMutation, SendInvoiceMutationVariables>, 'mutation'>;

    export const SendInvoiceComponent = (props: SendInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<SendInvoiceMutation, SendInvoiceMutationVariables> mutation={SendInvoiceDocument} {...props} />
    );
    
export type SendInvoiceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SendInvoiceMutation, SendInvoiceMutationVariables>
    } & TChildProps;
export function withSendInvoice<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendInvoiceMutation,
  SendInvoiceMutationVariables,
  SendInvoiceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SendInvoiceMutation, SendInvoiceMutationVariables, SendInvoiceProps<TChildProps, TDataName>>(SendInvoiceDocument, {
      alias: 'sendInvoice',
      ...operationOptions
    });
};

/**
 * __useSendInvoiceMutation__
 *
 * To run a mutation, you first call `useSendInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoiceMutation, { data, loading, error }] = useSendInvoiceMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useSendInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendInvoiceMutation, SendInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<SendInvoiceMutation, SendInvoiceMutationVariables>(SendInvoiceDocument, baseOptions);
      }
export type SendInvoiceMutationHookResult = ReturnType<typeof useSendInvoiceMutation>;
export type SendInvoiceMutationResult = ApolloReactCommon.MutationResult<SendInvoiceMutation>;
export type SendInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<SendInvoiceMutation, SendInvoiceMutationVariables>;
export const UpdateInvoiceDocument = gql`
    mutation updateInvoice($_id: ObjID!, $input: InvoiceInput!) {
  updateInvoice(_id: $_id, input: $input) {
    _id
    updatedAt
  }
}
    `;
export type UpdateInvoiceMutationFn = ApolloReactCommon.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export type UpdateInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>, 'mutation'>;

    export const UpdateInvoiceComponent = (props: UpdateInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables> mutation={UpdateInvoiceDocument} {...props} />
    );
    
export type UpdateInvoiceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>
    } & TChildProps;
export function withUpdateInvoice<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables,
  UpdateInvoiceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateInvoiceMutation, UpdateInvoiceMutationVariables, UpdateInvoiceProps<TChildProps, TDataName>>(UpdateInvoiceDocument, {
      alias: 'updateInvoice',
      ...operationOptions
    });
};

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, baseOptions);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = ApolloReactCommon.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const ProductsDocument = gql`
    query products($where: ProductWhereInput, $after: ProductWhereUniqueInput, $before: ProductWhereUniqueInput, $first: Int, $orderBy: [ProductOrderByInput!]) {
  products(where: $where, after: $after, before: $before, first: $first, orderBy: $orderBy) {
    _id: id
    id
    createdAt
    modelNumber
    enabled
    name
    price
    quantity
    sku
    type
    variants {
      id
    }
  }
}
    `;
export type ProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductsQuery, ProductsQueryVariables>, 'query'>;

    export const ProductsComponent = (props: ProductsComponentProps) => (
      <ApolloReactComponents.Query<ProductsQuery, ProductsQueryVariables> query={ProductsDocument} {...props} />
    );
    
export type ProductsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProductsQuery, ProductsQueryVariables>
    } & TChildProps;
export function withProducts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProductsQuery,
  ProductsQueryVariables,
  ProductsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProductsQuery, ProductsQueryVariables, ProductsProps<TChildProps, TDataName>>(ProductsDocument, {
      alias: 'products',
      ...operationOptions
    });
};

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
      }
export function useProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = ApolloReactCommon.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($data: ProductCreateInput!) {
  createProduct(data: $data) {
    id
    createdAt
  }
}
    `;
export type CreateProductMutationFn = ApolloReactCommon.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;
export type CreateProductComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateProductMutation, CreateProductMutationVariables>, 'mutation'>;

    export const CreateProductComponent = (props: CreateProductComponentProps) => (
      <ApolloReactComponents.Mutation<CreateProductMutation, CreateProductMutationVariables> mutation={CreateProductDocument} {...props} />
    );
    
export type CreateProductProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateProductMutation, CreateProductMutationVariables>
    } & TChildProps;
export function withCreateProduct<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateProductMutation,
  CreateProductMutationVariables,
  CreateProductProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateProductMutation, CreateProductMutationVariables, CreateProductProps<TChildProps, TDataName>>(CreateProductDocument, {
      alias: 'createProduct',
      ...operationOptions
    });
};

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = ApolloReactCommon.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const RemoveJewelryDocument = gql`
    mutation removeJewelry($_id: String!) {
  deleteProduct(where: {id: $_id}) {
    id
  }
}
    `;
export type RemoveJewelryMutationFn = ApolloReactCommon.MutationFunction<RemoveJewelryMutation, RemoveJewelryMutationVariables>;
export type RemoveJewelryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveJewelryMutation, RemoveJewelryMutationVariables>, 'mutation'>;

    export const RemoveJewelryComponent = (props: RemoveJewelryComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveJewelryMutation, RemoveJewelryMutationVariables> mutation={RemoveJewelryDocument} {...props} />
    );
    
export type RemoveJewelryProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveJewelryMutation, RemoveJewelryMutationVariables>
    } & TChildProps;
export function withRemoveJewelry<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveJewelryMutation,
  RemoveJewelryMutationVariables,
  RemoveJewelryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveJewelryMutation, RemoveJewelryMutationVariables, RemoveJewelryProps<TChildProps, TDataName>>(RemoveJewelryDocument, {
      alias: 'removeJewelry',
      ...operationOptions
    });
};

/**
 * __useRemoveJewelryMutation__
 *
 * To run a mutation, you first call `useRemoveJewelryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveJewelryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeJewelryMutation, { data, loading, error }] = useRemoveJewelryMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveJewelryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveJewelryMutation, RemoveJewelryMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveJewelryMutation, RemoveJewelryMutationVariables>(RemoveJewelryDocument, baseOptions);
      }
export type RemoveJewelryMutationHookResult = ReturnType<typeof useRemoveJewelryMutation>;
export type RemoveJewelryMutationResult = ApolloReactCommon.MutationResult<RemoveJewelryMutation>;
export type RemoveJewelryMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveJewelryMutation, RemoveJewelryMutationVariables>;
export const JewelryDocument = gql`
    query jewelry($where: ProductWhereUniqueInput!) {
  availabilities {
    _id: id
    id
    label
    order
  }
  diamondShapes {
    _id: id
    id
    label
    order
  }
  metals {
    _id: id
    id
    label
    order
  }
  paves {
    _id: id
    id
    label
    order
  }
  paveDiamondTypes {
    _id: id
    id
    label
    order
  }
  productTypes {
    _id: id
    id
    label
    order
  }
  prongs {
    _id: id
    id
    label
    order
  }
  ringTypes {
    _id: id
    id
    label
    order
  }
  bandTypes {
    _id: id
    id
    label
    order
  }
  shanks {
    _id: id
    id
    label
    order
  }
  shankThicknesses {
    _id: id
    id
    label
    value
  }
  optionSets {
    id
    name
    slug
    options {
      id
      name
      slug
    }
  }
  jewelry: product(where: $where) {
    _id: id
    id
    allowCheckout
    availability {
      value
    }
    categories {
      id
      name
      slug
      createdAt
      updatedAt
    }
    carat
    collections {
      id
      name
      slug
      createdAt
      updatedAt
    }
    cost
    description
    diamondShapeId
    diamondShape {
      _id: id
      label
      value
    }
    enabled
    fingerSize
    images(orderBy: [{order: asc}, {url: asc}]) {
      _id: id
      id
      category
      filename
      order
      path
      storageUrl
      url
    }
    isDefaultVariant
    isEngagementRing
    isHandFabricated
    isRightHandRing
    isBand
    isLabGrown
    lab {
      name
      certificate
      reportNumber
    }
    leadTimeFrom
    leadTimeTo
    metalId
    metal {
      _id: id
      label
      value
    }
    modelName
    modelNumber
    name
    notes
    options {
      id
      name
      slug
      optionSet {
        id
        name
        slug
      }
    }
    parentId
    paveId
    pave {
      _id: id
      id
      label
      value
    }
    paveDiamondTypeId
    paveDiamondType {
      _id: id
      id
      label
      value
    }
    price
    productTypeId
    productType {
      _id: id
      id
      label
      value
    }
    prongId
    prong {
      _id: id
      id
      label
      value
    }
    quantity
    ringTypeId
    ringType {
      _id: id
      id
      label
      value
    }
    bandTypeId
    bandType {
      _id: id
      id
      label
      value
    }
    salePrice
    shankId
    shank {
      _id: id
      id
      label
      value
    }
    shankThicknessId
    shankThickness {
      _id: id
      id
      label
      value
    }
    sku
    shortDescription
    slug
    type
    meta {
      title
      description
      keywords
    }
    variants(orderBy: {isDefaultVariant: desc}) {
      id
      sku
      quantity
      price
      isDefaultVariant
      isLabGrown
      parentId
      options {
        id
        name
        slug
        optionSet {
          id
          name
          slug
        }
      }
      images {
        id
        url
      }
      representativeImage
    }
    video {
      title
      code
      source
    }
    createdAt
    updatedAt
  }
}
    `;
export type JewelryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<JewelryQuery, JewelryQueryVariables>, 'query'> & ({ variables: JewelryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const JewelryComponent = (props: JewelryComponentProps) => (
      <ApolloReactComponents.Query<JewelryQuery, JewelryQueryVariables> query={JewelryDocument} {...props} />
    );
    
export type JewelryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<JewelryQuery, JewelryQueryVariables>
    } & TChildProps;
export function withJewelry<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  JewelryQuery,
  JewelryQueryVariables,
  JewelryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, JewelryQuery, JewelryQueryVariables, JewelryProps<TChildProps, TDataName>>(JewelryDocument, {
      alias: 'jewelry',
      ...operationOptions
    });
};

/**
 * __useJewelryQuery__
 *
 * To run a query within a React component, call `useJewelryQuery` and pass it any options that fit your needs.
 * When your component renders, `useJewelryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJewelryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useJewelryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JewelryQuery, JewelryQueryVariables>) {
        return ApolloReactHooks.useQuery<JewelryQuery, JewelryQueryVariables>(JewelryDocument, baseOptions);
      }
export function useJewelryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JewelryQuery, JewelryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JewelryQuery, JewelryQueryVariables>(JewelryDocument, baseOptions);
        }
export type JewelryQueryHookResult = ReturnType<typeof useJewelryQuery>;
export type JewelryLazyQueryHookResult = ReturnType<typeof useJewelryLazyQuery>;
export type JewelryQueryResult = ApolloReactCommon.QueryResult<JewelryQuery, JewelryQueryVariables>;
export const UpdateJewelryDocument = gql`
    mutation updateJewelry($where: ProductWhereUniqueInput!, $data: ProductUpdateInput!) {
  updateProduct(where: $where, data: $data) {
    id
    updatedAt
  }
}
    `;
export type UpdateJewelryMutationFn = ApolloReactCommon.MutationFunction<UpdateJewelryMutation, UpdateJewelryMutationVariables>;
export type UpdateJewelryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateJewelryMutation, UpdateJewelryMutationVariables>, 'mutation'>;

    export const UpdateJewelryComponent = (props: UpdateJewelryComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateJewelryMutation, UpdateJewelryMutationVariables> mutation={UpdateJewelryDocument} {...props} />
    );
    
export type UpdateJewelryProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateJewelryMutation, UpdateJewelryMutationVariables>
    } & TChildProps;
export function withUpdateJewelry<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateJewelryMutation,
  UpdateJewelryMutationVariables,
  UpdateJewelryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateJewelryMutation, UpdateJewelryMutationVariables, UpdateJewelryProps<TChildProps, TDataName>>(UpdateJewelryDocument, {
      alias: 'updateJewelry',
      ...operationOptions
    });
};

/**
 * __useUpdateJewelryMutation__
 *
 * To run a mutation, you first call `useUpdateJewelryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJewelryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJewelryMutation, { data, loading, error }] = useUpdateJewelryMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateJewelryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateJewelryMutation, UpdateJewelryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateJewelryMutation, UpdateJewelryMutationVariables>(UpdateJewelryDocument, baseOptions);
      }
export type UpdateJewelryMutationHookResult = ReturnType<typeof useUpdateJewelryMutation>;
export type UpdateJewelryMutationResult = ApolloReactCommon.MutationResult<UpdateJewelryMutation>;
export type UpdateJewelryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateJewelryMutation, UpdateJewelryMutationVariables>;
export const JobDocument = gql`
    query job($_id: ObjID!) {
  job(_id: $_id) {
    _id
    cost
    description
    internalNotes
    jobNumber
    price
    quantity
    services {
      name
      note
      unitPrice
      quantity
      price
    }
    status
    statusNotes
    title
    type
    unitPrice
    userId
    user {
      firstName
      lastName
      email
      phone
    }
    workOrderId
    promisedAt
    completedAt
    createdAt
    updatedAt
  }
}
    `;
export type JobComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<JobQuery, JobQueryVariables>, 'query'> & ({ variables: JobQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const JobComponent = (props: JobComponentProps) => (
      <ApolloReactComponents.Query<JobQuery, JobQueryVariables> query={JobDocument} {...props} />
    );
    
export type JobProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<JobQuery, JobQueryVariables>
    } & TChildProps;
export function withJob<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  JobQuery,
  JobQueryVariables,
  JobProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, JobQuery, JobQueryVariables, JobProps<TChildProps, TDataName>>(JobDocument, {
      alias: 'job',
      ...operationOptions
    });
};

/**
 * __useJobQuery__
 *
 * To run a query within a React component, call `useJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobQuery, JobQueryVariables>) {
        return ApolloReactHooks.useQuery<JobQuery, JobQueryVariables>(JobDocument, baseOptions);
      }
export function useJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobQuery, JobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobQuery, JobQueryVariables>(JobDocument, baseOptions);
        }
export type JobQueryHookResult = ReturnType<typeof useJobQuery>;
export type JobLazyQueryHookResult = ReturnType<typeof useJobLazyQuery>;
export type JobQueryResult = ApolloReactCommon.QueryResult<JobQuery, JobQueryVariables>;
export const CreateJobDocument = gql`
    mutation createJob($input: JobInput!) {
  createJob(input: $input) {
    _id
  }
}
    `;
export type CreateJobMutationFn = ApolloReactCommon.MutationFunction<CreateJobMutation, CreateJobMutationVariables>;
export type CreateJobComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateJobMutation, CreateJobMutationVariables>, 'mutation'>;

    export const CreateJobComponent = (props: CreateJobComponentProps) => (
      <ApolloReactComponents.Mutation<CreateJobMutation, CreateJobMutationVariables> mutation={CreateJobDocument} {...props} />
    );
    
export type CreateJobProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateJobMutation, CreateJobMutationVariables>
    } & TChildProps;
export function withCreateJob<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateJobMutation,
  CreateJobMutationVariables,
  CreateJobProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateJobMutation, CreateJobMutationVariables, CreateJobProps<TChildProps, TDataName>>(CreateJobDocument, {
      alias: 'createJob',
      ...operationOptions
    });
};

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateJobMutation, CreateJobMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, baseOptions);
      }
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = ApolloReactCommon.MutationResult<CreateJobMutation>;
export type CreateJobMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateJobMutation, CreateJobMutationVariables>;
export const RemoveJobDocument = gql`
    mutation removeJob($_id: ObjID!) {
  removeJob(_id: $_id) {
    n
    ok
  }
}
    `;
export type RemoveJobMutationFn = ApolloReactCommon.MutationFunction<RemoveJobMutation, RemoveJobMutationVariables>;
export type RemoveJobComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveJobMutation, RemoveJobMutationVariables>, 'mutation'>;

    export const RemoveJobComponent = (props: RemoveJobComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveJobMutation, RemoveJobMutationVariables> mutation={RemoveJobDocument} {...props} />
    );
    
export type RemoveJobProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveJobMutation, RemoveJobMutationVariables>
    } & TChildProps;
export function withRemoveJob<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveJobMutation,
  RemoveJobMutationVariables,
  RemoveJobProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveJobMutation, RemoveJobMutationVariables, RemoveJobProps<TChildProps, TDataName>>(RemoveJobDocument, {
      alias: 'removeJob',
      ...operationOptions
    });
};

/**
 * __useRemoveJobMutation__
 *
 * To run a mutation, you first call `useRemoveJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeJobMutation, { data, loading, error }] = useRemoveJobMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveJobMutation, RemoveJobMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveJobMutation, RemoveJobMutationVariables>(RemoveJobDocument, baseOptions);
      }
export type RemoveJobMutationHookResult = ReturnType<typeof useRemoveJobMutation>;
export type RemoveJobMutationResult = ApolloReactCommon.MutationResult<RemoveJobMutation>;
export type RemoveJobMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveJobMutation, RemoveJobMutationVariables>;
export const UpdateJobDocument = gql`
    mutation updateJob($_id: ObjID!, $input: JobInput!) {
  updateJob(_id: $_id, input: $input) {
    _id
    updatedAt
  }
}
    `;
export type UpdateJobMutationFn = ApolloReactCommon.MutationFunction<UpdateJobMutation, UpdateJobMutationVariables>;
export type UpdateJobComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateJobMutation, UpdateJobMutationVariables>, 'mutation'>;

    export const UpdateJobComponent = (props: UpdateJobComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateJobMutation, UpdateJobMutationVariables> mutation={UpdateJobDocument} {...props} />
    );
    
export type UpdateJobProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateJobMutation, UpdateJobMutationVariables>
    } & TChildProps;
export function withUpdateJob<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateJobMutation,
  UpdateJobMutationVariables,
  UpdateJobProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateJobMutation, UpdateJobMutationVariables, UpdateJobProps<TChildProps, TDataName>>(UpdateJobDocument, {
      alias: 'updateJob',
      ...operationOptions
    });
};

/**
 * __useUpdateJobMutation__
 *
 * To run a mutation, you first call `useUpdateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobMutation, { data, loading, error }] = useUpdateJobMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateJobMutation, UpdateJobMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateJobMutation, UpdateJobMutationVariables>(UpdateJobDocument, baseOptions);
      }
export type UpdateJobMutationHookResult = ReturnType<typeof useUpdateJobMutation>;
export type UpdateJobMutationResult = ApolloReactCommon.MutationResult<UpdateJobMutation>;
export type UpdateJobMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateJobMutation, UpdateJobMutationVariables>;
export const JobsDocument = gql`
    query jobs($workOrderId: ObjID, $userId: ObjID) {
  jobs(workOrderId: $workOrderId, userId: $userId) {
    _id
    cost
    description
    userId
    user {
      firstName
      lastName
      email
    }
    internalNotes
    jobNumber
    price
    quantity
    status
    statusNotes
    title
    type
    unitPrice
    workOrderId
    promisedAt
    completedAt
    createdAt
    updatedAt
  }
}
    `;
export type JobsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<JobsQuery, JobsQueryVariables>, 'query'>;

    export const JobsComponent = (props: JobsComponentProps) => (
      <ApolloReactComponents.Query<JobsQuery, JobsQueryVariables> query={JobsDocument} {...props} />
    );
    
export type JobsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<JobsQuery, JobsQueryVariables>
    } & TChildProps;
export function withJobs<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  JobsQuery,
  JobsQueryVariables,
  JobsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, JobsQuery, JobsQueryVariables, JobsProps<TChildProps, TDataName>>(JobsDocument, {
      alias: 'jobs',
      ...operationOptions
    });
};

/**
 * __useJobsQuery__
 *
 * To run a query within a React component, call `useJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useJobsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobsQuery, JobsQueryVariables>) {
        return ApolloReactHooks.useQuery<JobsQuery, JobsQueryVariables>(JobsDocument, baseOptions);
      }
export function useJobsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobsQuery, JobsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobsQuery, JobsQueryVariables>(JobsDocument, baseOptions);
        }
export type JobsQueryHookResult = ReturnType<typeof useJobsQuery>;
export type JobsLazyQueryHookResult = ReturnType<typeof useJobsLazyQuery>;
export type JobsQueryResult = ApolloReactCommon.QueryResult<JobsQuery, JobsQueryVariables>;
export const LoginWithPasswordDocument = gql`
    mutation loginWithPassword($email: String!, $password: String!) {
  loginWithPassword(email: $email, password: $password) {
    token
  }
}
    `;
export type LoginWithPasswordMutationFn = ApolloReactCommon.MutationFunction<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>;
export type LoginWithPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>, 'mutation'>;

    export const LoginWithPasswordComponent = (props: LoginWithPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<LoginWithPasswordMutation, LoginWithPasswordMutationVariables> mutation={LoginWithPasswordDocument} {...props} />
    );
    
export type LoginWithPasswordProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>
    } & TChildProps;
export function withLoginWithPassword<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoginWithPasswordMutation,
  LoginWithPasswordMutationVariables,
  LoginWithPasswordProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, LoginWithPasswordMutation, LoginWithPasswordMutationVariables, LoginWithPasswordProps<TChildProps, TDataName>>(LoginWithPasswordDocument, {
      alias: 'loginWithPassword',
      ...operationOptions
    });
};

/**
 * __useLoginWithPasswordMutation__
 *
 * To run a mutation, you first call `useLoginWithPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithPasswordMutation, { data, loading, error }] = useLoginWithPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginWithPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>(LoginWithPasswordDocument, baseOptions);
      }
export type LoginWithPasswordMutationHookResult = ReturnType<typeof useLoginWithPasswordMutation>;
export type LoginWithPasswordMutationResult = ApolloReactCommon.MutationResult<LoginWithPasswordMutation>;
export type LoginWithPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>;
export const RemoveOrderDocument = gql`
    mutation removeOrder($_id: ObjID!) {
  removeOrder(_id: $_id) {
    n
    ok
  }
}
    `;
export type RemoveOrderMutationFn = ApolloReactCommon.MutationFunction<RemoveOrderMutation, RemoveOrderMutationVariables>;
export type RemoveOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveOrderMutation, RemoveOrderMutationVariables>, 'mutation'>;

    export const RemoveOrderComponent = (props: RemoveOrderComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveOrderMutation, RemoveOrderMutationVariables> mutation={RemoveOrderDocument} {...props} />
    );
    
export type RemoveOrderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveOrderMutation, RemoveOrderMutationVariables>
    } & TChildProps;
export function withRemoveOrder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveOrderMutation,
  RemoveOrderMutationVariables,
  RemoveOrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveOrderMutation, RemoveOrderMutationVariables, RemoveOrderProps<TChildProps, TDataName>>(RemoveOrderDocument, {
      alias: 'removeOrder',
      ...operationOptions
    });
};

/**
 * __useRemoveOrderMutation__
 *
 * To run a mutation, you first call `useRemoveOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrderMutation, { data, loading, error }] = useRemoveOrderMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveOrderMutation, RemoveOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveOrderMutation, RemoveOrderMutationVariables>(RemoveOrderDocument, baseOptions);
      }
export type RemoveOrderMutationHookResult = ReturnType<typeof useRemoveOrderMutation>;
export type RemoveOrderMutationResult = ApolloReactCommon.MutationResult<RemoveOrderMutation>;
export type RemoveOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveOrderMutation, RemoveOrderMutationVariables>;
export const OrderDocument = gql`
    query order($_id: ObjID!) {
  order(_id: $_id) {
    _id
    number
    isBankWire
    status
    total
    totalPayments
    tax
    hasCCFee
    balance
    subtotal
    shipping
    referral {
      _id
      name
    }
    userId
    user {
      _id
      firstName
      lastName
      email
      billingAddress {
        phone
      }
      shippingAddress {
        address1
        city
        state
        country
        phone
      }
    }
    items {
      title
      sku
      type
      notes
      quantity
      price
      productId
      vendorId
    }
    invoiceId
    createdAt
    updatedAt
  }
}
    `;
export type OrderComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrderQuery, OrderQueryVariables>, 'query'> & ({ variables: OrderQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrderComponent = (props: OrderComponentProps) => (
      <ApolloReactComponents.Query<OrderQuery, OrderQueryVariables> query={OrderDocument} {...props} />
    );
    
export type OrderProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrderQuery, OrderQueryVariables>
    } & TChildProps;
export function withOrder<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrderQuery,
  OrderQueryVariables,
  OrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrderQuery, OrderQueryVariables, OrderProps<TChildProps, TDataName>>(OrderDocument, {
      alias: 'order',
      ...operationOptions
    });
};

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, baseOptions);
      }
export function useOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, baseOptions);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = ApolloReactCommon.QueryResult<OrderQuery, OrderQueryVariables>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($_id: ObjID!, $input: OrderUpdateInput!) {
  updateOrder(_id: $_id, input: $input) {
    _id
    updatedAt
  }
}
    `;
export type UpdateOrderMutationFn = ApolloReactCommon.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;
export type UpdateOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrderMutation, UpdateOrderMutationVariables>, 'mutation'>;

    export const UpdateOrderComponent = (props: UpdateOrderComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrderMutation, UpdateOrderMutationVariables> mutation={UpdateOrderDocument} {...props} />
    );
    
export type UpdateOrderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>
    } & TChildProps;
export function withUpdateOrder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrderMutation,
  UpdateOrderMutationVariables,
  UpdateOrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrderMutation, UpdateOrderMutationVariables, UpdateOrderProps<TChildProps, TDataName>>(UpdateOrderDocument, {
      alias: 'updateOrder',
      ...operationOptions
    });
};

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, baseOptions);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = ApolloReactCommon.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const InvoiceSearchDocument = gql`
    query invoiceSearch($startDate: DateTime, $endDate: DateTime) {
  invoiceSearch(startDate: $startDate, endDate: $endDate) {
    _id
    userId
    user {
      firstName
      lastName
      email
      __typename
      shippingAddress {
        state
        __typename
      }
    }
    workOrderId
    orderId
    items {
      productId
      title
      unitPrice
      quantity
      price
      createdAt
      __typename
    }
    shipping
    tax
    createdAt
    order {
      shippingAddress {
        state
        __typename
      }
      __typename
    }
    user {
      shippingAddress {
        state
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;
export type InvoiceSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InvoiceSearchQuery, InvoiceSearchQueryVariables>, 'query'>;

    export const InvoiceSearchComponent = (props: InvoiceSearchComponentProps) => (
      <ApolloReactComponents.Query<InvoiceSearchQuery, InvoiceSearchQueryVariables> query={InvoiceSearchDocument} {...props} />
    );
    
export type InvoiceSearchProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InvoiceSearchQuery, InvoiceSearchQueryVariables>
    } & TChildProps;
export function withInvoiceSearch<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InvoiceSearchQuery,
  InvoiceSearchQueryVariables,
  InvoiceSearchProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, InvoiceSearchQuery, InvoiceSearchQueryVariables, InvoiceSearchProps<TChildProps, TDataName>>(InvoiceSearchDocument, {
      alias: 'invoiceSearch',
      ...operationOptions
    });
};

/**
 * __useInvoiceSearchQuery__
 *
 * To run a query within a React component, call `useInvoiceSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceSearchQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInvoiceSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvoiceSearchQuery, InvoiceSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<InvoiceSearchQuery, InvoiceSearchQueryVariables>(InvoiceSearchDocument, baseOptions);
      }
export function useInvoiceSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvoiceSearchQuery, InvoiceSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvoiceSearchQuery, InvoiceSearchQueryVariables>(InvoiceSearchDocument, baseOptions);
        }
export type InvoiceSearchQueryHookResult = ReturnType<typeof useInvoiceSearchQuery>;
export type InvoiceSearchLazyQueryHookResult = ReturnType<typeof useInvoiceSearchLazyQuery>;
export type InvoiceSearchQueryResult = ApolloReactCommon.QueryResult<InvoiceSearchQuery, InvoiceSearchQueryVariables>;
export const OrdersDocument = gql`
    query orders {
  orders {
    _id
    number
    isBankWire
    status
    total
    user {
      _id
      email
      firstName
      lastName
    }
    createdAt
  }
}
    `;
export type OrdersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrdersQuery, OrdersQueryVariables>, 'query'>;

    export const OrdersComponent = (props: OrdersComponentProps) => (
      <ApolloReactComponents.Query<OrdersQuery, OrdersQueryVariables> query={OrdersDocument} {...props} />
    );
    
export type OrdersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrdersQuery, OrdersQueryVariables>
    } & TChildProps;
export function withOrders<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrdersQuery,
  OrdersQueryVariables,
  OrdersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrdersQuery, OrdersQueryVariables, OrdersProps<TChildProps, TDataName>>(OrdersDocument, {
      alias: 'orders',
      ...operationOptions
    });
};

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
      }
export function useOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = ApolloReactCommon.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const RemovePaymentDocument = gql`
    mutation removePayment($_id: ObjID!) {
  removePayment(_id: $_id) {
    n
    ok
  }
}
    `;
export type RemovePaymentMutationFn = ApolloReactCommon.MutationFunction<RemovePaymentMutation, RemovePaymentMutationVariables>;
export type RemovePaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemovePaymentMutation, RemovePaymentMutationVariables>, 'mutation'>;

    export const RemovePaymentComponent = (props: RemovePaymentComponentProps) => (
      <ApolloReactComponents.Mutation<RemovePaymentMutation, RemovePaymentMutationVariables> mutation={RemovePaymentDocument} {...props} />
    );
    
export type RemovePaymentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemovePaymentMutation, RemovePaymentMutationVariables>
    } & TChildProps;
export function withRemovePayment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemovePaymentMutation,
  RemovePaymentMutationVariables,
  RemovePaymentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemovePaymentMutation, RemovePaymentMutationVariables, RemovePaymentProps<TChildProps, TDataName>>(RemovePaymentDocument, {
      alias: 'removePayment',
      ...operationOptions
    });
};

/**
 * __useRemovePaymentMutation__
 *
 * To run a mutation, you first call `useRemovePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePaymentMutation, { data, loading, error }] = useRemovePaymentMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemovePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePaymentMutation, RemovePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePaymentMutation, RemovePaymentMutationVariables>(RemovePaymentDocument, baseOptions);
      }
export type RemovePaymentMutationHookResult = ReturnType<typeof useRemovePaymentMutation>;
export type RemovePaymentMutationResult = ApolloReactCommon.MutationResult<RemovePaymentMutation>;
export type RemovePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePaymentMutation, RemovePaymentMutationVariables>;
export const PaymentTypesDocument = gql`
    query paymentTypes {
  paymentTypes {
    _id
    name
  }
}
    `;
export type PaymentTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PaymentTypesQuery, PaymentTypesQueryVariables>, 'query'>;

    export const PaymentTypesComponent = (props: PaymentTypesComponentProps) => (
      <ApolloReactComponents.Query<PaymentTypesQuery, PaymentTypesQueryVariables> query={PaymentTypesDocument} {...props} />
    );
    
export type PaymentTypesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PaymentTypesQuery, PaymentTypesQueryVariables>
    } & TChildProps;
export function withPaymentTypes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PaymentTypesQuery,
  PaymentTypesQueryVariables,
  PaymentTypesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PaymentTypesQuery, PaymentTypesQueryVariables, PaymentTypesProps<TChildProps, TDataName>>(PaymentTypesDocument, {
      alias: 'paymentTypes',
      ...operationOptions
    });
};

/**
 * __usePaymentTypesQuery__
 *
 * To run a query within a React component, call `usePaymentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentTypesQuery, PaymentTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentTypesQuery, PaymentTypesQueryVariables>(PaymentTypesDocument, baseOptions);
      }
export function usePaymentTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentTypesQuery, PaymentTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentTypesQuery, PaymentTypesQueryVariables>(PaymentTypesDocument, baseOptions);
        }
export type PaymentTypesQueryHookResult = ReturnType<typeof usePaymentTypesQuery>;
export type PaymentTypesLazyQueryHookResult = ReturnType<typeof usePaymentTypesLazyQuery>;
export type PaymentTypesQueryResult = ApolloReactCommon.QueryResult<PaymentTypesQuery, PaymentTypesQueryVariables>;
export const PaymentsDocument = gql`
    query payments($orderId: ObjID, $workOrderId: ObjID, $userId: ObjID) {
  payments(orderId: $orderId, workOrderId: $workOrderId, userId: $userId) {
    _id
    amount
    paymentType
    tax
    userId
    user {
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export type PaymentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PaymentsQuery, PaymentsQueryVariables>, 'query'>;

    export const PaymentsComponent = (props: PaymentsComponentProps) => (
      <ApolloReactComponents.Query<PaymentsQuery, PaymentsQueryVariables> query={PaymentsDocument} {...props} />
    );
    
export type PaymentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PaymentsQuery, PaymentsQueryVariables>
    } & TChildProps;
export function withPayments<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PaymentsQuery,
  PaymentsQueryVariables,
  PaymentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PaymentsQuery, PaymentsQueryVariables, PaymentsProps<TChildProps, TDataName>>(PaymentsDocument, {
      alias: 'payments',
      ...operationOptions
    });
};

/**
 * __usePaymentsQuery__
 *
 * To run a query within a React component, call `usePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      workOrderId: // value for 'workOrderId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePaymentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, baseOptions);
      }
export function usePaymentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, baseOptions);
        }
export type PaymentsQueryHookResult = ReturnType<typeof usePaymentsQuery>;
export type PaymentsLazyQueryHookResult = ReturnType<typeof usePaymentsLazyQuery>;
export type PaymentsQueryResult = ApolloReactCommon.QueryResult<PaymentsQuery, PaymentsQueryVariables>;
export const ProductAttributesDocument = gql`
    query productAttributes {
  clarities(orderBy: [{order: desc}]) {
    id
    label
    value
  }
  colors(orderBy: [{order: asc}]) {
    id
    label
    value
  }
  culets {
    id
    label
    value
  }
  cuts(orderBy: [{order: desc}]) {
    id
    label
    value
  }
  fluorescences(orderBy: [{order: desc}]) {
    id
    label
    value
  }
  girdles {
    id
    label
    value
  }
  lightPerformances {
    id
    label
    value
  }
  paves {
    id
    label
    value
  }
  polishes(orderBy: [{order: desc}]) {
    id
    label
    value
  }
  symmetries(orderBy: [{order: desc}]) {
    id
    label
    value
  }
  metals(orderBy: [{order: asc}]) {
    id
    value
    label
    order
  }
  ringTypes(orderBy: [{order: asc}]) {
    id
    value
    label
    order
  }
  bandTypes(orderBy: [{order: asc}]) {
    id
    value
    label
    order
  }
  diamondShapes(orderBy: [{order: asc}]) {
    id
    label
    order
    value
  }
}
    `;
export type ProductAttributesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductAttributesQuery, ProductAttributesQueryVariables>, 'query'>;

    export const ProductAttributesComponent = (props: ProductAttributesComponentProps) => (
      <ApolloReactComponents.Query<ProductAttributesQuery, ProductAttributesQueryVariables> query={ProductAttributesDocument} {...props} />
    );
    
export type ProductAttributesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProductAttributesQuery, ProductAttributesQueryVariables>
    } & TChildProps;
export function withProductAttributes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProductAttributesQuery,
  ProductAttributesQueryVariables,
  ProductAttributesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProductAttributesQuery, ProductAttributesQueryVariables, ProductAttributesProps<TChildProps, TDataName>>(ProductAttributesDocument, {
      alias: 'productAttributes',
      ...operationOptions
    });
};

/**
 * __useProductAttributesQuery__
 *
 * To run a query within a React component, call `useProductAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductAttributesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductAttributesQuery, ProductAttributesQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductAttributesQuery, ProductAttributesQueryVariables>(ProductAttributesDocument, baseOptions);
      }
export function useProductAttributesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductAttributesQuery, ProductAttributesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductAttributesQuery, ProductAttributesQueryVariables>(ProductAttributesDocument, baseOptions);
        }
export type ProductAttributesQueryHookResult = ReturnType<typeof useProductAttributesQuery>;
export type ProductAttributesLazyQueryHookResult = ReturnType<typeof useProductAttributesLazyQuery>;
export type ProductAttributesQueryResult = ApolloReactCommon.QueryResult<ProductAttributesQuery, ProductAttributesQueryVariables>;
export const ProductTypesDocument = gql`
    query productTypes {
  productTypes {
    _id: id
    id
    label
    order
  }
}
    `;
export type ProductTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductTypesQuery, ProductTypesQueryVariables>, 'query'>;

    export const ProductTypesComponent = (props: ProductTypesComponentProps) => (
      <ApolloReactComponents.Query<ProductTypesQuery, ProductTypesQueryVariables> query={ProductTypesDocument} {...props} />
    );
    
export type ProductTypesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProductTypesQuery, ProductTypesQueryVariables>
    } & TChildProps;
export function withProductTypes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProductTypesQuery,
  ProductTypesQueryVariables,
  ProductTypesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProductTypesQuery, ProductTypesQueryVariables, ProductTypesProps<TChildProps, TDataName>>(ProductTypesDocument, {
      alias: 'productTypes',
      ...operationOptions
    });
};

/**
 * __useProductTypesQuery__
 *
 * To run a query within a React component, call `useProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductTypesQuery, ProductTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductTypesQuery, ProductTypesQueryVariables>(ProductTypesDocument, baseOptions);
      }
export function useProductTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductTypesQuery, ProductTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductTypesQuery, ProductTypesQueryVariables>(ProductTypesDocument, baseOptions);
        }
export type ProductTypesQueryHookResult = ReturnType<typeof useProductTypesQuery>;
export type ProductTypesLazyQueryHookResult = ReturnType<typeof useProductTypesLazyQuery>;
export type ProductTypesQueryResult = ApolloReactCommon.QueryResult<ProductTypesQuery, ProductTypesQueryVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($data: ProductUpdateInput!, $where: ProductWhereUniqueInput!) {
  updateProduct(where: $where, data: $data) {
    id
    updatedAt
  }
}
    `;
export type UpdateProductMutationFn = ApolloReactCommon.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;
export type UpdateProductComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProductMutation, UpdateProductMutationVariables>, 'mutation'>;

    export const UpdateProductComponent = (props: UpdateProductComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProductMutation, UpdateProductMutationVariables> mutation={UpdateProductDocument} {...props} />
    );
    
export type UpdateProductProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>
    } & TChildProps;
export function withUpdateProduct<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateProductMutation,
  UpdateProductMutationVariables,
  UpdateProductProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateProductMutation, UpdateProductMutationVariables, UpdateProductProps<TChildProps, TDataName>>(UpdateProductDocument, {
      alias: 'updateProduct',
      ...operationOptions
    });
};

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = ApolloReactCommon.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const CollectionsDocument = gql`
    query collections($where: CollectionWhereInput, $first: Int, $after: CollectionWhereUniqueInput, $orderBy: [CollectionOrderByInput!]) {
  collections(where: $where, first: $first, after: $after, orderBy: $orderBy) {
    id
    name
    slug
    description
    showInCatalog
    products {
      id
    }
    createdAt
    updatedAt
  }
}
    `;
export type CollectionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CollectionsQuery, CollectionsQueryVariables>, 'query'>;

    export const CollectionsComponent = (props: CollectionsComponentProps) => (
      <ApolloReactComponents.Query<CollectionsQuery, CollectionsQueryVariables> query={CollectionsDocument} {...props} />
    );
    
export type CollectionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CollectionsQuery, CollectionsQueryVariables>
    } & TChildProps;
export function withCollections<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CollectionsQuery,
  CollectionsQueryVariables,
  CollectionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CollectionsQuery, CollectionsQueryVariables, CollectionsProps<TChildProps, TDataName>>(CollectionsDocument, {
      alias: 'collections',
      ...operationOptions
    });
};

/**
 * __useCollectionsQuery__
 *
 * To run a query within a React component, call `useCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCollectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, baseOptions);
      }
export function useCollectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, baseOptions);
        }
export type CollectionsQueryHookResult = ReturnType<typeof useCollectionsQuery>;
export type CollectionsLazyQueryHookResult = ReturnType<typeof useCollectionsLazyQuery>;
export type CollectionsQueryResult = ApolloReactCommon.QueryResult<CollectionsQuery, CollectionsQueryVariables>;
export const CollectionDocument = gql`
    query collection($where: CollectionWhereUniqueInput!) {
  collection(where: $where) {
    id
    name
    slug
    description
    showInCatalog
    products {
      id
      sku
      name
      representativeImage
    }
    createdAt
    updatedAt
  }
}
    `;
export type CollectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CollectionQuery, CollectionQueryVariables>, 'query'> & ({ variables: CollectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CollectionComponent = (props: CollectionComponentProps) => (
      <ApolloReactComponents.Query<CollectionQuery, CollectionQueryVariables> query={CollectionDocument} {...props} />
    );
    
export type CollectionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CollectionQuery, CollectionQueryVariables>
    } & TChildProps;
export function withCollection<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CollectionQuery,
  CollectionQueryVariables,
  CollectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CollectionQuery, CollectionQueryVariables, CollectionProps<TChildProps, TDataName>>(CollectionDocument, {
      alias: 'collection',
      ...operationOptions
    });
};

/**
 * __useCollectionQuery__
 *
 * To run a query within a React component, call `useCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCollectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionQuery, CollectionQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionQuery, CollectionQueryVariables>(CollectionDocument, baseOptions);
      }
export function useCollectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionQuery, CollectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionQuery, CollectionQueryVariables>(CollectionDocument, baseOptions);
        }
export type CollectionQueryHookResult = ReturnType<typeof useCollectionQuery>;
export type CollectionLazyQueryHookResult = ReturnType<typeof useCollectionLazyQuery>;
export type CollectionQueryResult = ApolloReactCommon.QueryResult<CollectionQuery, CollectionQueryVariables>;
export const CreateCollectionDocument = gql`
    mutation createCollection($data: CollectionCreateInput!) {
  createCollection(data: $data) {
    id
  }
}
    `;
export type CreateCollectionMutationFn = ApolloReactCommon.MutationFunction<CreateCollectionMutation, CreateCollectionMutationVariables>;
export type CreateCollectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCollectionMutation, CreateCollectionMutationVariables>, 'mutation'>;

    export const CreateCollectionComponent = (props: CreateCollectionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCollectionMutation, CreateCollectionMutationVariables> mutation={CreateCollectionDocument} {...props} />
    );
    
export type CreateCollectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateCollectionMutation, CreateCollectionMutationVariables>
    } & TChildProps;
export function withCreateCollection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCollectionMutation,
  CreateCollectionMutationVariables,
  CreateCollectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCollectionMutation, CreateCollectionMutationVariables, CreateCollectionProps<TChildProps, TDataName>>(CreateCollectionDocument, {
      alias: 'createCollection',
      ...operationOptions
    });
};

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCollectionMutation, CreateCollectionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCollectionMutation, CreateCollectionMutationVariables>(CreateCollectionDocument, baseOptions);
      }
export type CreateCollectionMutationHookResult = ReturnType<typeof useCreateCollectionMutation>;
export type CreateCollectionMutationResult = ApolloReactCommon.MutationResult<CreateCollectionMutation>;
export type CreateCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCollectionMutation, CreateCollectionMutationVariables>;
export const UpdateCollectionDocument = gql`
    mutation updateCollection($where: CollectionWhereUniqueInput!, $data: CollectionUpdateInput!) {
  updateCollection(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateCollectionMutationFn = ApolloReactCommon.MutationFunction<UpdateCollectionMutation, UpdateCollectionMutationVariables>;
export type UpdateCollectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>, 'mutation'>;

    export const UpdateCollectionComponent = (props: UpdateCollectionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCollectionMutation, UpdateCollectionMutationVariables> mutation={UpdateCollectionDocument} {...props} />
    );
    
export type UpdateCollectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateCollectionMutation, UpdateCollectionMutationVariables>
    } & TChildProps;
export function withUpdateCollection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables,
  UpdateCollectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCollectionMutation, UpdateCollectionMutationVariables, UpdateCollectionProps<TChildProps, TDataName>>(UpdateCollectionDocument, {
      alias: 'updateCollection',
      ...operationOptions
    });
};

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMutation, { data, loading, error }] = useUpdateCollectionMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCollectionMutation, UpdateCollectionMutationVariables>(UpdateCollectionDocument, baseOptions);
      }
export type UpdateCollectionMutationHookResult = ReturnType<typeof useUpdateCollectionMutation>;
export type UpdateCollectionMutationResult = ApolloReactCommon.MutationResult<UpdateCollectionMutation>;
export type UpdateCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>;
export const DeleteCollectionDocument = gql`
    mutation deleteCollection($where: CollectionWhereUniqueInput!) {
  deleteCollection(where: $where) {
    id
  }
}
    `;
export type DeleteCollectionMutationFn = ApolloReactCommon.MutationFunction<DeleteCollectionMutation, DeleteCollectionMutationVariables>;
export type DeleteCollectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>, 'mutation'>;

    export const DeleteCollectionComponent = (props: DeleteCollectionComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCollectionMutation, DeleteCollectionMutationVariables> mutation={DeleteCollectionDocument} {...props} />
    );
    
export type DeleteCollectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteCollectionMutation, DeleteCollectionMutationVariables>
    } & TChildProps;
export function withDeleteCollection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteCollectionMutation,
  DeleteCollectionMutationVariables,
  DeleteCollectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteCollectionMutation, DeleteCollectionMutationVariables, DeleteCollectionProps<TChildProps, TDataName>>(DeleteCollectionDocument, {
      alias: 'deleteCollection',
      ...operationOptions
    });
};

/**
 * __useDeleteCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionMutation, { data, loading, error }] = useDeleteCollectionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCollectionMutation, DeleteCollectionMutationVariables>(DeleteCollectionDocument, baseOptions);
      }
export type DeleteCollectionMutationHookResult = ReturnType<typeof useDeleteCollectionMutation>;
export type DeleteCollectionMutationResult = ApolloReactCommon.MutationResult<DeleteCollectionMutation>;
export type DeleteCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation createCategory($data: CategoryCreateInput!) {
  createCategory(data: $data) {
    id
  }
}
    `;
export type CreateCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;
export type CreateCategoryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCategoryMutation, CreateCategoryMutationVariables>, 'mutation'>;

    export const CreateCategoryComponent = (props: CreateCategoryComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCategoryMutation, CreateCategoryMutationVariables> mutation={CreateCategoryDocument} {...props} />
    );
    
export type CreateCategoryProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>
    } & TChildProps;
export function withCreateCategory<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCategoryMutation,
  CreateCategoryMutationVariables,
  CreateCategoryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCategoryMutation, CreateCategoryMutationVariables, CreateCategoryProps<TChildProps, TDataName>>(CreateCategoryDocument, {
      alias: 'createCategory',
      ...operationOptions
    });
};

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, baseOptions);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = ApolloReactCommon.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation updateCategory($where: CategoryWhereUniqueInput!, $data: CategoryUpdateInput!) {
  updateCategory(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export type UpdateCategoryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>, 'mutation'>;

    export const UpdateCategoryComponent = (props: UpdateCategoryComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCategoryMutation, UpdateCategoryMutationVariables> mutation={UpdateCategoryDocument} {...props} />
    );
    
export type UpdateCategoryProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>
    } & TChildProps;
export function withUpdateCategory<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables,
  UpdateCategoryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCategoryMutation, UpdateCategoryMutationVariables, UpdateCategoryProps<TChildProps, TDataName>>(UpdateCategoryDocument, {
      alias: 'updateCategory',
      ...operationOptions
    });
};

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, baseOptions);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($where: CategoryWhereUniqueInput!) {
  deleteCategory(where: $where) {
    id
  }
}
    `;
export type DeleteCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export type DeleteCategoryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>, 'mutation'>;

    export const DeleteCategoryComponent = (props: DeleteCategoryComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCategoryMutation, DeleteCategoryMutationVariables> mutation={DeleteCategoryDocument} {...props} />
    );
    
export type DeleteCategoryProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>
    } & TChildProps;
export function withDeleteCategory<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables,
  DeleteCategoryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteCategoryMutation, DeleteCategoryMutationVariables, DeleteCategoryProps<TChildProps, TDataName>>(DeleteCategoryDocument, {
      alias: 'deleteCategory',
      ...operationOptions
    });
};

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, baseOptions);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const ExportProductsToFileDocument = gql`
    mutation exportProductsToFile {
  exportProductsToFile(name: "exportProductsToFile")
}
    `;
export type ExportProductsToFileMutationFn = ApolloReactCommon.MutationFunction<ExportProductsToFileMutation, ExportProductsToFileMutationVariables>;
export type ExportProductsToFileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ExportProductsToFileMutation, ExportProductsToFileMutationVariables>, 'mutation'>;

    export const ExportProductsToFileComponent = (props: ExportProductsToFileComponentProps) => (
      <ApolloReactComponents.Mutation<ExportProductsToFileMutation, ExportProductsToFileMutationVariables> mutation={ExportProductsToFileDocument} {...props} />
    );
    
export type ExportProductsToFileProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ExportProductsToFileMutation, ExportProductsToFileMutationVariables>
    } & TChildProps;
export function withExportProductsToFile<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ExportProductsToFileMutation,
  ExportProductsToFileMutationVariables,
  ExportProductsToFileProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ExportProductsToFileMutation, ExportProductsToFileMutationVariables, ExportProductsToFileProps<TChildProps, TDataName>>(ExportProductsToFileDocument, {
      alias: 'exportProductsToFile',
      ...operationOptions
    });
};

/**
 * __useExportProductsToFileMutation__
 *
 * To run a mutation, you first call `useExportProductsToFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportProductsToFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportProductsToFileMutation, { data, loading, error }] = useExportProductsToFileMutation({
 *   variables: {
 *   },
 * });
 */
export function useExportProductsToFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExportProductsToFileMutation, ExportProductsToFileMutationVariables>) {
        return ApolloReactHooks.useMutation<ExportProductsToFileMutation, ExportProductsToFileMutationVariables>(ExportProductsToFileDocument, baseOptions);
      }
export type ExportProductsToFileMutationHookResult = ReturnType<typeof useExportProductsToFileMutation>;
export type ExportProductsToFileMutationResult = ApolloReactCommon.MutationResult<ExportProductsToFileMutation>;
export type ExportProductsToFileMutationOptions = ApolloReactCommon.BaseMutationOptions<ExportProductsToFileMutation, ExportProductsToFileMutationVariables>;
export const UpdateProductsDocument = gql`
    mutation updateProducts($where: ProductWhereInput, $data: ProductUpdateManyMutationInput!) {
  updateProducts(where: $where, data: $data) {
    count
  }
}
    `;
export type UpdateProductsMutationFn = ApolloReactCommon.MutationFunction<UpdateProductsMutation, UpdateProductsMutationVariables>;
export type UpdateProductsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProductsMutation, UpdateProductsMutationVariables>, 'mutation'>;

    export const UpdateProductsComponent = (props: UpdateProductsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProductsMutation, UpdateProductsMutationVariables> mutation={UpdateProductsDocument} {...props} />
    );
    
export type UpdateProductsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateProductsMutation, UpdateProductsMutationVariables>
    } & TChildProps;
export function withUpdateProducts<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateProductsMutation,
  UpdateProductsMutationVariables,
  UpdateProductsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateProductsMutation, UpdateProductsMutationVariables, UpdateProductsProps<TChildProps, TDataName>>(UpdateProductsDocument, {
      alias: 'updateProducts',
      ...operationOptions
    });
};

/**
 * __useUpdateProductsMutation__
 *
 * To run a mutation, you first call `useUpdateProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductsMutation, { data, loading, error }] = useUpdateProductsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProductsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductsMutation, UpdateProductsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductsMutation, UpdateProductsMutationVariables>(UpdateProductsDocument, baseOptions);
      }
export type UpdateProductsMutationHookResult = ReturnType<typeof useUpdateProductsMutation>;
export type UpdateProductsMutationResult = ApolloReactCommon.MutationResult<UpdateProductsMutation>;
export type UpdateProductsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductsMutation, UpdateProductsMutationVariables>;
export const DeleteProductsDocument = gql`
    mutation deleteProducts($where: ProductWhereInput!) {
  deleteProducts(where: $where) {
    count
  }
}
    `;
export type DeleteProductsMutationFn = ApolloReactCommon.MutationFunction<DeleteProductsMutation, DeleteProductsMutationVariables>;
export type DeleteProductsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProductsMutation, DeleteProductsMutationVariables>, 'mutation'>;

    export const DeleteProductsComponent = (props: DeleteProductsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProductsMutation, DeleteProductsMutationVariables> mutation={DeleteProductsDocument} {...props} />
    );
    
export type DeleteProductsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteProductsMutation, DeleteProductsMutationVariables>
    } & TChildProps;
export function withDeleteProducts<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteProductsMutation,
  DeleteProductsMutationVariables,
  DeleteProductsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteProductsMutation, DeleteProductsMutationVariables, DeleteProductsProps<TChildProps, TDataName>>(DeleteProductsDocument, {
      alias: 'deleteProducts',
      ...operationOptions
    });
};

/**
 * __useDeleteProductsMutation__
 *
 * To run a mutation, you first call `useDeleteProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductsMutation, { data, loading, error }] = useDeleteProductsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteProductsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProductsMutation, DeleteProductsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProductsMutation, DeleteProductsMutationVariables>(DeleteProductsDocument, baseOptions);
      }
export type DeleteProductsMutationHookResult = ReturnType<typeof useDeleteProductsMutation>;
export type DeleteProductsMutationResult = ApolloReactCommon.MutationResult<DeleteProductsMutation>;
export type DeleteProductsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProductsMutation, DeleteProductsMutationVariables>;
export const QuoteDocument = gql`
    query quote($_id: ObjID!) {
  quote(_id: $_id) {
    _id
    userId
    user {
      firstName
      lastName
      email
      phone
    }
    number
    description
    items {
      title
      description
      unitPrice
      price
      quantity
      productId
      type
    }
    hasTax
    tax
    shipping
    subtotal
    total
    locked
    workOrder {
      _id
      createdAt
    }
    emails {
      _id
      sentAt
      createdAt
      updatedAt
    }
    validUntil
    createdByUser {
      firstName
      lastName
    }
    createdAt
    updatedAt
  }
}
    `;
export type QuoteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<QuoteQuery, QuoteQueryVariables>, 'query'> & ({ variables: QuoteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const QuoteComponent = (props: QuoteComponentProps) => (
      <ApolloReactComponents.Query<QuoteQuery, QuoteQueryVariables> query={QuoteDocument} {...props} />
    );
    
export type QuoteProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QuoteQuery, QuoteQueryVariables>
    } & TChildProps;
export function withQuote<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuoteQuery,
  QuoteQueryVariables,
  QuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QuoteQuery, QuoteQueryVariables, QuoteProps<TChildProps, TDataName>>(QuoteDocument, {
      alias: 'quote',
      ...operationOptions
    });
};

/**
 * __useQuoteQuery__
 *
 * To run a query within a React component, call `useQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useQuoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QuoteQuery, QuoteQueryVariables>) {
        return ApolloReactHooks.useQuery<QuoteQuery, QuoteQueryVariables>(QuoteDocument, baseOptions);
      }
export function useQuoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuoteQuery, QuoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuoteQuery, QuoteQueryVariables>(QuoteDocument, baseOptions);
        }
export type QuoteQueryHookResult = ReturnType<typeof useQuoteQuery>;
export type QuoteLazyQueryHookResult = ReturnType<typeof useQuoteLazyQuery>;
export type QuoteQueryResult = ApolloReactCommon.QueryResult<QuoteQuery, QuoteQueryVariables>;
export const CreateQuoteDocument = gql`
    mutation createQuote($input: QuoteInput!) {
  createQuote(input: $input) {
    _id
  }
}
    `;
export type CreateQuoteMutationFn = ApolloReactCommon.MutationFunction<CreateQuoteMutation, CreateQuoteMutationVariables>;
export type CreateQuoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateQuoteMutation, CreateQuoteMutationVariables>, 'mutation'>;

    export const CreateQuoteComponent = (props: CreateQuoteComponentProps) => (
      <ApolloReactComponents.Mutation<CreateQuoteMutation, CreateQuoteMutationVariables> mutation={CreateQuoteDocument} {...props} />
    );
    
export type CreateQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateQuoteMutation, CreateQuoteMutationVariables>
    } & TChildProps;
export function withCreateQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateQuoteMutation,
  CreateQuoteMutationVariables,
  CreateQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateQuoteMutation, CreateQuoteMutationVariables, CreateQuoteProps<TChildProps, TDataName>>(CreateQuoteDocument, {
      alias: 'createQuote',
      ...operationOptions
    });
};

/**
 * __useCreateQuoteMutation__
 *
 * To run a mutation, you first call `useCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteMutation, { data, loading, error }] = useCreateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateQuoteMutation, CreateQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateQuoteMutation, CreateQuoteMutationVariables>(CreateQuoteDocument, baseOptions);
      }
export type CreateQuoteMutationHookResult = ReturnType<typeof useCreateQuoteMutation>;
export type CreateQuoteMutationResult = ApolloReactCommon.MutationResult<CreateQuoteMutation>;
export type CreateQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateQuoteMutation, CreateQuoteMutationVariables>;
export const RemoveQuoteDocument = gql`
    mutation removeQuote($_id: ObjID!) {
  removeQuote(_id: $_id) {
    n
    ok
  }
}
    `;
export type RemoveQuoteMutationFn = ApolloReactCommon.MutationFunction<RemoveQuoteMutation, RemoveQuoteMutationVariables>;
export type RemoveQuoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveQuoteMutation, RemoveQuoteMutationVariables>, 'mutation'>;

    export const RemoveQuoteComponent = (props: RemoveQuoteComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveQuoteMutation, RemoveQuoteMutationVariables> mutation={RemoveQuoteDocument} {...props} />
    );
    
export type RemoveQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveQuoteMutation, RemoveQuoteMutationVariables>
    } & TChildProps;
export function withRemoveQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveQuoteMutation,
  RemoveQuoteMutationVariables,
  RemoveQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveQuoteMutation, RemoveQuoteMutationVariables, RemoveQuoteProps<TChildProps, TDataName>>(RemoveQuoteDocument, {
      alias: 'removeQuote',
      ...operationOptions
    });
};

/**
 * __useRemoveQuoteMutation__
 *
 * To run a mutation, you first call `useRemoveQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQuoteMutation, { data, loading, error }] = useRemoveQuoteMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveQuoteMutation, RemoveQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveQuoteMutation, RemoveQuoteMutationVariables>(RemoveQuoteDocument, baseOptions);
      }
export type RemoveQuoteMutationHookResult = ReturnType<typeof useRemoveQuoteMutation>;
export type RemoveQuoteMutationResult = ApolloReactCommon.MutationResult<RemoveQuoteMutation>;
export type RemoveQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveQuoteMutation, RemoveQuoteMutationVariables>;
export const UpdateQuoteDocument = gql`
    mutation updateQuote($_id: ObjID!, $input: QuoteInput!) {
  updateQuote(_id: $_id, input: $input) {
    _id
    updatedAt
  }
}
    `;
export type UpdateQuoteMutationFn = ApolloReactCommon.MutationFunction<UpdateQuoteMutation, UpdateQuoteMutationVariables>;
export type UpdateQuoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>, 'mutation'>;

    export const UpdateQuoteComponent = (props: UpdateQuoteComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateQuoteMutation, UpdateQuoteMutationVariables> mutation={UpdateQuoteDocument} {...props} />
    );
    
export type UpdateQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateQuoteMutation, UpdateQuoteMutationVariables>
    } & TChildProps;
export function withUpdateQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateQuoteMutation,
  UpdateQuoteMutationVariables,
  UpdateQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateQuoteMutation, UpdateQuoteMutationVariables, UpdateQuoteProps<TChildProps, TDataName>>(UpdateQuoteDocument, {
      alias: 'updateQuote',
      ...operationOptions
    });
};

/**
 * __useUpdateQuoteMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteMutation, { data, loading, error }] = useUpdateQuoteMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateQuoteMutation, UpdateQuoteMutationVariables>(UpdateQuoteDocument, baseOptions);
      }
export type UpdateQuoteMutationHookResult = ReturnType<typeof useUpdateQuoteMutation>;
export type UpdateQuoteMutationResult = ApolloReactCommon.MutationResult<UpdateQuoteMutation>;
export type UpdateQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>;
export const QuotesDocument = gql`
    query quotes {
  quotes {
    _id
    number
    emailedAt
    total
    user {
      firstName
      lastName
      email
    }
    customDesignRequest {
      _id
    }
    workOrder {
      _id
    }
    createdAt
  }
}
    `;
export type QuotesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<QuotesQuery, QuotesQueryVariables>, 'query'>;

    export const QuotesComponent = (props: QuotesComponentProps) => (
      <ApolloReactComponents.Query<QuotesQuery, QuotesQueryVariables> query={QuotesDocument} {...props} />
    );
    
export type QuotesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QuotesQuery, QuotesQueryVariables>
    } & TChildProps;
export function withQuotes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuotesQuery,
  QuotesQueryVariables,
  QuotesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QuotesQuery, QuotesQueryVariables, QuotesProps<TChildProps, TDataName>>(QuotesDocument, {
      alias: 'quotes',
      ...operationOptions
    });
};

/**
 * __useQuotesQuery__
 *
 * To run a query within a React component, call `useQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotesQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QuotesQuery, QuotesQueryVariables>) {
        return ApolloReactHooks.useQuery<QuotesQuery, QuotesQueryVariables>(QuotesDocument, baseOptions);
      }
export function useQuotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuotesQuery, QuotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuotesQuery, QuotesQueryVariables>(QuotesDocument, baseOptions);
        }
export type QuotesQueryHookResult = ReturnType<typeof useQuotesQuery>;
export type QuotesLazyQueryHookResult = ReturnType<typeof useQuotesLazyQuery>;
export type QuotesQueryResult = ApolloReactCommon.QueryResult<QuotesQuery, QuotesQueryVariables>;
export const SendQuoteDocument = gql`
    mutation sendQuote($_id: ObjID!) {
  sendQuote(_id: $_id) {
    _id
    emailedAt
    updatedAt
  }
}
    `;
export type SendQuoteMutationFn = ApolloReactCommon.MutationFunction<SendQuoteMutation, SendQuoteMutationVariables>;
export type SendQuoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendQuoteMutation, SendQuoteMutationVariables>, 'mutation'>;

    export const SendQuoteComponent = (props: SendQuoteComponentProps) => (
      <ApolloReactComponents.Mutation<SendQuoteMutation, SendQuoteMutationVariables> mutation={SendQuoteDocument} {...props} />
    );
    
export type SendQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SendQuoteMutation, SendQuoteMutationVariables>
    } & TChildProps;
export function withSendQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendQuoteMutation,
  SendQuoteMutationVariables,
  SendQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SendQuoteMutation, SendQuoteMutationVariables, SendQuoteProps<TChildProps, TDataName>>(SendQuoteDocument, {
      alias: 'sendQuote',
      ...operationOptions
    });
};

/**
 * __useSendQuoteMutation__
 *
 * To run a mutation, you first call `useSendQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendQuoteMutation, { data, loading, error }] = useSendQuoteMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useSendQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendQuoteMutation, SendQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<SendQuoteMutation, SendQuoteMutationVariables>(SendQuoteDocument, baseOptions);
      }
export type SendQuoteMutationHookResult = ReturnType<typeof useSendQuoteMutation>;
export type SendQuoteMutationResult = ApolloReactCommon.MutationResult<SendQuoteMutation>;
export type SendQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<SendQuoteMutation, SendQuoteMutationVariables>;
export const StaticPageDocument = gql`
    query staticPage($_id: ObjID!) {
  staticPage(_id: $_id) {
    _id
    title
    description
    content
    route
    createdAt
    updatedAt
  }
}
    `;
export type StaticPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaticPageQuery, StaticPageQueryVariables>, 'query'> & ({ variables: StaticPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StaticPageComponent = (props: StaticPageComponentProps) => (
      <ApolloReactComponents.Query<StaticPageQuery, StaticPageQueryVariables> query={StaticPageDocument} {...props} />
    );
    
export type StaticPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<StaticPageQuery, StaticPageQueryVariables>
    } & TChildProps;
export function withStaticPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StaticPageQuery,
  StaticPageQueryVariables,
  StaticPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, StaticPageQuery, StaticPageQueryVariables, StaticPageProps<TChildProps, TDataName>>(StaticPageDocument, {
      alias: 'staticPage',
      ...operationOptions
    });
};

/**
 * __useStaticPageQuery__
 *
 * To run a query within a React component, call `useStaticPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticPageQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useStaticPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaticPageQuery, StaticPageQueryVariables>) {
        return ApolloReactHooks.useQuery<StaticPageQuery, StaticPageQueryVariables>(StaticPageDocument, baseOptions);
      }
export function useStaticPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaticPageQuery, StaticPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaticPageQuery, StaticPageQueryVariables>(StaticPageDocument, baseOptions);
        }
export type StaticPageQueryHookResult = ReturnType<typeof useStaticPageQuery>;
export type StaticPageLazyQueryHookResult = ReturnType<typeof useStaticPageLazyQuery>;
export type StaticPageQueryResult = ApolloReactCommon.QueryResult<StaticPageQuery, StaticPageQueryVariables>;
export const UpdateStaticPageDocument = gql`
    mutation updateStaticPage($_id: ObjID!, $input: StaticPageUpdateInput!) {
  updateStaticPage(_id: $_id, input: $input) {
    _id
    updatedAt
  }
}
    `;
export type UpdateStaticPageMutationFn = ApolloReactCommon.MutationFunction<UpdateStaticPageMutation, UpdateStaticPageMutationVariables>;
export type UpdateStaticPageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateStaticPageMutation, UpdateStaticPageMutationVariables>, 'mutation'>;

    export const UpdateStaticPageComponent = (props: UpdateStaticPageComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateStaticPageMutation, UpdateStaticPageMutationVariables> mutation={UpdateStaticPageDocument} {...props} />
    );
    
export type UpdateStaticPageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateStaticPageMutation, UpdateStaticPageMutationVariables>
    } & TChildProps;
export function withUpdateStaticPage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateStaticPageMutation,
  UpdateStaticPageMutationVariables,
  UpdateStaticPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateStaticPageMutation, UpdateStaticPageMutationVariables, UpdateStaticPageProps<TChildProps, TDataName>>(UpdateStaticPageDocument, {
      alias: 'updateStaticPage',
      ...operationOptions
    });
};

/**
 * __useUpdateStaticPageMutation__
 *
 * To run a mutation, you first call `useUpdateStaticPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaticPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaticPageMutation, { data, loading, error }] = useUpdateStaticPageMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStaticPageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStaticPageMutation, UpdateStaticPageMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStaticPageMutation, UpdateStaticPageMutationVariables>(UpdateStaticPageDocument, baseOptions);
      }
export type UpdateStaticPageMutationHookResult = ReturnType<typeof useUpdateStaticPageMutation>;
export type UpdateStaticPageMutationResult = ApolloReactCommon.MutationResult<UpdateStaticPageMutation>;
export type UpdateStaticPageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStaticPageMutation, UpdateStaticPageMutationVariables>;
export const StaticPagesDocument = gql`
    query staticPages {
  staticPages {
    _id
    title
    content
    route
    createdAt
    updatedAt
  }
}
    `;
export type StaticPagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaticPagesQuery, StaticPagesQueryVariables>, 'query'>;

    export const StaticPagesComponent = (props: StaticPagesComponentProps) => (
      <ApolloReactComponents.Query<StaticPagesQuery, StaticPagesQueryVariables> query={StaticPagesDocument} {...props} />
    );
    
export type StaticPagesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<StaticPagesQuery, StaticPagesQueryVariables>
    } & TChildProps;
export function withStaticPages<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StaticPagesQuery,
  StaticPagesQueryVariables,
  StaticPagesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, StaticPagesQuery, StaticPagesQueryVariables, StaticPagesProps<TChildProps, TDataName>>(StaticPagesDocument, {
      alias: 'staticPages',
      ...operationOptions
    });
};

/**
 * __useStaticPagesQuery__
 *
 * To run a query within a React component, call `useStaticPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaticPagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaticPagesQuery, StaticPagesQueryVariables>) {
        return ApolloReactHooks.useQuery<StaticPagesQuery, StaticPagesQueryVariables>(StaticPagesDocument, baseOptions);
      }
export function useStaticPagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaticPagesQuery, StaticPagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaticPagesQuery, StaticPagesQueryVariables>(StaticPagesDocument, baseOptions);
        }
export type StaticPagesQueryHookResult = ReturnType<typeof useStaticPagesQuery>;
export type StaticPagesLazyQueryHookResult = ReturnType<typeof useStaticPagesLazyQuery>;
export type StaticPagesQueryResult = ApolloReactCommon.QueryResult<StaticPagesQuery, StaticPagesQueryVariables>;
export const UploadImageDocument = gql`
    mutation uploadImage($data: ImageUploadAttributesInput!, $file: Upload!) {
  uploadImage(data: $data, file: $file) {
    id
    url
  }
}
    `;
export type UploadImageMutationFn = ApolloReactCommon.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;
export type UploadImageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UploadImageMutation, UploadImageMutationVariables>, 'mutation'>;

    export const UploadImageComponent = (props: UploadImageComponentProps) => (
      <ApolloReactComponents.Mutation<UploadImageMutation, UploadImageMutationVariables> mutation={UploadImageDocument} {...props} />
    );
    
export type UploadImageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UploadImageMutation, UploadImageMutationVariables>
    } & TChildProps;
export function withUploadImage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UploadImageMutation,
  UploadImageMutationVariables,
  UploadImageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UploadImageMutation, UploadImageMutationVariables, UploadImageProps<TChildProps, TDataName>>(UploadImageDocument, {
      alias: 'uploadImage',
      ...operationOptions
    });
};

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      data: // value for 'data'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, baseOptions);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = ApolloReactCommon.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const UploadImagesDocument = gql`
    mutation uploadImages($files: [Upload!]!, $data: ImageUploadAttributesInput!) {
  uploadImages(files: $files, data: $data) {
    id
    filename
    url
  }
}
    `;
export type UploadImagesMutationFn = ApolloReactCommon.MutationFunction<UploadImagesMutation, UploadImagesMutationVariables>;
export type UploadImagesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UploadImagesMutation, UploadImagesMutationVariables>, 'mutation'>;

    export const UploadImagesComponent = (props: UploadImagesComponentProps) => (
      <ApolloReactComponents.Mutation<UploadImagesMutation, UploadImagesMutationVariables> mutation={UploadImagesDocument} {...props} />
    );
    
export type UploadImagesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UploadImagesMutation, UploadImagesMutationVariables>
    } & TChildProps;
export function withUploadImages<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UploadImagesMutation,
  UploadImagesMutationVariables,
  UploadImagesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UploadImagesMutation, UploadImagesMutationVariables, UploadImagesProps<TChildProps, TDataName>>(UploadImagesDocument, {
      alias: 'uploadImages',
      ...operationOptions
    });
};

/**
 * __useUploadImagesMutation__
 *
 * To run a mutation, you first call `useUploadImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImagesMutation, { data, loading, error }] = useUploadImagesMutation({
 *   variables: {
 *      files: // value for 'files'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUploadImagesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadImagesMutation, UploadImagesMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadImagesMutation, UploadImagesMutationVariables>(UploadImagesDocument, baseOptions);
      }
export type UploadImagesMutationHookResult = ReturnType<typeof useUploadImagesMutation>;
export type UploadImagesMutationResult = ApolloReactCommon.MutationResult<UploadImagesMutation>;
export type UploadImagesMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadImagesMutation, UploadImagesMutationVariables>;
export const RemoveUserDocument = gql`
    mutation removeUser($_id: ObjID!) {
  removeUser(_id: $_id) {
    n
    ok
  }
}
    `;
export type RemoveUserMutationFn = ApolloReactCommon.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;
export type RemoveUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveUserMutation, RemoveUserMutationVariables>, 'mutation'>;

    export const RemoveUserComponent = (props: RemoveUserComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveUserMutation, RemoveUserMutationVariables> mutation={RemoveUserDocument} {...props} />
    );
    
export type RemoveUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>
    } & TChildProps;
export function withRemoveUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveUserMutation,
  RemoveUserMutationVariables,
  RemoveUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveUserMutation, RemoveUserMutationVariables, RemoveUserProps<TChildProps, TDataName>>(RemoveUserDocument, {
      alias: 'removeUser',
      ...operationOptions
    });
};

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, baseOptions);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = ApolloReactCommon.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const UserDocument = gql`
    query user($_id: ObjID!) {
  user(_id: $_id) {
    _id
    email
    firstName
    lastName
    roles
    billingAddress {
      firstName
      lastName
      address1
      address2
      city
      state
      zip
      country
      phone
    }
    shippingAddress {
      firstName
      lastName
      address1
      address2
      city
      state
      zip
      country
      phone
    }
    billingIsSame
    customDesignRequests {
      _id
      designs
      productType
      quote {
        _id
      }
      createdAt
    }
    quotes {
      _id
      number
      total
      locked
      emailedAt
      workOrder {
        _id
      }
      customDesignRequestId
      createdAt
    }
    workOrders {
      _id
      number
      status
      user {
        _id
        firstName
        lastName
        email
      }
      total
      locked
      quoteId
    }
    orders {
      _id
      status
      total
      createdAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type UserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserQuery, UserQueryVariables>, 'query'> & ({ variables: UserQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserComponent = (props: UserComponentProps) => (
      <ApolloReactComponents.Query<UserQuery, UserQueryVariables> query={UserDocument} {...props} />
    );
    
export type UserProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserQuery, UserQueryVariables>
    } & TChildProps;
export function withUser<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserQuery,
  UserQueryVariables,
  UserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserQuery, UserQueryVariables, UserProps<TChildProps, TDataName>>(UserDocument, {
      alias: 'user',
      ...operationOptions
    });
};

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($_id: ObjID!, $input: UpdateUserInput!) {
  updateUser(_id: $_id, input: $input) {
    _id
    updatedAt
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'>;

    export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserMutation, UpdateUserMutationVariables> mutation={UpdateUserDocument} {...props} />
    );
    
export type UpdateUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>
    } & TChildProps;
export function withUpdateUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserMutation, UpdateUserMutationVariables, UpdateUserProps<TChildProps, TDataName>>(UpdateUserDocument, {
      alias: 'updateUser',
      ...operationOptions
    });
};

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UsersDocument = gql`
    query users($filter: JSON, $sort: JSON, $pagination: JSON) {
  users(filter: $filter, sort: $sort, pagination: $pagination) {
    _id
    firstName
    lastName
    email
    createdAt
  }
}
    `;
export type UsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UsersQuery, UsersQueryVariables>, 'query'>;

    export const UsersComponent = (props: UsersComponentProps) => (
      <ApolloReactComponents.Query<UsersQuery, UsersQueryVariables> query={UsersDocument} {...props} />
    );
    
export type UsersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UsersQuery, UsersQueryVariables>
    } & TChildProps;
export function withUsers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UsersQuery,
  UsersQueryVariables,
  UsersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UsersQuery, UsersQueryVariables, UsersProps<TChildProps, TDataName>>(UsersDocument, {
      alias: 'users',
      ...operationOptions
    });
};

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const VendorDocument = gql`
    query vendor($where: VendorWhereUniqueInput!) {
  vendor(where: $where) {
    id
    name
    slug
    source
    enabled
    markups(orderBy: {from: asc}) {
      id
      from
      to
      markupPercent
    }
    diamondsCount
    leadTimeFrom
    leadTimeTo
    createdAt
    updatedAt
  }
}
    `;
export type VendorComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<VendorQuery, VendorQueryVariables>, 'query'> & ({ variables: VendorQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const VendorComponent = (props: VendorComponentProps) => (
      <ApolloReactComponents.Query<VendorQuery, VendorQueryVariables> query={VendorDocument} {...props} />
    );
    
export type VendorProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<VendorQuery, VendorQueryVariables>
    } & TChildProps;
export function withVendor<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VendorQuery,
  VendorQueryVariables,
  VendorProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, VendorQuery, VendorQueryVariables, VendorProps<TChildProps, TDataName>>(VendorDocument, {
      alias: 'vendor',
      ...operationOptions
    });
};

/**
 * __useVendorQuery__
 *
 * To run a query within a React component, call `useVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVendorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VendorQuery, VendorQueryVariables>) {
        return ApolloReactHooks.useQuery<VendorQuery, VendorQueryVariables>(VendorDocument, baseOptions);
      }
export function useVendorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VendorQuery, VendorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VendorQuery, VendorQueryVariables>(VendorDocument, baseOptions);
        }
export type VendorQueryHookResult = ReturnType<typeof useVendorQuery>;
export type VendorLazyQueryHookResult = ReturnType<typeof useVendorLazyQuery>;
export type VendorQueryResult = ApolloReactCommon.QueryResult<VendorQuery, VendorQueryVariables>;
export const UpdateVendorDocument = gql`
    mutation updateVendor($where: VendorWhereUniqueInput!, $data: VendorUpdateInput!) {
  updateVendor(where: $where, data: $data) {
    id
    updatedAt
  }
}
    `;
export type UpdateVendorMutationFn = ApolloReactCommon.MutationFunction<UpdateVendorMutation, UpdateVendorMutationVariables>;
export type UpdateVendorComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateVendorMutation, UpdateVendorMutationVariables>, 'mutation'>;

    export const UpdateVendorComponent = (props: UpdateVendorComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateVendorMutation, UpdateVendorMutationVariables> mutation={UpdateVendorDocument} {...props} />
    );
    
export type UpdateVendorProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateVendorMutation, UpdateVendorMutationVariables>
    } & TChildProps;
export function withUpdateVendor<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateVendorMutation,
  UpdateVendorMutationVariables,
  UpdateVendorProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateVendorMutation, UpdateVendorMutationVariables, UpdateVendorProps<TChildProps, TDataName>>(UpdateVendorDocument, {
      alias: 'updateVendor',
      ...operationOptions
    });
};

/**
 * __useUpdateVendorMutation__
 *
 * To run a mutation, you first call `useUpdateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorMutation, { data, loading, error }] = useUpdateVendorMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVendorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVendorMutation, UpdateVendorMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateVendorMutation, UpdateVendorMutationVariables>(UpdateVendorDocument, baseOptions);
      }
export type UpdateVendorMutationHookResult = ReturnType<typeof useUpdateVendorMutation>;
export type UpdateVendorMutationResult = ApolloReactCommon.MutationResult<UpdateVendorMutation>;
export type UpdateVendorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVendorMutation, UpdateVendorMutationVariables>;
export const VendorsDocument = gql`
    query vendors($where: VendorWhereInput) {
  totalDiamonds: diamondsCount(where: {vendorId: {not: null}})
  vendors(where: $where, orderBy: [{source: asc}, {name: asc}]) {
    id
    name
    slug
    source
    enabled
    diamondsCount
    createdAt
    updatedAt
  }
}
    `;
export type VendorsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<VendorsQuery, VendorsQueryVariables>, 'query'>;

    export const VendorsComponent = (props: VendorsComponentProps) => (
      <ApolloReactComponents.Query<VendorsQuery, VendorsQueryVariables> query={VendorsDocument} {...props} />
    );
    
export type VendorsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<VendorsQuery, VendorsQueryVariables>
    } & TChildProps;
export function withVendors<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VendorsQuery,
  VendorsQueryVariables,
  VendorsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, VendorsQuery, VendorsQueryVariables, VendorsProps<TChildProps, TDataName>>(VendorsDocument, {
      alias: 'vendors',
      ...operationOptions
    });
};

/**
 * __useVendorsQuery__
 *
 * To run a query within a React component, call `useVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVendorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
        return ApolloReactHooks.useQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, baseOptions);
      }
export function useVendorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, baseOptions);
        }
export type VendorsQueryHookResult = ReturnType<typeof useVendorsQuery>;
export type VendorsLazyQueryHookResult = ReturnType<typeof useVendorsLazyQuery>;
export type VendorsQueryResult = ApolloReactCommon.QueryResult<VendorsQuery, VendorsQueryVariables>;
export const VirtualProductDocument = gql`
    query virtualProduct($id: String!) {
  virtualProduct(id: $id) {
    id
    carat
    clarity
    color
    cost
    crownAngle
    culet
    cut
    depthMM
    depthPercent
    fluorescence
    girdle
    images
    images360
    lab
    lengthMM
    lightPerformance
    measurements
    name
    pavilionAngle
    polish
    price
    shape
    sku
    slug
    symmetry
    tablePercent
    widthMM
    vendor {
      id
      name
    }
  }
}
    `;
export type VirtualProductComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<VirtualProductQuery, VirtualProductQueryVariables>, 'query'> & ({ variables: VirtualProductQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const VirtualProductComponent = (props: VirtualProductComponentProps) => (
      <ApolloReactComponents.Query<VirtualProductQuery, VirtualProductQueryVariables> query={VirtualProductDocument} {...props} />
    );
    
export type VirtualProductProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<VirtualProductQuery, VirtualProductQueryVariables>
    } & TChildProps;
export function withVirtualProduct<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VirtualProductQuery,
  VirtualProductQueryVariables,
  VirtualProductProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, VirtualProductQuery, VirtualProductQueryVariables, VirtualProductProps<TChildProps, TDataName>>(VirtualProductDocument, {
      alias: 'virtualProduct',
      ...operationOptions
    });
};

/**
 * __useVirtualProductQuery__
 *
 * To run a query within a React component, call `useVirtualProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVirtualProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VirtualProductQuery, VirtualProductQueryVariables>) {
        return ApolloReactHooks.useQuery<VirtualProductQuery, VirtualProductQueryVariables>(VirtualProductDocument, baseOptions);
      }
export function useVirtualProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualProductQuery, VirtualProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VirtualProductQuery, VirtualProductQueryVariables>(VirtualProductDocument, baseOptions);
        }
export type VirtualProductQueryHookResult = ReturnType<typeof useVirtualProductQuery>;
export type VirtualProductLazyQueryHookResult = ReturnType<typeof useVirtualProductLazyQuery>;
export type VirtualProductQueryResult = ApolloReactCommon.QueryResult<VirtualProductQuery, VirtualProductQueryVariables>;
export const VirtualProductsDocument = gql`
    query virtualProducts($where: VirtualProductsWhereInput, $limit: Int, $next: String, $previous: String) {
  virtualProducts(where: $where, limit: $limit, next: $next, previous: $previous) {
    results {
      _id
      id
      carat
      clarity
      color
      cost
      crownAngle
      culet
      cut
      depthMM
      depthPercent
      fluorescence
      girdle
      images
      images360
      lab
      lengthMM
      lightPerformance
      measurements
      name
      pavilionAngle
      polish
      price
      shape
      sku
      slug
      symmetry
      tablePercent
      widthMM
    }
    hasNext
    next
    previous
    count
  }
}
    `;
export type VirtualProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<VirtualProductsQuery, VirtualProductsQueryVariables>, 'query'>;

    export const VirtualProductsComponent = (props: VirtualProductsComponentProps) => (
      <ApolloReactComponents.Query<VirtualProductsQuery, VirtualProductsQueryVariables> query={VirtualProductsDocument} {...props} />
    );
    
export type VirtualProductsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<VirtualProductsQuery, VirtualProductsQueryVariables>
    } & TChildProps;
export function withVirtualProducts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VirtualProductsQuery,
  VirtualProductsQueryVariables,
  VirtualProductsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, VirtualProductsQuery, VirtualProductsQueryVariables, VirtualProductsProps<TChildProps, TDataName>>(VirtualProductsDocument, {
      alias: 'virtualProducts',
      ...operationOptions
    });
};

/**
 * __useVirtualProductsQuery__
 *
 * To run a query within a React component, call `useVirtualProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      next: // value for 'next'
 *      previous: // value for 'previous'
 *   },
 * });
 */
export function useVirtualProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VirtualProductsQuery, VirtualProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<VirtualProductsQuery, VirtualProductsQueryVariables>(VirtualProductsDocument, baseOptions);
      }
export function useVirtualProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VirtualProductsQuery, VirtualProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VirtualProductsQuery, VirtualProductsQueryVariables>(VirtualProductsDocument, baseOptions);
        }
export type VirtualProductsQueryHookResult = ReturnType<typeof useVirtualProductsQuery>;
export type VirtualProductsLazyQueryHookResult = ReturnType<typeof useVirtualProductsLazyQuery>;
export type VirtualProductsQueryResult = ApolloReactCommon.QueryResult<VirtualProductsQuery, VirtualProductsQueryVariables>;
export const WorkOrderDocument = gql`
    query workOrder($_id: ObjID!) {
  workOrder(_id: $_id) {
    _id
    userId
    user {
      _id
      firstName
      lastName
      email
      phone
      shippingAddress {
        name
        address1
        address2
        city
        state
        zip
        country
        phone
        isCopy
      }
      billingAddress {
        name
        address1
        address2
        city
        state
        zip
        country
        phone
        isCopy
      }
    }
    number
    invoiceId
    items {
      _id
      title
      description
      inStock
      unitPrice
      price
      quantity
      productId
      type
    }
    jobs {
      _id
      workOrderItemId
      completedAt
    }
    payments {
      _id
      amount
      paymentType
      status
      tax
      taxPercent
      createdAt
      createdByUser {
        _id
        email
      }
      user {
        _id
        email
      }
    }
    hasTax
    tax
    shipping
    subtotal
    total
    totalPayments
    balance
    createdByUser {
      firstName
      lastName
    }
    locked
    isComplete
    completedAt
    createdAt
    updatedAt
  }
}
    `;
export type WorkOrderComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<WorkOrderQuery, WorkOrderQueryVariables>, 'query'> & ({ variables: WorkOrderQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const WorkOrderComponent = (props: WorkOrderComponentProps) => (
      <ApolloReactComponents.Query<WorkOrderQuery, WorkOrderQueryVariables> query={WorkOrderDocument} {...props} />
    );
    
export type WorkOrderProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<WorkOrderQuery, WorkOrderQueryVariables>
    } & TChildProps;
export function withWorkOrder<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  WorkOrderQuery,
  WorkOrderQueryVariables,
  WorkOrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, WorkOrderQuery, WorkOrderQueryVariables, WorkOrderProps<TChildProps, TDataName>>(WorkOrderDocument, {
      alias: 'workOrder',
      ...operationOptions
    });
};

/**
 * __useWorkOrderQuery__
 *
 * To run a query within a React component, call `useWorkOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOrderQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useWorkOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkOrderQuery, WorkOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<WorkOrderQuery, WorkOrderQueryVariables>(WorkOrderDocument, baseOptions);
      }
export function useWorkOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkOrderQuery, WorkOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorkOrderQuery, WorkOrderQueryVariables>(WorkOrderDocument, baseOptions);
        }
export type WorkOrderQueryHookResult = ReturnType<typeof useWorkOrderQuery>;
export type WorkOrderLazyQueryHookResult = ReturnType<typeof useWorkOrderLazyQuery>;
export type WorkOrderQueryResult = ApolloReactCommon.QueryResult<WorkOrderQuery, WorkOrderQueryVariables>;
export const CreateWorkOrderDocument = gql`
    mutation createWorkOrder($input: WorkOrderInput!) {
  createWorkOrder(input: $input) {
    _id
  }
}
    `;
export type CreateWorkOrderMutationFn = ApolloReactCommon.MutationFunction<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>;
export type CreateWorkOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>, 'mutation'>;

    export const CreateWorkOrderComponent = (props: CreateWorkOrderComponentProps) => (
      <ApolloReactComponents.Mutation<CreateWorkOrderMutation, CreateWorkOrderMutationVariables> mutation={CreateWorkOrderDocument} {...props} />
    );
    
export type CreateWorkOrderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>
    } & TChildProps;
export function withCreateWorkOrder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateWorkOrderMutation,
  CreateWorkOrderMutationVariables,
  CreateWorkOrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateWorkOrderMutation, CreateWorkOrderMutationVariables, CreateWorkOrderProps<TChildProps, TDataName>>(CreateWorkOrderDocument, {
      alias: 'createWorkOrder',
      ...operationOptions
    });
};

/**
 * __useCreateWorkOrderMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkOrderMutation, { data, loading, error }] = useCreateWorkOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>(CreateWorkOrderDocument, baseOptions);
      }
export type CreateWorkOrderMutationHookResult = ReturnType<typeof useCreateWorkOrderMutation>;
export type CreateWorkOrderMutationResult = ApolloReactCommon.MutationResult<CreateWorkOrderMutation>;
export type CreateWorkOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWorkOrderMutation, CreateWorkOrderMutationVariables>;
export const RemoveWorkOrderDocument = gql`
    mutation removeWorkOrder($_id: ObjID!) {
  removeWorkOrder(_id: $_id) {
    n
    ok
  }
}
    `;
export type RemoveWorkOrderMutationFn = ApolloReactCommon.MutationFunction<RemoveWorkOrderMutation, RemoveWorkOrderMutationVariables>;
export type RemoveWorkOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveWorkOrderMutation, RemoveWorkOrderMutationVariables>, 'mutation'>;

    export const RemoveWorkOrderComponent = (props: RemoveWorkOrderComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveWorkOrderMutation, RemoveWorkOrderMutationVariables> mutation={RemoveWorkOrderDocument} {...props} />
    );
    
export type RemoveWorkOrderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveWorkOrderMutation, RemoveWorkOrderMutationVariables>
    } & TChildProps;
export function withRemoveWorkOrder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveWorkOrderMutation,
  RemoveWorkOrderMutationVariables,
  RemoveWorkOrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveWorkOrderMutation, RemoveWorkOrderMutationVariables, RemoveWorkOrderProps<TChildProps, TDataName>>(RemoveWorkOrderDocument, {
      alias: 'removeWorkOrder',
      ...operationOptions
    });
};

/**
 * __useRemoveWorkOrderMutation__
 *
 * To run a mutation, you first call `useRemoveWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkOrderMutation, { data, loading, error }] = useRemoveWorkOrderMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveWorkOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveWorkOrderMutation, RemoveWorkOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveWorkOrderMutation, RemoveWorkOrderMutationVariables>(RemoveWorkOrderDocument, baseOptions);
      }
export type RemoveWorkOrderMutationHookResult = ReturnType<typeof useRemoveWorkOrderMutation>;
export type RemoveWorkOrderMutationResult = ApolloReactCommon.MutationResult<RemoveWorkOrderMutation>;
export type RemoveWorkOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveWorkOrderMutation, RemoveWorkOrderMutationVariables>;
export const CreateWorkOrderItemDocument = gql`
    mutation createWorkOrderItem($input: WorkOrderItemInput!) {
  createWorkOrderItem(input: $input) {
    _id
  }
}
    `;
export type CreateWorkOrderItemMutationFn = ApolloReactCommon.MutationFunction<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>;
export type CreateWorkOrderItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>, 'mutation'>;

    export const CreateWorkOrderItemComponent = (props: CreateWorkOrderItemComponentProps) => (
      <ApolloReactComponents.Mutation<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables> mutation={CreateWorkOrderItemDocument} {...props} />
    );
    
export type CreateWorkOrderItemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>
    } & TChildProps;
export function withCreateWorkOrderItem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateWorkOrderItemMutation,
  CreateWorkOrderItemMutationVariables,
  CreateWorkOrderItemProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables, CreateWorkOrderItemProps<TChildProps, TDataName>>(CreateWorkOrderItemDocument, {
      alias: 'createWorkOrderItem',
      ...operationOptions
    });
};

/**
 * __useCreateWorkOrderItemMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkOrderItemMutation, { data, loading, error }] = useCreateWorkOrderItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkOrderItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>(CreateWorkOrderItemDocument, baseOptions);
      }
export type CreateWorkOrderItemMutationHookResult = ReturnType<typeof useCreateWorkOrderItemMutation>;
export type CreateWorkOrderItemMutationResult = ApolloReactCommon.MutationResult<CreateWorkOrderItemMutation>;
export type CreateWorkOrderItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWorkOrderItemMutation, CreateWorkOrderItemMutationVariables>;
export const RemoveWorkOrderItemDocument = gql`
    mutation removeWorkOrderItem($_id: ObjID!) {
  removeWorkOrderItem(_id: $_id) {
    n
    ok
  }
}
    `;
export type RemoveWorkOrderItemMutationFn = ApolloReactCommon.MutationFunction<RemoveWorkOrderItemMutation, RemoveWorkOrderItemMutationVariables>;
export type RemoveWorkOrderItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveWorkOrderItemMutation, RemoveWorkOrderItemMutationVariables>, 'mutation'>;

    export const RemoveWorkOrderItemComponent = (props: RemoveWorkOrderItemComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveWorkOrderItemMutation, RemoveWorkOrderItemMutationVariables> mutation={RemoveWorkOrderItemDocument} {...props} />
    );
    
export type RemoveWorkOrderItemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveWorkOrderItemMutation, RemoveWorkOrderItemMutationVariables>
    } & TChildProps;
export function withRemoveWorkOrderItem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveWorkOrderItemMutation,
  RemoveWorkOrderItemMutationVariables,
  RemoveWorkOrderItemProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveWorkOrderItemMutation, RemoveWorkOrderItemMutationVariables, RemoveWorkOrderItemProps<TChildProps, TDataName>>(RemoveWorkOrderItemDocument, {
      alias: 'removeWorkOrderItem',
      ...operationOptions
    });
};

/**
 * __useRemoveWorkOrderItemMutation__
 *
 * To run a mutation, you first call `useRemoveWorkOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkOrderItemMutation, { data, loading, error }] = useRemoveWorkOrderItemMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveWorkOrderItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveWorkOrderItemMutation, RemoveWorkOrderItemMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveWorkOrderItemMutation, RemoveWorkOrderItemMutationVariables>(RemoveWorkOrderItemDocument, baseOptions);
      }
export type RemoveWorkOrderItemMutationHookResult = ReturnType<typeof useRemoveWorkOrderItemMutation>;
export type RemoveWorkOrderItemMutationResult = ApolloReactCommon.MutationResult<RemoveWorkOrderItemMutation>;
export type RemoveWorkOrderItemMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveWorkOrderItemMutation, RemoveWorkOrderItemMutationVariables>;
export const UpdateWorkOrderItemDocument = gql`
    mutation updateWorkOrderItem($_id: ObjID!, $input: WorkOrderItemInput!) {
  updateWorkOrderItem(_id: $_id, input: $input) {
    _id
    updatedAt
  }
}
    `;
export type UpdateWorkOrderItemMutationFn = ApolloReactCommon.MutationFunction<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables>;
export type UpdateWorkOrderItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables>, 'mutation'>;

    export const UpdateWorkOrderItemComponent = (props: UpdateWorkOrderItemComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables> mutation={UpdateWorkOrderItemDocument} {...props} />
    );
    
export type UpdateWorkOrderItemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables>
    } & TChildProps;
export function withUpdateWorkOrderItem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateWorkOrderItemMutation,
  UpdateWorkOrderItemMutationVariables,
  UpdateWorkOrderItemProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables, UpdateWorkOrderItemProps<TChildProps, TDataName>>(UpdateWorkOrderItemDocument, {
      alias: 'updateWorkOrderItem',
      ...operationOptions
    });
};

/**
 * __useUpdateWorkOrderItemMutation__
 *
 * To run a mutation, you first call `useUpdateWorkOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkOrderItemMutation, { data, loading, error }] = useUpdateWorkOrderItemMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkOrderItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables>(UpdateWorkOrderItemDocument, baseOptions);
      }
export type UpdateWorkOrderItemMutationHookResult = ReturnType<typeof useUpdateWorkOrderItemMutation>;
export type UpdateWorkOrderItemMutationResult = ApolloReactCommon.MutationResult<UpdateWorkOrderItemMutation>;
export type UpdateWorkOrderItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables>;
export const LockWorkOrderDocument = gql`
    mutation lockWorkOrder($_id: ObjID!) {
  lockWorkOrder(_id: $_id) {
    _id
    locked
    updatedAt
  }
}
    `;
export type LockWorkOrderMutationFn = ApolloReactCommon.MutationFunction<LockWorkOrderMutation, LockWorkOrderMutationVariables>;
export type LockWorkOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LockWorkOrderMutation, LockWorkOrderMutationVariables>, 'mutation'>;

    export const LockWorkOrderComponent = (props: LockWorkOrderComponentProps) => (
      <ApolloReactComponents.Mutation<LockWorkOrderMutation, LockWorkOrderMutationVariables> mutation={LockWorkOrderDocument} {...props} />
    );
    
export type LockWorkOrderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<LockWorkOrderMutation, LockWorkOrderMutationVariables>
    } & TChildProps;
export function withLockWorkOrder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LockWorkOrderMutation,
  LockWorkOrderMutationVariables,
  LockWorkOrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, LockWorkOrderMutation, LockWorkOrderMutationVariables, LockWorkOrderProps<TChildProps, TDataName>>(LockWorkOrderDocument, {
      alias: 'lockWorkOrder',
      ...operationOptions
    });
};

/**
 * __useLockWorkOrderMutation__
 *
 * To run a mutation, you first call `useLockWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockWorkOrderMutation, { data, loading, error }] = useLockWorkOrderMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useLockWorkOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LockWorkOrderMutation, LockWorkOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<LockWorkOrderMutation, LockWorkOrderMutationVariables>(LockWorkOrderDocument, baseOptions);
      }
export type LockWorkOrderMutationHookResult = ReturnType<typeof useLockWorkOrderMutation>;
export type LockWorkOrderMutationResult = ApolloReactCommon.MutationResult<LockWorkOrderMutation>;
export type LockWorkOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<LockWorkOrderMutation, LockWorkOrderMutationVariables>;
export const UnlockWorkOrderDocument = gql`
    mutation unlockWorkOrder($_id: ObjID!) {
  unlockWorkOrder(_id: $_id) {
    _id
    locked
    updatedAt
  }
}
    `;
export type UnlockWorkOrderMutationFn = ApolloReactCommon.MutationFunction<UnlockWorkOrderMutation, UnlockWorkOrderMutationVariables>;
export type UnlockWorkOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnlockWorkOrderMutation, UnlockWorkOrderMutationVariables>, 'mutation'>;

    export const UnlockWorkOrderComponent = (props: UnlockWorkOrderComponentProps) => (
      <ApolloReactComponents.Mutation<UnlockWorkOrderMutation, UnlockWorkOrderMutationVariables> mutation={UnlockWorkOrderDocument} {...props} />
    );
    
export type UnlockWorkOrderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UnlockWorkOrderMutation, UnlockWorkOrderMutationVariables>
    } & TChildProps;
export function withUnlockWorkOrder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnlockWorkOrderMutation,
  UnlockWorkOrderMutationVariables,
  UnlockWorkOrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnlockWorkOrderMutation, UnlockWorkOrderMutationVariables, UnlockWorkOrderProps<TChildProps, TDataName>>(UnlockWorkOrderDocument, {
      alias: 'unlockWorkOrder',
      ...operationOptions
    });
};

/**
 * __useUnlockWorkOrderMutation__
 *
 * To run a mutation, you first call `useUnlockWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockWorkOrderMutation, { data, loading, error }] = useUnlockWorkOrderMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useUnlockWorkOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnlockWorkOrderMutation, UnlockWorkOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UnlockWorkOrderMutation, UnlockWorkOrderMutationVariables>(UnlockWorkOrderDocument, baseOptions);
      }
export type UnlockWorkOrderMutationHookResult = ReturnType<typeof useUnlockWorkOrderMutation>;
export type UnlockWorkOrderMutationResult = ApolloReactCommon.MutationResult<UnlockWorkOrderMutation>;
export type UnlockWorkOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<UnlockWorkOrderMutation, UnlockWorkOrderMutationVariables>;
export const UpdateWorkOrderDocument = gql`
    mutation updateWorkOrder($_id: ObjID!, $input: WorkOrderInput!) {
  updateWorkOrder(_id: $_id, input: $input) {
    _id
    updatedAt
  }
}
    `;
export type UpdateWorkOrderMutationFn = ApolloReactCommon.MutationFunction<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>;
export type UpdateWorkOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>, 'mutation'>;

    export const UpdateWorkOrderComponent = (props: UpdateWorkOrderComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables> mutation={UpdateWorkOrderDocument} {...props} />
    );
    
export type UpdateWorkOrderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>
    } & TChildProps;
export function withUpdateWorkOrder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateWorkOrderMutation,
  UpdateWorkOrderMutationVariables,
  UpdateWorkOrderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables, UpdateWorkOrderProps<TChildProps, TDataName>>(UpdateWorkOrderDocument, {
      alias: 'updateWorkOrder',
      ...operationOptions
    });
};

/**
 * __useUpdateWorkOrderMutation__
 *
 * To run a mutation, you first call `useUpdateWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkOrderMutation, { data, loading, error }] = useUpdateWorkOrderMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>(UpdateWorkOrderDocument, baseOptions);
      }
export type UpdateWorkOrderMutationHookResult = ReturnType<typeof useUpdateWorkOrderMutation>;
export type UpdateWorkOrderMutationResult = ApolloReactCommon.MutationResult<UpdateWorkOrderMutation>;
export type UpdateWorkOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWorkOrderMutation, UpdateWorkOrderMutationVariables>;
export const WorkOrdersDocument = gql`
    query workOrders {
  workOrders {
    _id
    number
    user {
      _id
      firstName
      lastName
      email
    }
    quote {
      _id
    }
    payments {
      _id
    }
    total
    isComplete
    completedAt
    createdAt
  }
}
    `;
export type WorkOrdersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<WorkOrdersQuery, WorkOrdersQueryVariables>, 'query'>;

    export const WorkOrdersComponent = (props: WorkOrdersComponentProps) => (
      <ApolloReactComponents.Query<WorkOrdersQuery, WorkOrdersQueryVariables> query={WorkOrdersDocument} {...props} />
    );
    
export type WorkOrdersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<WorkOrdersQuery, WorkOrdersQueryVariables>
    } & TChildProps;
export function withWorkOrders<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  WorkOrdersQuery,
  WorkOrdersQueryVariables,
  WorkOrdersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, WorkOrdersQuery, WorkOrdersQueryVariables, WorkOrdersProps<TChildProps, TDataName>>(WorkOrdersDocument, {
      alias: 'workOrders',
      ...operationOptions
    });
};

/**
 * __useWorkOrdersQuery__
 *
 * To run a query within a React component, call `useWorkOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkOrdersQuery, WorkOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<WorkOrdersQuery, WorkOrdersQueryVariables>(WorkOrdersDocument, baseOptions);
      }
export function useWorkOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkOrdersQuery, WorkOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorkOrdersQuery, WorkOrdersQueryVariables>(WorkOrdersDocument, baseOptions);
        }
export type WorkOrdersQueryHookResult = ReturnType<typeof useWorkOrdersQuery>;
export type WorkOrdersLazyQueryHookResult = ReturnType<typeof useWorkOrdersLazyQuery>;
export type WorkOrdersQueryResult = ApolloReactCommon.QueryResult<WorkOrdersQuery, WorkOrdersQueryVariables>;