import gql from 'graphql-tag';

export default gql`
  query quotes {
    quotes {
      _id
      number
      emailedAt
      total
      user {
        firstName
        lastName
        email
      }
      customDesignRequest {
        _id
      }
      workOrder {
        _id
      }
      createdAt
    }
  }
`;
