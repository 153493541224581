import gql from 'graphql-tag';

export default gql`
  query staticPages {
    staticPages {
      _id
      title
      content
      route
      createdAt
      updatedAt
    }
  }
`;
