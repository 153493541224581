/* eslint-disable no-restricted-globals */
// @ts-nocheck
import React from 'react';
import { Form, Card, CardBody, CardHeader, CardTitle, CardFooter,
  Row, Col, Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import _ from 'lodash';
import NotificationAlert from 'react-notification-alert';
import { PanelHeader, FormInputsLegacy, Button, Loading, UserIsInRole } from 'components';
import withDiamond from 'containers/withDiamond';
import withDiamondUpdate from 'containers/withDiamondUpdate';
import diamondQuery from 'graphql/diamondQuery';
import diamondDescriptiveFieldsQuery from 'graphql/diamondDescriptiveFields';
import { notify, Alert } from 'lib/utils';
import { Diamond as IDiamond, MutationUpdateDiamondArgs } from 'types/graphql';
import DiamondImages from './DiamondImages';
import Diamond360Images from './Diamond360Images';

class Diamond extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    error: PropTypes.string,
    loading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    refetch: PropTypes.func.isRequired,
    updateDiamond: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'basicInfo',
      ...props.data.diamond
    };
  }

  componentDidUpdate(prevProps, prevState): void {
    const { client: apolloClient, match: { params: { _id } } } = this.props;
    const {
      autopopulateMetaDescription,
      autopopulateMetaTitle,
      autopopulateDescription,
      description,
      meta
    } = this.state;
    if ((!prevState.autopopulateMetaDescription && autopopulateMetaDescription)
      || (!prevState.autopopulateMetaTitle && autopopulateMetaTitle)
      || (!prevState.autopopulateDescription && autopopulateDescription)
    ) {
      const diamond = this.diamondFromState();
      apolloClient.query({
        query: diamondDescriptiveFieldsQuery,
        variables: { _id, input: diamond },
        fetchPolicy: 'no-cache'
      }).then(({ data }) => {
        const { descriptiveFields } = data;
        super.setState({
          description: autopopulateDescription ? descriptiveFields.description : description,
          meta: {
            ...meta,
            description: autopopulateMetaDescription ? descriptiveFields.metaDescription : meta.description,
            title: autopopulateMetaTitle ? descriptiveFields.metaTitle : meta.title
          }
        });
      }).catch(() => { /* Noop */ });
    }
  }

  handleStateChange = (e): void => {
    const t = e.target;
    let val = t.value;
    if (t.type === 'number') {
      val = parseInt(t.value, 10);
    }
    this.setState((state) => _.setWith(_.clone(state), t.name, t.type === 'checkbox' ? t.checked : val, _.clone));
  }

  diamondFromState(): any {
    const diamond = _.omit(this.state, [
      'currentTab',
      '_id',
      'clarity',
      'color',
      'createdAt',
      'culet',
      'cut',
      'fluorescence',
      'images',
      'lightPerformance',
      'polish',
      'shape',
      'symmetry',
      'Symbol(id)',
      '__typename',
      'updatedAt'
    ]) as any;

    diamond.meta = _.omit(diamond.meta, ['__typename']);
    diamond.lab = _.omit(diamond.lab, ['__typename']);
    diamond.video = _.omit(diamond.video, ['__typename']);
    return diamond;
  }

  handleSubmit = (e): any => {
    e.preventDefault();

    const diamond: IDiamond = this.diamondFromState();

    const variables: MutationUpdateDiamondArgs = {
      where: { id: diamond.id },
      data: {
        autopopulateDescription: { set: diamond.autopopulateDescription },
        autopopulateMetaDescription: { set: diamond.autopopulateMetaDescription },
        autopopulateMetaTitle: { set: diamond.autopopulateMetaTitle },
        carat: { set: diamond.carat },
        clarity: diamond.clarityId ? { connect: { id: diamond.clarityId } } : undefined,
        color: diamond.colorId ? { connect: { id: diamond.colorId } } : undefined,
        cost: { set: diamond.cost },
        crownAngle: { set: diamond.crownAngle },
        culet: diamond.culetId ? { connect: { id: diamond.culetId } } : undefined,
        cut: diamond.cutId ? { connect: { id: diamond.cutId } } : undefined,
        depthMM: { set: isNaN(Number(diamond.depthMM)) ? undefined : Number(diamond.depthMM) },
        depthPercent: { set: isNaN(Number(diamond.depthPercent)) ? undefined : Number(diamond.depthPercent) },
        description: { set: diamond.description },
        enabled: { set: diamond.enabled },
        fluorescence: diamond.fluorescenceId ? { connect: { id: diamond.fluorescenceId } } : undefined,
        girdles: { connect: diamond?.girdles?.map((g) => ({ id: g.id })) },
        isLabGrown: { set: diamond.isLabGrown },
        lab: diamond.lab.name ? {
          upsert: {
            create: diamond.lab,
            update: {
              name: { set: diamond.lab.name },
              reportNumber: { set: diamond.lab.reportNumber }
            }
          }
        } : undefined,
        lengthMM: { set: diamond.lengthMM },
        lightPerformance: diamond.lightPerformanceId ? { connect: { id: diamond.lightPerformanceId } } : undefined,
        lowerGirdleFacetPercent: { set: diamond.lowerGirdleFacetPercent },
        meta: {
          upsert: {
            create: diamond.meta,
            update: {
              title: { set: diamond.meta.title },
              description: { set: diamond.meta.description },
              keywords: { set: diamond.meta.keywords }
            }
          }
        },
        name: { set: diamond.name },
        notes: { set: diamond.notes },
        onSale: { set: diamond.onSale },
        pavilionAngle: { set: diamond.pavilionAngle },
        polish: diamond.polishId ? { connect: { id: diamond.polishId } } : undefined,
        price: { set: isNaN(Number(diamond.price)) ? 0 : Number(diamond.price) },
        quantity: { set: isNaN(Number(diamond.quantity)) ? 0 : Number(diamond.quantity) },
        salePrice: { set: isNaN(Number(diamond.salePrice)) ? 0 : Number(diamond.salePrice) },
        shape: diamond.shapeId ? { connect: { id: diamond.shapeId } } : undefined,
        sku: { set: diamond.sku },
        slug: { set: diamond.slug },
        starLengthPercent: { set: diamond.starLengthPercent },
        symmetry: diamond.symmetryId ? { connect: { id: diamond.symmetryId } } : undefined,
        tablePercent: { set: isNaN(Number(diamond.tablePercent)) ? undefined : Number(diamond.tablePercent) },
        widthMM: { set: diamond.widthMM }
      }
    };

    // eslint-disable-next-line react/destructuring-assignment
    this.props.updateDiamond({
      variables,
      refetchQueries: [{
        query: diamondQuery,
        variables: { slugOrId: diamond.id }
      }]
    })
      .then(() => notify('success', 'Diamond updated!'))
      .catch((err) => {
        Alert({
          icon: 'error',
          title: 'Oh no!',
          text: err.message.split('GraphQL error: ')[1]
        });
      });
  }

  createSelectOptions(items): any {
    if (!items || !Array.isArray(items) || !items.length) {
      return;
    }
    return items.map(({ _id, label }) => ({ _id, label, value: _id, id: _id }));
  }

  notify(type, message): void {
    this.refs.notificationAlert.notificationAlert({
      place: 'tc',
      message,
      type, // success|danger
      autoDismiss: 5
    });
  }

  render() {
    const { loading, error, data, refetch } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <div>
          <p>Error :(</p>
        </div>
      );
    }

    const {
      state: { currentTab, autopopulateDescription, autopopulateMetaTitle, autopopulateMetaDescription },
      props: { data: { diamond } }
    } = this;

    if (!diamond) {
      return <div>No diamond found</div>;
    }
    return (
      <div>
        <NotificationAlert ref="notificationAlert" />
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={12} xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle><h5>DIAMOND DETAIL</h5></CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={2} xs={12}>
                      <Nav pills className="nav-pills-primary flex-column">
                        <NavItem>
                          <NavLink
                            className={currentTab === 'basicInfo' ? 'active' : ''}
                            onClick={(): void => this.setState({ currentTab: 'basicInfo' })}
                          >
                            Basic Info
                          </NavLink>
                        </NavItem>
                        <UserIsInRole roles="admin">
                          <NavItem>
                            <NavLink
                              className={currentTab === 'costs' ? 'active' : ''}
                              onClick={(): void => this.setState({ currentTab: 'costs' })}
                            >
                              Med'o Gawonoyo
                            </NavLink>
                          </NavItem>
                        </UserIsInRole>
                        <NavItem>
                          <NavLink
                            className={currentTab === 'diamondInfo' ? 'active' : ''}
                            onClick={(): void => this.setState({ currentTab: 'diamondInfo' })}
                          >
                            Diamond Info
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={currentTab === 'metaData' ? 'active' : ''}
                            onClick={(): void => this.setState({ currentTab: 'metaData' })}
                          >
                            Meta Data
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={currentTab === 'images' ? 'active' : ''}
                            onClick={(): void => this.setState({ currentTab: 'images' })}
                          >
                            Images
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={currentTab === 'video' ? 'active' : ''}
                            onClick={(): void => this.setState({ currentTab: 'video' })}
                          >
                            Video
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={currentTab === '360' ? 'active' : ''}
                            onClick={(): void => this.setState({ currentTab: '360' })}
                          >
                            360
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col md={10} xs={12}>
                      <TabContent activeTab={currentTab}>
                        <TabPane tabId="basicInfo">
                          <Form onSubmit={this.handleSubmit}>
                            <FormInputsLegacy
                              inputs={[
                                // {
                                //   label: 'Product Name',
                                //   colProps: {
                                //     md: { size: 6, offset: 1 }
                                //   },
                                //   inputProps: {
                                //     type: 'text',
                                //     name: 'name',
                                //     defaultValue: diamond.name,
                                //     onChange: this.handleStateChange
                                //   }
                                // },
                                {
                                  label: 'Autopopulate description?',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'checkbox',
                                    name: 'autopopulateDescription',
                                    defaultChecked: diamond.autopopulateDescription,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Description',
                                  cond: !autopopulateDescription,
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'wysiwyg',
                                    name: 'description',
                                    defaultValue: diamond.description,
                                    onChange: (val) => {
                                      this.setState((state) => _.setWith(_.clone(state), 'description', val, _.clone));
                                    }
                                  }
                                },
                                {
                                  label: 'Description',
                                  cond: autopopulateDescription,
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'textarea',
                                    name: 'description',
                                    rows: 5,
                                    readOnly: true,
                                    value: this.state.description
                                  }
                                },
                                {
                                  label: 'SKU',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'sku',
                                    defaultValue: diamond.sku,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Price',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'currency',
                                    name: 'price',
                                    value: diamond.price / 100,
                                    onChange: (e): void => {
                                      const { name, rawValue } = e.target;
                                      let value = parseFloat(rawValue, 10) * 100;
                                      if (isNaN(value)) {
                                        value = 0;
                                      }
                                      this.setState((state) => _.setWith(_.clone(state), name, value, _.clone));
                                    }
                                  }
                                },
                                {
                                  label: 'Sale Price',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'currency',
                                    name: 'salePrice',
                                    value: diamond.salePrice / 100,
                                    onChange: (e): void => {
                                      const { name, rawValue } = e.target;
                                      let value = parseFloat(rawValue, 10) * 100;
                                      if (isNaN(value)) {
                                        value = 0;
                                      }
                                      this.setState((state) => _.setWith(_.clone(state), name, value, _.clone));
                                    }
                                  }
                                },
                                {
                                  label: 'On Sale?',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'checkbox',
                                    name: 'onSale',
                                    defaultChecked: diamond.onSale,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Quantity in Stock',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'number',
                                    name: 'quantity',
                                    defaultValue: diamond.quantity,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Enabled',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'checkbox',
                                    name: 'enabled',
                                    defaultChecked: diamond.enabled,
                                    onChange: this.handleStateChange
                                  }
                                }
                              ]}
                            />
                          </Form>
                        </TabPane>
                        <TabPane tabId="costs">
                          <Form onSubmit={this.handleSubmit}>
                            <FormInputsLegacy
                              inputs={[
                                {
                                  label: 'Nefaqto',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'currency',
                                    name: 'cost',
                                    value: diamond.cost / 100,
                                    onChange: (e): void => {
                                      const { name, rawValue } = e.target;
                                      let value = parseFloat(rawValue, 10) * 100;
                                      if (isNaN(value)) {
                                        value = 0;
                                      }
                                      this.setState((state) => _.setWith(_.clone(state), name, value, _.clone));
                                    }
                                  }
                                },
                                {
                                  label: 'Notes',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'textarea',
                                    name: 'notes',
                                    rows: 6,
                                    defaultValue: diamond.notes,
                                    onChange: this.handleStateChange
                                  }
                                }
                              ]}
                            />
                          </Form>
                        </TabPane>
                        <TabPane tabId="diamondInfo">
                          <Form onSubmit={this.handleSubmit}>
                            <FormInputsLegacy
                              inputs={[
                                {
                                  label: 'Natural Diamond / Lab Grown',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'radio',
                                    name: 'isLabGrown',
                                    options: [{ label: 'Natural Diamond', value: '0' }, { label: 'Lab Grown', value: '1' }],
                                    defaultValue: this.state.isLabGrown ? '1' : '0',
                                    onChange: (event): void => {
                                      const el = event.target;
                                      const { name, value } = el;
                                      this.setState((state) => _.setWith(_.clone(state), name, value === '1', _.clone));
                                    }
                                  }
                                },
                                {
                                  label: 'Diamond Shape',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'shapeId',
                                    options: this.createSelectOptions(data.diamondShapes),
                                    defaultValue: this.state.shape,
                                    onChange: (shape): void => this.setState({ shapeId: shape.value, shape })
                                  }
                                },
                                {
                                  label: 'Carat',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'carat',
                                    defaultValue: this.state.carat,
                                    onChange: (e): void => {
                                      const { name } = e.target;
                                      let { value } = e.target;
                                      value = parseFloat(value, 10);
                                      if (isNaN(value)) {
                                        value = 0;
                                      }
                                      this.setState((state) => _.setWith(_.clone(state), name, value, _.clone));
                                    }
                                  }
                                },
                                {
                                  label: 'Color',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'color',
                                    options: this.createSelectOptions(data.colors),
                                    defaultValue: this.state.color,
                                    onChange: (color): void => this.setState({ colorId: color.value, color })
                                  }
                                },
                                {
                                  label: 'Clarity',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'clarity',
                                    options: this.createSelectOptions(data.clarities),
                                    defaultValue: this.state.clarity,
                                    onChange: (clarity): void => this.setState({ clarityId: clarity.value, clarity })
                                  }
                                },
                                {
                                  label: 'Cut',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'cut',
                                    options: this.createSelectOptions(data.cuts),
                                    defaultValue: this.state.cut,
                                    onChange: (cut): void => this.setState({ cutId: cut.value, cut })
                                  }
                                },
                                {
                                  label: 'Polish',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'polish',
                                    options: this.createSelectOptions(data.polishes),
                                    defaultValue: this.state.polish,
                                    onChange: (polish): void => this.setState({ polishId: polish.value, polish })
                                  }
                                },
                                {
                                  label: 'Symmetry',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'symmetry',
                                    options: this.createSelectOptions(data.symmetries),
                                    defaultValue: this.state.symmetry,
                                    onChange: (symmetry): void => this.setState({ symmetryId: symmetry.value, symmetry })
                                  }
                                },
                                {
                                  label: 'Light Performance',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'lightPerformance',
                                    options: this.createSelectOptions(data.lightPerformances),
                                    defaultValue: this.state.lightPerformance,
                                    onChange: (lightPerformance): void => this.setState({
                                      lightPerformanceId: lightPerformance.value,
                                      lightPerformance
                                    })
                                  }
                                },
                                {
                                  label: 'Fluorescence',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'fluorescence',
                                    options: this.createSelectOptions(data.fluorescences),
                                    defaultValue: this.state.fluorescence,
                                    onChange: (fluorescence): void => this.setState({
                                      fluorescenceId: fluorescence.value,
                                      fluorescence
                                    })
                                  }
                                },
                                {
                                  label: 'Culet',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'culet',
                                    options: this.createSelectOptions(data.culets),
                                    defaultValue: this.state.culet,
                                    onChange: (culet): void => this.setState({ culetId: culet.value, culet })
                                  }
                                },
                                {
                                  label: 'Girdle',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    isMulti: true,
                                    name: 'girdles',
                                    defaultValue: this.state.girdles,
                                    options: this.createSelectOptions(data.girdles),
                                    onChange: (girdles): void => {
                                      this.setState({
                                        girdles,
                                        girdleIds: girdles.map((g) => g.id)
                                      });
                                    }
                                  }
                                },
                                {
                                  label: 'Depth Percent',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'depthPercent',
                                    defaultValue: diamond.depthPercent,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Table Percent',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'tablePercent',
                                    defaultValue: diamond.tablePercent,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Length (mm)',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'lengthMM',
                                    defaultValue: diamond.lengthMM,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Width (mm)',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'widthMM',
                                    defaultValue: diamond.widthMM,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Depth (mm)',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'depthMM',
                                    defaultValue: diamond.depthMM,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Crown Angle',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'crownAngle',
                                    defaultValue: diamond.crownAngle,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Pavilion Angle',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'pavilionAngle',
                                    defaultValue: diamond.pavilionAngle,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Lower Girdle Facet Percent',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'lowerGirdleFacetPercent',
                                    defaultValue: diamond.lowerGirdleFacetPercent,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Star Length Percent',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'starLengthPercent',
                                    defaultValue: diamond.starLengthPercent,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Laboratory',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'lab.name',
                                    options: [{
                                      label: 'AGS',
                                      value: 'AGS'
                                    }, {
                                      label: 'GIA',
                                      value: 'GIA'
                                    }],
                                    defaultValue: {
                                      label: _.get(diamond, 'lab.name'),
                                      value: _.get(diamond, 'lab.name')
                                    },
                                    onChange: (lab): void => this.setState((state) => _.setWith(_.clone(state), 'lab.name', lab.value, _.clone))
                                  }
                                },
                                {
                                  label: 'Lab Report Number',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'lab.reportNumber',
                                    defaultValue: _.get(diamond, 'lab.reportNumber'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Lab Certificate',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'imageUpload',
                                    name: 'lab.certificate',
                                    image: diamond.images.filter((img) => img.category === 'lab')[0],
                                    category: 'lab',
                                    productId: diamond.id,
                                    productType: 'Diamond',
                                    refetchQueries: [{
                                      query: diamondQuery,
                                      variables: { slugOrId: diamond.id }
                                    }],
                                    thumbnailProps: {
                                      width: 250,
                                      height: 250,
                                      header: 'Lab Certificate',
                                      refetchQueries: [{
                                        query: diamondQuery,
                                        variables: { slugOrId: diamond.id }
                                      }]
                                    }
                                  }
                                }
                              ]}
                            />
                          </Form>
                        </TabPane>
                        <TabPane tabId="metaData">
                          <Form onSubmit={this.handleSubmit}>
                            <FormInputsLegacy
                              inputs={[
                                {
                                  label: 'Autopopulate page title?',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'checkbox',
                                    name: 'autopopulateMetaTitle',
                                    defaultChecked: diamond.autopopulateMetaTitle,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Page Title',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    readOnly: autopopulateMetaTitle,
                                    value: this.state.meta.title,
                                    type: 'textarea',
                                    name: 'meta.title',
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Autopopulate meta description?',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'checkbox',
                                    name: 'autopopulateMetaDescription',
                                    defaultChecked: diamond.autopopulateMetaDescription,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Meta Description',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    readOnly: autopopulateMetaDescription,
                                    value: this.state.meta.description,
                                    type: 'textarea',
                                    rows: 4,
                                    name: 'meta.description',
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Meta Keywords',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'meta.keywords',
                                    defaultValue: _.get(diamond, 'meta.keywords'),
                                    onChange: this.handleStateChange
                                  }
                                }
                              ]}
                            />
                          </Form>
                        </TabPane>
                        <TabPane tabId="images">
                          <DiamondImages diamond={diamond} refetch={refetch} />
                        </TabPane>
                        <TabPane tabId="video">
                          <Form onSubmit={this.handleSubmit}>
                            <FormInputsLegacy
                              inputs={[
                                {
                                  label: 'Video Source',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'video.source',
                                    options: [{
                                      label: '',
                                      value: ''
                                    }, {
                                      label: 'Youtube',
                                      value: 'Youtube'
                                    }, {
                                      label: 'Vimeo',
                                      value: 'Vimeo'
                                    }, {
                                      label: 'MP4',
                                      value: 'MP4'
                                    }],
                                    defaultValue: {
                                      label: _.get(diamond, 'video.source'),
                                      value: _.get(diamond, 'video.source')
                                    },
                                    onChange: ({ value }): void => this.setState((state) => _.setWith(_.clone(state), 'video.source', value, _.clone))
                                  }
                                },
                                {
                                  label: 'Video Code/URL',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'video.code',
                                    defaultValue: _.get(diamond, 'video.code'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Video Title',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'video.title',
                                    defaultValue: _.get(diamond, 'video.title'),
                                    onChange: this.handleStateChange
                                  }
                                }
                              ]}
                            />
                          </Form>
                        </TabPane>
                        <TabPane tabId="360">
                          <Diamond360Images diamond={diamond} refetch={refetch} />
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button color="primary" type="submit" onClick={this.handleSubmit}>Save</Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default compose(
  withDiamond,
  withDiamondUpdate
)(Diamond);
