import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, Table, Form, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { Link, Prompt } from 'react-router-dom';
import _ from 'lodash';
import NotificationAlert from 'react-notification-alert';
import Toggle from 'react-toggle';
import format from 'date-fns/format';
import Cleave from 'cleave.js/react';
import { faLockOpen, faLock, faPlus, faTrash, faPrint } from '@fortawesome/free-solid-svg-icons';
import { PanelHeader, Button, Icon, FormInputsLegacy, Modal, DateFormat, RightAligned } from 'components';
import withQuote from 'containers/withQuote';
import withQuoteUpdate from 'containers/withQuoteUpdate';
import withWorkOrderCreate from 'containers/withWorkOrderCreate';
import withWorkOrderLock from 'containers/withWorkOrderLock';
import withWorkOrderItemCreate from 'containers/withWorkOrderItemCreate';
import withFormContactUpdate from 'containers/withFormContactUpdate';
import withAvailableProducts from 'containers/withAvailableProducts';
import withSendQuote from 'containers/withSendQuote';
import quoteQuery from 'graphql/quote';
import { formatPrice, notify, Alert, multiply, calculateOrderTotals } from 'lib/utils';

const Wrapper = styled.div`
  @media print {
    display: none;
  }
`;

const ListItem = styled.div`
  margin-bottom: 0.5rem;
`;

class Quote extends React.Component {
  static propTypes = {
    createWorkOrder: PropTypes.func.isRequired,
    createWorkOrderItem: PropTypes.func.isRequired,
    diamonds: PropTypes.arrayOf(PropTypes.object),
    history: PropTypes.object.isRequired,
    jewelries: PropTypes.arrayOf(PropTypes.object),
    lockWorkOrder: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    quote: PropTypes.object,
    rings: PropTypes.arrayOf(PropTypes.object),
    sendQuote: PropTypes.func.isRequired,
    updateFormContact: PropTypes.func.isRequired,
    updateQuote: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      locked: props.quote && props.quote.locked,
      quote: props.quote,
      quoteItemsUpdated: false,
      availableProducts: {}
    };
  }

  createProductSelectOptions(items) {
    if (!items || !Array.isArray(items) || !items.length) {
      return;
    }
    return items.map(({ _id, name, sku, ...rest }) => ({ label: `${sku} - ${name}`, _id, value: _id, name, ...rest }));
  }

  generateWorkOrder = () => {
    const { quote } = this.state;

    if (!Array.isArray(quote.items) || quote.items.length < 1) {
      return this.notify('danger', 'A work order requires at least one quote item!');
    }

    this.props
      .createWorkOrder({
        variables: {
          input: {
            quoteId: quote._id,
            userId: quote.userId,
            locked: false
          },
          refetchQueries: [
            {
              query: quoteQuery,
              variables: { _id: quote._id }
            }
          ]
        }
      })
      .then(
        ({data: {
            createWorkOrder: { _id }
          }}) => {
          const items = quote.items.map((item) => _.omit(item, ['price', '__typename']));
          // now that the work order has been created,
          // create the individual work order items
          return Promise.all([
            _id,
            ...items.map((item) =>
              this.props.createWorkOrderItem({
                variables: {
                  input: {
                    ...item,
                    workOrderId: _id
                  }
                }
              })
            )
          ]);
        }
      )
      .then(([_id]) =>
        Promise.all([
          _id,
          this.props.lockWorkOrder({
            variables: {
              _id
            }
          })
        ])
      )
      .then(([_id]) => this.props.history.push(`/quoting/work-orders/${_id}`))
      .catch((e) => {
        Alert({
          icon: 'error',
          title: 'Oh no!',
          text: e.message.split('GraphQL error: ')[1]
        });
      });
  };

  handleStateChange = (e) => {
    const t = e.target;
    this.setState((state) => _.setWith(_.clone(state), t.name, t.value, _.clone));
  };

  handleLockedStateChange = async (e) => {
    const locked = e.target.checked;

    let quote = {};

    // if we're locking it, make sure to save any additional changes that were made
    if (locked) {
      // save any changes to the user details
      await this.handleFormContactUpdate();

      quote = _.omit(this.state.quote, [
        '_id',
        'user',
        'emails',
        'number',
        'subtotal',
        'shipping',
        'hasTax',
        'tax',
        'total',
        'totalPayments',
        'balance',
        'workOrder',
        'createdByUser',
        'createdAt',
        'updatedAt',
        '__typename'
      ]);

      quote.items = quote.items.map((item) => _.omit(item, ['price', '__typename']));
    }

    // set the new locked state
    quote.locked = locked;

    // update the quote
    this.props
      .updateQuote({
        variables: {
          _id: this.props.quote._id,
          input: quote
        },
        refetchQueries: [
          {
            query: quoteQuery,
            variables: { _id: this.props.quote._id }
          }
        ]
      })
      .then(() => {
        this.notify('primary', `Quote ${locked ? 'saved and locked' : 'unlocked'}!`);
        this.setState({ locked });
      })
      .catch(() => this.notify('danger', 'Save failed!'));
  };

  handleProductSelectChange = ({ newProductItem }) => {
    let availableProducts;

    if (newProductItem.type === 'Diamond') {
      availableProducts = {
        selectedDiamond: newProductItem,
        selectedRing: { label: '', value: '' },
        selectedJewelry: { label: '', value: '' }
      };
    } else if (newProductItem.type === 'Ring') {
      availableProducts = {
        selectedDiamond: { label: '', value: '' },
        selectedRing: newProductItem,
        selectedJewelry: { label: '', value: '' }
      };
    } else {
      // 'Bracelet', 'Earrings', 'Necklace'
      availableProducts = {
        selectedDiamond: { label: '', value: '' },
        selectedRing: { label: '', value: '' },
        selectedJewelry: newProductItem
      };
    }

    this.setState({
      newProductItem,
      availableProducts
    });
  };

  handleFormContactUpdate = () => {
    const { user, userId } = this.state.quote;

    return this.props
      .updateFormContact({
        variables: {
          _id: userId,
          input: _.pick(user, ['firstName', 'lastName', 'email', 'phone'])
        },
        refetchQueries: [
          {
            query: quoteQuery,
            variables: { _id: this.props.match.params._id }
          }
        ]
      })
      .then(() => this.notify('primary', 'User updated!'))
      .catch(() => this.notify('danger', 'Save failed!'));
  };

  handleQuoteUpdate = () => {
    const quote = _.omit(this.state.quote, [
      '_id',
      'user',
      'emails',
      'number',
      'subtotal',
      'tax',
      'total',
      'totalPayments',
      'balance',
      'workOrder',
      'createdByUser',
      'createdAt',
      'updatedAt',
      '__typename'
    ]);

    quote.items = quote.items.map((item) => _.omit(item, ['price', '__typename']));

    this.props
      .updateQuote({
        variables: {
          _id: this.props.quote._id,
          input: quote
        },
        refetchQueries: [
          {
            query: quoteQuery,
            variables: { _id: this.props.quote._id }
          }
        ]
      })
      .then(() => {
        this.notify('primary', 'Quote updated!');
        this.setState({ quoteItemsUpdated: false });
      })
      .catch(() => this.notify('danger', 'Save failed!'));
  };

  handleQuoteSend = () => {
    Alert({
      icon: 'warning',
      content: (
        <div>
          <h4>Are you sure?</h4>
          <div>
            This will send a new quote email to:
            <br />
            <strong>{this.props.quote.user.email}</strong>
          </div>
        </div>
      ),
      buttons: {
        cancel: true,
        confirm: 'Do it!'
      }
    }).then((confirmed) => {
      if (confirmed) {
        this.props
          .sendQuote(this.props.quote._id)
          .then(() => notify('success', 'Quote is being emailed!'))
          .catch((e) => notify('error', e.message.split('GraphQL error: ')[1]));
      }
    });
  };

  addProductLineItem = () => {
    const { newProductItem } = this.state;
    const items = _.clone(this.state.quote.items) || [];

    items.push({
      title: newProductItem.name,
      description: newProductItem.description,
      unitPrice: newProductItem.price,
      quantity: 1,
      price: newProductItem.price,
      productId: newProductItem._id,
      type: newProductItem.type
    });

    this.setState((state) => _.setWith(_.clone(state), 'quote.items', items, _.clone));

    this.setState({
      quoteItemsUpdated: true,
      availableProducts: {
        selectedDiamond: { label: '', value: '' },
        selectedRing: { label: '', value: '' },
        selectedJewelry: { label: '', value: '' }
      }
    });
  };

  addMiscLineItem = () => {
    const items = _.clone(this.state.quote.items) || [];

    items.push({
      title: '',
      description: '',
      unitPrice: 0,
      quantity: 1,
      price: 0,
      productId: null,
      type: 'Misc'
    });

    this.setState((state) => _.setWith(_.clone(state), 'quote.items', items, _.clone));
    this.setState({ quoteItemsUpdated: true });
  };

  deleteQuoteItem = (itemIndex) => {
    const items = _.clone(this.state.quote.items);
    items.splice(itemIndex, 1);
    this.setState((state) => _.setWith(_.clone(state), 'quote.items', items, _.clone));
    this.setState({ quoteItemsUpdated: true });
  };

  notify(type, message) {
    this.refs.notificationAlert.notificationAlert({
      place: 'tc',
      message,
      type, // success|danger
      autoDismiss: 3
    });
  }

  calculateTotals = (cb) => {
    const totals = calculateOrderTotals(this.state.quote);
    this.setState((state) => _.setWith(_.clone(state), 'quote', { ...state.quote, ...totals }, _.clone), cb);
  };

  render() {
    const { quote } = this.props;

    if (!quote) {
      return <div>No quote found!</div>;
    }

    return (
      <>
        <Wrapper>
          <NotificationAlert ref="notificationAlert" />
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col md={{ size: 3, order: 3 }}>
                {/* Quote Lock */}
                <Row>
                  <Col sm={12}>
                    <Card>
                      <CardBody>
                        <label htmlFor="lock-switch">
                          <span>
                            <h4 style={{ marginTop: '0px' }}>Quote {this.state.locked ? 'Locked' : 'Unlocked'}</h4>
                          </span>
                          <Toggle
                            onChange={this.handleLockedStateChange}
                            defaultChecked={this.state.locked}
                            className="react-switch"
                            id="lock-switch"
                            icons={{
                              checked: <Icon name={faLock} />,
                              unchecked: <Icon name={faLockOpen} />
                            }}
                          />
                        </label>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {/* Email History */}
                <Row>
                  <Col sm={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle>
                          <h5>Email History</h5>
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        {quote.emails && quote.emails.length ? (
                          quote.emails.map((email) => (
                            <ListItem key={email._id}>
                              <strong>Sent:</strong> {format(new Date(email.updatedAt), 'MMMM d, yyyy @ h:mma')}
                            </ListItem>
                          ))
                        ) : (
                          <div>No emails sent yet!</div>
                        )}
                        {/* <pre>{JSON.stringify(quote.emails[quote.emails.length - 1], null, 2)}</pre> */}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>

              {/* Contact Info */}
              <Col md={3}>
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <h5>Contact Info</h5>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={11}>
                        <Form>
                          <FormInputsLegacy
                            inputs={[
                              {
                                label: 'First Name',
                                colProps: {
                                  xs: 12
                                },
                                inputProps: {
                                  type: 'text',
                                  name: 'quote.user.firstName',
                                  defaultValue: quote.user.firstName,
                                  onChange: this.handleStateChange,
                                  disabled: this.state.locked
                                }
                              },
                              {
                                label: 'Last Name',
                                colProps: {
                                  xs: 12
                                },
                                inputProps: {
                                  type: 'text',
                                  name: 'quote.user.lastName',
                                  defaultValue: quote.user.lastName,
                                  onChange: this.handleStateChange,
                                  disabled: this.state.locked
                                }
                              },
                              {
                                label: 'Email',
                                colProps: {
                                  xs: 12
                                },
                                inputProps: {
                                  type: 'text',
                                  name: 'quote.user.email',
                                  defaultValue: quote.user.email,
                                  onChange: this.handleStateChange,
                                  disabled: this.state.locked
                                }
                              },
                              {
                                label: 'Phone',
                                colProps: {
                                  xs: 12
                                },
                                inputProps: {
                                  type: 'text',
                                  name: 'quote.user.phone',
                                  defaultValue: quote.user.phone,
                                  onChange: this.handleStateChange,
                                  disabled: this.state.locked
                                }
                              }
                            ]}
                          />
                        </Form>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    {!this.state.locked && (
                      <RightAligned>
                        <Button color="primary" type="submit" onClick={this.handleFormContactUpdate}>
                          Save User
                        </Button>
                      </RightAligned>
                    )}
                  </CardFooter>
                </Card>
              </Col>

              {/* message */}
              <Col md={6}>
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <h5>Message</h5>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        {this.state.locked ? (
                          <div dangerouslySetInnerHTML={{ __html: quote.description }} />
                        ) : (
                          <Form>
                            <FormInputsLegacy
                              inputs={[
                                {
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'wysiwyg',
                                    name: 'quote.description',
                                    defaultValue: quote.description,
                                    theme: 'snow',
                                    onChange: (val) => {
                                      this.setState((state) =>
                                        _.setWith(_.clone(state), 'quote.description', val, _.clone)
                                      );
                                    }
                                  }
                                }
                              ]}
                            />
                          </Form>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col sm={12}>
                        <RightAligned>
                          {this.state.locked ? (
                            <Button color="default" type="submit" onClick={this.handleQuoteSend}>
                              {!quote.emails || !quote.emails.length ? 'Send Email' : 'Resend Email'}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit" onClick={this.handleQuoteUpdate}>
                              Save Quote
                            </Button>
                          )}
                        </RightAligned>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md={3}>
                        <h2>Quote Items</h2>
                      </Col>
                      <Col md={9}>
                        <RightAligned>
                          {!quote.workOrder && (
                            <Button
                              color="default"
                              onClick={this.generateWorkOrder}
                              style={{ cursor: 'pointer', marginRight: '2em' }}
                            >
                              Generate Work Order
                            </Button>
                          )}
                          {!this.state.locked && (
                            <Modal
                              launchButtonText={
                                <span>
                                  <Icon name={faPlus} /> Product
                                </span>
                              }
                              submitButtonText="Add Item"
                              onSubmit={this.addProductLineItem}
                            >
                              <Form onSubmit={this.handleSubmit}>
                                <FormInputsLegacy
                                  inputs={[
                                    {
                                      label: 'Diamonds',
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'select',
                                        name: 'diamonds',
                                        options: this.createProductSelectOptions(this.props.diamonds),
                                        value: this.state.availableProducts.selectedDiamond,
                                        onChange: (item) => {
                                          this.handleProductSelectChange({ newProductItem: item });
                                        }
                                      }
                                    },
                                    {
                                      label: 'Rings',
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'select',
                                        name: 'rings',
                                        options: this.createProductSelectOptions(this.props.rings),
                                        value: this.state.availableProducts.selectedRing,
                                        onChange: (item) => {
                                          const product = {
                                            ...item,
                                            description: item.parent.shortDescription?.replace(/\n/g, '<br>')
                                          };
                                          this.handleProductSelectChange({ newProductItem: product });
                                        }
                                      }
                                    },
                                    {
                                      label: 'Jewelry',
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'select',
                                        name: 'jewelries',
                                        options: this.createProductSelectOptions(this.props.jewelries),
                                        value: this.state.availableProducts.selectedJewelry,
                                        onChange: (item) => {
                                          const product = {
                                            ...item,
                                            description: item.parent.shortDescription?.replace(/\n/g, '<br>')
                                          };
                                          this.handleProductSelectChange({ newProductItem: product });
                                        }
                                      }
                                    }
                                  ]}
                                />
                              </Form>
                            </Modal>
                          )}
                          {!this.state.locked && (
                            <Button color="primary" onClick={this.addMiscLineItem}>
                              <Icon name={faPlus} />
                            </Button>
                          )}
                          {this.state.locked && (
                            <Link to={`/quoting/quotes/${this.props.match.params._id}/print`}>
                              <Button color="primary" type="submit">
                                <Icon name={faPrint} /> Print Quote
                              </Button>
                            </Link>
                          )}
                        </RightAligned>
                      </Col>
                      <hr />
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Unit Price</th>
                          <th>Quantity</th>
                          <th>Total</th>
                          {!this.state.locked && <th />}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.quote.items.map((item, i) =>
                          this.state.locked ? (
                            <tr key={i}>
                              <td>
                                <strong>{item.title}</strong>
                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                              </td>
                              <td>{formatPrice(item.unitPrice)}</td>
                              <td>{item.quantity}</td>
                              <td>{formatPrice(item.price)}</td>
                            </tr>
                          ) : (
                            <tr key={i}>
                              <td>
                                <Form onSubmit={this.handleSubmit}>
                                  <FormInputsLegacy
                                    inputs={[
                                      {
                                        colProps: {
                                          xs: 12
                                        },
                                        inputProps: {
                                          type: 'text',
                                          name: `quote.items[${i}].title`,
                                          defaultValue: item.title,
                                          onChange: (e) => {
                                            this.handleStateChange(e);
                                          }
                                        }
                                      },
                                      {
                                        colProps: {
                                          xs: 12
                                        },
                                        inputProps: {
                                          type: 'wysiwyg',
                                          name: `quote.items[${i}].description`,
                                          theme: 'snow',
                                          defaultValue: item.description,
                                          onChange: (val) => {
                                            this.setState((state) =>
                                              _.setWith(_.clone(state), `quote.items[${i}].description`, val, _.clone)
                                            );
                                          }
                                        }
                                      }
                                    ]}
                                  />
                                </Form>
                              </td>
                              <td>
                                <Cleave
                                  name={`quote.items[${i}].unitPrice`}
                                  value={parseFloat(_.get(this.state, `quote.items[${i}].unitPrice`), 10) * 0.01}
                                  className="form-control"
                                  onChange={(e) => {
                                    const { name, rawValue } = e.target;

                                    const unitPrice = Math.round(parseFloat(rawValue) * 100);
                                    const quantity = _.get(this.state, `quote.items[${i}].quantity`);
                                    const price = multiply(unitPrice, quantity);

                                    this.setState((state) => _.setWith(_.clone(state), name, unitPrice, _.clone));
                                    this.setState(
                                      (state) => _.setWith(_.clone(state), `quote.items[${i}].price`, price, _.clone),
                                      () => this.calculateTotals()
                                    );
                                  }}
                                  options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand',
                                    numeralDecimalScale: 2,
                                    prefix: '$',
                                    rawValueTrimPrefix: true,
                                    numericOnly: true
                                  }}
                                />
                              </td>
                              <td>
                                <Input
                                  name={`quote.items[${i}].quantity`}
                                  type="number"
                                  defaultValue={item.quantity}
                                  onChange={(e) => {
                                    const { name, value } = e.target;

                                    const unitPrice = _.get(this.state, `quote.items[${i}].unitPrice`);
                                    const quantity = parseFloat(value, 10);
                                    const price = multiply(unitPrice, value);

                                    this.setState((state) => _.setWith(_.clone(state), name, quantity, _.clone));
                                    this.setState(
                                      (state) => _.setWith(_.clone(state), `quote.items[${i}].price`, price, _.clone),
                                      () => this.calculateTotals()
                                    );
                                  }}
                                />
                              </td>
                              <td>{formatPrice(_.get(this.state, `quote.items[${i}].price`))}</td>
                              <td>
                                <Button color="danger" onClick={() => this.deleteQuoteItem(i)}>
                                  <Icon name={faTrash} />
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                    <br />
                    <hr />
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md={{ size: 3, offset: 9 }}>
                        <Row>
                          <Col sm={4}>
                            <Label>Subtotal</Label>
                          </Col>
                          <Col sm={8}>{formatPrice(_.get(this.state, 'quote.subtotal'))}</Col>
                        </Row>
                        <Row style={{ marginTop: '1.5rem' }}>
                          <Col sm={4}>
                            <Label>Shipping</Label>
                          </Col>
                          <Col sm={8}>
                            {this.state.locked ? (
                              !!this.state.quote.shipping && formatPrice(_.get(this.state, 'quote.shipping'))
                            ) : (
                              <Cleave
                                name="quote.shipping"
                                value={parseFloat(_.get(this.state, 'quote.shipping'), 10) * 0.01}
                                className="form-control"
                                style={{ maxWidth: '100px' }}
                                disabled={this.state.locked}
                                onChange={(e) => {
                                  const { rawValue } = e.target;
                                  const shipping = parseFloat(Number(rawValue), 10) * 100;
                                  this.setState(
                                    (state) => _.setWith(_.clone(state), 'quote.shipping', shipping, _.clone),
                                    () => this.calculateTotals()
                                  );
                                }}
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand',
                                  numeralDecimalScale: 2,
                                  prefix: '$',
                                  rawValueTrimPrefix: true,
                                  numericOnly: true
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <label htmlFor="tax" style={{ marginTop: '1rem' }}>
                              <p>Tax</p>
                              <Toggle
                                onChange={(e) => {
                                  const hasTax = e.target.checked;
                                  this.setState(
                                    (state) => _.setWith(_.clone(state), 'quote.hasTax', hasTax, _.clone),
                                    () => this.calculateTotals()
                                  );
                                }}
                                defaultChecked={!!this.state.quote.hasTax}
                                disabled={this.state.locked}
                                className="react-switch"
                                id="tax"
                              />
                            </label>
                          </Col>
                          <Col md={6}>
                            <div style={{ marginTop: '3rem' }}>
                              {!!this.state.quote.tax && formatPrice(_.get(this.state, 'quote.tax'))}
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: '1rem' }}>
                          <Col md={4}>
                            <Label>
                              <strong>Total</strong>
                            </Label>
                          </Col>
                          <Col md={6}>
                            <div>
                              <strong>{formatPrice(_.get(this.state, 'quote.total'))}</strong>
                            </div>
                          </Col>
                        </Row>
                        <hr />
                      </Col>
                    </Row>
                    {!!quote.workOrder && (
                      <Link to={`/quoting/work-orders/${quote.workOrder._id}`}>
                        A work order was generated on{' '}
                        <DateFormat date={quote.workOrder.createdAt} format="MMMM d, yyyy @ h:mma" />
                      </Link>
                    )}
                    {!this.state.locked && (
                      <RightAligned>
                        <Button color="primary" type="submit" onClick={this.handleQuoteUpdate}>
                          Save Quote
                        </Button>
                      </RightAligned>
                    )}
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
        </Wrapper>

        {/* alert the user if they try to leave the page before saving changes */}
        <Prompt
          when={this.state.quoteItemsUpdated}
          message="You have unsaved changes! Are you sure you want to leave?"
        />
      </>
    );
  }
}

export default compose(
  withQuote,
  withQuoteUpdate,
  withSendQuote,
  withFormContactUpdate,
  withAvailableProducts,
  withWorkOrderCreate,
  withWorkOrderItemCreate,
  withWorkOrderLock
)(Quote);
