import gql from 'graphql-tag';

export default gql`
  mutation updateDiamond($where: DiamondWhereUniqueInput!, $data: DiamondUpdateInput!) {
    updateDiamond(where: $where, data: $data) {
      id
      updatedAt
    }
  }
`;
