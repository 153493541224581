import gql from 'graphql-tag';

export default gql`
  query workOrder($_id: ObjID!) {
    workOrder(_id: $_id) {
      _id
      userId
      user {
        _id
        firstName
        lastName
        email
        phone
        shippingAddress {
          name
          address1
          address2
          city
          state
          zip
          country
          phone
          isCopy
        }
        billingAddress {
          name
          address1
          address2
          city
          state
          zip
          country
          phone
          isCopy
        }
      }
      number
      invoiceId
      items {
        _id
        title
        description
        inStock
        unitPrice
        price
        quantity
        productId
        type
      }
      jobs {
        _id
        workOrderItemId
        completedAt
      }
      payments {
        _id
        amount
        paymentType
        status
        tax
        taxPercent
        createdAt
        createdByUser {
          _id
          email
        }
        user {
          _id
          email
        }
      }
      hasTax
      tax
      shipping
      subtotal
      total
      totalPayments
      balance
      createdByUser {
        firstName
        lastName
      }
      locked
      isComplete
      completedAt
      createdAt
      updatedAt
    }
  }
`;
