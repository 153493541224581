import { graphql } from '@apollo/react-hoc';
import sendQuote from 'graphql/sendQuote';
import quoteQuery from 'graphql/quote';

export default (Component) => graphql(sendQuote, {
  props: ({ mutate }) => ({
    sendQuote: (_id) => mutate({
      variables: { _id },
      refetchQueries: [{
        query: quoteQuery,
        variables: { _id }
      }]
    })
  })
})(Component);
