import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = ({ name }) => (
  <FontAwesomeIcon icon={name} />
);

Icon.propTypes = {
  name: PropTypes.any.isRequired
};

export default Icon;
