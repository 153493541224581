import gql from 'graphql-tag';

export default gql`
  query products(
    $where: ProductWhereInput
    $after: ProductWhereUniqueInput
    $before: ProductWhereUniqueInput
    $first: Int
    $orderBy: [ProductOrderByInput!]
  ) {
    products(where: $where, after: $after, before: $before, first: $first, orderBy: $orderBy) {
      _id: id
      id
      createdAt
      modelNumber
      enabled
      name
      price
      quantity
      sku
      type
      variants {
        id
      }
    }
  }
`;
