import gql from 'graphql-tag';

export default gql`
  mutation lockWorkOrder($_id: ObjID!) {
    lockWorkOrder(_id: $_id) {
      _id
      locked
      updatedAt
    }
  }
`;
