import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import withUploadImages from 'containers/withUploadImages';
import { notify } from 'lib/utils';

const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  height: 150px;
  padding: .5rem;
  border: 2px dashed #acacac;
  text-align: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

class BulkImageUpload extends React.Component {
  static propTypes = {
    category: PropTypes.string,
    productId: PropTypes.string,
    productType: PropTypes.string,
    refetch: PropTypes.func,
    refetchQueries: PropTypes.arrayOf(PropTypes.object),
    uploadImages: PropTypes.func.isRequired
  }

  state = {
    files: [],
    uploaded: 0,
    buttonText: 'Upload'
  }

  onDrop = (files) => {
    this.setState({ files });
  }

  handleUpload = () => {
    this.setState({ buttonText: 'Uploading...' });

    const { files } = this.state;
    const { productId, productType, category, refetchQueries } = this.props;
    const data = {
      productId: productType !== 'diamond' ? productId : undefined,
      diamondId: productType === 'diamond' ? productId : undefined,
      productType,
      category
    };
    this.props.uploadImages({ variables: { files, data }, refetchQueries })
      .then(({ data: { uploadImages } }) => {
        this.setState({ files: [], uploaded: 0, buttonText: 'Upload' });
        !!this.props.refetch && this.props.refetch();
        notify('success', `${uploadImages.length} files successfully uploaded!`);
      })
      .catch((error) => notify('error', `Upload failed! \n ${error}`));
  }

  render() {
    return (
      <Row>
        <Col md={3}>
          <Dropzone
            accept="image/*"
            onDrop={this.onDrop}
            multiple
          >
            {({ getRootProps, getInputProps }) => (
              <DropzoneWrapper {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drop some images here,<br />or click to select files to upload.</p>
              </DropzoneWrapper>
            )}
          </Dropzone>

          {!!this.state.files.length
            && (
            <Button onClick={this.handleUpload} disabled={this.state.buttonText === 'Uploading'}>
              {this.state.buttonText} {this.state.files.length} files
            </Button>
            )}
        </Col>

        {!!this.state.files.length
          && (
          <Col xs={12}>
            <h4 style={{ marginTop: '0px' }}>SELECTED FILES: {this.state.files.length}</h4>
            {/* <p>
              Uploaded {this.state.uploaded} of {this.state.files.length} ({(Math.round((this.state.uploaded / this.state.files.length) * 100))}%)
            </p> */}
          </Col>
          )}
      </Row>
    );
  }
}

export default withUploadImages(BulkImageUpload);
