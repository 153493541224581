import gql from 'graphql-tag';

export default gql`
  mutation uploadImage($data: ImageUploadAttributesInput!, $file: Upload!) {
    uploadImage(data: $data, file: $file) {
      id
      url
    }
  }
`;
