import React from 'react';
import { Mutation } from '@apollo/react-components';
import quoteUpdate from 'graphql/quoteUpdate';

export default (Component) => (
  class withQuoteUpdate extends React.Component {
    render() {
      return (
        <Mutation mutation={quoteUpdate}>
          {(mutate) => <Component {...this.props} updateQuote={mutate} />}
        </Mutation>
      );
    }
  }
);
