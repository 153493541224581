import gql from 'graphql-tag';

export default gql`
  query customDesignRequest($_id: ObjID!) {
    customDesignRequest(_id: $_id) {
      _id
      userId
      user {
        _id
        firstName
        lastName
        email
        phone
      }
      quote {
        _id
      }
      designs
      productType
      metal
      budgetFrom
      budgetTo
      timeframe
      comments
      caratFrom
      caratTo
      clarityFrom
      clarityTo
      colorFrom
      colorTo
      cut
      fingerSize
      ringType
      prongs
      shank
      shankThickness
      shape
      skuOrTitle
      pave
      isCenterstoneRequired
      isEngravingRequired
      isPaveRequired
      isFiligreeRequired
      isCenterstoneRequired
      status
      createdAt
      updatedAt
      remoteAddress
    }
  }
`;
