import React from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Card, CardBody, Button, Row, Col } from 'reactstrap';
import styled from 'styled-components';

import { Loading, Image } from '../components';
import { formatPrice } from '../lib/utils';
import { Diamond, useDiamondQuery } from '../graphql/codegen';

interface VirtualProductData {
  virtualProduct: Diamond;
}

const ProductDetailCol = styled(Col)`
  padding-left: 2rem;

  span {
    font-weight: bold;
    margin-right: 0.7rem;
  }
`;

const VirtualProduct: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { loading, error, data } = useDiamondQuery({ variables: { slugOrId: id || '' } });

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  const diamond = data?.diamond;

  if (!diamond) {
    return <div>Diamond not found</div>;
  }

  return (
    <Row>
      <Col xs={12}>
        <Card>
          <CardBody style={{ paddingTop: '2rem', paddingBottom: '3rem' }}>
            {loading ? (
              <Loading />
            ) : (
              <Row>
                <ProductDetailCol sm={12} md={3}>
                  <h5>Virtual Product Detail</h5>
                  <hr />
                  <div>
                    <span>Vendor:</span>
                    <Link to={`/vendors/${diamond.vendorId}`}>{diamond.vendor?.name}</Link>
                  </div>
                  <div>
                    <span>Reference No (SKU):</span>
                    {diamond.sku}
                  </div>
                  <div>
                    <span>Cost (vendor price):</span>
                    {diamond.cost && formatPrice(diamond.cost)}
                  </div>
                  <div>
                    <span>Price (our price):</span>
                    {diamond.price && formatPrice(diamond.price)}
                  </div>
                  <div>
                    <span>Carat:</span>
                    {diamond.carat}
                  </div>
                  <div>
                    <span>Clarity:</span>
                    {diamond.clarity?.label}
                  </div>
                  <div>
                    <span>Color:</span>
                    {diamond.color?.label}
                  </div>
                  <div>
                    <span>Crown Angle:</span>
                    {diamond.crownAngle}
                  </div>
                  <div>
                    <span>Culet:</span>
                    {diamond.culet?.label}
                  </div>
                  <div>
                    <span>Cut:</span>
                    {diamond.cut?.label}
                  </div>
                  <div>
                    <span>Depth (mm):</span>
                    {diamond.depthMM}
                  </div>
                  <div>
                    <span>Depth Percent:</span>
                    {diamond.depthPercent}
                  </div>
                  <div>
                    <span>Fluorescence:</span>
                    {diamond.fluorescence?.label}
                  </div>
                  {/* <div>
                      <span>Girdle:</span>
                      {diamond?.girdles.map((g) => g.label).join(', ')}
                    </div> */}
                  <div>
                    <span>Lab:</span>
                    {diamond.lab?.name}
                  </div>
                  <div>
                    <span>Lab Report #:</span>
                    {diamond.lab?.reportNumber}
                  </div>
                  <div>
                    <span>Light Performance:</span>
                    {diamond.lightPerformance?.label}
                  </div>
                  <div>
                    <span>Name:</span>
                    {diamond.name}
                  </div>
                  <div>
                    <span>Pavilion Angle:</span>
                    {diamond.pavilionAngle}
                  </div>
                  <div>
                    <span>Polish:</span>
                    {diamond.polish?.label}
                  </div>
                  <div>
                    <span>Shape:</span>
                    {diamond.shape?.label}
                  </div>
                  <div>
                    <span>Symmetry:</span>
                    {diamond.symmetry?.label}
                  </div>
                  <div>
                    <span>Table Percent:</span>
                    {diamond.tablePercent}
                  </div>
                  <div>
                    <span>Last Updated:</span>
                    {new Date(diamond.updatedAt).toLocaleString()}
                  </div>
                  {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                </ProductDetailCol>
                <Col sm={12} md={9}>
                  <Row>
                    <Col sm={12} md={5}>
                      <div>
                        {!!diamond.images?.length && diamond.images[0] && (
                          <Image src={diamond.images[0].url} alt="product" width={500} />
                        )}
                      </div>
                    </Col>
                    <Col sm={12} md={7}>
                      {!!diamond.images360IFrameUrl && (
                        <iframe
                          id="product360"
                          title="Product 360 View"
                          width="550px"
                          height="650px"
                          src={diamond.images360IFrameUrl}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Button onClick={(): void => history.goBack()}>Go back</Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default VirtualProduct;
