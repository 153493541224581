import React from 'react';

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

export const Card: React.FC<Props> = ({ children }) => (
  <div className="bg-white overflow-hidden shadow sm:rounded-lg">
    <div className="px-4 py-5 sm:p-6">
      {children}
    </div>
  </div>
);

export default Card;
