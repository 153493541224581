import gql from 'graphql-tag';

export default gql`
  mutation removePayment($_id: ObjID!) {
    removePayment(_id: $_id) {
      n
      ok
    }
  }
`;
