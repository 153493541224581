import gql from 'graphql-tag';

export default gql`
  query formContacts {
    formContacts {
      _id
      email
      firstName
      lastName
      customDesignRequests {
        _id
      }
      quotes {
        _id
      }
      workOrders {
        _id
      }
      createdAt
    }
  }
`;
