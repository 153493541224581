import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import format from 'date-fns/format';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { PanelHeader, Button, FormInputsLegacy, FlexSpaceBetween, Icon, RightAligned } from 'components';
import withInvoice from 'containers/withInvoice';
import withInvoiceUpdate from 'containers/withInvoiceUpdate';
import withInvoiceSend from 'containers/withInvoiceSend';
import invoiceQuery from 'graphql/invoiceQuery';
import emailsQuery from 'graphql/emailsQuery';
import { formatPrice, notify } from 'lib/utils';

const ListItem = styled.div`
  margin-bottom: 0.5rem;
`;

const InvoicePreview = styled.div`
  padding: 1rem 2rem;
`;

const Wrapper = styled.div`
  @media print {
    display: none;
  }
`;

class Invoice extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    invoice: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    sendInvoice: PropTypes.func.isRequired,
    updateInvoice: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      invoice: props.invoice,
      unsavedWork: false
    };
  }

  handleStateChange = (e) => {
    const t = e.target;
    this.setState({ unsavedWork: true });
    this.setState((state) => _.setWith(_.clone(state), t.name, t.value, _.clone));
  };

  handleInvoiceUpdate = () => {
    const invoice = _.pick(this.state.invoice, ['to', 'subject', 'note', 'terms']);

    this.props
      .updateInvoice({
        variables: {
          _id: this.props.invoice._id,
          input: invoice
        },
        refetchQueries: [
          {
            query: invoiceQuery,
            variables: { _id: this.props.invoice._id }
          }
        ]
      })
      .then(() => {
        this.setState({ unsavedWork: false });
        notify('success', 'Invoice updated!');
      })
      .catch(() => notify('error', 'Save failed!'));
  };

  handleInvoiceSend = () => {
    this.props
      .sendInvoice({
        variables: {
          _id: this.props.invoice._id
        },
        refetchQueries: [
          {
            query: invoiceQuery,
            variables: { _id: this.props.invoice._id }
          },
          {
            query: emailsQuery,
            variables: { invoiceId: this.props.invoice._id }
          }
        ]
      })
      .then(() => notify('success', 'Invoice is being emailed!'))
      .catch(() => notify('error', 'Save failed!'));
  };

  render() {
    const { invoice } = this.props;

    if (!invoice) {
      return <div>No invoice found!</div>;
    }

    const latestEmailSent = invoice.emails && invoice.emails.length && invoice.emails[invoice.emails.length - 1];

    return (
      <div>
        <Wrapper>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col md={4}>
                <Card>
                  {invoice.workOrderId ? (
                    <>
                      <CardHeader>
                        <CardTitle>
                          <h5>Work Order Details</h5>
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <ListItem>
                          <strong>Work Order:</strong>{' '}
                          <Link to={`/quoting/work-orders/${invoice.workOrderId}`}>{invoice.workOrderId}</Link>
                        </ListItem>
                        <ListItem>
                          <strong>Quote:</strong>{' '}
                          <Link to={`/quoting/quotes/${invoice.workOrder.quoteId}`}>{invoice.workOrder.quoteId}</Link>
                        </ListItem>
                        <ListItem>
                          <strong>Completed:</strong>{' '}
                          {format(new Date(invoice.workOrder.completedAt), 'MMMM d, yyyy @ h:mma')}
                        </ListItem>
                        <ListItem>
                          <strong>Total:</strong> {formatPrice(invoice.workOrder.total)}
                        </ListItem>
                      </CardBody>
                    </>
                  ) : (
                    <>
                      <CardHeader>
                        <CardTitle>
                          <h5>Order Details</h5>
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <ListItem>
                          <strong>Order:</strong> <Link to={`/orders/${invoice.orderId}`}>{invoice.orderId}</Link>
                        </ListItem>
                        <ListItem>
                          <strong>Total:</strong> {formatPrice(invoice.total)}
                        </ListItem>
                      </CardBody>
                    </>
                  )}
                  <CardFooter>
                    <RightAligned>
                      <Link to={`/invoices/${this.props.match.params._id}/print`}>
                        <Button color="primary" type="submit">
                          <Icon name={faPrint} /> Print Invoice
                        </Button>
                      </Link>
                    </RightAligned>
                  </CardFooter>
                </Card>
              </Col>
              <Col md={5}>
                <Card>
                  <CardHeader>
                    <CardTitle>Invoice</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <FormInputsLegacy
                        inputs={[
                          {
                            label: 'To',
                            colProps: {
                              xs: 6
                            },
                            inputProps: {
                              type: 'email',
                              name: 'invoice.to',
                              defaultValue: invoice.to,
                              onChange: this.handleStateChange
                            }
                          },
                          {
                            label: 'Subject',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'text',
                              name: 'invoice.subject',
                              defaultValue: invoice.subject,
                              onChange: this.handleStateChange
                            }
                          }
                        ]}
                      />
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <FlexSpaceBetween>
                        <Button color="primary" type="submit" onClick={this.handleInvoiceUpdate}>
                          Save
                        </Button>
                        <Button
                          color="info"
                          type="submit"
                          onClick={this.handleInvoiceSend}
                          disabled={this.state.unsavedWork}
                        >
                          {!invoice.emails || !invoice.emails.length ? 'Send Email' : 'Resend Email'}
                        </Button>
                      </FlexSpaceBetween>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardHeader>
                    <CardTitle>Email History</CardTitle>
                  </CardHeader>
                  <CardBody>
                    {invoice.emails && invoice.emails.length ? (
                      invoice.emails.map((email) => (
                        <ListItem key={email._id}>
                          <strong>Sent:</strong> {format(new Date(email.updatedAt), 'MMMM d, yyyy @ h:mma')}
                        </ListItem>
                      ))
                    ) : (
                      <div>No emails sent yet!</div>
                    )}
                    {/* <pre>{JSON.stringify(invoice.emails[invoice.emails.length - 1], null, 2)}</pre> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!!latestEmailSent && (
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <div>
                        <strong>Last email sent:</strong>{' '}
                        {format(new Date(latestEmailSent.updatedAt), 'MMMM d, yyyy @ h:mma')}
                      </div>
                      <InvoicePreview dangerouslySetInnerHTML={{ __html: latestEmailSent.html }} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </Wrapper>
      </div>
    );
  }
}

export default compose(withInvoice, withInvoiceUpdate, withInvoiceSend)(Invoice);
