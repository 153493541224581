import { graphql } from '@apollo/react-hoc';
import gql from 'graphql-tag';

const sendPaymentReceivedEmail = gql`
  mutation sendPaymentReceivedEmail($input: PaymentReceivedEmailInput!) {
    sendPaymentReceivedEmail(input: $input) {
      result
    }
  }
`;

export default (Component) => graphql(sendPaymentReceivedEmail, {
  props: ({ mutate }) => ({
    sendPaymentReceivedEmail: (input) => mutate({ variables: { input } })
  })
})(Component);
