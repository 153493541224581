import React from 'react';
import {
  Form,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Table,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { faCircle, faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Mutation } from '@apollo/react-components';
import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { format, parseISO } from 'date-fns';
import { PanelHeader, FormInputsLegacy, Button, Icon, UserIsInRole } from 'components';
import withOrder from 'containers/withOrder';
import withOrderPayments from 'containers/withOrderPayments';
import withInvoiceCreate from 'containers/withInvoiceCreate';
import orderQuery from 'graphql/orderQuery';
import orderUpdate from 'graphql/orderUpdate';
import paymentDelete from 'graphql/paymentDelete';
import { formatPrice, notify } from 'lib/utils';

const ActionBar = styled.div`
  display: flex;
  justify-content: space-around;
`;

class Order extends React.Component {
  static propTypes = {
    createInvoice: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    order: PropTypes.object,
    payments: PropTypes.object,
    refetchOrder: PropTypes.func.isRequired,
    refetchPayments: PropTypes.func.isRequired,
    updateOrder: PropTypes.func.isRequired
  };

  state = {
    statusChanged: false
  };

  handleStatusUpdate = () => {
    this.props
      .updateOrder({ _id: this.props.match.params._id, input: { status: this.state.status } })
      .then(() => {
        notify('success', 'Order status updated!');
        this.setState({ statusChanged: false });
      })
      .catch(() => notify('error', 'Save failed!'));
  };

  createInvoice = () => {
    const { order } = this.props;

    const invoice = {
      userId: order.userId,
      orderId: order._id,
      to: order.user.email,
      type: 'order'
    };

    this.props
      .createInvoice({
        variables: {
          input: invoice
        },
        refetchQueries: [
          {
            query: orderQuery,
            variables: { _id: order._id }
          }
        ]
      })
      .then(({ data: { createInvoice } }) => {
        this.props.history.push(`/invoices/${createInvoice._id}`);
      })
      .catch(() => notify('error', 'Invoice creation failed!'));
  };

  createProductLineItemUrl = (item) => {
    switch (item.type) {
      case 'Diamond':
        return `/products/${item.vendorId ? 'virtual' : 'diamonds'}/${item.productId}`;
      case 'Ring':
        return `/products/rings/${item.productId}`;
      default:
        return `/products/jewelry/${item.productId}`;
    }
  };

  render() {
    const { order, payments } = this.props;

    if (!order) {
      return <div>No order found!</div>;
    }

    const { user, items } = order;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col sm={6} xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle>Customer Info</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <ListGroup>
                        <ListGroupItem>
                          <span style={{ fontWeight: 'bold' }}>First Name: </span>
                          <span>{user.firstName}</span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <span style={{ fontWeight: 'bold' }}>Last Name: </span>
                          <span>{user.lastName}</span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <span style={{ fontWeight: 'bold' }}>Email: </span>
                          <span>{user.email}</span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <span style={{ fontWeight: 'bold' }}>Billing Telephone: </span>
                          <span>{user.billingAddress.phone}</span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <span style={{ fontWeight: 'bold' }}>Shipping Telephone: </span>
                          <span>{user.shippingAddress.phone}</span>
                        </ListGroupItem>
                        <ListGroupItem>
                          <span style={{ fontWeight: 'bold' }}>Shipping Address: </span>
                          <br />
                          <br />
                          <div>
                            <address>
                              {user.shippingAddress.name || `${user.firstName} ${user.lastName}`}
                              <br />
                              {user.shippingAddress.address1}
                              <br />
                              {user.shippingAddress.city}, {user.shippingAddress.state} {user.shippingAddress.zip}
                              <br />
                              {user.shippingAddress.country}
                            </address>
                          </div>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6} xs={12}>
              <Col sm={12}>
                <Card>
                  <CardHeader>
                    <CardTitle>Order Info</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm={12}>
                        <ListGroup>
                          <ListGroupItem>
                            <span style={{ fontWeight: 'bold' }}>Order Number: </span>
                            <span>{order.number}</span>
                          </ListGroupItem>
                          {order.referral && order.referral.name && (
                            <ListGroupItem>
                              <span style={{ fontWeight: 'bold' }}>Referred By: </span>
                              <span>{order.referral.name}</span>
                            </ListGroupItem>
                          )}
                          <ListGroupItem>
                            <span style={{ fontWeight: 'bold' }}>Sales Tax: </span>
                            <span>{formatPrice(order.tax)}</span>
                          </ListGroupItem>
                          <ListGroupItem>
                            <span style={{ fontWeight: 'bold' }}>Shipping: </span>
                            <span>{formatPrice(order.shipping)}</span>
                          </ListGroupItem>
                          <ListGroupItem>
                            <span style={{ fontWeight: 'bold' }}>Order Total: </span>
                            <span>{formatPrice(order.total)}</span>
                          </ListGroupItem>
                          <ListGroupItem>
                            <span style={{ fontWeight: 'bold' }}>Order Status: </span>
                            <br />
                            <br />
                            {order.status === 'Cancelled' ? (
                              <strong>Cancelled</strong>
                            ) : (
                              <Form>
                                <FormInputsLegacy
                                  inputs={[
                                    {
                                      colProps: {
                                        xs: 12
                                      },
                                      inputProps: {
                                        type: 'select',
                                        name: 'status',
                                        options: [
                                          {
                                            label: 'Bank Wire Pending',
                                            value: 'Bank Wire Pending'
                                          },
                                          {
                                            label: 'Bank Wire Received',
                                            value: 'Bank Wire Received'
                                          },
                                          {
                                            label: 'Credit Card Authorized',
                                            value: 'Credit Card Authorized'
                                          },
                                          {
                                            label: 'Credit Card Charged',
                                            value: 'Credit Card Charged'
                                          },
                                          {
                                            label: 'PayPal Authorized',
                                            value: 'PayPal Authorized'
                                          },
                                          {
                                            label: 'PayPal Settled',
                                            value: 'PayPal Settled'
                                          },
                                          {
                                            label: 'Complete',
                                            value: 'Complete'
                                          }
                                        ],
                                        defaultValue: {
                                          label: order.status,
                                          value: order.status
                                        },
                                        onChange: (value) => this.setState({ status: value.value, statusChanged: true })
                                      }
                                    }
                                  ]}
                                />
                              </Form>
                            )}
                            {this.state.statusChanged && (
                              <CardFooter>
                                <Button color="primary" type="submit" onClick={this.handleStatusUpdate}>
                                  Update Status
                                </Button>
                              </CardFooter>
                            )}
                          </ListGroupItem>
                        </ListGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={12}>
                <Card>
                  <CardBody>
                    <ActionBar>
                      {order.invoiceId ? (
                        <Link to={`/invoices/${order.invoiceId}`}>
                          <Button color="primary">View Invoice</Button>
                        </Link>
                      ) : (
                        <Button color="primary" onClick={this.createInvoice}>
                          Create Invoice
                        </Button>
                      )}
                      <Link to={`/orders/${order._id}/payments/new`}>
                        <Button color="primary">
                          <Icon name={faCircle} />
                          &nbsp; Record Payment
                        </Button>
                      </Link>
                      <ButtonDropdown
                        isOpen={this.state.emailDropdownOpen}
                        toggle={() => this.setState({ emailDropdownOpen: !this.state.emailDropdownOpen })}
                      >
                        <DropdownToggle caret color="primary">
                          <Icon name={faEnvelope} />
                          &nbsp; Transactional Emails
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => this.props.history.push(`/orders/${order._id}/shipment-confirmation`)}
                          >
                            <Link to={`/orders/${order._id}/shipment-confirmation`}>Shipment Confirmation</Link>
                          </DropdownItem>
                          <DropdownItem onClick={() => this.props.history.push(`/orders/${order._id}/follow-up`)}>
                            <Link to={`/orders/${order._id}/follow-up`}>Post sale follow up</Link>
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </ActionBar>
                  </CardBody>
                </Card>
              </Col>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h5>ORDER ITEMS</h5>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Quantity</th>
                            <th style={{ textAlign: 'right' }}>Unit Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!items.length &&
                            items.map((item) => (
                              <tr key={item.productId}>
                                <th>
                                  <Link to={this.createProductLineItemUrl(item)}>
                                    {item.sku} - {item.title}
                                  </Link>
                                  <div>{item.notes}</div>
                                </th>
                                <th>{item.quantity}</th>
                                <th style={{ textAlign: 'right' }}>{formatPrice(item.price)}</th>
                              </tr>
                            ))}
                          <tr>
                            <td />
                            <td />
                            <td style={{ lineHeight: '2' }}>
                              <div className="d-flex">
                                <div>Subtotal</div>
                                <div className="flex-grow-0 ml-auto">
                                  {formatPrice(order.subtotal, { trimDecimals: false })}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div>Tax</div>
                                <div className="flex-grow-0 ml-auto">
                                  {formatPrice(order.tax, { trimDecimals: false })}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div>Shipping</div>
                                <div className="flex-grow-0 ml-auto">
                                  {formatPrice(order.shipping, { trimDecimals: false })}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div>
                                  <strong>Total</strong>
                                </div>
                                <div className="flex-grow-0 ml-auto">
                                  <strong>{formatPrice(order.total, { trimDecimals: false })}</strong>
                                </div>
                              </div>
                              <div className="d-flex">
                                <div>Payments</div>
                                <div className="flex-grow-0 ml-auto">
                                  -{formatPrice(order.totalPayments, { trimDecimals: false })}
                                </div>
                              </div>
                              <div className="d-flex">
                                <div>
                                  <strong>Balance</strong>
                                </div>
                                <div className="flex-grow-0 ml-auto">
                                  <strong>{formatPrice(order.balance, { trimDecimals: false })}</strong>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h5>PAYMENTS</h5>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      {!payments.length ? (
                        <h4 className="text-center">No payments yet!</h4>
                      ) : (
                        <Table>
                          <thead>
                            <tr>
                              <th>Payment Type</th>
                              <th>Amount</th>
                              <th>Submitted</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {!!payments.length &&
                              payments.map((p) => (
                                <tr key={p._id}>
                                  <th>{p.paymentType}</th>
                                  <th>{formatPrice(p.amount)}</th>
                                  <th>{format(parseISO(p.createdAt), 'MMMM d, yyyy h:mma')}</th>
                                  <th>
                                    <UserIsInRole roles="admin">
                                      <Mutation
                                        mutation={paymentDelete}
                                        variables={{ _id: p._id }}
                                        onCompleted={() => {
                                          notify(
                                            'success',
                                            `Successfully deleted payment of: ${formatPrice(p.amount)}`
                                          );
                                          this.props.refetchOrder();
                                          this.props.refetchPayments();
                                        }}
                                        onError={(e) => notify('error', e.message.split('GraphQL error: ')[1])}
                                      >
                                        {(mutate) => (
                                          <Button onClick={mutate} color="danger" size="sm" icon>
                                            <Icon name={faTrash} />
                                          </Button>
                                        )}
                                      </Mutation>
                                    </UserIsInRole>
                                  </th>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(orderUpdate, {
    props: ({ mutate }) => ({
      updateOrder: (args) =>
        mutate({
          variables: args,
          refetchQueries: [
            {
              query: orderQuery,
              variables: { _id: args._id }
            }
          ]
        })
    })
  }),
  withOrder,
  withOrderPayments,
  withInvoiceCreate
)(Order);
