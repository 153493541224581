/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react';
import {
  Form,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Badge
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import { compose } from 'recompose';
import styled from 'styled-components';
import ReactTable from 'react-table-v6';
import _ from 'lodash';
import { faEye, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PanelHeader, FormInputsLegacy, Button, Loading, UserIsInRole, DateFormat, Icon } from 'components';
import withUser from 'containers/withUser';
import withUserUpdate from 'containers/withUserUpdate';
import withQuoteCreate from 'containers/withQuoteCreate';
import userQuery from 'graphql/userQuery';
import quoteDelete from 'graphql/quoteDelete';
import workOrderDelete from 'graphql/workOrderDelete';
import customDesignRequestDelete from 'graphql/customDesignRequestDelete';
import { usStates } from 'lib/constants';
import { formatPrice, notify } from 'lib/utils';

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

class User extends React.Component {
  static propTypes = {
    createQuote: PropTypes.func.isRequired,
    data: PropTypes.object,
    error: PropTypes.bool,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      userChanged: false,
      currentAddressTab: 'shipping',
      currentAssetsTab: 'tab1',
      ...props.data.user
    };
  }

  createSelectOptions(items) {
    if (!items || !Array.isArray(items) || !items.length) {
      return [{ label: 'Choose...', value: '' }];
    }
    return items.map(({ _id, label }) => ({ _id, label, value: _id }));
  }

  createUSStatesOptions() {
    return Object.keys(usStates).map((st) => ({ label: st, value: st }));
  }

  handleStateChange = (e) => {
    const t = e.target;
    this.setState(_.set(this.state, t.name, t.type === 'checkbox' ? t.checked : t.value));
  };

  handleSelectStateChange = (name, value) => {
    this.setState((state) => _.setWith(_.clone(state), name, value.value, _.clone));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const user = _.pick(this.state, [
      'email',
      'firstName',
      'lastName',
      'roles',
      'shippingAddress',
      'billingAddress',
      'billingIsSame'
    ]);

    delete user.__typename;

    if (user.shippingAddress) {
      delete user.shippingAddress.__typename;
    }

    if (user.billingAddress) {
      delete user.billingAddress.__typename;
    }

    user.billingAddress = user.billingIsSame ? user.shippingAddress : user.billingAddress;

    this.props
      .updateUser({ _id: this.props.data.user._id, input: user })
      // eslint-disable-next-line no-undef
      .then(() => document.location.reload())
      .catch((err) => notify('error', err.toString()));
  };

  generateQuote = () => {
    const { user } = this.props.data;

    this.props
      .createQuote({
        variables: {
          input: {
            userId: user._id,
            items: []
          }
        },
        refetchQueries: [
          {
            query: userQuery,
            variables: { _id: user._id }
          }
        ]
      })
      .then(({ data: { createQuote } }) => {
        this.props.history.push(`/quoting/quotes/${createQuote._id}`);
      })
      .catch(() => notify('error', 'Quote create failed!'));
  };

  renderAddressTabNav(tabName, label) {
    return (
      <NavItem>
        <NavLink
          className={this.state.currentAddressTab === tabName ? 'active' : ''}
          onClick={() => this.setState({ currentTab: tabName })}
        >
          {label}
        </NavLink>
      </NavItem>
    );
  }

  renderQuoteActions(_id) {
    return (
      <div className="actions-right">
        <Link to={`/quoting/quotes/${_id}`}>
          <Button color="primary" size="sm" icon>
            <Icon name={faEdit} />
          </Button>
        </Link>
        &nbsp;
        <Mutation
          mutation={quoteDelete}
          variables={{ _id }}
          refetchQueries={() => [
            {
              query: userQuery,
              variables: { _id: this.props.data.user._id }
            }
          ]}
          onCompleted={() => notify('success', `Successfully deleted quote ID: ${_id}`)}
          onError={(e) => notify('error', e.message.split('GraphQL error: ')[1])}
        >
          {(mutate) => (
            <Button onClick={mutate} color="danger" size="sm" icon>
              <Icon name={faTimes} />
            </Button>
          )}
        </Mutation>
        &nbsp;
      </div>
    );
  }

  renderWorkOrderActions(_id) {
    return (
      <div className="actions-right">
        <Link to={`/quoting/work-orders/${_id}`}>
          <Button color="primary" size="sm" icon>
            <Icon name={faEdit} />
          </Button>
        </Link>
        &nbsp;
        <Mutation
          mutation={workOrderDelete}
          variables={{ _id }}
          refetchQueries={() => [
            {
              query: userQuery,
              variables: { _id: this.props.data.user._id }
            }
          ]}
          onCompleted={() => notify('success', `Successfully deleted work order ID: ${_id}`)}
          onError={(e) => notify('error', e.message.split('GraphQL error: ')[1])}
        >
          {(mutate) => (
            <Button onClick={mutate} color="danger" size="sm" icon>
              <Icon name={faTimes} />
            </Button>
          )}
        </Mutation>
        &nbsp;
      </div>
    );
  }

  renderCustomRequestActions(_id) {
    return (
      <div className="actions-right">
        <Link to={`/quoting/custom-design-requests/${_id}`}>
          <Button color="primary" size="sm" icon>
            <Icon name={faEye} />
          </Button>
        </Link>
        &nbsp;
        <Mutation
          mutation={customDesignRequestDelete}
          variables={{ _id }}
          refetchQueries={() => [
            {
              query: userQuery,
              variables: { _id: this.props.data.user._id }
            }
          ]}
          onCompleted={() => notify('success', `Successfully deleted custom design request ID: ${_id}`)}
          onError={(e) => notify('error', e.message.split('GraphQL error: ')[1])}
        >
          {(mutate) => (
            <Button onClick={mutate} color="danger" size="sm" icon>
              <Icon name={faTimes} />
            </Button>
          )}
        </Mutation>
        &nbsp;
      </div>
    );
  }

  quoteColumns() {
    return [
      {
        Header: 'Quote Number',
        accessor: '_id',
        maxWidth: 240,
        sortable: false
      },
      {
        Header: 'Total Price',
        accessor: 'total',
        Cell: ({ value }) => formatPrice(value),
        maxWidth: 100,
        filterable: false
      },
      {
        Header: 'Locked?',
        accessor: 'locked',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        maxWidth: 100,
        filterable: false
      },
      {
        Header: 'Email Sent',
        accessor: 'emailedAt',
        Cell: ({ value }) => !!value && <DateFormat date={value} />,
        maxWidth: 150,
        filterable: false
      },
      {
        Header: 'Work Order?',
        accessor: 'workOrder',
        Cell: ({ value }) => (value && value._id ? <Link to={`/quoting/work-orders/${value._id}`}>Yes</Link> : 'No'),
        maxWidth: 120,
        filterable: false
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        sortMethod: (a, b) => (a > b ? 1 : -1),
        Cell: ({ value }) => <DateFormat date={value} />,
        maxWidth: 150
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
        sortable: false,
        filterable: false
      }
    ];
  }

  workOrderColumns() {
    return [
      {
        Header: 'Work Order #',
        accessor: '_id',
        maxWidth: 240,
        sortable: false
      },
      {
        Header: 'Client',
        accessor: 'user',
        Cell: ({ value }) => (value ? `${value.firstName} ${value.lastName} (${value.email})` : ''),
        maxWidth: 360,
        sortable: false,
        filterable: false
      },
      {
        Header: 'Total Price',
        accessor: 'total',
        sortMethod: (a, b) => (a > b ? 1 : -1),
        Cell: ({ value }) => formatPrice(value),
        maxWidth: 100,
        filterable: false
      },
      {
        Header: 'Locked?',
        accessor: 'locked',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        maxWidth: 100,
        filterable: false
      },
      {
        Header: 'Quote',
        accessor: 'quoteId',
        Cell: ({ value }) => !!value && <Link to={`/quoting/quotes/${value}`}>View</Link>,
        maxWidth: 120,
        filterable: false
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
        sortable: false,
        filterable: false
      }
    ];
  }

  customDesignRequestColumns() {
    return [
      {
        Header: 'Designs',
        accessor: 'designs',
        maxWidth: 140,
        filterable: false
      },
      {
        Header: 'Product Type',
        accessor: 'productType',
        maxWidth: 160
      },
      {
        Header: 'Has quote',
        accessor: 'quote',
        Cell: ({ value }) => (value && value._id ? <Link to={`/quoting/quotes/${value._id}`}>Yes</Link> : 'No'),
        maxWidth: 120,
        filterable: false
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        sortMethod: (a, b) => (a > b ? 1 : -1),
        Cell: ({ value }) => <DateFormat date={value} />,
        maxWidth: 150
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
        sortable: false,
        filterable: false
      }
    ];
  }

  orderColumns() {
    return [
      {
        Header: 'Order ID',
        accessor: '_id',
        filterable: false,
        Cell: ({ value }) => <Link to={`/orders/${value}`}>{value}</Link>
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Total Price',
        accessor: 'total',
        sortMethod: (a, b) => (a > b ? 1 : -1),
        Cell: ({ value }) => formatPrice(value),
        filterable: false
      },
      {
        Header: 'Submitted',
        accessor: 'createdAt',
        sortMethod: (a, b) => (a > b ? 1 : -1),
        Cell: ({ value }) => <DateFormat date={value} />,
        filterable: false
      }
    ];
  }

  render() {
    const { loading, error, data } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <div>
          <p>Error :(</p>
        </div>
      );
    }

    const { user } = data;

    if (!user) {
      return <div>No user found!</div>;
    }

    const { quotes, workOrders, customDesignRequests, orders } = user;

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={6} xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle>User Detail</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Form onSubmit={this.handleSubmit}>
                        <FormInputsLegacy
                          inputs={[
                            {
                              label: 'First Name',
                              colProps: {
                                xs: 12
                              },
                              inputProps: {
                                type: 'text',
                                name: 'firstName',
                                defaultValue: user.firstName,
                                onChange: this.handleStateChange
                              }
                            },
                            {
                              label: 'Last Name',
                              colProps: {
                                xs: 12
                              },
                              inputProps: {
                                type: 'text',
                                name: 'lastName',
                                defaultValue: user.lastName,
                                onChange: this.handleStateChange
                              }
                            },
                            {
                              label: 'Email',
                              colProps: {
                                xs: 12
                              },
                              inputProps: {
                                type: 'text',
                                name: 'email',
                                defaultValue: user.email,
                                onChange: this.handleStateChange
                              }
                            }
                          ]}
                        />
                        <UserIsInRole roles="admin">
                          <FormInputsLegacy
                            inputs={[
                              {
                                label: 'Role',
                                colProps: {
                                  xs: 12
                                },
                                inputProps: {
                                  type: 'select',
                                  name: 'role',
                                  options: [
                                    {
                                      label: 'admin',
                                      value: 'admin'
                                    },
                                    {
                                      label: 'employee',
                                      value: 'employee'
                                    },
                                    {
                                      label: 'customer',
                                      value: 'customer'
                                    }
                                  ],
                                  defaultValue: { label: user.roles[0], value: user.roles[0] },
                                  onChange: (role) => this.setState({ roles: [role.value] })
                                }
                              }
                            ]}
                          />
                        </UserIsInRole>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button color="primary" type="submit" onClick={this.handleSubmit}>
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col md={6} xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle>Addresses</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <Nav pills className="nav-pills-primary">
                            <NavItem>
                              <NavLink
                                className={this.state.currentAddressTab === 'shipping' ? 'active' : ''}
                                onClick={() => this.setState({ currentAddressTab: 'shipping' })}
                              >
                                Shipping Address
                              </NavLink>
                            </NavItem>
                            {!this.state.billingIsSame && (
                              <NavItem>
                                <NavLink
                                  className={this.state.currentAddressTab === 'billing' ? 'active' : ''}
                                  onClick={() => this.setState({ currentAddressTab: 'billing' })}
                                >
                                  Billing Address
                                </NavLink>
                              </NavItem>
                            )}
                          </Nav>
                          <hr />
                        </Col>
                      </Row>
                      <TabContent activeTab={this.state.currentAddressTab}>
                        <TabPane tabId="shipping">
                          <Form onSubmit={this.handleSubmit}>
                            <FormInputsLegacy
                              inputs={[
                                {
                                  label: 'First Name',
                                  colProps: {
                                    xs: 6
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'shippingAddress.firstName',
                                    defaultValue: _.get(user, 'shippingAddress.firstName'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Last Name',
                                  colProps: {
                                    xs: 6
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'shippingAddress.lastName',
                                    defaultValue: _.get(user, 'shippingAddress.lastName'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Address 1',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'shippingAddress.address1',
                                    defaultValue: _.get(user, 'shippingAddress.address1'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Address 2',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'shippingAddress.address2',
                                    defaultValue: _.get(user, 'shippingAddress.address2'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'City',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'shippingAddress.city',
                                    defaultValue: _.get(user, 'shippingAddress.city'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'State',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'shippingAddress.state',
                                    options: this.createUSStatesOptions(),
                                    defaultValue: {
                                      label: _.get(user, 'shippingAddress.state') || 'Choose...',
                                      value: _.get(user, 'shippingAddress.state') || ''
                                    },
                                    onChange: (value) => this.handleSelectStateChange('shippingAddress.state', value)
                                  }
                                },
                                {
                                  label: 'Zip Code',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'shippingAddress.zip',
                                    defaultValue: _.get(user, 'shippingAddress.zip'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Country',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'shippingAddress.country',
                                    defaultValue: _.get(user, 'shippingAddress.country'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Telephone',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'shippingAddress.phone',
                                    defaultValue: _.get(user, 'shippingAddress.phone'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Same for billing?',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'checkbox',
                                    name: 'billingIsSame',
                                    defaultChecked: _.get(user, 'billingIsSame'),
                                    onChange: this.handleStateChange
                                  }
                                }
                              ]}
                            />
                          </Form>
                        </TabPane>
                        <TabPane tabId="billing">
                          <Form onSubmit={this.handleSubmit}>
                            <FormInputsLegacy
                              inputs={[
                                {
                                  label: 'First Name',
                                  colProps: {
                                    xs: 6
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'billingAddress.firstName',
                                    defaultValue: _.get(user, 'billingAddress.firstName'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Last Name',
                                  colProps: {
                                    xs: 6
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'billingAddress.lastName',
                                    defaultValue: _.get(user, 'billingAddress.lastName'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Address 1',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'billingAddress.address1',
                                    defaultValue: _.get(user, 'billingAddress.address1'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Address 2',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'billingAddress.address2',
                                    defaultValue: _.get(user, 'billingAddress.address2'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'City',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'billingAddress.city',
                                    defaultValue: _.get(user, 'billingAddress.city'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'State',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'billingAddress.state',
                                    options: this.createUSStatesOptions(),
                                    defaultValue: {
                                      label: _.get(user, 'billingAddress.state') || 'Choose...',
                                      value: _.get(user, 'billingAddress.state') || ''
                                    },
                                    onChange: (value) => this.handleSelectStateChange('billingAddress.state', value)
                                  }
                                },
                                {
                                  label: 'Zip Code',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'billingAddress.zip',
                                    defaultValue: _.get(user, 'billingAddress.zip'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Country',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'billingAddress.country',
                                    defaultValue: _.get(user, 'billingAddress.country'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Telephone',
                                  colProps: {
                                    xs: 12
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'billingAddress.phone',
                                    defaultValue: _.get(user, 'billingAddress.phone'),
                                    onChange: this.handleStateChange
                                  }
                                }
                              ]}
                            />
                          </Form>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button color="primary" type="submit" onClick={this.handleSubmit}>
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>

          {/* tabbed table on bottom */}
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <TableHeader>
                    <Nav pills>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer', borderRadius: '3px' }}
                          className={this.state.currentAssetsTab === 'tab1' ? 'active' : ''}
                          onClick={() => this.setState({ currentAssetsTab: 'tab1' })}
                        >
                          Quotes <Badge color="default">{quotes.length}</Badge>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer', borderRadius: '3px' }}
                          className={this.state.currentAssetsTab === 'tab2' ? 'active' : ''}
                          onClick={() => this.setState({ currentAssetsTab: 'tab2' })}
                        >
                          Work Orders <Badge color="default">{workOrders.length}</Badge>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer', borderRadius: '3px' }}
                          className={this.state.currentAssetsTab === 'tab3' ? 'active' : ''}
                          onClick={() => this.setState({ currentAssetsTab: 'tab3' })}
                        >
                          Custom Design Requests <Badge color="default">{customDesignRequests.length}</Badge>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer', borderRadius: '3px' }}
                          className={this.state.currentAssetsTab === 'tab4' ? 'active' : ''}
                          onClick={() => this.setState({ currentAssetsTab: 'tab4' })}
                        >
                          Orders <Badge color="default">{orders.length}</Badge>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Button color="primary" onClick={this.generateQuote} style={{ cursor: 'pointer' }}>
                      Generate Quote
                    </Button>
                  </TableHeader>
                  <hr />
                  <TabContent activeTab={this.state.currentAssetsTab}>
                    <TabPane tabId="tab1">
                      <ReactTable
                        data={quotes.map((item) => ({ ...item, actions: this.renderQuoteActions(item._id) }))}
                        filterable
                        columns={this.quoteColumns()}
                        defaultPageSize={5}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </TabPane>
                    <TabPane tabId="tab2">
                      <ReactTable
                        data={workOrders.map((item) => ({ ...item, actions: this.renderWorkOrderActions(item._id) }))}
                        filterable
                        columns={this.workOrderColumns()}
                        defaultPageSize={5}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </TabPane>
                    <TabPane tabId="tab3">
                      <ReactTable
                        data={customDesignRequests.map((item) => ({
                          ...item,
                          actions: this.renderCustomRequestActions(item._id)
                        }))}
                        filterable
                        columns={this.customDesignRequestColumns()}
                        defaultPageSize={5}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </TabPane>
                    <TabPane tabId="tab4">
                      <ReactTable
                        data={orders}
                        filterable
                        columns={this.orderColumns()}
                        defaultPageSize={5}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default compose(withUser, withUserUpdate, withQuoteCreate)(User);
