import gql from 'graphql-tag';

export default gql`
  mutation sendQuote($_id: ObjID!) {
    sendQuote(_id: $_id) {
      _id
      emailedAt
      updatedAt
    }
  }
`;
