import gql from 'graphql-tag';

export default gql`
  mutation updateQuote($_id: ObjID! $input: QuoteInput!) {
    updateQuote(_id: $_id, input: $input) {
      _id
      updatedAt
    }
  }
`;
