import gql from 'graphql-tag';

export default gql`
  mutation updateFormContact($_id: ObjID! $input: UpdateUserInput!) {
    updateFormContact(_id: $_id, input: $input) {
      _id
      updatedAt
    }
  }
`;
