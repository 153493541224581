import React from 'react';
import { Table } from 'reactstrap';
import styled from 'styled-components';
import { FormInputsLegacy } from 'components';
import { formatPrice } from 'lib/utils';
import { VendorMarkup } from 'graphql/codegen';

const Markups = styled.div`
  padding-top: 2rem;

  thead {
    tr > th {
      font-size: 1rem;
      padding-bottom: .8rem;
    }
  }
`;

interface Props {
  markups: VendorMarkup[];
  setMarkupsState: (value: React.SetStateAction<VendorMarkup[] | undefined>) => void;
}

const MarkupTable: React.FC<Props> = (props) => {
  const { markups, setMarkupsState } = props;

  return (
    <Markups>
      <div className="vendor-settings__header font-weight-bold">Markup Percentages:</div>
      <Table>
        <thead>
          <tr>
            <th>From</th>
            <th>To</th>
            <th>Markup (%)</th>
          </tr>
        </thead>
        <tbody>
          {markups?.map((m, i) => (
            <tr key={i}>
              <td>{formatPrice(m?.from || 0)}</td>
              <td>{m?.to ? formatPrice(m.to) : '~'}</td>
              <td>
                <FormInputsLegacy
                  inputs={[
                    {
                      colProps: { xs: 4 },
                      inputProps: {
                        type: 'number',
                        placeholder: 'markup %',
                        name: 'markup',
                        defaultValue: m?.markupPercent,
                        onChange(e): void {
                          const updates = markups.map(({ id, to, from, markupPercent }) => ({ id, to, from, markupPercent }));
                          updates[i].markupPercent = Number(e.target.value);
                          setMarkupsState(updates);
                        }
                      }
                    }
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Markups>
  );
};

export default MarkupTable;
