import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class CustomModal extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    closeButtonText: PropTypes.any,
    header: PropTypes.any,
    launchButtonText: PropTypes.any,
    onSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.any
  }

  state = {
    modalOpen: false
  };

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  render() {
    const { launchButtonText, header, children, onSubmit, closeButtonText, submitButtonText } = this.props;

    return (
      <div className="modal-wrapper">
        <Button color="primary" onClick={this.toggleModal}>
          {launchButtonText || 'Open Modal'}
        </Button>
        <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
          {!!header &&
            <ModalHeader className="justify-content-center" toggle={this.toggleModal}>
              {header}
            </ModalHeader>
          }
          <ModalBody>
            {children}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>
              {closeButtonText || 'Close'}
            </Button>
            <Button color="primary" onClick={onSubmit}>
              {submitButtonText || 'Save'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default CustomModal;
