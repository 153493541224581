import gql from 'graphql-tag';

export default gql`
  query customDesignRequests {
    customDesignRequests {
      _id
      email
      firstName
      lastName
      status
      createdAt
    }
  }
`;
