import { graphql } from '@apollo/react-hoc';
import imagesRemove from 'graphql/imagesRemove';

export default (Component, { refetchQueries } = {}) => graphql(imagesRemove, {
  props: ({ mutate }) => ({
    removeImages: (input) => mutate({
      variables: { input },
      refetchQueries
    })
  })
})(Component);
