import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-v6';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';

import { PanelHeader, Button, DateFormat, Icon, Loading, UserIsInRole } from 'components';
import formContacts from 'graphql/formContacts';
import formContactDelete from 'graphql/formContactDelete';
import { Alert } from 'lib/utils';

class FormContacts extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  renderActions(_id) {
    return (
      <div className="actions-right">
        <Link to={`/quoting/form-contacts/${_id}`}>
          <Button
            color="primary"
            size="sm"
            icon
          >
            <Icon name={faEdit}/>
          </Button>
        </Link>
        &nbsp;
        <UserIsInRole roles="admin">
          <Mutation
            mutation={formContactDelete}
            variables={{ _id }}
            refetchQueries={() => ([{ query: formContacts }])}
            onCompleted={() => Alert('Form contact deleted!', {
              icon: 'success',
              buttons: false,
              timer: 1500
            })}
            onError={(e) => Alert('Oh no!', {
              text: e.message.split('GraphQL error: ')[1],
              icon: 'error'
            })}
          >
            {(mutate) => (
              <Button
                onClick={() => {
                  Alert({
                    title: 'Are you sure?',
                    text: 'There\'s no going back!',
                    icon: 'warning',
                    dangerMode: true,
                    buttons: {
                      cancel: true,
                      confirm: 'Do it!'
                    }
                  }).then((confirmed) => confirmed && mutate());
                }}
                color="danger"
                size="sm"
                icon
              >
                <Icon name={faTimes}/>
              </Button>
            )}
          </Mutation>
          &nbsp;
        </UserIsInRole>
      </div>
    );
  }

  render() {
    const columns = [
      {
        Header: 'First Name',
        accessor: 'firstName',
        maxWidth: 100,
        sortable: false
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        maxWidth: 100,
        sortable: false
      },
      {
        Header: 'Email',
        accessor: 'email',
        maxWidth: 240,
        sortable: false
      },
      {
        Header: 'Custom Design Requests',
        accessor: 'customDesignRequests',
        Cell: ({ value }) => value.length,
        maxWidth: 200,
        filterable: false
      },
      {
        Header: 'Quotes',
        accessor: 'quotes',
        Cell: ({ value }) => value.length,
        maxWidth: 100,
        filterable: false
      },
      {
        Header: 'Work Orders',
        accessor: 'workOrders',
        Cell: ({ value }) => value.length,
        maxWidth: 120,
        filterable: false
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        sortMethod: (a, b) => a > b ? 1 : -1,
        Cell: ({ value }) => <DateFormat date={value}/>,
        maxWidth: 150
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
        sortable: false,
        filterable: false
      }
    ];

    return (
      <div>
        <PanelHeader size="sm" />
        <Query
          query={formContacts}
          variables={{ pagination: { limit: 500 }, sort: { createdAt: -1 } }}
        >
          {({ loading, error, data }) => {

            if (loading) {
              return <Loading/>;
            }

            if (error) {
              return <p>Error :(</p>;
            }

            const tableData = data.formContacts.map((item) => ({
              ...item,
              actions: this.renderActions(item._id)
            }));

            return (
              <div className="content">
                <Row>
                  <Col xs={12} md={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Form Contacts</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          data={tableData}
                          filterable
                          columns={columns}
                          defaultPageSize={20}
                          showPaginationBottom
                          className="-striped -highlight"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default FormContacts;
