import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';
import _ from 'lodash';
import { PanelHeader, Button, FormInputsLegacy } from 'components';
import withWorkOrder from 'containers/withWorkOrder';
import withSendWorkOrderFollowupEmail from 'containers/withSendWorkOrderFollowupEmail';
import { workOrderFollowUpEmail } from 'lib/snippets';
import { notify } from 'lib/utils';

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
  padding-bottom: 1rem;

  .cancel-link {
    display: block;
    padding-top: 0.5rem;
    padding-right: 1rem;
  }
`;

class WorkOrderFollowUp extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    sendWorkOrderFollowupEmail: PropTypes.func.isRequired,
    workOrder: PropTypes.object.isRequired
  }

  state = {
    to: '',
    subject: 'Victor Canera Order Followup',
    message: workOrderFollowUpEmail
  }

  handleStateChange = (e) => {
    const t = e.target;
    this.setState((state) => _.setWith(_.clone(state), t.name, t.value, _.clone));
  }

  handleSubmit = () => {
    // send the email
    this.props.sendWorkOrderFollowupEmail({
      to: this.state.to || this.props.workOrder.user.email,
      subject: this.state.subject,
      workOrderId: this.props.workOrder._id
    })
      .then(() => {
        notify('success', 'Email sent!');
        this.props.history.push(`/quoting/work-orders/${this.props.workOrder._id}`);
      }).catch((err) => notify('error', err.toString()));
  }

  render() {
    const { workOrder } = this.props;

    if (!workOrder) {
      return <div>No work order found!</div>;
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col sm={{ size: 6, offset: 3 }}>
              <Card>
                <CardHeader>
                  <CardTitle><h5>POST SALE FOLLOW-UP</h5></CardTitle>
                  <hr />
                </CardHeader>
                <CardBody>
                  <Col sm={12}>
                    <Form onSubmit={this.handleSubmit}>
                      <FormInputsLegacy
                        inputs={[
                          {
                            label: 'To',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'text',
                              name: 'to',
                              defaultValue: workOrder.user.email,
                              onChange: this.handleStateChange
                            }
                          },
                          {
                            label: 'Subject',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'text',
                              name: 'subject',
                              defaultValue: this.state.subject,
                              onChange: this.handleStateChange
                            }
                          }
                        ]}
                      />
                    </Form>
                    <p style={{ textAlign: 'center', paddingTop: '2rem' }}>
                      Email content not editable at this time because the WYSWIG editer ruins the layout.
                    </p>
                  </Col>
                </CardBody>
                <CardFooter>
                  <Col sm={12}>
                    <FormFooter>
                      <div className="cancel-link">
                        <Link to={`/quoting/work-orders/${workOrder._id}`}>Cancel</Link>
                      </div>
                      <Button className="submit-btn" color="primary" onClick={this.handleSubmit}>Send Email</Button>
                    </FormFooter>
                  </Col>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default compose(
  withWorkOrder,
  withSendWorkOrderFollowupEmail
)(WorkOrderFollowUp);
