import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';
import ReactTable from 'react-table-v6';
import styled from 'styled-components';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Form, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuid } from 'uuid';

import { PanelHeader, Button, DateFormat, Icon, Loading, FormInputsLegacy, DeleteProductButton } from 'components';
import withCreateProduct from 'containers/withProductCreate';
import jewelriesQuery from 'graphql/jewelriesQuery';
import { formatPrice, notify } from 'lib/utils';

const ActionBar = styled.section`
  display: flex;
  justify-content: flex-end;
`;

class Jewelry extends Component {
  static propTypes = {
    createProduct: PropTypes.func.isRequired,
    history: PropTypes.object
  };

  state = {
    modalOpen: false,
    type: ''
  };

  handleFormStateChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCreateProduct = (e, { refetch }) => {
    e.preventDefault();

    const { type } = this.state;

    if (!type || !['Earrings', 'Bracelet', 'Necklace'].includes(type)) {
      return notify('error', 'Please choose a product type.');
    }

    const skuAndSlug = uuid().replace(/-/g, '');

    this.props
      .createProduct({
        name: 'New Product',
        type,
        cost: 0,
        price: 0,
        enabled: false,
        sku: skuAndSlug,
        slug: skuAndSlug,
        meta: {
          create: {
            title: 'New Product',
            description: '',
            keywords: ''
          }
        },
        productType: {
          connect: {
            label: type
          }
        }
      })
      .then(
        async ({
          data: {
            createProduct: { id }
          }
        }) => {
          await refetch();
          this.props.history.push(`/products/jewelry/${id}`);
        }
      )
      .catch((error) => notify('error', error.toString()));
  };

  renderActions = (product, { refetch }) => (
    <div className="actions-right">
      <Link to={`/products/jewelry/${product.id}`}>
        <Button color="primary" size="sm" icon>
          <Icon name={faEdit} />
        </Button>
      </Link>
      &nbsp;
      <DeleteProductButton product={product} refetch={refetch} />
    </div>
  );

  render() {
    const columns = [
      {
        Header: 'SKU',
        accessor: 'sku',
        maxWidth: 140
      },
      {
        Header: 'Product Name',
        accessor: 'name',
        maxWidth: 1000
      },
      {
        Header: 'Product Type',
        accessor: 'type',
        maxWidth: 120,
        filterable: false
      },
      {
        Header: 'Model #',
        accessor: 'modelNumber',
        maxWidth: 110
      },
      {
        Header: 'Enabled',
        accessor: 'enabled',
        maxWidth: 110,
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        filterable: false
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        sortMethod: (a, b) => (a > b ? 1 : -1),
        Cell: ({ value }) => value && <DateFormat date={value} />,
        maxWidth: 150
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ value }) => value && formatPrice(value),
        sortMethod: (a, b) => (a > b ? 1 : -1),
        maxWidth: 100
      },
      {
        Header: 'Qty',
        accessor: 'quantity',
        maxWidth: 50,
        sortable: false,
        filterable: false
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 90,
        sortable: false,
        filterable: false
      }
    ];

    return (
      <div>
        <PanelHeader size="sm" />
        <Query
          query={jewelriesQuery}
          variables={{
            where: { type: { not: { equals: 'Ring' } }, parentId: { equals: null } },
            orderBy: [{ createdAt: 'desc' }]
          }}
        >
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) {
              return <p>Error :(</p>;
            }

            const tableData = data.products.map((item) => ({
              ...item,
              actions: this.renderActions(item, { refetch })
            }));

            return (
              <div className="content">
                <Row>
                  <Col xs={12} md={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle>
                          <h5>Jewelry</h5>
                        </CardTitle>
                        {/* create product button */}
                        <ActionBar>
                          <Button color="primary" onClick={() => this.setState({ modalOpen: !this.state.modalOpen })}>
                            <span>
                              <Icon name={faPlus} /> Add Jewelry
                            </span>
                          </Button>
                          <Modal
                            isOpen={this.state.modalOpen}
                            toggle={() => this.setState({ modalOpen: !this.state.modalOpen })}
                          >
                            <ModalBody>
                              <Form onSubmit={(e) => this.handleCreateProduct(e, { refetch })}>
                                <FormInputsLegacy
                                  inputs={[
                                    {
                                      label: 'Product Type',
                                      colProps: { xs: 12 },
                                      inputProps: {
                                        type: 'select',
                                        name: 'type',
                                        defaultValue: { label: '', value: '' },
                                        options: [
                                          { label: 'Earrings', value: 'Earrings' },
                                          { label: 'Bracelet', value: 'Bracelet' },
                                          { label: 'Necklace', value: 'Necklace' }
                                        ],
                                        onChange: ({ value }) => this.setState({ type: value })
                                      }
                                    }
                                  ]}
                                />
                              </Form>
                              <hr />
                            </ModalBody>
                            <ModalFooter>
                              <Button color="secondary" onClick={this.toggleModal}>
                                Close
                              </Button>
                              <Button color="primary" onClick={(e) => this.handleCreateProduct(e, { refetch })}>
                                Save
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </ActionBar>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          data={tableData}
                          filterable
                          columns={columns}
                          defaultPageSize={20}
                          showPaginationBottom
                          className="-striped -highlight"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withCreateProduct(Jewelry);
