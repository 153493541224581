import React from 'react';

interface Props {
  children?: React.ReactElement | React.ReactElement[] | string;
}

export const Table: React.FC = ({ children }) => (
  <div className="flex flex-col">
    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="my-4 py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            {children}
          </table>
        </div>
      </div>
    </div>
  </div>
);

export const THead: React.FC = ({ children }) => (
  <thead className="bg-gray-50">
    {children}
  </thead>
);

export const TBody: React.FC = ({ children }) => (
  <tbody className="bg-white divide-y divide-gray-200">
    {children}
  </tbody>
);

export const Th: React.FC = ({ children }) => (
  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
    {children}
  </th>
);

export const Td: React.FC = ({ children }) => (
  <td className="px-6 py-4 whitespace-nowrap">
    {children}
  </td>
);

export default Table;
