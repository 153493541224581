import React from 'react';
import { Row, Col, Form, Card, CardBody, CardFooter, Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { FormInputsLegacy, Button, RightAligned, Image } from 'components';
import staticPageQuery from 'graphql/staticPage';
import staticPageUpdate from 'graphql/staticPageUpdate';
import { notify } from 'lib/utils';

class StaticPage extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    error: PropTypes.string,
    loading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    refetch: PropTypes.func,
    updateStaticPage: PropTypes.func.isRequired
  }

  state = {
    currentTab: 'edit'
  }

  handleStateChange = (e) => {
    const t = e.target;
    this.setState((state) => _.setWith(_.clone(state), t.name, t.value, _.clone));
  }

  handleSubmit = () => {
    const { _id } = this.props.match.params;
    const { content, description, route, title } = this.state;

    this.props.updateStaticPage({ _id, input: { content, description, route, title } })
      .then(() => notify('success', 'Static page content updated!'))
      .catch(() => notify('error', 'Save failed!'));
  }

  renderTabNav(name, label) {
    return (
      <NavItem>
        <NavLink
          className={this.state.currentTab === name ? 'active' : ''}
          onClick={() => this.setState({ currentTab: name })}
        >
          {label}
        </NavLink>
      </NavItem>
    );
  }

  render() {
    const { data: { staticPage }, loading, error } = this.props;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return (
        <div>
          <p>Error :(</p>
        </div>
      );
    }

    if (!staticPage) {
      return <div>No page found!</div>;
    }

    return (
      <div className="content">
        <Row>
          <Col sm={{ size: 8, offset: 2 }} xs={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <Nav tabs>
                      {/* {this.renderTabNav('details', 'Details')} */}
                      {this.renderTabNav('edit', 'Edit')}
                      {this.renderTabNav('preview', 'Preview')}
                    </Nav>
                  </Col>
                  <Col xs={12}>
                    <TabContent activeTab={this.state.currentTab}>
                      <TabPane tabId="details">
                        <Col xs={12}>
                          {/* <Form onSubmit={this.handleSubmit}>
                            <RightAligned>
                              <Button color="primary" onClick={this.handleSubmit}>Save</Button>
                            </RightAligned>
                            <FormInputsLegacy
                              inputs={[
                                {
                                  label: 'Title',
                                  colProps: { xs: 12 },
                                  inputProps: {
                                    type: 'text',
                                    name: 'title',
                                    defaultValue: staticPage.title,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Route',
                                  colProps: { xs: 12 },
                                  inputProps: {
                                    type: 'text',
                                    name: 'route',
                                    defaultValue: staticPage.route,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Excerpt',
                                  colProps: { xs: 12 },
                                  inputProps: {
                                    type: 'textarea',
                                    rows: 4,
                                    name: 'description',
                                    defaultValue: staticPage.description,
                                    onChange: this.handleStateChange
                                  }
                                }
                              ]}
                            />
                          </Form> */}
                          {/* {Array.isArray(staticPage.images) && !!staticPage.images.length
                            ? (
                              <Row>
                                <Col sm={6} xs={12}>
                                  <Label>Featured Image</Label>
                                  <ImageThumbnail
                                    bgColor="#d6d6d6"
                                    image={staticPage.images[0]}
                                    refetchQueries={[{
                                      query: staticPageQuery,
                                      variables: { _id: staticPage._id }
                                    }]}
                                  />
                                </Col>
                              </Row>
                            )
                            : (
                              <Row>
                                <Col xs={12}>
                                  <Label>Featured Image</Label>
                                  <ImageUpload
                                    pageId={staticPage._id}
                                    category="static"
                                    dropzoneText="Drop an image here or click to find one"
                                    dropzoneProps={{ multiple: false }}
                                    refetchQueries={[{
                                      query: staticPageQuery,
                                      variables: { _id: staticPage._id }
                                    }]}
                                  />
                                </Col>
                              </Row>
                            )} */}
                        </Col>
                      </TabPane>
                      <TabPane tabId="edit">
                        <Col xs={12}>
                          <Form onSubmit={this.handleSubmit}>
                            <RightAligned>
                              <Button color="primary" onClick={this.handleSubmit}>Save</Button>
                            </RightAligned>
                            <FormInputsLegacy
                              inputs={[
                                {
                                  label: 'Title',
                                  colProps: { xs: 12 },
                                  inputProps: {
                                    type: 'text',
                                    name: 'title',
                                    defaultValue: staticPage.title,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Route',
                                  colProps: { xs: 12 },
                                  inputProps: {
                                    type: 'text',
                                    name: 'route',
                                    defaultValue: staticPage.route,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Excerpt',
                                  colProps: { xs: 12 },
                                  inputProps: {
                                    type: 'textarea',
                                    rows: 4,
                                    name: 'description',
                                    defaultValue: staticPage.description,
                                    onChange: this.handleStateChange
                                  }
                                }
                              ]}
                            />
                            <FormInputsLegacy
                              inputs={[
                                {
                                  label: 'Content',
                                  colProps: { xs: 12 },
                                  inputProps: {
                                    type: 'wysiwyg',
                                    name: 'content',
                                    defaultValue: staticPage.content,
                                    theme: 'snow',
                                    onChange: (content) => this.setState({ content })
                                  }
                                }
                              ]}
                            />
                          </Form>
                        </Col>
                      </TabPane>
                      <TabPane tabId="preview">
                        <Row>
                          <Col xs={12}>
                            {Array.isArray(staticPage.images) && !!staticPage.images.length
                              && (
                              <Image
                                {...staticPage.images[0]}
                                sizes="100vw"
                                imgixParams={{ fit: 'fill' }}
                              />
                              )}
                            <h4 dangerouslySetInnerHTML={{ __html: this.state.title || staticPage.title }} />
                            <div dangerouslySetInnerHTML={{ __html: this.state.content || staticPage.content }} />
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                {this.state.currentTab === 'edit'
                  && (
                  <RightAligned>
                    <Button color="primary" onClick={this.handleSubmit}>Save</Button>
                  </RightAligned>
                  )}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default compose(
  graphql(staticPageQuery, {
    options: ({ match }) => ({
      variables: { _id: match.params._id }
    })
  }),
  graphql(staticPageUpdate, {
    props: ({ mutate }) => ({
      updateStaticPage: ({ _id, input }) => mutate({
        variables: { _id, input },
        refetchQueries: [{
          query: staticPageQuery,
          variables: { _id }
        }]
      })
    })
  })
)(StaticPage);
