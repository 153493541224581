import React from 'react';
import { Mutation } from '@apollo/react-components';
import jobUpdate from 'graphql/jobUpdate';

export default (Component) => (
  class withJobUpdate extends React.Component {
    render() {
      return (
        <Mutation mutation={jobUpdate}>
          {(mutate) => <Component {...this.props} updateJob={mutate} />}
        </Mutation>
      );
    }
  }
);
