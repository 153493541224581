import gql from 'graphql-tag';

export default gql`
  query payments($orderId: ObjID, $workOrderId: ObjID, $userId: ObjID) {
    payments(orderId: $orderId, workOrderId: $workOrderId, userId: $userId) {
      _id
      amount
      paymentType
      tax
      userId
      user {
        email
      }
      createdAt
      updatedAt
    }
  }
`;
