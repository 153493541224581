import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Imgix from 'react-imgix';
import withRemoveImage from 'containers/withImageDelete';
import { notify } from 'lib/utils';

const Figure = styled.figure`
	position: relative;
	float: left;
	overflow: hidden;
	margin: 10px 1%;
	height: auto;
	background: ${({ bgColor }) => bgColor};
	text-align: center;
	cursor: pointer;
  min-width: 200px;
  min-height: 200px;

  img {
  	position: relative;
  	display: block;
  	min-height: 100%;
  	max-width: 100%;
  	opacity: 0.85;
  }

  figcaption {
  	padding: 1em;
  	color: #fff;
  	text-transform: uppercase;
  	font-size: 1.25em;
  	backface-visibility: hidden;

    &::before,
    &::after {
    	pointer-events: none;
      position: absolute;
      width: 200%;
      height: 200%;
      border-style: solid;
      border-color: ${({ bgColor }) => bgColor};
      content: '';
      transition: transform 0.35s;
    }

    ::before {
    	right: 0;
    	bottom: 0;
    	border-width: 0 50px 40px 0;
    	transform: translate3d(50px,40px,0);
    }

    ::after {
    	top: 0;
    	left: 0;
    	border-width: 10px 0 0 10px;
    	transform: translate3d(-10px,-10px,0);
    }

    &,
    > a {
    	position: absolute;
    	top: 0;
    	left: 0;
    	width: 100%;
    	height: 100%;
    }

    > a {
    	z-index: 1000;
    	text-indent: 200%;
    	white-space: nowrap;
    	font-size: 0;
    	opacity: 0;
    }
  }

  h4 {
    font-size: 1em;
  	word-spacing: -0.15em;
    position: absolute;
  	bottom: 0;
  	left: 0;
  	padding: 0.4em 10px;
  	width: 80%;
		opacity: 0;
  	transition: transform 0.35s;
  	transform: translate3d(20%,0,0);
  }

  h4, p {
  	margin: 0;
  }

  p {
  	letter-spacing: 1px;
  	font-size: 68.5%;
    float: right;
  	clear: both;
  	text-align: left;
  	text-transform: none;
  	font-size: 111%;
  }

  img, i {
  	transition: opacity 0.35s, transform 0.35s;
  }

  @media screen and (max-width: 920px) {
  	h4 {
  		padding: 0.75em 10px;
  		font-size: 120%;
  	}
  }

  i {
  	display: block;
  	margin-bottom: 1em;
  	color: #fff;
  	opacity: 0;
  	transform: translate3d(90px,0,0);
  }

  i:hover,
  i:focus {
  	color: #f3cf3f;
  }

  &:hover figcaption::before,
  &:hover figcaption::after {
  	transform: translate3d(0,0,0);
  }

  &:hover img {
  	opacity: 0.6;

  }

  &:hover h4,
  &:hover i {
  	transform: translate3d(6px,0,0);
		opacity: 1;
  }

  &:hover i {
  	opacity: 1;
  }

  &:hover i:first-child {
  	transition-delay: 0.025s;
  }

  &:hover i:nth-child(2) {
  	transition-delay: 0.05s;
  }

  &:hover i:nth-child(3) {
  	transition-delay: 0.075s;
  }

  &:hover i:nth-child(4) {
  	transition-delay: 0.1s;
  }
`;

const ImageThumbnail = ({ image, removeImage, refetchQueries, bgColor, header, ...rest }) => (
  <>
    {!!header && <div><label>{header}</label></div>}
    <Figure bgColor={bgColor}>
      <Imgix src={image.url} {...rest} />
      <figcaption>
        <h4>{image.filename}</h4>
        <p>
          <a href={image.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-fw fa-download" /></a>
          <a href={`mailto:?subject=${image.filename}&body=${image.url}`}><i className="fa fa-fw fa-share" /></a>
          <i
            className="fa fa-fw fa-trash"
            onClick={() => {
              removeImage({ variables: { id: image.id }, refetchQueries })
                .then(() => notify('success', `Successfully deleted ${image.filename}`))
                .catch(() => notify('error', 'Delete failed!'));
            }}
          />
        </p>
      </figcaption>
    </Figure>
  </>
);

ImageThumbnail.propTypes = {
  bgColor: PropTypes.string,
  header: PropTypes.node,
  image: PropTypes.shape({
    id: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    storageUrl: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  refetchQueries: PropTypes.array,
  removeImage: PropTypes.func.isRequired,
  size: PropTypes.string
};

ImageThumbnail.defaultProps = {
  bgColor: '#34495e'
};

export default withRemoveImage(ImageThumbnail);
