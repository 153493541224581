import React from 'react';
import Imgix, { SharedImigixAndSourceProps } from 'react-imgix';

interface Props extends SharedImigixAndSourceProps {
  alt?: string;
  caption?: string;
  size?: string;
  url?: string;
}

const Image = ({ url, src, caption, ...rest }: Props): React.ReactElement => (
  <figure>
    <Imgix src={url || src} {...rest} />
    {caption && <figcaption>{caption}</figcaption>}
  </figure>
);

export default Image;
