import gql from 'graphql-tag';

export default gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      _id
      email
      firstName
      lastName
      roles
      phone
    }
  }
`;
