import React from 'react';
import PropTypes from 'prop-types';
import withCurrentUser from 'containers/withCurrentUser';
import { userIsInRole } from 'lib/utils';

// only render children if current user has the provided role(s)
const UserIsInRole = ({ currentUser, roles, children }) => userIsInRole(currentUser, roles) ? children : <></>;

UserIsInRole.propTypes = {
  children: PropTypes.node.isRequired,
  currentUser: PropTypes.object,
  roles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]).isRequired
};

export default withCurrentUser(UserIsInRole);
