import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { ImageUpload, ImageThumbnail } from 'components';
import jewelryQuery from 'graphql/jewelryQuery';
import withImageUpdate from 'containers/withImageUpdate';
import { notify } from 'lib/utils';

const ImageItem = SortableElement(({ image, jewelry }) => (
  <Col md={3} style={{ maxHeight: '300px' }}>
    <ImageThumbnail
      image={image}
      refetchQueries={[{
        query: jewelryQuery,
        variables: { where: { id: jewelry.id } }
      }]}
    />
  </Col>
));

const ImageGrid = SortableContainer(({ images, jewelry }) => (
  <Row>
    {images.map((image, i) => (
      <ImageItem key={image.id} index={i} image={image} jewelry={jewelry} />
    ))}
  </Row>
));

class SortableImageGrid extends React.Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.object),
    jewelry: PropTypes.object.isRequired,
    refetch: PropTypes.func.isRequired,
    updateImage: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      images: props.images
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const reorderedImages = arrayMove(this.state.images, oldIndex, newIndex);
    const updatedImages = reorderedImages.map((image, i) => ({ ...image, order: i + 1 }));

    Promise.all(updatedImages.map(({ _id, order }) => this.props.updateImage({
      variables: {
        where: { id: _id },
        data: { order: { set: order } }
      },
      refetchQueries: [{
        query: jewelryQuery,
        variables: { where: { id: this.props.jewelry.id } }
      }]
    }))).then(async () => notify('success', 'Image order updated!'))
      .catch((error) => notify('error', error.toString()));

    this.setState({ images: updatedImages });
  }

  render() {
    return (
      <ImageGrid
        axis="xy"
        pressDelay={150}
        images={this.state.images}
        onSortEnd={this.onSortEnd}
        jewelry={this.props.jewelry}
      />
    );
  }
}

const WrappedImageGrid = withImageUpdate(SortableImageGrid);

const JewelryImages = ({ jewelry, refetch }) => (
  <div>
    <ImageUpload
      productType="jewelry"
      productId={jewelry.id}
      refetchQueries={[{
        query: jewelryQuery,
        variables: { where: { id: jewelry.id } }
      }]}
    />
    <hr />
    <Row>
      <Col md={12}>
        {!!jewelry.images.filter((i) => i.category !== '360').length
        && (
        <div>
          <h4>Uploaded Images</h4>
          <WrappedImageGrid jewelry={jewelry} images={jewelry.images.filter((i) => i.category !== '360')} refetch={refetch} />
        </div>
        )}
      </Col>
    </Row>
  </div>
);

JewelryImages.propTypes = {
  jewelry: PropTypes.object,
  refetch: PropTypes.func.isRequired
};

export default JewelryImages;
