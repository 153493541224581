import gql from 'graphql-tag';

export default gql`
  query invoiceSearch($startDate: DateTime, $endDate: DateTime) {
    invoiceSearch(startDate: $startDate, endDate: $endDate) {
      _id
      userId
      user {
        firstName
        lastName
        email
        __typename
      }
      workOrderId
      orderId
      items {
        productId
        title
        unitPrice
        quantity
        price
        createdAt
        __typename
      }
      shipping
      tax
      createdAt
      order {
        shippingAddress {
          state
          __typename
        }
        __typename
      }
      user {
        shippingAddress {
          state
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
