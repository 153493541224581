import gql from 'graphql-tag';

export default gql`
  mutation updateInvoice($_id: ObjID! $input: InvoiceInput!) {
    updateInvoice(_id: $_id, input: $input) {
      _id
      updatedAt
    }
  }
`;
