import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';
import _ from 'lodash';
import { PanelHeader, Button, FormInputsLegacy } from 'components';
import withOrder from 'containers/withOrder';
import withPaymentTypes from 'containers/withPaymentTypes';
import withCreatePayment from 'containers/withCreatePayment';
import withSendPaymentReceivedEmail from 'containers/withSendPaymentReceivedEmail';
import orderQuery from 'graphql/orderQuery';
import { notify } from 'lib/utils';

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
  padding-bottom: 1rem;

  .cancel-link {
    display: block;
    padding-top: .5rem;
    padding-right: 1rem;
  }
`;

class OrderRecordPayment extends React.Component {
  static propTypes = {
    createPayment: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    paymentTypes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    sendPaymentReceivedEmail: PropTypes.func.isRequired
  }

  state = {
    amount: 0,
    paymentTypeId: '',
    paymentType: '',
    sendEmail: false,
    to: ''
  }

  // componentDidMount() {
  //   this.calculateTotals();
  // }

  handleStateChange = (e) => {
    const t = e.target;
    this.setState((state) => _.setWith(_.clone(state), t.name, t.value, _.clone));
  }

  calculateTotals = (cb) => {
    let subtotal = 0;
    const tax = this.props.order.tax || 0;
    const taxPercent = this.taxRate;

    const items = _.get(this.props, 'order.items');

    if (items.length) {
      subtotal = items.map((item) => item.unitPrice * item.quantity).reduce((a, b) => a + b, 0);
    }

    const shipping = this.props.order.shipping || 0;

    const total = subtotal + tax + shipping;

    const updates = {
      subtotal,
      tax,
      taxPercent,
      total
    };

    this.setState((state) => _.setWith(_.clone(state), 'order', { ...state.order, ...updates }, _.clone), cb);
  }

  handleSubmit = () => {
    const payment = {
      amount: this.state.amount,
      paymentTypeId: this.state.paymentTypeId,
      orderId: this.props.order._id,
      userId: this.props.order.userId
    };

    // submit the payment
    this.props.createPayment({
      variables: { input: payment },
      refetchQueries: [{
        query: orderQuery,
        variables: { _id: this.props.order._id }
      }]
    }).then(async ({ data: { createPayment } }) => {
      // send the email if the payment successfully saves
      if (this.state.sendEmail) {
        await this.props.sendPaymentReceivedEmail({
          to: this.state.to || this.props.order.user.email,
          paymentId: createPayment._id
        });
      }
      notify('success', 'Payment successfully submitted!');
      document.location.replace(`/orders/${this.props.order._id}`);
    }).catch((err) => notify('error', err.toString()));
  }

  render() {
    const { order } = this.props;

    if (!order) {
      return <div>No work order found!</div>;
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col sm={{ size: 6, offset: 3 }}>
              <Card>
                <CardHeader>
                  <CardTitle><h5>RECORD PAYMENT</h5></CardTitle>
                  <hr />
                </CardHeader>
                <CardBody>
                  <Col sm={12}>
                    <Form onSubmit={this.handleSubmit}>
                      <FormInputsLegacy
                        inputs={[
                          {
                            label: 'Payment Type',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'select',
                              name: 'paymentType',
                              options: this.props.paymentTypes.map(({ _id, name }) => ({ label: name, value: _id })),
                              onChange: ({ label, value }) => this.setState({ paymentTypeId: value, paymentType: label })
                            }
                          },
                          {
                            label: 'Payment Amount',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'currency',
                              name: 'amount',
                              value: this.state.amount / 100,
                              onChange: (e) => {
                                const { rawValue } = e.target;
                                let amount = parseInt((parseFloat(rawValue, 10) * 100).toFixed(), 10);
                                if (isNaN(amount)) {
                                  amount = 0;
                                }
                                this.setState({ amount });
                              }
                            }
                          }
                        ]}
                      />
                      <hr />
                      <FormInputsLegacy
                        inputs={[
                          {
                            label: 'Send Email?',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'checkbox',
                              name: 'sendEmail',
                              defaultChecked: false,
                              onChange: (e) => this.setState({ sendEmail: e.target.checked })
                            }
                          }
                        ]}
                      />
                      <br />
                      {this.state.sendEmail
                        && (
                        <FormInputsLegacy
                          inputs={[
                            {
                              label: 'To',
                              colProps: {
                                xs: 12
                              },
                              inputProps: {
                                type: 'text',
                                name: 'to',
                                defaultValue: order.user.email,
                                onChange: this.handleStateChange
                              }
                            }
                          ]}
                        />
                        )}
                    </Form>
                  </Col>
                </CardBody>
                <CardFooter>
                  <Col sm={12}>
                    <FormFooter>
                      <div className="cancel-link">
                        <Link to={`/orders/${order._id}`}>Cancel</Link>
                      </div>
                      <Button className="submit-btn" color="primary" onClick={this.handleSubmit}>Record Payment</Button>
                    </FormFooter>
                  </Col>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default compose(
  withOrder,
  withPaymentTypes,
  withCreatePayment,
  withSendPaymentReceivedEmail
)(OrderRecordPayment);
