import gql from 'graphql-tag';

export default gql`
  mutation updateOrder($_id: ObjID! $input: OrderUpdateInput!) {
    updateOrder(_id: $_id, input: $input) {
      _id
      updatedAt
    }
  }
`;
