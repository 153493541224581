import gql from 'graphql-tag';

export default gql`
  query availableProducts {
    diamonds(where: { vendorId: { equals: null } }) {
      _id: id
      id
      sku
      name
      description
      price
      quantity
      type
      meta {
        description
      }
    }
    rings: products(where: { type: { equals: "Ring" }, parentId: { not: null } }) {
      _id: id
      id
      sku
      name
      description
      shortDescription
      price
      quantity
      type
      meta {
        description
      }
      parent {
        shortDescription
      }
    }
    jewelries: products(where: { type: { in: ["Earrings", "Bracelet", "Necklace"] }, parentId: { not: null } }) {
      _id: id
      id
      sku
      name
      description
      shortDescription
      price
      quantity
      type
      meta {
        description
      }
      parent {
        shortDescription
      }
    }
  }
`;
