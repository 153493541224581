import gql from 'graphql-tag';

export default gql`
  mutation updateJewelry($where: ProductWhereUniqueInput! $data: ProductUpdateInput!) {
    updateProduct(where: $where, data: $data) {
      id
      updatedAt
    }
  }
`;
