/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import _ from 'lodash';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import {
  format,
  // parseISO,
  // getYear,
  // getMonth,
  // getDate,
  isBefore,
  isAfter,
  subYears,
  startOfDay,
  endOfDay
} from 'date-fns';
// import Chart from 'react-apexcharts';
import { parse as json2csv } from 'json2csv';
import { DateRangePicker } from 'react-date-range';
import { Button, PanelHeader, UserIsInRole } from 'components';
import withOrdersExport from 'containers/withOrdersExport';
import { downloadCSV } from 'lib/utils';
import { Invoice } from 'graphql/codegen';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// const ChartWrapper = styled.div`
//   margin-top: -30px;
// `;

const DatePickerWrapper = styled.span`
  margin-right: 4rem;
`;

interface Props {
  invoices: Invoice[];
}

interface LineItem {
  Customer: string;
  State: string;
  Type: string;
  'Work Order ID': string;
  'Order ID': string;
  'Product ID': string;
  Date: string;
  Title: string;
  'Unit Price'?: number | string;
  Quantity: number | string;
  Shipping: number | string;
  Tax: number | string;
  Total: number | string;
}

interface DateRangeSelection {
  startDate: Date;
  endDate: Date;
  key: string;
}

const Dashboard: React.FC<Props> = (props) => {
  // const [lineItems, setLineItems] = React.useState<LineItem[]>([]);

  const [dateSelection, setDateSelection] = React.useState<DateRangeSelection>({
    startDate: subYears(new Date(), 1),
    endDate: new Date(),
    key: 'selection'
  });

  const getLineItems = (): LineItem[] => {
    const { startDate, endDate } = dateSelection;
    const { invoices } = props;

    if (!startDate || !endDate) {
      return [];
    }

    const lineItems: LineItem[] = [];

    invoices.forEach((invoice) => {
      if (
        isBefore(new Date(invoice.createdAt), startOfDay(startDate)) ||
        isAfter(new Date(invoice.createdAt), endOfDay(endDate))
      ) {
        return;
      }

      // work order items
      // eslint-disable-next-line no-unused-expressions
      invoice?.items
        ?.map((item) => ({
          Customer: invoice.user ? `${invoice.user.firstName} ${invoice.user.lastName}` : '',
          State: '',
          Type: invoice.workOrderId ? 'work-order-item' : 'order-item',
          'Work Order ID': invoice.workOrderId,
          'Order ID': invoice.orderId,
          'Product ID': item?.productId || '',
          Date: invoice.createdAt,
          Title: item?.title || '',
          'Unit Price': item?.unitPrice ? item.unitPrice / 100 : 0,
          Quantity: item?.quantity || '',
          Shipping: '',
          Tax: '',
          Total: item?.price ? item.price / 100 : 0
        }))
        .forEach((item) => lineItems.push(item));

      let invoiceState = _.get(invoice, 'user.shippingAddress.state', '');
      if (invoice.orderId) {
        // Shipment state of order has precedence over user setting.
        invoiceState = _.get(invoice, 'order.shippingAddress.state', invoiceState);
      }

      // tax/shipping line items
      lineItems.push({
        Customer: invoice.user ? `${invoice.user.firstName} ${invoice.user.lastName}` : '',
        State: invoiceState,
        Type: 'shipping-tax',
        'Work Order ID': invoice.workOrderId,
        'Order ID': invoice.orderId,
        'Product ID': '',
        Date: invoice.createdAt,
        Title: 'shipping + tax',
        'Unit Price': '',
        Quantity: '',
        Shipping: invoice.shipping ? invoice.shipping / 100 : 0,
        Tax: invoice.tax ? invoice.tax / 100 : 0,
        Total: invoice.shipping && invoice.tax ? (invoice.shipping + invoice.tax) / 100 : 0
      });
    });

    return lineItems;
  };

  const getFormattedData = (): LineItem[] => {
    const lineItems = getLineItems();
    return lineItems.map((item) => ({
      ...item,
      Date: format(new Date(item.Date), 'MMMM d Y h:mma'),
      'Unit Price': item['Unit Price'] || '',
      Shipping: item.Shipping || '',
      Tax: item.Tax || '',
      Total: item.Total || ''
    }));
  };

  // const getXlxsData = () => {
  //   const formattedData = getFormattedData();

  //   // https://github.com/egeriis/zipcelx/wiki/The-config-object
  //   const headerVals = Object.keys(formattedData[0]);
  //   const headerRow = headerVals.map((value) => ({ type: 'string', value }));
  //   const xlsxData = formattedData.map((item) => Object.values(item).map((value) => ({ type: typeof value, value })));

  //   xlsxData.unshift(headerRow);

  //   return xlsxData;
  // };

  // const getChartConfig = (): any => {
  //   const lineItems = getLineItems();

  //   const convertedDates = lineItems.map((item) => ({
  //     Date: new Date(getYear(new Date(item.Date)), getMonth(new Date(item.Date)), 0),
  //     Total: item.Total
  //   }));

  //   const data = {};

  //   convertedDates.forEach((item) => {
  //     const year = getYear(item.Date).toString();
  //     let month = getMonth(item.Date).toString();
  //     const day = getDate(item.Date).toString();

  //     if (Number(month) < 10) {
  //       month = `0${month}`;
  //     }

  //     const dateString = year + month + day;

  //     data[dateString] = data[dateString] || [];

  //     data[dateString].push(item.Total);
  //   });

  //   const chartData: any = [];

  //   // eslint-disable-next-line
  //   for (const month in data) {
  //     if ({}.hasOwnProperty.call(data, month)) {
  //       chartData.push([parseISO(month), data[month].reduce((a, b) => a + b, 0)]);
  //     }
  //   }

  //   return {
  //     options: {
  //       chart: {
  //         id: 'sales',
  //         foreColor: '#fff',

  //         zoom: {
  //           enabled: true,
  //           type: 'x'
  //         }
  //       },
  //       grid: {
  //         yaxis: {
  //           lines: {
  //             show: false
  //           }
  //         },
  //         xaxis: {
  //           lines: {
  //             show: false
  //           }
  //         }
  //       },
  //       yaxis: {
  //         labels: {
  //           formatter(value: number): string {
  //             return formatPrice(value * 100);
  //           }
  //         }
  //       },
  //       xaxis: {
  //         type: 'datetime'
  //       }
  //     },
  //     series: [
  //       {
  //         name: 'Sales Totals',
  //         data: chartData
  //       }
  //     ]
  //   };
  // };

  // const chartConfig = getChartConfig();

  return (
    <div>
      <UserIsInRole roles="admin">
        <PanelHeader
          size="sm"
          // content={
          //   <ChartWrapper>
          //     <Chart
          //       options={chartConfig.options}
          //       series={chartConfig.series}
          //       type="line"
          //       width="100%"
          //       height="330px"
          //     />
          //   </ChartWrapper>
          // }
        />
        <Row>
          <Col md={{ size: 10, offset: 1 }} style={{ marginTop: '1rem' }}>
            <Card>
              <CardHeader>
                <h4 className="mx-2 text-lg">Invoice Totals Export</h4>
              </CardHeader>
              <CardBody>
                <DatePickerWrapper>
                  {/* <DateRangePicker
                    startDate={state.startDate}
                    startDateId="your_unique_start_date_id"
                    endDate={state.endDate}
                    endDateId="your_unique_end_date_id"
                    isDayBlocked={(): boolean => false}
                    isOutsideRange={(): boolean => false}
                    onDatesChange={({ startDate, endDate }): void => setState({ ...state, startDate, endDate })}
                    focusedInput={state.focusedInput}
                    onFocusChange={(focusedInput): void => setState({ ...state, focusedInput })}
                  /> */}
                  <DateRangePicker
                    onChange={(item: any): void => setDateSelection(item.selection)}
                    showSelectionPreview
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={[dateSelection]}
                    direction="horizontal"
                  />
                </DatePickerWrapper>
                <Button color="primary" onClick={(): void => downloadCSV(json2csv(getFormattedData()))}>
                  Download .csv
                </Button>
                {/* <Button
                  color="primary"
                      sheet: {
                        data: getXlxsData()
                      }
                    });
                  }}
                >
                  Download .xlsx
                </Button> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </UserIsInRole>
    </div>
  );
};

export default withOrdersExport(Dashboard);
