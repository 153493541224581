import gql from 'graphql-tag';

export default gql`
  mutation uploadImages($files: [Upload!]! $data: ImageUploadAttributesInput!) {
    uploadImages(files: $files, data: $data) {
      id
      filename
      url
    }
  }
`;
