import gql from 'graphql-tag';

export default gql`
  query order($_id: ObjID!) {
    order(_id: $_id) {
      _id
      number
      isBankWire
      status
      total
      totalPayments
      tax
      hasCCFee
      balance
      subtotal
      shipping
      referral {
        _id
        name
      }
      userId
      user {
        _id
        firstName
        lastName
        email
        billingAddress {
          phone
        }
        shippingAddress {
          address1
          city
          state
          country
          phone
        }
      }
      items {
        title
        sku
        type
        notes
        quantity
        price
        productId
        vendorId
      }
      invoiceId
      createdAt
      updatedAt
    }

  }
`;
