import gql from 'graphql-tag';

export default gql`
  query emails($workOrderId: ObjID, $quoteId: ObjID, $type: String, $userId: ObjID) {
    emails(workOrderId: $workOrderId, quoteId: $quoteId, type: $type, userId: $userId) {
      _id
      body
      from
      hasEmail
      html
      invoiceId
      message
      orderId
      paymentType
      shippingCompanyId
      subject
      template
      text
      to
      trackingNumber
      type
      userId
      workOrderId
      sentAt
      createdAt
      updatedAt
    }
  }
`;
