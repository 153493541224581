import gql from 'graphql-tag';

export default gql`
  mutation updateWorkOrder($_id: ObjID! $input: WorkOrderInput!) {
    updateWorkOrder(_id: $_id, input: $input) {
      _id
      updatedAt
    }
  }
`;
