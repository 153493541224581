import gql from 'graphql-tag';

export default gql`
  query quote($_id: ObjID!) {
    quote(_id: $_id) {
      _id
      userId
      user {
        firstName
        lastName
        email
        phone
      }
      number
      description
      items {
        title
        description
        unitPrice
        price
        quantity
        productId
        type
      }
      hasTax
      tax
      shipping
      subtotal
      total
      locked
      workOrder {
        _id
        createdAt
      }
      emails {
        _id
        sentAt
        createdAt
        updatedAt
      }
      validUntil
      createdByUser {
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;
