import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import PerfectScrollbar from 'perfect-scrollbar';
import { ToastContainer } from 'react-toastify';
import { Header, Footer, Sidebar } from 'components';
import withCurrentUser from 'containers/withCurrentUser';
import { userIsInRole } from 'lib/utils';

import 'react-toastify/dist/ReactToastify.css';

// Pages
import Collections from 'pages/Collections';
import Collection from 'pages/Collection';
import CustomDesignRequest from 'pages/CustomDesignRequest';
import CustomDesignRequests from 'pages/CustomDesignRequests';
import Dashboard from 'pages/Dashboard';
import Diamond from 'pages/Diamond';
import Diamonds from 'pages/Diamonds';
import FormContact from 'pages/FormContact';
import FormContacts from 'pages/FormContacts';
import GeneralInquiries from 'pages/GeneralInquiries';
import Invoice from 'pages/Invoice';
import InvoicePrint from 'pages/InvoicePrint';
import Jewelries from 'pages/Jewelries';
import Jewelry from 'pages/Jewelry';
import Jobs from 'pages/Jobs';
import Job from 'pages/Job';
import Login from 'pages/Login';
import Order from 'pages/Order';
import Orders from 'pages/Orders';
import OrderFollowUp from 'pages/OrderFollowUp';
import OrderRecordPayment from 'pages/OrderRecordPayment';
import OrderShipmentConfirmation from 'pages/OrderShipmentConfirmation';
import Quote from 'pages/Quote';
import QuotePrint from 'pages/QuotePrint';
import Quotes from 'pages/Quotes';
import Ring from 'pages/Ring';
import Rings from 'pages/Rings';
import StaticPages from 'pages/StaticPages';
import StaticPage from 'pages/StaticPage';
import User from 'pages/User';
import Users from 'pages/Users';
import Vendors from 'pages/Vendors';
import Vendor from 'pages/Vendor';
import VirtualProducts from 'pages/VirtualProducts';
import VirtualProduct from 'pages/VirtualProduct';
import WorkOrder from 'pages/WorkOrder';
import WorkOrderPrint from 'pages/WorkOrderPrint';
import WorkOrders from 'pages/WorkOrders';
import WorkOrderApproval from 'pages/WorkOrderApproval';
import WorkOrderFollowUp from 'pages/WorkOrderFollowUp';
import WorkOrderRecordPayment from 'pages/WorkOrderRecordPayment';
import WorkOrderShipmentConfirmation from 'pages/WorkOrderShipmentConfirmation';
import dashboardRoutes from 'routes/dashboard';
import { User as UserType } from 'graphql/codegen';

const DashboardLayout: React.FC<{ currentUser?: UserType }> = (props) => {
  const { currentUser } = props;

  if (!currentUser) {
    // eslint-disable-next-line no-undef
    if (window.location.pathname !== '/login') {
      // eslint-disable-next-line no-undef
      localStorage.setItem('redirected-from', window.location.pathname);
    }

    return (
      <>
        <Route path="/login" exact component={Login} />
        <Redirect from="*" to="/login" />
      </>
    );
  }

  if (!userIsInRole(currentUser, ['admin', 'employee'])) {
    return <div>Unauthorized!</div>;
  }

  return (
    <div className="wrapper">
      <ToastContainer />
      <Sidebar {...props} routes={dashboardRoutes} />
      <div className="main-panel">
        <Header {...props} />
        <Switch>
          <Route path="/dashboard" component={Dashboard} />

          <Route path="/products/diamonds" exact component={Diamonds} />
          <Route path="/products/diamonds/:_id" component={Diamond} />

          <Route path="/products/virtual" exact component={VirtualProducts} />
          <Route path="/products/virtual/:id" component={VirtualProduct} />

          <Route path="/products/rings" exact component={Rings} />
          <Route path="/products/rings/:_id" component={Ring} />

          <Route path="/products/jewelry" exact component={Jewelries} />
          <Route path="/products/jewelry/:_id" component={Jewelry} />

          <Route path="/products/collections" exact component={Collections} />
          <Route path="/products/collections/:id" component={Collection} />

          <Route path="/orders" exact component={Orders} />
          <Route path="/orders/:_id" exact component={Order} />
          <Route path="/orders/:_id/payments/new" exact component={OrderRecordPayment} />
          <Route path="/orders/:_id/shipment-confirmation" exact component={OrderShipmentConfirmation} />
          <Route path="/orders/:_id/follow-up" exact component={OrderFollowUp} />

          <Route path="/quoting/custom-design-requests" exact component={CustomDesignRequests} />
          <Route path="/quoting/custom-design-requests/:_id" component={CustomDesignRequest} />

          <Route path="/quoting/form-contacts" exact component={FormContacts} />
          <Route path="/quoting/form-contacts/:_id" component={FormContact} />

          <Route path="/quoting/quotes" exact component={Quotes} />
          <Route path="/quoting/quotes/:_id" exact component={Quote} />
          <Route path="/quoting/quotes/:_id/print" component={QuotePrint} />

          <Route path="/quoting/work-orders" exact component={WorkOrders} />
          <Route path="/quoting/work-orders/:_id" exact component={WorkOrder} />
          <Route path="/quoting/work-orders/:_id/payments/new" exact component={WorkOrderRecordPayment} />
          <Route path="/quoting/work-orders/:_id/approval" exact component={WorkOrderApproval} />
          <Route
            path="/quoting/work-orders/:_id/shipment-confirmation"
            exact
            component={WorkOrderShipmentConfirmation}
          />
          <Route path="/quoting/work-orders/:_id/follow-up" exact component={WorkOrderFollowUp} />
          <Route path="/quoting/work-orders/:_id/print" exact component={WorkOrderPrint} />

          <Route path="/invoices/:_id" exact component={Invoice} />
          <Route path="/invoices/:_id/print" component={InvoicePrint} />

          <Route path="/jobs" exact component={Jobs} />
          <Route path="/jobs/:_id" component={Job} />

          <Route path="/static-pages" exact component={StaticPages} />
          <Route path="/static-pages/:_id" component={StaticPage} />

          <Route path="/vendors" exact component={Vendors} />
          <Route path="/vendors/:_id" component={Vendor} />

          <Route path="/inquiries" exact component={GeneralInquiries} />

          <Route path="/users" exact component={Users} />
          <Route path="/users/:_id" component={User} />

          <Redirect from="/" to={userIsInRole(currentUser, 'admin') ? '/dashboard' : '/products/diamonds'} />
        </Switch>
        <Footer fluid />
      </div>
    </div>
  );
};

export default withCurrentUser(DashboardLayout);
