import gql from 'graphql-tag';

export default gql`
  query diamonds(
    $where: DiamondWhereInput
    $first: Int
    $last: Int
    $after: DiamondWhereUniqueInput
    $before: DiamondWhereUniqueInput
    $orderBy: [DiamondOrderByInput!]
  ) {
    count: diamondsCount(where: $where)
    diamonds(where: $where, first: $first, last: $last, after: $after, before: $before, orderBy: $orderBy) {
      _id: id
      id
      carat
      clarity {
        id
        label
      }
      color {
        id
        label
      }
      cost
      createdAt
      crownAngle
      culet {
        id
        label
      }
      cut {
        id
        label
      }
      cut {
        id
        label
      }
      lab {
        name
        reportNumber
      }
      name
      price
      quantity
      shape {
        id
        label
      }
      sku
    }
  }
`;
