import gql from 'graphql-tag';

export default gql`
  query jewelry($where: ProductWhereUniqueInput!) {
    availabilities {
      _id: id
      id
      label
      order
    }
    diamondShapes {
      _id: id
      id
      label
      order
    }
    metals {
      _id: id
      id
      label
      order
    }
    paves {
      _id: id
      id
      label
      order
    }
    paveDiamondTypes {
      _id: id
      id
      label
      order
    }
    productTypes {
      _id: id
      id
      label
      order
    }
    prongs {
      _id: id
      id
      label
      order
    }
    ringTypes {
      _id: id
      id
      label
      order
    }
    bandTypes {
      _id: id
      id
      label
      order
    }
    shanks {
      _id: id
      id
      label
      order
    }
    shankThicknesses {
      _id: id
      id
      label
      value
    }

    optionSets {
      id
      name
      slug
      options {
        id
        name
        slug
      }
    }

    jewelry: product(where: $where) {
      _id: id
      id
      allowCheckout
      availability {
        value
      }
      categories {
        id
        name
        slug
        createdAt
        updatedAt
      }
      carat
      collections {
        id
        name
        slug
        createdAt
        updatedAt
      }
      cost
      description
      diamondShapeId
      diamondShape {
        _id: id
        label
        value
      }
      enabled
      fingerSize
      images(orderBy: [{ order: asc }, { url: asc }]) {
        _id: id
        id
        category
        filename
        order
        path
        storageUrl
        url
      }
      isDefaultVariant
      isEngagementRing
      isHandFabricated
      isRightHandRing
      isBand
      isLabGrown
      lab {
        name
        certificate
        reportNumber
      }
      leadTimeFrom
      leadTimeTo
      metalId
      metal {
        _id: id
        label
        value
      }
      modelName
      modelNumber
      name
      notes
      options {
        id
        name
        slug
        optionSet {
          id
          name
          slug
        }
      }
      parentId
      paveId
      pave {
        _id: id
        id
        label
        value
      }
      paveDiamondTypeId
      paveDiamondType {
        _id: id
        id
        label
        value
      }
      price
      productTypeId
      productType {
        _id: id
        id
        label
        value
      }
      prongId
      prong {
        _id: id
        id
        label
        value
      }
      quantity
      ringTypeId
      ringType {
        _id: id
        id
        label
        value
      }
      bandTypeId
      bandType {
        _id: id
        id
        label
        value
      }
      salePrice
      shankId
      shank {
        _id: id
        id
        label
        value
      }
      shankThicknessId
      shankThickness {
        _id: id
        id
        label
        value
      }
      sku
      shortDescription
      slug
      type
      meta {
        title
        description
        keywords
      }
      variants(orderBy: { isDefaultVariant: desc }) {
        id
        sku
        quantity
        price
        isDefaultVariant
        isLabGrown
        parentId
        options {
          id
          name
          slug
          optionSet {
            id
            name
            slug
          }
        }
        images {
          id
          url
        }
        representativeImage
      }
      video {
        title
        code
        source
      }
      createdAt
      updatedAt
    }
  }
`;
