import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';
import { Loading } from 'components';
import orderQuery from 'graphql/orderQuery';

export default (Component) => {
  class withOrder extends React.Component {
    static propTypes = {
      match: PropTypes.object.isRequired
    }

    render() {
      const parentProps = this.props;
      return (
        <Query query={orderQuery} variables={{ _id: this.props.match.params._id }}>
          {({ error, loading, data, refetch }) => {
            if (loading) {
              return <Loading/>;
            }

            if (error) {
              return (
                <div>
                  <p>Error :(</p>
                </div>
              );
            }

            return (
              <Component
                {...parentProps}
                order={data.order}
                refetchOrder={refetch}
              />
            );
          }}
        </Query>
      );
    }
  }

  return withOrder;
};
