import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';
import _ from 'lodash';
import { PanelHeader, Button, FormInputsLegacy } from 'components';
import withOrder from 'containers/withOrder';
import withSendEmail from 'containers/withSendEmail';
import { workOrderFollowUpEmail } from 'lib/snippets';
import { notify } from 'lib/utils';

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
  padding-bottom: 1rem;

  .cancel-link {
    display: block;
    padding-top: .5rem;
    padding-right: 1rem;
  }
`;

class OrderFollowUp extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    sendEmail: PropTypes.func.isRequired
  }

  state = {
    to: '',
    subject: 'Victor Canera Order Followup',
    html: workOrderFollowUpEmail
  }

  handleStateChange = (e) => {
    const t = e.target;
    this.setState((state) => _.setWith(_.clone(state), t.name, t.value, _.clone));
  }

  handleSubmit = () => {
    const email = {
      data: {
        firstName: this.props.order.user.firstName,
        lastName: this.props.order.user.lastName,
        orderId: this.props.order._id
      },
      type: 'shipment-confirmation',
      to: this.state.to || this.props.order.user.email,
      subject: this.state.subject,
      html: this.state.html,
      orderId: this.props.order._id,
      userId: this.props.order.userId
    };

    // send the email
    this.props.sendEmail({ variables: { input: email } })
      .then(() => {
        notify('success', 'Email sent!');
        this.props.history.push(`/orders/${this.props.order._id}`);
      }).catch((err) => notify('error', err.toString()));
  }

  render() {
    const { order } = this.props;

    if (!order) {
      return <div>No work order found!</div>;
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col sm={{ size: 6, offset: 3 }}>
              <Card>
                <CardHeader>
                  <CardTitle><h5>POST SALE FOLLOW-UP</h5></CardTitle>
                  <hr />
                </CardHeader>
                <CardBody>
                  <Col sm={12}>
                    <Form onSubmit={this.handleSubmit}>
                      <FormInputsLegacy
                        inputs={[
                          {
                            label: 'To',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'text',
                              name: 'to',
                              defaultValue: order.user.email,
                              onChange: this.handleStateChange
                            }
                          },
                          {
                            label: 'Subject',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'text',
                              name: 'subject',
                              defaultValue: this.state.subject,
                              onChange: this.handleStateChange
                            }
                          },
                          {
                            label: 'Message',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'wysiwyg',
                              name: 'html',
                              defaultValue: this.state.html,
                              onChange: (html) => this.setState({ html })
                            }
                          }
                        ]}
                      />
                    </Form>
                  </Col>
                </CardBody>
                <CardFooter>
                  <Col sm={12}>
                    <FormFooter>
                      <div className="cancel-link">
                        <Link to={`/orders/${order._id}`}>Cancel</Link>
                      </div>
                      <Button className="submit-btn" color="primary" onClick={this.handleSubmit}>Send Email</Button>
                    </FormFooter>
                  </Col>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default compose(
  withOrder,
  withSendEmail
)(OrderFollowUp);
