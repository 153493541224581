import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';

/**
 * withCurrentUser HOC
 * @param {React.Component} Component A React component to inject the query result into
 * @return {Component} returns a wrapped React component with the GraphQL result on the 'data' prop
 */
export function withCurrentUser(Component) {
  const currentUserQuery = gql`
    query currentUser {
      currentUser {
        _id
        email
        firstName
        lastName
        roles
        createdAt
      }
    }
  `;

  return graphql(currentUserQuery)(Component);
}

export default withCurrentUser;
