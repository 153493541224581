/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Row, Col, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Imgix from 'react-imgix';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Button, FormInputs, FormInputsLegacy, Modal } from 'components';
import Card from 'components/tailwinds/Card';
import { Table, THead, TBody, Th, Td } from 'components/tailwinds/Table';
import { notify, Alert } from 'lib/utils';
import { useCollectionQuery, useProductsQuery, useUpdateCollectionMutation } from 'graphql/codegen';

interface FormData {
  name: string;
  slug: string;
  description: string;
  showInCatalog: boolean;
}

interface SelectOption {
  label: string;
  value: string;
}

interface MatchParams {
  id: string;
}

const Collection: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { id } = match.params;

  const { error, loading, refetch, data } = useCollectionQuery({ variables: { where: { id } } });
  const { data: productData, loading: productsLoading } = useProductsQuery({
    variables: { where: { parentId: null } }
  });
  const [updateCollection] = useUpdateCollectionMutation();
  const { handleSubmit, register, reset, formState } = useForm<FormData>();
  const [selectedProduct, setSelectedProduct] = React.useState<SelectOption>({ label: '', value: '' });

  // onSubmit handler
  async function handleUpdateCollection(formData: FormData): Promise<void> {
    // run mutation
    await updateCollection({
      variables: {
        where: { id },
        data: {
          name: { set: formData.name.trim() },
          description: { set: formData.description.trim() },
          showInCatalog: { set: formData.showInCatalog }
          // slug: { set: formData.slug.trim() }, // TODO: temporary until slugs are dynamic on frontend nav menus
        }
      }
    });
    // refetch query
    await refetch();
    // reset form
    reset();
    notify('success', 'Successfully saved!');
  }

  // add product to collection
  async function handleProductAdd(product: SelectOption): Promise<void> {
    // run mutation
    await updateCollection({
      variables: {
        where: { id },
        data: {
          products: {
            connect: [{ id: product.value }]
          }
        }
      }
    });
    // refetch query
    await refetch();

    notify('success', 'Product added!');
  }

  // remove product from collection
  async function handleProductRemove(productId: string): Promise<void> {
    // run mutation
    await updateCollection({
      variables: {
        where: { id },
        data: {
          products: {
            disconnect: [{ id: productId }]
          }
        }
      }
    });
    // refetch query
    await refetch();

    notify('success', 'Product removed!');
  }

  if (loading || productsLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error.toString()}</div>;
  }

  return (
    <>
      <Row style={{ marginTop: '3rem' }}>
        <Col md={{ size: 4, offset: 2 }}>
          <Card>
            <Row>
              <Col className="prose">
                <h2>Collection Details</h2>
                <FormInputs
                  inputs={[
                    {
                      label: 'Name',
                      colProps: { xs: 12 },
                      inputProps: {
                        type: 'text',
                        name: 'name',
                        defaultValue: data?.collection?.name,
                        innerRef: register({ required: true })
                      }
                    },
                    {
                      label: 'Slug',
                      colProps: { xs: 12 },
                      inputProps: {
                        type: 'text',
                        name: 'slug',
                        disabled: true, // TODO: temporary until slugs are dynamic on frontend nav menus
                        defaultValue: data?.collection?.slug,
                        innerRef: register({ required: true })
                      }
                    },
                    {
                      label: 'Description',
                      colProps: { xs: 12 },
                      inputProps: {
                        type: 'textarea',
                        name: 'description',
                        rows: 6,
                        defaultValue: data?.collection?.description,
                        innerRef: register({ required: true })
                      }
                    },
                    {
                      label: 'Show items on catalog pages?',
                      colProps: { xs: 12 },
                      inputProps: {
                        type: 'checkbox',
                        name: 'showInCatalog',
                        rows: 6,
                        defaultChecked: data?.collection?.showInCatalog,
                        innerRef: register()
                      }
                    }
                  ]}
                />
                <div className="float-right">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!formState.isDirty}
                    onClick={handleSubmit(handleUpdateCollection, (errors, e) => console.log(errors, e))}
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="my-8">
        <Col md={12}>
          <Row>
            <Col md={{ size: 8, offset: 2 }}>
              <h2 className="text-xl">Products</h2>
              <Modal
                launchButtonText={
                  <span>
                    <Icon icon={faPlus} /> Product
                  </span>
                }
                submitButtonText="Add Product"
                onSubmit={(): void => {
                  handleProductAdd(selectedProduct);
                }}
              >
                <Form>
                  <FormInputsLegacy
                    inputs={[
                      {
                        label: 'Products',
                        colProps: { xs: 12 },
                        inputProps: {
                          type: 'select',
                          name: 'products',
                          options: productData?.products
                            .filter((p) => !data?.collection?.products.map((p) => p.id).includes(p.id))
                            .map((p) => ({
                              label: `${p.sku} - ${p.name}`,
                              value: p.id
                            })),
                          value: selectedProduct,
                          onChange: (item: SelectOption): void => {
                            setSelectedProduct(item);
                          }
                        }
                      }
                    ]}
                  />
                </Form>
              </Modal>
              {data?.collection?.products.length ? (
                <Table>
                  <THead>
                    <tr>
                      <Th />
                      <Th>SKU</Th>
                      <Th>Name</Th>
                      <Th />
                    </tr>
                  </THead>
                  <TBody>
                    {data?.collection?.products.map((p) => (
                      <tr key={p.id}>
                        <Td>{p.representativeImage && <Imgix src={p.representativeImage} width={100} />}</Td>
                        <Td>{p.sku}</Td>
                        <Td>{p.name}</Td>
                        <Td>
                          <Icon
                            icon={faTrash}
                            onClick={(): any => {
                              Alert({
                                title: 'Are you sure?',
                                text: 'You can re-add this product later if needed.',
                                icon: 'warning',
                                dangerMode: true,
                                buttons: {
                                  cancel: true,
                                  confirm: 'Remove'
                                }
                              }).then((confirmed: boolean): any => confirmed && handleProductRemove(p.id));
                            }}
                          />
                        </Td>
                      </tr>
                    ))}
                  </TBody>
                </Table>
              ) : (
                <div className="text-center">No products yet!</div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Collection;
