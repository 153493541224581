import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Print } from 'react-easy-print';

// query for the fully rendered email html
const workOrderEmail = gql`
  query workOrderEmail($_id: ObjID!) {
    workOrderEmail(_id: $_id) {
      html
    }
  }
`;

// just fetch the email HTML from the server and render it to the page to print
const WorkOrderPrint = ({ match }) => (
  <Print>
    <Query query={workOrderEmail} variables={{ _id: match.params._id }}>
      {({ error, loading, data }) => {
        if (loading) {
          return null;
        }

        if (error) {
          return (
            <div>
              <p>Oops! Something went wrong on the server!</p>
              {error.toString()}
            </div>
          );
        }

        let html = data.workOrderEmail.html;

        // allow the email HTML to take up full width
        html = html.replace(/800px/g, '100%');

        // remove the unsubscribe link in the footer
        html = html.replace('<p style="margin: 0;">To stop receiving these emails, <a href="https://sendgrid.com" target="_blank">Unsubscribe</a>.</p>', '');

        return (
          <div dangerouslySetInnerHTML={{ __html: html }}/>
        );
      }}
    </Query>
  </Print>
);

WorkOrderPrint.propTypes = {
  match: PropTypes.object.isRequired
};

export default WorkOrderPrint;
