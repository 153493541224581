import React from 'react';
import { Mutation } from '@apollo/react-components';
import invoiceUpdate from 'graphql/invoiceUpdate';

export default (Component) => (
  class withInvoiceUpdate extends React.Component {
    render() {
      return (
        <Mutation mutation={invoiceUpdate}>
          {(mutate) => <Component {...this.props} updateInvoice={mutate} />}
        </Mutation>
      );
    }
  }
);
