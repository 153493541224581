import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { notify } from 'lib/utils';
import { PanelHeader, Loading, Button } from 'components';
import MarkupTable from 'components/Vendors/MarkupTable';
import LeadTimeRange, { ILeadTimeRange } from 'components/Vendors/LeadTimeRange';
import vendorQuery from 'graphql/vendorQuery';
import UPDATE_VENDOR from 'graphql/vendorUpdate';
import { Vendor, QueryVendorArgs, MutationUpdateVendorArgs } from 'graphql/codegen';

interface VendorData {
  vendor: Vendor;
}

const VendorDetailCol = styled(Col)`
  padding-left: 2rem;

  .vendor-settings__header {
    font-size: 1rem;
    padding-bottom: 1.5rem;
  }

  /* No spinner for number fields */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const VendorPage: React.FC = () => {
  const { _id } = useParams<{ _id: string }>();
  const history = useHistory();

  const [saveVendor, { error: saveVendorError }] = useMutation<VendorData, MutationUpdateVendorArgs>(UPDATE_VENDOR);

  const { loading, error, refetch, data: { vendor } = { vendor: undefined } } = useQuery<VendorData, QueryVendorArgs>(
    vendorQuery,
    { variables: { where: { id: _id } } }
  );

  const [leadTimeRange, setLeadTimeRange] = useState<ILeadTimeRange>({
    leadTimeFrom: vendor?.leadTimeFrom || undefined,
    leadTimeTo: vendor?.leadTimeTo || undefined
  });

  const [markups, setMarkupsState] = useState(vendor?.markups);

  useEffect(() => {
    setLeadTimeRange({
      leadTimeFrom: vendor?.leadTimeFrom || undefined,
      leadTimeTo: vendor?.leadTimeTo || undefined
    });
    setMarkupsState(vendor?.markups);
  }, [vendor]);

  if (error || saveVendorError) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  if (!vendor || !markups) {
    return <div>no data!</div>;
  }

  return (
    <>
      <PanelHeader size="sm" />
      <Row className="content">
        <Col xs={12} sm={{ size: 6, offset: 3 }}>
          <Card>
            <CardBody style={{ paddingTop: '2rem', paddingBottom: '3rem' }}>
              {loading ? (
                <Loading />
              ) : (
                <Row>
                  <VendorDetailCol sm={12}>
                    <h3>Vendor Settings</h3>
                    <hr />
                    <div className="vendor-settings__header">
                      <span className="font-weight-bold mr-4">Name:</span>
                      {vendor.name}
                    </div>
                    <MarkupTable markups={markups} setMarkupsState={setMarkupsState} />
                    <LeadTimeRange setLeadTimeRange={setLeadTimeRange} leadTimeRange={leadTimeRange} />
                  </VendorDetailCol>
                </Row>
              )}
              <hr />
              <Row>
                <Col>
                  <Button onClick={(): void => history.goBack()}>Go back</Button>
                </Col>
                <Col>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={async (): Promise<void> => {
                      await saveVendor({
                        variables: {
                          where: { id: vendor.id },
                          data: {
                            markups: {
                              updateMany: markups.map(({ id, from, to, markupPercent }) => ({
                                where: { id: { equals: id } },
                                data: {
                                  from: { set: from },
                                  to: { set: to },
                                  markupPercent: { set: markupPercent }
                                }
                              }))
                            },
                            leadTimeFrom: { set: leadTimeRange.leadTimeFrom },
                            leadTimeTo: { set: leadTimeRange.leadTimeTo }
                          }
                        }
                      }).then(() => refetch());
                      notify('success', 'Vendor updated!');
                    }}
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default VendorPage;
