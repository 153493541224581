import { graphql } from '@apollo/react-hoc';
import updateUser from 'graphql/userUpdate';
import userQuery from 'graphql/userQuery';

export default (Component) => graphql(updateUser, {
  props: ({ mutate }) => ({
    updateUser: (args) => mutate({
      variables: args,
      refetchQueries: [{
        query: userQuery,
        variables: { _id: args._id }
      }]
    })
  })
})(Component);
