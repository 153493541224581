// link inside a collapsable base level
interface SideBarSubLink {
  name: string;
  path: string;
  mini: string;
}

// top level link
interface SideBarLink {
  name: string;
  path: string;
  icon?: string;
  collapse?: boolean;
  state?: string;
  views?: SideBarSubLink[];
}

const links: SideBarLink[] = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'design_app'
  },
  {
    collapse: true,
    path: '/products',
    name: 'Products',
    state: 'openProducts',
    icon: 'objects_diamond',
    views: [
      {
        path: '/products/diamonds',
        name: 'Diamonds',
        mini: 'D'
      },
      {
        path: '/products/rings',
        name: 'Rings',
        mini: 'R'
      },
      {
        path: '/products/jewelry',
        name: 'Jewelry',
        mini: 'J'
      },
      {
        path: '/products/virtual',
        name: 'Virtual',
        mini: 'V'
      },
      {
        path: '/products/collections',
        name: 'Collections',
        mini: 'C'
      }
    ]
  },

  {
    path: '/users',
    name: 'Users',
    icon: 'design_app'
  },

  {
    path: '/orders',
    name: 'Orders',
    icon: 'design_app'
  },

  {
    collapse: true,
    path: '/quoting',
    name: 'Quoting',
    state: 'openQuoting',
    icon: 'objects_diamond',
    views: [
      {
        path: '/quoting/custom-design-requests',
        name: 'Custom Design Requests',
        mini: 'C'
      },
      {
        path: '/quoting/quotes',
        name: 'Quotes',
        mini: 'Q'
      },
      {
        path: '/quoting/work-orders',
        name: 'Work Orders',
        mini: 'W'
      }
    ]
  },

  {
    path: '/jobs',
    name: 'Jobs',
    icon: 'design_app'
  },

  {
    path: '/static-pages',
    name: 'Static Pages',
    icon: 'design_app'
  },

  {
    path: '/vendors',
    name: 'Vendors',
    icon: 'design_app'
  },

  {
    path: '/inquiries',
    name: 'Customer Inquiries',
    icon: 'design_app'
  }
];

export default links;
