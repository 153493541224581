/* eslint-disable max-len */

export const paymentReceivedEmail = `
Hi {{firstName}} {{lastName}},<br />
<br />
<p>Thank you for shopping at VictorCanera.com.</p>
<br />
<p>We wanted to notify you that we have received your {{paymentType}} payment in the amount of {{amount}} credited towards order number {{orderId}}.</p>
<br />
<p>Your order will now be processed and once complete, we will issue an email with your shipment tracking information.</p>
<br />
`;

export const workOrderApprovalEmail = `
Dear {{user.firstName}} {{user.lastName}},<br />
<p>
  This Work Order is the blueprint of your project. Confirm these specifications by signing this document and emailing it back to support@victorcanera.com.
</p>
<p>
  You may also visit your VictorCanera.com Account Panel's <a href="https://victorcanera.com/account/work-orders">Work Order History</a> page to check on the status of your order.
</p>
<br />
`;


export const workOrderShipmentConfirmationEmail = `
Great news, {{user.firstName}}.<br />
<p>
  We wanted to let you know that your Victor Canera order, order number <a href="https://victorcanera.com/account/work-orders/{{workOrder._id}}">{{workOrder._id}}</a> is on its way!
</p>
<p>
  Your package was shipped using {{shippingCompany}} and has a tracking number of <a href="{{trackingUrl}}{{trackingNumber}}">{{trackingNumber}}</a>. You can track your package by following this link:
</p>
<p>{{trackingUrl}}{{trackingNumber}}</p>
`;

export const orderShipmentConfirmationEmail = `
Great news, {{user.firstName}}.<br />
<p>
  We wanted to let you know that your Victor Canera order, order number <a href="https://victorcanera.com/account/orders/{{order._id}}">{{order._id}}</a> is on its way!
</p>
<p>
  Your package was shipped using {{shippingCompany}} and has a tracking number of <a href="{{trackingUrl}}{{trackingNumber}}">{{trackingNumber}}</a>. You can track your package by following this link:
</p>
<p>{{trackingUrl}}{{trackingNumber}}</p>
`;

export const workOrderFollowUpEmail = `
<table>
<tr>
<td align="center" bgcolor="#f9f9f9">
  <!--[if (gte mso 9)|(IE)]>
  <table align="center" border="0" cellpadding="0" cellspacing="0" width="680">
  <tr>
  <td align="center" valign="top" width="680">
  <![endif]-->
  <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 680px;">
    <!-- start copy -->
    <tr>
      <td align="left" bgcolor="#ffffff" style="padding: 0 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
        <p style="margin: 0; padding:0 0 20px 0; border-bottom: 1px solid #aeaeae;">We wanted to follow up with your recent order at VictorCanera.com to say thank you for letting us serve you.
          <br>
          <br>
          Spread the word about Victor Canera! Your testimonials and reviews are important to us and help to "pass it on" to others giving them a chance to hear about VC. Share your positive experience working with us and as a token of our appreciationreceive credit towards your next jewelry purchase with us;
          <br>
          <br>
          <ul style="font-size:13px; font-family:'Source Sans Pro', Helvetica, Arial, sans-serif; font-weight:400; line-height: 22px; ">
            <li>Show off your beautiful Victor Canera product and share your positive experience on <a href="http://www.pricescope.com">Pricescope.com</a>'s <a href="http://www.pricescope.com/forum/show-me-the-ring" target="_blank">Diamond Ring Showcase</a> and receive a $75 credit.
            </li>
            <br>
            <li>Forward us a real life photo of your VC design being worn and if it's featured on our <a href="http://instagram/victorcanera" target="_blank">Instagram</a> receive a $75 credit.
            </li>
            <br>
            <li>
            <a href="https://www.yelp.com/writeareview/biz/bZOs_J5fCaJ-a4C_W1iDtw?return_url=%2Fbiz%2FbZOs_J5fCaJ-a4C_W1iDtw" target="_blank">Yelp</a> us a review with a Five ⭐⭐⭐⭐⭐ Star rating with <a href="https://www.yelp.com/writeareview/biz/bZOs_J5fCaJ-a4C_W1iDtw?return_url=%2Fbiz%2FbZOs_J5fCaJ-a4C_W1iDtw" target="_blank">Yelp</a> and receive a $25 credit.
            </li>
            <br>
            <li>Share your positive experience with us with a Five ⭐⭐⭐⭐⭐ Star rating on <a href="https://www.google.com/#q=victor+canera&amp;lrd=0x80c2c7b4fbf4ea19:0x4e38e81cabff5993,3," target="_blank">Google Review</a> and receive a $25 credit.
            </li>
          </ul>
        </p>
      </td>
    </tr>
    <!-- end copy -->
  </table>
  <!--[if (gte mso 9)|(IE)]>
  </td>
  </tr>
  </table>
  <![endif]-->
</td>
</tr>
</table>
`;
