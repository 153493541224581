import gql from 'graphql-tag';

export default gql`
  query jobs($workOrderId: ObjID, $userId: ObjID) {
    jobs(workOrderId: $workOrderId, userId: $userId) {
      _id
      cost
      description
      userId
      user {
        firstName
        lastName
        email
      }
      internalNotes
      jobNumber
      price
      quantity
      status
      statusNotes
      title
      type
      unitPrice
      workOrderId
      promisedAt
      completedAt
      createdAt
      updatedAt
    }
  }
`;
