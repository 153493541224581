import gql from 'graphql-tag';

export default gql`
  mutation removeWorkOrderItem($_id: ObjID!) {
    removeWorkOrderItem(_id: $_id) {
      n
      ok
    }
  }
`;
