import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Table,
  Form,
  Label,
  Input,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import NotificationAlert from 'react-notification-alert';
import Select from 'react-select';
import Cleave from 'cleave.js/react';
import { faPlus, faTrash, faPrint } from '@fortawesome/free-solid-svg-icons';
import { PanelHeader, Button, Icon, FormInputsLegacy, DateFormat, DatePicker, RightAligned } from 'components';
import withJob from 'containers/withJob';
import withJobUpdate from 'containers/withJobUpdate';
import jobQuery from 'graphql/job';
import workOrderQuery from 'graphql/workOrder';
import { formatPrice, multiply } from 'lib/utils';
import JobEnvelopePrint from './JobEnvelopePrint';

const Wrapper = styled.div`
  @media print {
    display: none;
  }
`;

class Job extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    job: PropTypes.object,
    match: PropTypes.object.isRequired,
    updateJob: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      job: props.job,
      statusChanged: false
    };
  }

  handleStateChange = (e) => {
    const t = e.target;
    this.setState((state) => _.setWith(_.clone(state), t.name, t.value, _.clone));
  };

  handleStatusUpdate = () => {
    this.props
      .updateJob({
        variables: {
          _id: this.props.job._id,
          input: { status: this.state.status }
        },
        refetchQueries: [
          {
            query: jobQuery,
            variables: { _id: this.props.job._id }
          }
        ]
      })
      .then(() => {
        this.notify('primary', `Status updated to "${this.state.status}".`);
        this.setState({ statusChanged: false });
      })
      .catch(() => this.notify('danger', 'Save failed!'));
  };

  handleJobUpdate = () => {
    const job = _.omit(this.state.job, ['_id', 'user', 'workOrder', 'createdAt', 'updatedAt', '__typename']);

    job.services = job.services.map((item) => _.omit(item, ['__typename']));

    this.props
      .updateJob({
        variables: {
          _id: this.props.job._id,
          input: job
        },
        refetchQueries: [
          {
            query: jobQuery,
            variables: { _id: this.props.job._id }
          }
        ]
      })
      .then(() => {
        this.notify('primary', 'Job updated!');
        this.setState({ jobItemsUpdated: false });
      })
      .catch(() => this.notify('danger', 'Save failed!'));
  };

  updateCompletedState = (isComplete) => {
    const completedAt = isComplete ? new Date() : null;

    this.props
      .updateJob({
        variables: {
          _id: this.props.job._id,
          input: { completedAt }
        },
        refetchQueries: [
          {
            query: jobQuery,
            variables: { _id: this.props.job._id }
          },
          {
            query: workOrderQuery,
            variables: { _id: this.props.job.workOrderId }
          }
        ]
      })
      .then(() => {
        this.notify('primary', 'Job updated!');
        this.setState((state) => _.setWith(_.clone(state), 'job.completedAt', completedAt, _.clone));
      })
      .catch(() => this.notify('danger', 'Save failed!'));
  };

  addJobServiceItem = () => {
    const services = _.clone(this.state.job.services) || [];

    services.push({
      name: '',
      note: '',
      unitPrice: 0,
      quantity: 1,
      price: 0
    });

    this.setState((state) => _.setWith(_.clone(state), 'job.services', services, _.clone));
  };

  deleteJobService = (itemIndex) => {
    const services = _.clone(this.state.job.services);
    services.splice(itemIndex, 1);
    this.setState((state) => _.setWith(_.clone(state), 'job.services', services, _.clone));
  };

  notify(type, message) {
    this.refs.notificationAlert.notificationAlert({
      place: 'tc',
      message,
      type, // success|danger
      autoDismiss: 3
    });
  }

  renderListItem(label, value) {
    return (
      <ListGroupItem>
        <span style={{ fontWeight: 'bold' }}>{label}: </span>
        <span>{value}</span>
      </ListGroupItem>
    );
  }

  render() {
    const { job } = this.props;

    if (!job) {
      return <div>No job found!</div>;
    }

    const isComplete = !!job.completedAt;

    return (
      <div>
        <Wrapper>
          <NotificationAlert ref="notificationAlert" />
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col md={6}>
                <Card>
                  <CardHeader>
                    <CardTitle>Client Info</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <ListGroup>
                          {this.renderListItem('First Name', job.user.firstName)}
                          {this.renderListItem('Last Name', job.user.lastName)}
                          {this.renderListItem('Email', job.user.email)}
                          {this.renderListItem('Phone', job.user.phone)}
                        </ListGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter />
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardHeader>
                    <CardTitle>Job Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={11}>
                        <Form>
                          <FormInputsLegacy
                            inputs={[
                              {
                                label: 'Type',
                                colProps: {
                                  xs: 12
                                },
                                inputProps: {
                                  type: 'text',
                                  name: 'job.type',
                                  defaultValue: job.type,
                                  disabled: isComplete,
                                  onChange: this.handleStateChange
                                }
                              },
                              {
                                label: 'Title',
                                colProps: {
                                  xs: 12
                                },
                                inputProps: {
                                  type: 'text',
                                  name: 'job.title',
                                  defaultValue: job.title,
                                  disabled: isComplete,
                                  onChange: this.handleStateChange
                                }
                              },
                              {
                                label: 'Description',
                                colProps: {
                                  xs: 12
                                },
                                inputProps: {
                                  type: isComplete ? 'texarea' : 'wysiwyg',
                                  name: 'job.description',
                                  defaultValue: job.description,
                                  disabled: isComplete,
                                  onChange: (val) => {
                                    this.setState((state) =>
                                      _.setWith(_.clone(state), 'job.description', val, _.clone)
                                    );
                                  }
                                }
                              },
                              {
                                label: 'Internal Notes',
                                colProps: {
                                  xs: 12
                                },
                                inputProps: {
                                  type: 'textarea',
                                  name: 'job.internalNotes',
                                  defaultValue: job.internalNotes,
                                  disabled: isComplete,
                                  onChange: this.handleStateChange
                                }
                              }
                            ]}
                          />
                          <Label>Promised At</Label>
                          <br />
                          {isComplete ? (
                            this.state.job.promisedAt && (
                              <DateFormat date={job.promisedAt} format="MMMM d, yyyy @ h:mma" />
                            )
                          ) : (
                            <DatePicker
                              date={this.state.job.promisedAt && moment(this.state.job.promisedAt)}
                              focused={this.state.focused}
                              onFocusChange={({ focused }) => this.setState({ focused })}
                              id="promisedAt"
                              isDayBlocked={() => false}
                              isOutsideRange={() => false}
                              showDefaultInputIcon
                              readOnly={isComplete}
                              onDateChange={(date) => {
                                this.setState((state) =>
                                  _.setWith(_.clone(state), 'job.promisedAt', date.toISOString(), _.clone)
                                );
                              }}
                            />
                          )}
                          <br />
                          <br />

                          {isComplete && (
                            <div>
                              <Label>Completed At</Label>
                              <br />
                              <DateFormat date={job.completedAt} format="MMMM d, yyyy @ h:mma" />
                            </div>
                          )}
                        </Form>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <RightAligned>
                      <Button color="primary" type="submit" onClick={() => window.print()}>
                        <Icon name={faPrint} /> Print Job Envelope
                      </Button>
                    </RightAligned>
                  </CardFooter>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        {isComplete ? (
                          <div>
                            <strong>Status:</strong> Complete
                          </div>
                        ) : (
                          <div>
                            <Form>
                              <FormInputsLegacy
                                inputs={[
                                  {
                                    label: 'Status',
                                    colProps: {
                                      xs: 12
                                    },
                                    inputProps: {
                                      type: 'select',
                                      name: 'status',
                                      options: [
                                        {
                                          label: 'Bench Work',
                                          value: 'Bench Work'
                                        },
                                        {
                                          label: 'Stone Setting',
                                          value: 'Stone Setting'
                                        },
                                        {
                                          label: 'Polishing',
                                          value: 'Polishing'
                                        },
                                        {
                                          label: 'Engraving',
                                          value: 'Engraving'
                                        }
                                      ],
                                      defaultValue: { label: job.status, value: job.status },
                                      onChange: (value) => {
                                        this.setState({ status: value.value, statusChanged: true });
                                      }
                                    }
                                  }
                                ]}
                              />
                            </Form>
                            {this.state.statusChanged && (
                              <CardFooter>
                                <Button color="primary" type="submit" onClick={this.handleStatusUpdate}>
                                  Update Status
                                </Button>
                              </CardFooter>
                            )}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter />
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md={3}>
                        <h2>Services</h2>
                      </Col>
                      <Col md={9}>
                        <RightAligned>
                          {!isComplete && (
                            <Button color="primary" onClick={this.addJobServiceItem}>
                              <Icon name={faPlus} />
                            </Button>
                          )}
                        </RightAligned>
                      </Col>
                      <hr />
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th>Service Name</th>
                          <th>Notes</th>
                          <th>Unit Price</th>
                          <th>Quantity</th>
                          <th style={{ textAlign: 'right' }}>Total</th>
                          {!isComplete && <th />}
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(this.state.job.services) &&
                          this.state.job.services.map((item, i) =>
                            isComplete ? (
                              <tr key={i}>
                                <td>{item.name}</td>
                                <td>{item.note}</td>
                                <td>{formatPrice(item.unitPrice)}</td>
                                <td>{item.quantity}</td>
                                <td style={{ textAlign: 'right' }}>
                                  {formatPrice(item.price, { trimDecimals: false })}
                                </td>
                              </tr>
                            ) : (
                              <tr key={i}>
                                <td width="200px">
                                  <Select
                                    name={`job.services[${i}].name`}
                                    options={[
                                      {
                                        label: 'Precious Stones',
                                        value: 'Precious Stones'
                                      },
                                      {
                                        label: 'Precious Metal',
                                        value: 'Precious Metal'
                                      },
                                      {
                                        label: 'Bench Work',
                                        value: 'Bench Work'
                                      },
                                      {
                                        label: 'Stone Setting',
                                        value: 'Stone Setting'
                                      },
                                      {
                                        label: 'Engraving',
                                        value: 'Engraving'
                                      },
                                      {
                                        label: 'Polishing',
                                        value: 'Polishing'
                                      }
                                    ]}
                                    defaultValue={{ label: item.name, value: item.name }}
                                    onChange={(value) => {
                                      this.setState((state) =>
                                        _.setWith(_.clone(state), `job.services[${i}].name`, value.value, _.clone)
                                      );
                                    }}
                                    disabled={isComplete}
                                  />
                                </td>
                                <td>
                                  <Input
                                    name={`job.services[${i}].note`}
                                    type="textarea"
                                    defaultValue={item.note}
                                    onChange={this.handleStateChange}
                                    disabled={isComplete}
                                  />
                                </td>
                                <td>
                                  <Cleave
                                    name={`job.services[${i}].unitPrice`}
                                    value={parseFloat(_.get(this.state, `job.services[${i}].unitPrice`), 10) * 0.01}
                                    className="form-control"
                                    onChange={(e) => {
                                      const { name, rawValue } = e.target;

                                      const unitPrice = parseFloat(Number(rawValue), 10) * 100;
                                      const quantity = _.get(this.state, `job.services[${i}].quantity`);
                                      const price = multiply(unitPrice, quantity);

                                      this.setState((state) => _.setWith(_.clone(state), name, unitPrice, _.clone));
                                      this.setState((state) =>
                                        _.setWith(_.clone(state), `job.services[${i}].price`, price, _.clone)
                                      );
                                    }}
                                    options={{
                                      numeral: true,
                                      numeralThousandsGroupStyle: 'thousand',
                                      numeralDecimalScale: 2,
                                      prefix: '$',
                                      rawValueTrimPrefix: true,
                                      numericOnly: true
                                    }}
                                    disabled={isComplete}
                                  />
                                </td>
                                <td>
                                  <Input
                                    name={`job.services[${i}].quantity`}
                                    type="number"
                                    defaultValue={item.quantity}
                                    onChange={(e) => {
                                      const { name, value } = e.target;

                                      const unitPrice = _.get(this.state, `job.services[${i}].unitPrice`);
                                      const quantity = parseFloat(value, 10);
                                      const price = multiply(unitPrice, quantity);

                                      this.setState((state) => _.setWith(_.clone(state), name, quantity, _.clone));
                                      this.setState((state) =>
                                        _.setWith(_.clone(state), `job.services[${i}].price`, price, _.clone)
                                      );
                                    }}
                                    disabled={isComplete}
                                  />
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {formatPrice(_.get(this.state, `job.services[${i}].price`), { trimDecimals: false })}
                                </td>
                                <td>
                                  <Button color="danger" onClick={() => this.deleteJobService(i)}>
                                    <Icon name={faTrash} />
                                  </Button>
                                </td>
                              </tr>
                            )
                          )}
                        <tr>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td style={{ textAlign: 'right', fontWeight: 600 }}>
                            {Array.isArray(this.state.job.services) &&
                              formatPrice(
                                this.state.job.services.reduce((sum, item) => sum + item.price, 0),
                                { trimDecimals: false }
                              )}
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </Table>
                    <br />
                    <hr />
                  </CardBody>
                  <CardFooter>
                    {!!job.workOrderId && (
                      <Link to={`/quoting/work-orders/${job.workOrderId}`}>
                        <Button style={{ marginRight: '2em' }}>View Work Order</Button>
                      </Link>
                    )}
                    {isComplete && (
                      <span>
                        Job completed on <DateFormat date={job.completedAt} format="MMMM d, yyyy @ h:mma" />
                      </span>
                    )}
                    <RightAligned>
                      {isComplete ? (
                        <Button color="danger" type="submit" onClick={() => this.updateCompletedState(false)}>
                          Mark Job Incomplete
                        </Button>
                      ) : (
                        <div>
                          <Button color="default" type="submit" onClick={() => this.updateCompletedState(true)}>
                            Mark Job Complete
                          </Button>
                          &nbsp;
                          <Button color="primary" type="submit" onClick={this.handleJobUpdate}>
                            Save Job
                          </Button>
                        </div>
                      )}
                    </RightAligned>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
        </Wrapper>
        <JobEnvelopePrint job={this.state.job} />
      </div>
    );
  }
}

export default compose(withJob, withJobUpdate)(Job);
