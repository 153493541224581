import styled from 'styled-components';

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: .5rem;
  padding-right: .5rem;
`;

export default FlexSpaceBetween;
