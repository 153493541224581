/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Button, FormInputs } from 'components';
import Card from 'components/tailwinds/Card';
import { Table, THead, TBody, Th, Td } from 'components/tailwinds/Table';
import { notify, Alert } from 'lib/utils';
import { useCollectionsQuery, useCreateCollectionMutation, useDeleteCollectionMutation } from 'graphql/codegen';

interface FormData {
  name: string;
  slug: string;
}

const Collections: React.FC = () => {
  const {
    error,
    loading,
    refetch,
    data
  } = useCollectionsQuery();

  const { handleSubmit, register, setValue, reset, formState } = useForm<FormData>();

  const [createCollection] = useCreateCollectionMutation();
  const [deleteCollection] = useDeleteCollectionMutation();

  // onSubmit handler
  async function handleCreateCollection(formData: FormData): Promise<void> {
    // run mutation
    await createCollection({
      variables: {
        data: {
          name: formData.name.trim(),
          slug: formData.slug.trim()
        }
      }
    });
    // refetch query
    await refetch();
    // reset form
    reset();
    setValue('name', '');
    setValue('slug', '');
    notify('success', 'Category created!');
  }

  async function handleDeleteCollection(id: string): Promise<void> {
    // run mutation
    await deleteCollection({ variables: { where: { id } } });
    // refetch query
    await refetch();
    // reset form
    reset();
    notify('success', 'Category deleted!');
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error.toString()}</div>;
  }

  return (
    <Row style={{ marginTop: '3rem' }}>
      <Col md={{ size: 8, offset: 2 }}>
        <Card>
          <Row>
            <Col className="prose">
              <h2>Product Collections</h2>
              <FormInputs
                inputs={[
                  {
                    label: 'Name',
                    colProps: { xs: 12, md: 6 },
                    inputProps: {
                      type: 'text',
                      name: 'name',
                      innerRef: register({ required: true })
                    }
                  },
                  {
                    label: 'Slug',
                    colProps: { xs: 12, md: 6 },
                    inputProps: {
                      type: 'text',
                      name: 'slug',
                      innerRef: register({ required: true })
                    }
                  }
                ]}
              />
              {formState.isDirty && (
                <Button color="primary" type="submit" onClick={handleSubmit(handleCreateCollection, (errors, e) => console.log(errors, e))}>
                  Save
                </Button>
              )}
            </Col>
          </Row>
        </Card>
        <Row className="py-10">
          <Col>
            <Table>
              <THead>
                <tr>
                  <Th>Name</Th>
                  <Th>Slug</Th>
                  <Th>Products</Th>
                  <Th />
                </tr>
              </THead>
              <TBody>
                {data?.collections.map((c) => (
                  <tr key={c.id}>
                    <Td>{c.name}</Td>
                    <Td>{c.slug}</Td>
                    <Td>{c.products.length}</Td>
                    <Td>
                      <div className="space-x-5">
                        <Link to={`/products/collections/${c.id}`}>
                          <Icon icon={faEdit} />
                        </Link>
                        <Icon
                          icon={faTrash}
                          onClick={(): any => {
                            Alert({
                              title: 'Are you sure?',
                              text: "There's no going back!",
                              icon: 'warning',
                              dangerMode: true,
                              buttons: {
                                cancel: true,
                                confirm: 'Do it!'
                              }
                            }).then((confirmed: boolean): any => confirmed && handleDeleteCollection(c.id));
                          }}
                        />
                      </div>
                    </Td>
                  </tr>
                ))}
              </TBody>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Collections;
