import gql from 'graphql-tag';

export default gql`
  query descriptiveFields ($_id: String!) {
    diamond(slugOrId: $_id) {
      description
      metaDescription: meta { description }
      metaTitle: meta { title }
    }
  }
`;
