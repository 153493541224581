import gql from 'graphql-tag';

export default gql`
  mutation updateWorkOrderItem($_id: ObjID! $input: WorkOrderItemInput!) {
    updateWorkOrderItem(_id: $_id, input: $input) {
      _id
      updatedAt
    }
  }
`;
