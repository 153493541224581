import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';
import _ from 'lodash';
import { PanelHeader, Button, FormInputsLegacy } from 'components';
import withWorkOrder from 'containers/withWorkOrder';
import withSendWorkOrderShipmentEmail from 'containers/withSendWorkOrderShipmentEmail';
import { workOrderShipmentConfirmationEmail } from 'lib/snippets';
import { notify } from 'lib/utils';

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
  padding-bottom: 1rem;

  .cancel-link {
    display: block;
    padding-top: 0.5rem;
    padding-right: 1rem;
  }
`;

class WorkOrderShipmentConfirmation extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    sendWorkOrderShipmentEmail: PropTypes.func.isRequired,
    workOrder: PropTypes.object.isRequired
  }

  state = {
    to: '',
    subject: 'Your Victor Canera Order has Shipped!',
    message: workOrderShipmentConfirmationEmail
  }

  handleStateChange = (e) => {
    const t = e.target;
    this.setState((state) => _.setWith(_.clone(state), t.name, t.value, _.clone));
  }

  handleSubmit = () => {
    // send the email
    this.props.sendWorkOrderShipmentEmail({
      to: this.state.to || this.props.workOrder.user.email,
      subject: this.state.subject,
      message: this.state.message,
      workOrderId: this.props.workOrder._id,
      data: {
        shippingCompany: this.state.shippingCompany,
        trackingNumber: this.state.trackingNumber,
        trackingUrl: this.state.trackingUrl
      }
    })
      .then(() => {
        notify('success', 'Email sent!');
        this.props.history.push(`/quoting/work-orders/${this.props.workOrder._id}`);
      }).catch((err) => notify('error', err.toString()));
  }

  render() {
    const { workOrder } = this.props;

    if (!workOrder) {
      return <div>No work order found!</div>;
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col sm={{ size: 6, offset: 3 }}>
              <Card>
                <CardHeader>
                  <CardTitle><h5>SHIPMENT CONFIRMATION EMAIL</h5></CardTitle>
                  <hr />
                </CardHeader>
                <CardBody>
                  <Col sm={12}>
                    <Form onSubmit={this.handleSubmit}>
                      <FormInputsLegacy
                        inputs={[
                          {
                            label: 'Shipping Company',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'select',
                              name: 'shippingCompany',
                              options: [{
                                label: 'Malca-Amit',
                                value: 'Malca-Amit'
                              }, {
                                label: 'UPS',
                                value: 'UPS'
                              }, {
                                label: 'FedEx',
                                value: 'FedEx'
                              }],
                              onChange: ({ value }) => {
                                let trackingUrl;

                                switch (value) {
                                  case 'Malca-Amit':
                                    trackingUrl = 'https://tracking.malca-amit.com/';
                                    break;
                                  case 'UPS':
                                    trackingUrl = 'https://www.ups.com/track?loc=en_US&tracknum=';
                                    break;
                                  case 'FedEx':
                                    trackingUrl = 'https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=';
                                    break;
                                  default:
                                    trackingUrl = '';
                                }

                                this.setState({ shippingCompany: value, trackingUrl });
                              }
                            }
                          },
                          {
                            label: 'Tracking Number',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'text',
                              name: 'trackingNumber',
                              defaultValue: '',
                              onChange: this.handleStateChange
                            }
                          }
                        ]}
                      />
                      <hr />
                      <FormInputsLegacy
                        inputs={[
                          {
                            label: 'To',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'text',
                              name: 'to',
                              defaultValue: workOrder.user.email,
                              onChange: this.handleStateChange
                            }
                          },
                          {
                            label: 'Subject',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'text',
                              name: 'subject',
                              defaultValue: this.state.subject,
                              onChange: this.handleStateChange
                            }
                          },
                          {
                            label: 'Message',
                            colProps: {
                              xs: 12
                            },
                            inputProps: {
                              type: 'wysiwyg',
                              name: 'message',
                              defaultValue: this.state.message,
                              onChange: (message) => this.setState({ message })
                            }
                          }
                        ]}
                      />
                    </Form>
                  </Col>
                </CardBody>
                <CardFooter>
                  <Col sm={12}>
                    <FormFooter>
                      <div className="cancel-link">
                        <Link to={`/quoting/work-orders/${workOrder._id}`}>Cancel</Link>
                      </div>
                      <Button className="submit-btn" color="primary" onClick={this.handleSubmit}>Send Email</Button>
                    </FormFooter>
                  </Col>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default compose(
  withWorkOrder,
  withSendWorkOrderShipmentEmail
)(WorkOrderShipmentConfirmation);
