import gql from 'graphql-tag';

export default gql`
  mutation removeWorkOrder($_id: ObjID!) {
    removeWorkOrder(_id: $_id) {
      n
      ok
    }
  }
`;
