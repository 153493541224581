import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import withUploadImage from 'containers/withUploadImage';
import { notify } from 'lib/utils';

const ImgPreview = styled.img`
  max-width: 200px;
  margin: .5em;
`;

const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  height: 150px;
  padding: .5rem;
  border: 2px dashed #acacac;
  text-align: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

class ImageUpload extends React.Component {
  static propTypes = {
    category: PropTypes.string,
    dropzoneProps: PropTypes.object,
    dropzoneText: PropTypes.string,
    pageId: PropTypes.string,
    productId: PropTypes.string,
    productType: PropTypes.string,
    refetch: PropTypes.func,
    refetchQueries: PropTypes.arrayOf(PropTypes.object),
    uploadButtonText: PropTypes.string,
    uploadImage: PropTypes.func.isRequired
  }

  static defaultProps = {
    dropzoneProps: {},
    dropzoneText: 'Drop some images here,<br/>or click to select files to upload.'
  }

  state = {
    files: []
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  onDrop = (files) => {
    this.setState({
      files: files.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    });
  }

  handleUpload = () => {
    const { files } = this.state;
    const { pageId, productId, productType, category } = this.props;
    const data = {
      productType,
      pageId,
      productId: productType !== 'Diamond' ? productId : undefined,
      diamondId: productType === 'Diamond' ? productId : undefined,
      category
    };
    Promise.all(files.map((file) => this.props.uploadImage({
      variables: { file, data },
      refetchQueries: this.props.refetchQueries
    })))
      .then((result) => {
        this.setState({ files: [] });
        if (this.props.refetch) {
          this.props.refetch();
        }
        notify('success', `${result.length} images successfully uploaded!`);
      })
      .catch((error) => notify('error', `Upload failed! \n ${error}`));
  }

  render() {
    return (
      <Row>
        <Col md={3}>
          <Dropzone
            accept="image/*"
            onDrop={this.onDrop}
            {...this.props.dropzoneProps}
          >
            {({ getRootProps, getInputProps }) => (
              <DropzoneWrapper {...getRootProps()}>
                <input {...getInputProps()} />
                <p dangerouslySetInnerHTML={{ __html: this.props.dropzoneText }} />
              </DropzoneWrapper>
            )}
          </Dropzone>

          {!!this.state.files.length
            && (
            <Button onClick={this.handleUpload}>
              {this.props.uploadButtonText || `Upload ${this.state.files.length} files`}
            </Button>
            )}
        </Col>

        {!!this.state.files.length
          && (
          <Col sm={12}>
            <h4 style={{ marginTop: '0px' }}>Selected files</h4>
            <ul>
              {this.state.files.map((f) => <li key={f.name}>{f.name} - {Math.round(f.size * 0.0001) / 100}MB</li>)}
            </ul>
            {this.state.files.map((file) => (
              <ImgPreview
                alt="Preview"
                key={file.preview}
                src={file.preview}
              />
            ))}
          </Col>
          )}
      </Row>
    );
  }
}

export default withUploadImage(ImageUpload);
