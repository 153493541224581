import React from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';

const createPayment = gql`
  mutation createPayment($input: PaymentCreateInput!) {
    createPayment(input: $input) {
      _id
      updatedAt
    }
  }
`;

export default (Component) => (
  class withCreatePayment extends React.Component {
    render() {
      return (
        <Mutation mutation={createPayment}>
          {(mutate) => <Component {...this.props} createPayment={mutate} />}
        </Mutation>
      );
    }
  }
);
