import { graphql } from '@apollo/react-hoc';
import gql from 'graphql-tag';

const sendOrderShipmentEmail = gql`
  mutation sendOrderShipmentEmail($input: OrderEmailInput!) {
    sendOrderShipmentEmail(input: $input) {
      result
    }
  }
`;

export default (Component) => graphql(sendOrderShipmentEmail, {
  props: ({ mutate }) => ({
    sendOrderShipmentEmail: (input) => mutate({ variables: { input } })
  })
})(Component);
