import React from 'react';
import Spinner from 'react-spinkit';

// Spinner options
// https://github.com/KyleAMathews/react-spinkit
// http://kyleamathews.github.io/react-spinkit/

const styles = {
  position: 'absolute',
  top: '100%',
  left: '45%'
};

const Loading = () => (
  <div className="loader" style={styles}>
    <Spinner name="ball-grid-pulse" fadeIn="none"/>
  </div>
);

export default Loading;
