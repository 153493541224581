import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-v6';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import matchSorter from 'match-sorter';
import formatDate from 'date-fns/format';

import { PanelHeader, Button, DateFormat, Icon, Loading, UserIsInRole } from 'components';
import quotesQuery from 'graphql/quotes';
import quoteDelete from 'graphql/quoteDelete';
import { formatPrice, Alert } from 'lib/utils';

class Quotes extends Component {
  static propTypes = {
    history: PropTypes.object
  };

  renderActions(_id) {
    return (
      <div className="actions-right">
        <Link to={`/quoting/quotes/${_id}`}>
          <Button color="primary" size="sm" icon>
            <Icon name={faEdit} />
          </Button>
        </Link>
        &nbsp;
        <UserIsInRole roles="admin">
          <Mutation
            mutation={quoteDelete}
            variables={{ _id }}
            refetchQueries={() => [{ query: quotesQuery }]}
            onCompleted={() =>
              Alert('Quote deleted!', {
                icon: 'success',
                buttons: false,
                timer: 1500
              })
            }
            onError={(e) =>
              Alert('Oh no!', {
                text: e.message.split('GraphQL error: ')[1],
                icon: 'error'
              })
            }
          >
            {(mutate) => (
              <Button
                onClick={() => {
                  Alert({
                    title: 'Are you sure?',
                    text: "There's no going back!",
                    icon: 'warning',
                    dangerMode: true,
                    buttons: {
                      cancel: true,
                      confirm: 'Do it!'
                    }
                  }).then((confirmed) => confirmed && mutate());
                }}
                color="danger"
                size="sm"
                icon
              >
                <Icon name={faTimes} />
              </Button>
            )}
          </Mutation>
          &nbsp;
        </UserIsInRole>
      </div>
    );
  }

  render() {
    const columns = [
      {
        Header: 'First Name',
        accessor: 'user.firstName',
        filterMethod: (filter, row) => ~String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()),
        maxWidth: 100
      },
      {
        Header: 'Last Name',
        accessor: 'user.lastName',
        maxWidth: 100,
        filterMethod: (filter, row) => ~String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())
      },
      {
        Header: 'Email',
        accessor: 'user.email',
        maxWidth: 240,
        filterMethod: (filter, row) => ~String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())
      },
      {
        Header: 'Total Price',
        accessor: 'total',
        Cell: ({ value }) => formatPrice(value),
        maxWidth: 100,
        filterable: false
      },
      {
        Header: 'Locked?',
        accessor: 'locked',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        maxWidth: 100,
        filterable: false
      },
      {
        Header: 'Email Sent',
        accessor: 'emailedAt',
        Cell: ({ value }) => value && <DateFormat date={value} />,
        maxWidth: 150,
        filterable: false
      },
      {
        Header: 'Work Order?',
        accessor: 'workOrder',
        Cell: ({ value }) => (value && value._id ? 'Yes' : 'No'),
        maxWidth: 120,
        filterable: false
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        sortMethod: (a, b) => (a > b ? 1 : -1),
        Cell: ({ value }) => <DateFormat date={value} />,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: [(item) => formatDate(item.createdAt, 'MMMM d, yyyy')] }),
        filterAll: true,
        maxWidth: 150
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
        sortable: false,
        filterable: false
      }
    ];

    return (
      <div>
        <PanelHeader size="sm" />
        <Query query={quotesQuery} variables={{ pagination: { limit: 500 }, sort: { createdAt: -1 } }}>
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) {
              return <p>Error :(</p>;
            }

            const tableData = data.quotes.map((item) => ({
              ...item,
              actions: this.renderActions(item._id)
            }));

            return (
              <div className="content">
                <Row>
                  <Col xs={12} md={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Quotes</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          data={tableData}
                          filterable
                          columns={columns}
                          defaultPageSize={20}
                          showPaginationBottom
                          className="-striped -highlight"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Quotes;
