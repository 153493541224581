import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-v6';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { PanelHeader, Button, Icon, Loading } from 'components';
import staticPagesQuery from 'graphql/staticPages';

class StaticPages extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  renderActions(_id) {
    return (
      <div className="actions-right">
        <Link to={`/static-pages/${_id}`}>
          <Button
            color="primary"
            size="sm"
            icon
          >
            <Icon name={faEdit}/>
          </Button>
        </Link>
        &nbsp;
      </div>
    );
  }

  render() {
    const columns = [
      {
        Header: 'Route',
        accessor: 'route'
      },
      {
        Header: 'Title',
        accessor: 'title'
      },
      {
        Header: 'Content',
        accessor: 'content',
        sortable: false
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
        sortable: false,
        filterable: false
      }
    ];

    return (
      <div>
        <PanelHeader size="sm" />
        <Query query={staticPagesQuery}>
          {({ loading, error, data }) => {

            if (loading) {
              return <Loading/>;
            }

            if (error) {
              return <p>Error :(</p>;
            }

            const tableData = data.staticPages.map((item) => ({
              ...item,
              actions: this.renderActions(item._id)
            }));

            return (
              <div className="content">
                <Row>
                  <Col xs={12} sm={{ size: 10, offset: 1 }}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Static Pages</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          data={tableData}
                          filterable
                          columns={columns}
                          defaultPageSize={20}
                          showPaginationBottom
                          className="-striped -highlight"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default StaticPages;
