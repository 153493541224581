import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Nav, NavItem, NavLink,
  Row, Col, ListGroup, ListGroupItem, TabContent, TabPane, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import ReactTable from 'react-table-v6';
import NotificationAlert from 'react-notification-alert';
import { faEye, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PanelHeader, Button, DateFormat, Icon, RightAligned } from 'components';
import withFormContact from 'containers/withFormContact';
import withQuoteCreate from 'containers/withQuoteCreate';
import formContactQuery from 'graphql/formContact';
import formContactUpdate from 'graphql/formContactUpdate';
import quoteDelete from 'graphql/quoteDelete';
import workOrderDelete from 'graphql/workOrderDelete';
import customDesignRequestDelete from 'graphql/customDesignRequestDelete';
import { formatPrice, notify } from 'lib/utils';


class FormContact extends React.Component {
  static propTypes = {
    createQuote: PropTypes.func.isRequired,
    formContact: PropTypes.object,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    updateFormContact: PropTypes.func.isRequired
  }

  state = {
    statusChanged: false,
    currentTab: 'tab1'
  }

  handleStatusUpdate = () => {
    this.props.updateFormContact({ _id: this.props.match.params._id, input: { status: this.state.status } })
      .then(() => {
        notify('success', 'Status updated!');
        this.setState({ statusChanged: false });
      })
      .catch(() => notify('error', 'Save failed!'));
  }

  generateQuote = () => {
    const { formContact } = this.props;

    this.props.createQuote({
      variables: {
        input: {
          userId: formContact._id,
          items: []
        }
      },
      refetchQueries: [{
        query: formContactQuery,
        variables: { _id: formContact._id }
      }]
    }).then(({ data: { createQuote } }) => {
      this.props.history.push(`/quoting/quotes/${createQuote._id}`);
    })
      .catch(() => notify('error', 'Quote create failed!'));
  }

  renderListItem(label, value) {
    return (
      <ListGroupItem>
        <span style={{ fontWeight: 'bold' }}>{label}: </span>
        <span>{value}</span>
      </ListGroupItem>
    );
  }

  renderQuoteActions(_id) {
    return (
      <div className="actions-right">
        <Link to={`/quoting/quotes/${_id}`}>
          <Button
            color="primary"
            size="sm"
            icon
          >
            <Icon name={faEdit}/>
          </Button>
        </Link>
        &nbsp;
        <Mutation
          mutation={quoteDelete}
          variables={{ _id }}
          refetchQueries={() => ([{
            query: formContactQuery,
            variables: { _id }
          }])}
          onCompleted={() => notify('success', `Successfully deleted quote ID: ${_id}`)}
          onError={(e) => notify('error', e.message.split('GraphQL error: ')[1])}
        >
          {(mutate) => (
            <Button
              onClick={mutate}
              color="danger"
              size="sm"
              icon
            >
              <Icon name={faTimes}/>
            </Button>
          )}
        </Mutation>
        &nbsp;
      </div>
    );
  }

  renderWorkOrderActions(_id) {
    return (
      <div className="actions-right">
        <Link to={`/quoting/work-orders/${_id}`}>
          <Button
            color="primary"
            size="sm"
            icon
          >
            <Icon name={faEdit}/>
          </Button>
        </Link>
        &nbsp;
        <Mutation
          mutation={workOrderDelete}
          variables={{ _id }}
          refetchQueries={() => ([{
            query: formContactQuery,
            variables: { _id }
          }])}
          onCompleted={() => notify('success', `Successfully deleted work order ID: ${_id}`)}
          onError={(e) => notify('error', e.message.split('GraphQL error: ')[1])}
        >
          {(mutate) => (
            <Button
              onClick={mutate}
              color="danger"
              size="sm"
              icon
            >
              <Icon name={faTimes}/>
            </Button>
          )}
        </Mutation>
        &nbsp;
      </div>
    );
  }

  renderCustomRequestActions(_id) {
    return (
      <div className="actions-right">
        <Link to={`/quoting/custom-design-requests/${_id}`}>
          <Button
            color="primary"
            size="sm"
            icon
          >
            <Icon name={faEye}/>
          </Button>
        </Link>
        &nbsp;
        <Mutation
          mutation={customDesignRequestDelete}
          variables={{ _id }}
          refetchQueries={() => ([{
            query: formContactQuery,
            variables: { _id }
          }])}
          onCompleted={() => notify('success', `Successfully deleted custom design request ID: ${_id}`)}
          onError={(e) => notify('error', e.message.split('GraphQL error: ')[1])}
        >
          {(mutate) => (
            <Button
              onClick={mutate}
              color="danger"
              size="sm"
              icon
            >
              <Icon name={faTimes}/>
            </Button>
          )}
        </Mutation>
        &nbsp;
      </div>
    );
  }

  quoteColumns() {
    return [
      {
        Header: 'Quote Number',
        accessor: '_id',
        maxWidth: 240,
        sortable: false
      },
      {
        Header: 'Total Price',
        accessor: 'total',
        Cell: ({ value }) => formatPrice(value),
        maxWidth: 100,
        filterable: false
      },
      {
        Header: 'Locked?',
        accessor: 'locked',
        Cell: ({ value }) => value ? 'Yes' : 'No',
        maxWidth: 100,
        filterable: false
      },
      {
        Header: 'Email Sent',
        accessor: 'emailedAt',
        Cell: ({ value }) => !!value && <DateFormat date={value}/>,
        maxWidth: 150,
        filterable: false
      },
      {
        Header: 'Work Order?',
        accessor: 'workOrder',
        Cell: ({ value }) => value && value._id ? <Link to={`/quoting/work-orders/${value._id}`}>Yes</Link> : 'No',
        maxWidth: 120,
        filterable: false
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        sortMethod: (a, b) => a > b ? 1 : -1,
        Cell: ({ value }) => <DateFormat date={value}/>,
        maxWidth: 150
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
        sortable: false,
        filterable: false
      }
    ];
  }

  workOrderColumns() {
    return [
      {
        Header: 'Work Order #',
        accessor: '_id',
        maxWidth: 240,
        sortable: false
      },
      {
        Header: 'Client',
        accessor: 'user',
        Cell: ({ value }) => value ? `${value.firstName} ${value.lastName} (${value.email})` : '',
        maxWidth: 360,
        sortable: false,
        filterable: false
      },
      {
        Header: 'Total Price',
        accessor: 'total',
        sortMethod: (a, b) => a > b ? 1 : -1,
        Cell: ({ value }) => formatPrice(value),
        maxWidth: 100,
        filterable: false
      },
      {
        Header: 'Locked?',
        accessor: 'locked',
        Cell: ({ value }) => value ? 'Yes' : 'No',
        maxWidth: 100,
        filterable: false
      },
      {
        Header: 'Quote',
        accessor: 'quoteId',
        Cell: ({ value }) => !!value && <Link to={`/quoting/quotes/${value}`}>View</Link>,
        maxWidth: 120,
        filterable: false
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
        sortable: false,
        filterable: false
      }
    ];
  }

  customDesignRequestColumns() {
    return [
      {
        Header: 'Designs',
        accessor: 'designs',
        maxWidth: 140,
        filterable: false
      },
      {
        Header: 'Product Type',
        accessor: 'productType',
        maxWidth: 160
      },
      {
        Header: 'Has quote',
        accessor: 'quote',
        Cell: ({ value }) => value && value._id ? <Link to={`/quoting/quotes/${value._id}`}>Yes</Link> : 'No',
        maxWidth: 120,
        filterable: false
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        sortMethod: (a, b) => a > b ? 1 : -1,
        Cell: ({ value }) => <DateFormat date={value}/>,
        maxWidth: 150
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
        sortable: false,
        filterable: false
      }
    ];
  }

  render() {
    const { formContact } = this.props;

    if (!formContact) {
      return <div>No form contact found!</div>;
    }

    const { quotes, workOrders, customDesignRequests } = formContact;

    return (
      <div>
        <NotificationAlert ref="notificationAlert" />
        <PanelHeader size="sm"/>
        <div className="content">
          <Row>
            <Col md={6}>
              <Card>
                <CardHeader>
                  <CardTitle>Contact Info</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <ListGroup>
                        {this.renderListItem('First Name', formContact.firstName)}
                        {this.renderListItem('Last Name', formContact.lastName)}
                        {this.renderListItem('Email', formContact.email)}
                        {this.renderListItem('Phone', formContact.phone)}
                        {this.renderListItem('Created', formContact.createdAt)}
                      </ListGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardHeader>
                  <CardTitle>Customer Notes</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <ListGroup>
                        {this.renderListItem('Notes', formContact.notes)}
                      </ListGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Col md={{ size: 6, offset: 6 }}>
                <Card>
                  <CardBody>
                    <RightAligned>
                      <Button
                        color="primary"
                        onClick={this.generateQuote}
                        style={{ cursor: 'pointer' }}
                      >
                        Generate Quote
                      </Button>
                    </RightAligned>
                  </CardBody>
                </Card>
              </Col>
            </Col>
          </Row>

          {/* tabbed table on bottom */}
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <Nav pills>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer', borderRadius: '3px' }}
                        className={this.state.currentTab === 'tab1' ? 'active' : ''}
                        onClick={() => this.setState({ currentTab: 'tab1' })}
                      >
                        Quotes <Badge color="default">{quotes.length}</Badge>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer', borderRadius: '3px' }}
                        className={this.state.currentTab === 'tab2' ? 'active' : ''}
                        onClick={() => this.setState({ currentTab: 'tab2' })}
                      >
                        Work Orders <Badge color="default">{workOrders.length}</Badge>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer', borderRadius: '3px' }}
                        className={this.state.currentTab === 'tab3' ? 'active' : ''}
                        onClick={() => this.setState({ currentTab: 'tab3' })}
                      >
                        Custom Design Requests <Badge color="default">{customDesignRequests.length}</Badge>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <hr/>
                  <TabContent activeTab={this.state.currentTab}>
                    <TabPane tabId="tab1">
                      <ReactTable
                        data={quotes.map((item) => ({ ...item, actions: this.renderQuoteActions(item._id) }))}
                        filterable
                        columns={this.quoteColumns()}
                        defaultPageSize={5}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </TabPane>
                    <TabPane tabId="tab2">
                      <ReactTable
                        data={workOrders.map((item) => ({ ...item, actions: this.renderWorkOrderActions(item._id) }))}
                        filterable
                        columns={this.workOrderColumns()}
                        defaultPageSize={5}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </TabPane>
                    <TabPane tabId="tab3">
                      <ReactTable
                        data={customDesignRequests.map((item) => ({ ...item, actions: this.renderCustomRequestActions(item._id) }))}
                        filterable
                        columns={this.customDesignRequestColumns()}
                        defaultPageSize={5}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(formContactUpdate, {
    props: ({ mutate }) => ({
      updateFormContact: (args) => mutate({
        variables: args,
        refetchQueries: [{
          query: formContactQuery,
          variables: { _id: args._id }
        }]
      })
    })
  }),
  withFormContact,
  withQuoteCreate
)(FormContact);
