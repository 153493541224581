import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-v6';
import styled from 'styled-components';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuid } from 'uuid';

import { PanelHeader, Button, DateFormat, Icon, Loading, DeleteProductButton } from 'components';
import withCreateProduct from 'containers/withProductCreate';
import jewelriesQuery from 'graphql/jewelriesQuery';
import { formatPrice, notify } from 'lib/utils';

const ActionBar = styled.section`
  display: flex;
  justify-content: flex-end;
`;

class Rings extends Component {
  static propTypes = {
    createProduct: PropTypes.func.isRequired,
    history: PropTypes.object
  };

  handleCreateProduct = (e, { refetch }) => {
    e.preventDefault();

    const skuAndSlug = uuid().replace(/-/g, '');

    this.props
      .createProduct({
        name: 'New Ring',
        type: 'Ring',
        cost: 0,
        price: 0,
        enabled: false,
        sku: skuAndSlug,
        slug: skuAndSlug,
        meta: {
          create: {
            title: 'New Ring',
            description: '',
            keywords: ''
          }
        },
        productType: {
          connect: {
            label: 'Ring'
          }
        }
      })
      .then(
        async ({
          data: {
            createProduct: { id }
          }
        }) => {
          await refetch();
          this.props.history.push(`/products/rings/${id}`);
        }
      )
      .catch((error) => notify('error', error.toString()));
  };

  renderActions = (product, { refetch }) => (
    <div className="actions-right">
      <Link to={`/products/rings/${product.id}`}>
        <Button color="primary" size="sm" icon>
          <Icon name={faEdit} />
        </Button>
      </Link>
      &nbsp;
      <DeleteProductButton product={product} refetch={refetch} />
    </div>
  );

  render() {
    const columns = [
      {
        Header: 'SKU',
        accessor: 'sku',
        maxWidth: 140
      },
      {
        Header: 'Product Name',
        accessor: 'name',
        maxWidth: 1000
      },
      {
        Header: 'Model #',
        accessor: 'modelNumber',
        maxWidth: 110
      },
      {
        Header: 'Enabled',
        accessor: 'enabled',
        maxWidth: 110,
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        filterable: false
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        sortMethod: (a, b) => (a > b ? 1 : -1),
        Cell: ({ value }) => <DateFormat date={value} />,
        maxWidth: 150
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ value }) => formatPrice(value),
        sortMethod: (a, b) => (a > b ? 1 : -1),
        maxWidth: 100
      },
      {
        Header: 'Qty',
        accessor: 'quantity',
        maxWidth: 50,
        sortable: false,
        filterable: false
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 90,
        sortable: false,
        filterable: false
      }
    ];

    return (
      <div>
        <PanelHeader size="sm" />
        <Query
          query={jewelriesQuery}
          variables={{
            where: { type: { equals: 'Ring' }, parentId: { equals: null } },
            orderBy: [{ createdAt: 'desc' }]
          }}
        >
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) {
              return <p>Error :(</p>;
            }

            const tableData = data.products.map((item) => ({
              ...item,
              actions: this.renderActions(item, { refetch })
            }));

            return (
              <div className="content">
                <Row>
                  <Col xs={12} md={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle>
                          <h5>Rings</h5>
                        </CardTitle>
                        {/* create product button */}
                        <ActionBar>
                          <Button color="primary" onClick={(e) => this.handleCreateProduct(e, { refetch })}>
                            <span>
                              <Icon name={faPlus} /> Add Ring
                            </span>
                          </Button>
                        </ActionBar>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          data={tableData}
                          filterable
                          columns={columns}
                          defaultPageSize={20}
                          showPaginationBottom
                          className="-striped -highlight"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withCreateProduct(Rings);
