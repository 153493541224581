import gql from 'graphql-tag';

export default gql`
  mutation createDiamond ($data: DiamondCreateInput!) {
    createDiamond (data: $data) {
      _id: id
      id
      createdAt
    }
  }
`;
