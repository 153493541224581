import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select, { ActionTypes } from 'react-select';
import { Card, CardBody, Button, Row, Col, Form, FormGroup, Label, Table } from 'reactstrap';
import styled from 'styled-components';
import cuid from 'cuid';
import combinate from 'combinate';
import _ from 'lodash';
import { faEdit, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useQueryParams, StringParam } from 'use-query-params';
import { Loading } from 'components';
import { formatPrice, notify } from 'lib/utils';
import { useJewelryQuery, useUpdateJewelryMutation, useRemoveImagesMutation } from 'graphql/codegen';
import VariantItemEdit from './VariantItemEdit';

interface FormData {
  [key: string]: SelectValue[];
}

interface SelectValue {
  label: string;
  value: string;
}

interface SelectOptionEvent {
  action: ActionTypes;
  optionId: string;
}

interface Option {
  id: string;
  name: string;
  slug: string;
  optionSet: {
    id: string;
    name: string;
    slug: string;
  };
}

const OptionsList = styled.div`
  padding-top: 2rem;
  padding-bottom: 1rem;

  .btn {
    margin-top: 1.2rem;
  }
`;

const Variants = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const VariantsTable = styled.div`
  background: #ededed;
  border: 1px solid #ccc;
  padding: 1rem;

  thead > tr > th {
    font-size: 1.1rem;
  }
`;

const ProductVariant: React.FC = () => {
  // get URL params
  const { _id } = useParams<{ _id: string }>();

  // define query param filter schema
  // https://github.com/pbeshai/use-query-params
  const [query, setQueryParams] = useQueryParams({
    tab: StringParam,
    variant: StringParam
  });

  // graphql query
  const { loading, error, data, refetch } = useJewelryQuery({ variables: { where: { id: _id || '' } } });

  // update mutation
  const [updateProduct] = useUpdateJewelryMutation();
  const [deleteImages] = useRemoveImagesMutation();

  // form handlers
  const { handleSubmit, control, watch, formState } = useForm<FormData>();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  // handle option select input changes
  const vals = watch();
  const selectedOptionIds: string[] = [];

  Object.keys(vals)
    .filter((key) => !!vals[key])
    .map((key) => Object.values(vals[key]).forEach((v) => selectedOptionIds.push(v.value)));

  const jewelry = data?.jewelry;

  if (!jewelry) {
    return <div>Product not found!</div>;
  }

  // onSubmit handler
  const onSubmit = async (formData: FormData[]): Promise<any> => {
    const previousOptionIds = jewelry.options.map((o) => o.id).sort();
    const currentOptionIds = selectedOptionIds.sort();

    if (_.isEqual(previousOptionIds, currentOptionIds)) {
      return notify('success', "No variant options have changed. You're all set!");
    }

    const addedOptions: string[] = [];
    const removedOptions: string[] = [];

    // figure out what options have been added
    currentOptionIds.forEach((option) => {
      if (!previousOptionIds.includes(option)) {
        addedOptions.push(option);
      }
    });

    // figure out what options have been removed
    previousOptionIds.forEach((option) => {
      if (!currentOptionIds.includes(option)) {
        removedOptions.push(option);
      }
    });

    // handle variant removal based on the deselected options
    if (removedOptions.length) {
      const variantsToDelete: string[] = [];
      jewelry.variants.forEach((v) => {
        v.options.forEach((o) => {
          if (removedOptions.includes(o.id)) {
            variantsToDelete.push(v.id);
          }
        });
      });

      // clean up any uploaded images first
      variantsToDelete.forEach(async (id) => {
        const imagesToDelete: string[] = [];

        jewelry.variants
          .filter((variant) => variant.id === id)
          .forEach((variant) => variant.images.forEach((img) => imagesToDelete.push(img.id)));

        if (imagesToDelete.length) {
          await deleteImages({ variables: { input: imagesToDelete } });
        }
      });

      // remove the variant options and delete the associated variants
      await updateProduct({
        variables: {
          where: { id: _id },
          data: {
            options: {
              disconnect: removedOptions.map((v) => ({ id: v }))
            },
            variants: {
              deleteMany: [
                {
                  id: { in: variantsToDelete }
                }
              ]
            }
          }
        }
      });

      await refetch();

      // eslint-disable-next-line max-len
      notify(
        'success',
        `${removedOptions.length} option(s) removed and ${variantsToDelete.length} variant(s) deleted.`
      );
    }

    // handle variant creation based on the newly selected options
    if (addedOptions.length) {
      const currentOptions: Record<string, string[]> = {};

      Object.keys(formData).forEach((key) => {
        if (Array.isArray(formData[key]) && formData[key].length) {
          currentOptions[key] = formData[key].map((item: SelectValue) => item.value);
        }
      });

      // generate all possible variant combinations
      // https://github.com/nas5w/combinate
      const allOptionCombinations = combinate(currentOptions).map((opts) => Object.values(opts));

      // filter out variants that already exist in the database
      const newVariantsToCreate = allOptionCombinations.filter((combo) => {
        let hasMatch = false;
        addedOptions.forEach((o) => {
          if (combo.includes(o)) {
            hasMatch = true;
          }
        });
        return hasMatch;
      });

      await updateProduct({
        variables: {
          where: { id: _id },
          data: {
            options: {
              connect: addedOptions.map((v) => ({ id: v }))
            },
            variants: {
              create: newVariantsToCreate.map((opts) => {
                const skuAndSlug = `${jewelry.sku}_${cuid.slug()}`;
                return {
                  name: jewelry.name,
                  enabled: jewelry.enabled,
                  sku: skuAndSlug,
                  slug: skuAndSlug,
                  type: jewelry.type,
                  cost: jewelry.cost,
                  price: jewelry.price,
                  quantity: 0,
                  options: {
                    connect: opts.map((v) => ({ id: v }))
                  }
                };
              })
            }
          }
        }
      });

      await refetch();

      // eslint-disable-next-line max-len
      notify(
        'success',
        `${addedOptions.length} option(s) added and ${newVariantsToCreate.length} new variant(s) created.`
      );
    }

    return true;
  };

  if (query.variant) {
    return <VariantItemEdit />;
  }

  return (
    <Row>
      <Col xs={12}>
        <Card>
          <CardBody>
            {loading ? (
              <Loading />
            ) : (
              data?.optionSets && (
                <Row>
                  <Col xs={12}>
                    <h2>Variant Options</h2>
                    <hr />
                    <OptionsList>
                      <div>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          {data?.optionSets.map((optionSet) => (
                            <FormGroup key={optionSet.id}>
                              <Label>{optionSet.name}</Label>
                              <Controller
                                name={optionSet.slug}
                                as={Select}
                                control={control}
                                isMulti
                                options={optionSet.options.map((option) => ({ label: option.name, value: option.id }))}
                                defaultValue={jewelry.options
                                  .filter((o) => o.optionSet.id === optionSet.id)
                                  .map((o) => ({ label: o.name, value: o.id }))}
                              />
                            </FormGroup>
                          ))}
                        </Form>
                      </div>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={!formState.isDirty}
                        onClick={handleSubmit(onSubmit, (errors, e) => console.log(errors, e))}
                      >
                        Save and Generate Variants
                      </Button>
                    </OptionsList>
                    <hr />
                    {!!jewelry.variants?.length && (
                      <Variants>
                        <h5>Variants</h5>
                        <Row>
                          <Col>
                            <VariantsTable>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Default</th>
                                    <th>ID</th>
                                    <th>SKU</th>
                                    <th>Metal</th>
                                    <th>Shape</th>
                                    <th>Lab Grown</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>EDIT</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {jewelry.variants.map((v) => (
                                    <tr key={v.id}>
                                      <td>{v.isDefaultVariant && <Icon icon={faStar} />}</td>
                                      <td>{v.id}</td>
                                      <td>{v.sku}</td>
                                      <td>{v.options.find((o) => o.optionSet.name === 'Metal')?.name}</td>
                                      <td>{v.options.find((o) => o.optionSet.name === 'Diamond Shape')?.name}</td>
                                      <td>{v.isLabGrown ? 'Yes' : 'No'}</td>
                                      <td>{v.quantity}</td>
                                      <td>{formatPrice(v.price)}</td>
                                      <td>
                                        <Icon
                                          icon={faEdit}
                                          onClick={(): void => {
                                            setQueryParams({ tab: 'options', variant: v.sku });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </VariantsTable>
                          </Col>
                        </Row>
                      </Variants>
                    )}
                  </Col>
                </Row>
              )
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ProductVariant;
