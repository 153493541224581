import gql from 'graphql-tag';

export default gql`
  query workOrders {
    workOrders {
      _id
      number
      user {
        _id
        firstName
        lastName
        email
      }
      quote {
        _id
      }
      payments {
        _id
      }
      total
      isComplete
      completedAt
      createdAt
    }
  }
`;
