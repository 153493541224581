import gql from 'graphql-tag';

export default gql`
  query paymentTypes {
    paymentTypes {
      _id
      name
    }
  }
`;
