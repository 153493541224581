import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-v6';
import matchSorter from 'match-sorter';
import formatDate from 'date-fns/format';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PanelHeader, Button, DateFormat, Icon, Loading, UserIsInRole } from 'components';
import ordersQuery from 'graphql/ordersQuery';
import orderDelete from 'graphql/orderDelete';
import { formatPrice, Alert } from 'lib/utils';

class Orders extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  renderActions(_id) {
    return (
      <div className="actions-right">
        <Link to={`/orders/${_id}`}>
          <Button
            color="primary"
            size="sm"
            icon
          >
            <Icon name={faEdit}/>
          </Button>
        </Link>
        &nbsp;
        <UserIsInRole roles="admin">
          <Mutation
            mutation={orderDelete}
            variables={{ _id }}
            refetchQueries={() => ([{
              query: ordersQuery,
              variables: { pagination: { limit: 500 }, sort: { createdAt: -1 } }
            }])}
            onCompleted={() => Alert('Order deleted!', {
              icon: 'success',
              buttons: false,
              timer: 1500
            })}
            onError={(e) => Alert('Oh no!', {
              text: e.message.split('GraphQL error: ')[1],
              icon: 'error'
            })}
          >
            {(mutate) => (
              <Button
                onClick={() => {
                  Alert({
                    title: 'Are you sure?',
                    text: 'There\'s no going back!',
                    icon: 'warning',
                    dangerMode: true,
                    buttons: {
                      cancel: true,
                      confirm: 'Do it!'
                    }
                  }).then((confirmed) => confirmed && mutate());
                }}
                color="danger"
                size="sm"
                icon
              >
                <Icon name={faTimes}/>
              </Button>
            )}
          </Mutation>
          &nbsp;
        </UserIsInRole>
      </div>
    );
  }

  render() {
    const columns = [
      {
        Header: 'Status',
        accessor: 'status',
        maxWidth: 160,
        filterMethod: (filter, row) =>
          ~String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())
      },
      {
        Header: 'First Name',
        accessor: 'user.firstName',
        filterMethod: (filter, row) =>
          ~String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()),
        maxWidth: 100
      },
      {
        Header: 'Last Name',
        accessor: 'user.lastName',
        maxWidth: 100,
        filterMethod: (filter, row) =>
          ~String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())
      },
      {
        Header: 'Email',
        accessor: 'user.email',
        maxWidth: 240,
        filterMethod: (filter, row) =>
          ~String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())
      },
      {
        Header: 'Order Total',
        accessor: 'total',
        Cell: ({ value }) => formatPrice(value),
        sortMethod: (a, b) => a > b ? 1 : -1,
        maxWidth: 100
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        sortMethod: (a, b) => a > b ? 1 : -1,
        Cell: ({ value }) => <DateFormat date={value}/>,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: [(item) => formatDate(item.createdAt, 'MMMM d, yyyy')] }),
        filterAll: true,
        maxWidth: 150
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
        sortable: false,
        filterable: false
      }
    ];

    return (
      <div>
        <PanelHeader size="sm" />
        <Query
          query={ordersQuery}
          variables={{ pagination: { limit: 500 }, sort: { createdAt: -1 } }}
        >
          {({ loading, error, data }) => {

            if (loading) {
              return <Loading/>;
            }

            if (error) {
              return <p>Error :(</p>;
            }

            const tableData = data.orders.map((item) => ({
              ...item,
              actions: this.renderActions(item._id)
            }));

            return (
              <div className="content">
                <Row>
                  <Col xs={12} md={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Orders</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          data={tableData}
                          filterable
                          columns={columns}
                          defaultPageSize={20}
                          showPaginationBottom
                          className="-striped -highlight"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Orders;
