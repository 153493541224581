import React from 'react';
import { Query } from '@apollo/react-components';
import { Loading } from 'components';
import { startOfTomorrow } from 'date-fns';
import ordersExport from 'graphql/ordersExport';

// default query date range
// TODO: tie this to the datepicker
const filter = {
  // Jan 1, 2018
  startDate: new Date('2018').toISOString(),
  // any time today before midnight
  endDate: startOfTomorrow().toISOString()
};

export default (Component) =>
  function withOrdersExport(props) {
    return (
      <Query query={ordersExport} variables={filter}>
        {({ error, loading, data }) => {
          if (loading) {
            return <Loading />;
          }

          if (error) {
            return (
              <div>
                <p>Error :(</p>
              </div>
            );
          }

          // console.log(data);

          return <Component {...props} invoices={data.invoiceSearch} />;
        }}
      </Query>
    );
  };
