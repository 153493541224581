import gql from 'graphql-tag';

export default gql`
  query user($_id: ObjID!) {
    user(_id: $_id) {
      _id
      email
      firstName
      lastName
      roles
      billingAddress {
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        country
        phone
      }
      shippingAddress {
        firstName
        lastName
        address1
        address2
        city
        state
        zip
        country
        phone
      }
      billingIsSame
      customDesignRequests {
        _id
        designs
        productType
        quote {
          _id
        }
        createdAt
      }
      quotes {
        _id
        number
        total
        locked
        emailedAt
        workOrder {
          _id
        }
        customDesignRequestId
        createdAt
      }
      workOrders {
        _id
        number
        status
        user {
          _id
          firstName
          lastName
          email
        }
        total
        locked
        quoteId
      }
      orders {
        _id
        status
        total
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
