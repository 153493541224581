import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { Print } from 'react-easy-print';
import { DateFormat } from 'components';

const Wrapper = styled.div`
  @media screen {
    display: none;
  }

  @media print {
    display: block;
  }

  padding: 1rem;
`;

const ColumnWrapper = styled.div`
  .row {
    border: 1px solid #4a4a4a;
    padding: 1rem .2rem;
  }

  .job-title {
    font-size: 13px;
    padding-left: .3rem;
  }

  .job-description {
    font-size: 11px;

    p {
      margin-bottom: 0;
      padding-left: .3rem;
    }
  }
`;

const Column = ({ job }) => (
  <ColumnWrapper>
    <Row>
      <Col sm={12}>
        <div><strong>Type:</strong> {job.type}</div>
        <div><strong>Job ID:</strong> {job._id}</div><br/>
        <div><strong>Date Created:</strong> <DateFormat date={job.createdAt} format="MMMM d, yyyy"/></div>
        <div><strong>Date Promised:</strong> <DateFormat date={job.promisedAt} format="MMMM d, yyyy"/></div>
      </Col>
    </Row>
    <Row>
      <Col sm={12}>
        <div><strong>Client Info:</strong></div>
        <div>{job.user.firstName.substring(0, 1)}. {job.user.lastName}</div>
      </Col>
    </Row>
    <Row>
      <Col sm={12}>
        <div><strong>Item:</strong></div>
        <div className="job-title">{job.title}</div>
        <br/>
        <div><strong>Description:</strong></div>
        <div className="job-description" dangerouslySetInnerHTML={{ __html: job.description.replace(/\\r|\\n|<br>/g, '') }} />
      </Col>
    </Row>
    <Row>
      <Col sm={12} className="text-center">
        <address>
          <strong>Canera, Inc.</strong><br/>
          631 South Olive St. #950<br/>
          Los Angeles, CA 90014<br/>
          (213)623-8313
        </address>
      </Col>
    </Row>
  </ColumnWrapper>
);

Column.propTypes = {
  job: PropTypes.object.isRequired
};

const JobEnvelopePrint = ({ job }) => (
  <Print>
    <Wrapper>
      <Row>
        <Col md={4}>
          <Column job={job} />
        </Col>
        <Col md={4}>
          <Column job={job} />
        </Col>
        <Col md={4}>
          <Column job={job} />
        </Col>
      </Row>
    </Wrapper>
  </Print>
);

JobEnvelopePrint.propTypes = {
  job: PropTypes.object.isRequired
};

export default JobEnvelopePrint;
