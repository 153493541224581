import React from 'react';
import PropTypes from 'prop-types';

const PanelHeader = ({ content, size }) => (
  <div
    className={'panel-header ' + (size !== undefined ? 'panel-header-' + size : '')}
  >
    {content}
  </div>
);

PanelHeader.propTypes = {
  content: PropTypes.any,
  size: PropTypes.string
};

export default PanelHeader;
