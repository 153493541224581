import Dinero from 'dinero.js';
import _ from 'lodash';
import { toast } from 'react-toastify';


export function userIsInRole(user, roles) {
  if (!user || !roles) {
    return false;
  }

  // if provided an array of roles
  if (Array.isArray(roles)) {
    // return true if any role is a match
    // https://lodash.com/docs/4.17.11#some
    return _.some(roles, (r) => user.roles.includes(r));
  }

  // if provided a single role
  if (typeof roles === 'string') {
    return user.roles.includes(roles);
  }

  // otherwise default to false
  return false;
}


// basic currency math with two integer values
// https://sarahdayan.github.io/dinero.js/
export const add = (a, b) => Dinero({ amount: a }).add(Dinero({ amount: b })).getAmount();
export const subtract = (a, b) => Dinero({ amount: a }).subtract(Dinero({ amount: b })).getAmount();
export const multiply = (a, b) => Dinero({ amount: a }).multiply(b).getAmount();
export const divide = (a, b) => Dinero({ amount: a }).divide(b).getAmount();

// better rounding of floats when doing currency math
// https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places
export function roundToDecimalPlace(n, digits = 0) {
  let num = n;
  let negative = false;

  if (num < 0) {
    negative = true;
    num *= -1;
  }

  const multiplicator = Math.pow(10, digits);

  num = parseFloat((num * multiplicator).toFixed(11));
  num = (Math.round(num) / multiplicator).toFixed(2);

  if (negative) {
    num = (num * -1).toFixed(2);
  }

  return num;
}

// calculate order totals
export function calculateOrderTotals(order) {
  const hasTax = order.hasTax || (!!order.shippingAddress && order.shippingAddress.state === 'CA');
  const taxRate = 0.095;
  const ccRate = 3;

  // if we don't have any items in the order,
  // we can assume all totals are zero and bail
  if (!order.items || !order.items.length) {
    return {
      hasTax,
      taxRate,
      shipping: order.shipping || 0,
      subtotal: 0,
      tax: 0,
      total: 0
    };
  }

  // use Dinero.js to calculate all of the totals
  // https://sarahdayan.github.io/dinero.js/
  const shipping = Dinero({ amount: order.shipping || 0 });
  const subtotal = Dinero({ amount: order.items.map((item) => multiply(item.unitPrice || item.price || 0, item.quantity || 1)).reduce(add, 0) });
  const tax = hasTax ? subtotal.add(shipping).percentage(9.5) : Dinero({ amount: 0 }); // TODO: make configurable via env
  const ccFee = order.hasCCFee ? subtotal.add(shipping).add(tax).percentage(ccRate) : Dinero({ amount: 0 });
  const total = subtotal.add(shipping).add(tax).add(ccFee);

  let totalPayments = 0;
  let balance = total.getAmount();

  // calculate payments if any were provided
  if (Array.isArray(order.payments) && !!order.payments.length) {
    // add all payment amounts together
    totalPayments = order.payments.map((p) => p.amount).reduce(add, 0);
    // subtract payment totals from order total
    balance = subtract(total.getAmount(), totalPayments);
  }

  return {
    hasTax,
    shipping: shipping.getAmount(),
    subtotal: subtotal.getAmount(),
    tax: tax.getAmount(),
    total: total.getAmount(),
    totalPayments,
    balance
  };
}


// Currency string formatting for display
// https://sarahdayan.github.io/dinero.js/
export function formatPrice(cents = 0, { trimDecimals = true, addCCFee = false, format = '$0,0.00' } = {}) {
  // if (isNaN(cents)) {
  //   throw new TypeError('formatPrice() must be passed an integer value representing "cents"');
  // }

  let price = Dinero({ amount: !Number.isInteger(cents) ? 0 : cents, currency: 'USD' });

  if (addCCFee) {
    // add 3% credit card fee
    price = price.add(price.percentage(3));
  }

  // optionally drop the decimal and cents if it's .00
  if (trimDecimals && !price.hasCents()) {
    return price.toFormat('$0,0');
  }

  // format as $12,345.67 by default
  // https://sarahdayan.github.io/dinero.js/module-Dinero.html#~toFormat
  return price.toFormat(format);
}


export function notify(type, message) {
  toast[type](message, {
    position: 'top-right',
    autoClose: type === 'error' ? 7000 : 3000,
    hideProgressBar: true
  });
}


// Array of objects deep comparison with lodash
// https://stackoverflow.com/questions/37065663/array-of-object-deep-comparison-with-lodash
export function isArrayEqual(x, y) {
  return _(x).xorWith(y, _.isEqual).isEmpty();
}


export function convertArrayOfObjectsToCSV(data) {
  if (!data || !Array.isArray(data) || !data.length) {
    return null;
  }

  const columnDelimiter = ',';
  const lineDelimiter = '\n';

  const keys = Object.keys(data[0]);

  let result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach((item) => {
    let i = 0;

    keys.forEach((key) => {
      if (i > 0) {
        result += columnDelimiter;
      }

      result += '"';
      if (item[key] && item[key].replace) {
        result += item[key].replace(/"/g, '""');
      } else {
        result += item[key];
      }
      result += '"';

      i++;
    });

    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(csv, filename) {
  if (csv === null) {
    return;
  }

  const name = filename || `${new Date().toISOString()}.csv`;
  let contents = csv;

  if (!csv.match(/^data:text\/csv/i)) {
    contents = `data:text/csv;charset=utf-8,${csv}`;
  }

  const data = encodeURI(contents);

  const link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', name);
  link.click();
}


export { default as xlsxExport } from './xlsxExport';
export { default as Alert } from '@sweetalert/with-react';
