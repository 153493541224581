import gql from 'graphql-tag';

export default gql`
  mutation removeUser($_id: ObjID!) {
    removeUser(_id: $_id) {
      n
      ok
    }
  }
`;
