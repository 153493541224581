import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';
import { Loading } from 'components';
import customDesignRequest from 'graphql/customDesignRequest';

export default (Component) => {
  class withCustomDesignRequest extends React.Component {
    static propTypes = {
      match: PropTypes.object.isRequired
    }

    render() {
      const parentProps = this.props;
      return (
        <Query query={customDesignRequest} variables={{ _id: this.props.match.params._id }}>
          {({ error, loading, data }) => {
            if (loading) {
              return <Loading/>;
            }

            if (error) {
              return (
                <div>
                  <p>Error :(</p>
                </div>
              );
            }

            return (
              <Component
                {...parentProps}
                customDesignRequest={data.customDesignRequest}
              />
            );
          }}
        </Query>
      );
    }
  }

  return withCustomDesignRequest;
};
