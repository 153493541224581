import { graphql } from '@apollo/react-hoc';
import gql from 'graphql-tag';

const sendWorkOrderShipmentEmail = gql`
  mutation sendWorkOrderShipmentEmail($input: WorkOrderEmailInput!) {
    sendWorkOrderShipmentEmail(input: $input) {
      result
    }
  }
`;

export default (Component) => graphql(sendWorkOrderShipmentEmail, {
  props: ({ mutate }) => ({
    sendWorkOrderShipmentEmail: (input) => mutate({ variables: { input } })
  })
})(Component);
