import gql from 'graphql-tag';

export default gql`
  mutation removeOrder($_id: ObjID!) {
    removeOrder(_id: $_id) {
      n
      ok
    }
  }
`;
