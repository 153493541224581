import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import styled from 'styled-components';

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem 1rem;
  font-size: 0.8rem;
`;

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={'footer' + (this.props.default ? ' footer-default' : '')}
      >
        <Container fluid={!!this.props.fluid}>
          <FooterContent>
            <div className="version">
              Version {require('../../../package.json').version}
            </div>
            <div className="copyright">
              &copy; {1900 + new Date().getYear()}, Victor Canera{' '}
            </div>
          </FooterContent>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
