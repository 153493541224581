import React from 'react';
import {
  Form,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import format from 'date-fns/format';
import NotificationAlert from 'react-notification-alert';
import { PanelHeader, FormInputsLegacy, Button } from 'components';
import withCustomDesignRequest from 'containers/withCustomDesignRequest';
import withQuoteCreate from 'containers/withQuoteCreate';
import customDesignRequestQuery from 'graphql/customDesignRequest';
import customDesignRequestUpdate from 'graphql/customDesignRequestUpdate';
import { notify, formatPrice } from 'lib/utils';

class CustomDesignRequest extends React.Component {
  static propTypes = {
    createQuote: PropTypes.func.isRequired,
    customDesignRequest: PropTypes.object,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    updateCustomDesignRequest: PropTypes.func.isRequired,
    user: PropTypes.object
  };

  state = {
    statusChanged: false
  };

  handleStatusUpdate = () => {
    this.props
      .updateCustomDesignRequest({ _id: this.props.match.params._id, input: { status: this.state.status } })
      .then(() => {
        notify('success', 'Status updated!');
        this.setState({ statusChanged: false });
      })
      .catch(() => notify('error', 'Save failed!'));
  };

  generateQuote = () => {
    const { _id, userId } = this.props.customDesignRequest;

    this.props
      .createQuote({
        variables: {
          input: {
            userId,
            customDesignRequestId: _id,
            items: []
          }
        },
        refetchQueries: [
          {
            query: customDesignRequestQuery,
            variables: { _id }
          }
        ]
      })
      .then(({ data: { createQuote } }) => {
        this.props.history.push(`/quoting/quotes/${createQuote._id}`);
      })
      .catch(() => notify('error', 'Quote create failed!'));
  };

  renderListItem(label, value) {
    return (
      <ListGroupItem>
        <span style={{ fontWeight: 'bold' }}>{label}: </span>
        <span>{value}</span>
      </ListGroupItem>
    );
  }

  render() {
    const { customDesignRequest } = this.props;

    if (!customDesignRequest) {
      return <div>No customDesignRequest found!</div>;
    }

    const { user } = customDesignRequest;

    return (
      <div>
        <NotificationAlert ref="notificationAlert" />
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={6}>
              <Col md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle>Customer Info</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <ListGroup>
                          {this.renderListItem('First Name', user.firstName)}
                          {this.renderListItem('Last Name', user.lastName)}
                          {this.renderListItem('Email', user.email)}
                          {this.renderListItem('Phone', user.phone)}
                        </ListGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle>Status</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <span style={{ fontWeight: 'bold' }}>Status: </span>
                        <br />
                        <br />
                        <Form>
                          <FormInputsLegacy
                            inputs={[
                              {
                                colProps: {
                                  xs: 12
                                },
                                inputProps: {
                                  type: 'select',
                                  name: 'status',
                                  options: [
                                    {
                                      label: 'Awaiting Response',
                                      value: 'Awaiting Response'
                                    },
                                    {
                                      label: 'Quote Generated',
                                      value: 'Quote Generated'
                                    },
                                    {
                                      label: 'Responded By Email',
                                      value: 'Responded By Email'
                                    },
                                    {
                                      label: 'Rejected Without Email',
                                      value: 'Rejected Without Email'
                                    }
                                  ],
                                  defaultValue: {
                                    label: customDesignRequest.status,
                                    value: customDesignRequest.status
                                  },
                                  onChange: (value) => this.setState({ status: value.value, statusChanged: true })
                                }
                              }
                            ]}
                          />
                        </Form>
                        {this.state.statusChanged && (
                          <CardFooter>
                            <Button color="primary" type="submit" onClick={this.handleStatusUpdate}>
                              Update Status
                            </Button>
                          </CardFooter>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Col>
            <Col md={6} xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle>Custom Design Request Info</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <ListGroup>
                        {this.renderListItem('Design', customDesignRequest.skuOrTitle)}
                        {/* {this.renderListItem('Metal', customDesignRequest.metal)} */}
                        {this.renderListItem('Budget From', formatPrice(customDesignRequest.budgetFrom || 0))}
                        {this.renderListItem('Budget To', formatPrice(customDesignRequest.budgetTo || 0))}
                        {/* {this.renderListItem('Timeframe', customDesignRequest.timeframe)} */}
                        {this.renderListItem('Carat from', customDesignRequest.caratFrom)}
                        {this.renderListItem('Carat to', customDesignRequest.caratTo)}
                        {this.renderListItem('Clarity from', customDesignRequest.clarityFrom)}
                        {this.renderListItem('Clarity to', customDesignRequest.clarityTo)}
                        {this.renderListItem('Color from', customDesignRequest.colorFrom)}
                        {this.renderListItem('Color to', customDesignRequest.colorTo)}
                        {/* {this.renderListItem('Finger size', customDesignRequest.fingerSize)} */}
                        {this.renderListItem(
                          'Is center stone required',
                          customDesignRequest.isCenterstoneRequired ? 'Yes' : 'No'
                        )}
                        {this.renderListItem('Shape', customDesignRequest.cut)}
                        {this.renderListItem('Comments', customDesignRequest.comments)}
                        {this.renderListItem(
                          'Submitted',
                          format(new Date(customDesignRequest.createdAt), 'MMMM d, yyyy - h:mma')
                        )}
                        {this.renderListItem('IP address of submitter', customDesignRequest.remoteAddress)}
                      </ListGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  {!customDesignRequest.quote && (
                    <Button color="primary" onClick={this.generateQuote}>
                      Generate Quote
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(customDesignRequestUpdate, {
    props: ({ mutate }) => ({
      updateCustomDesignRequest: (args) =>
        mutate({
          variables: args,
          refetchQueries: [
            {
              query: customDesignRequestQuery,
              variables: { _id: args._id }
            }
          ]
        })
    })
  }),
  withCustomDesignRequest,
  withQuoteCreate
)(CustomDesignRequest);
