import React from 'react';
import { ApolloQueryResult } from 'apollo-client';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import _ from 'lodash';
import { Product, useUpdateProductMutation, useCollectionsQuery } from 'graphql/codegen';
import { notify } from 'lib/utils';

interface Props {
  product: Product;
  refetch: () => Promise<ApolloQueryResult<any>>;
}

interface FormData {
  collections?: {
    label: string;
    value: string;
  }[];
}

const CollectionsEditInput: React.FC<Props> = ({ product, refetch }) => {
  const { control, formState, handleSubmit, reset } = useForm<FormData>();

  const {
    error,
    data
  } = useCollectionsQuery();

  const [updateProduct, { error: updateProductError }] = useUpdateProductMutation();

  if (error) {
    return <div>{error.toString()}</div>;
  }

  const onSubmit = async (formData: FormData): Promise<any> => {
    const previousOptionIds = product.collections.map((c) => c.id).sort();
    const selectedOptionIds = Array.isArray(formData.collections) ? formData.collections.map((c) => c.value).sort() : [];

    if (_.isEqual(previousOptionIds, selectedOptionIds)) {
      reset();
      return notify('success', 'No options have changed. You\'re all set!');
    }

    const addedOptions: string[] = [];
    const removedOptions: string[] = [];

    // figure out what options have been added
    selectedOptionIds.forEach((option) => {
      if (!previousOptionIds.includes(option)) {
        addedOptions.push(option);
      }
    });

    // figure out what options have been removed
    previousOptionIds.forEach((option) => {
      if (!selectedOptionIds.includes(option)) {
        removedOptions.push(option);
      }
    });

    // handle collection removal
    if (removedOptions.length) {
      await updateProduct({
        variables: {
          where: { id: product.id },
          data: {
            collections: {
              disconnect: removedOptions.map((v) => ({ id: v }))
            }
          }
        }
      });

      notify('success', `Removed product from ${removedOptions.length} collection(s).`);
    }

    // handle collection addition
    if (addedOptions.length) {
      await updateProduct({
        variables: {
          where: { id: product.id },
          data: {
            collections: {
              connect: addedOptions.map((v) => ({ id: v }))
            }
          }
        }
      });

      notify('success', `Added product to ${addedOptions.length} collection(s).`);
    }

    // refetch product query
    await refetch();

    // reset form state
    reset();

    return true;
  };

  if (updateProductError) {
    return <div>{updateProductError.toString()}</div>;
  }

  return (
    <Row>
      <Col xs={formState.isDirty ? 10 : 12}>
        <FormGroup>
          <Label>Collections</Label>
          <Controller
            as={Select}
            control={control}
            isMulti
            name="collections"
            options={data?.collections.map((c) => ({ label: c.name, value: c.id }))}
            placeholder="Add this product to some collections..."
            defaultValue={product.collections.map((c) => ({ label: c.name, value: c.id }))}
          />
        </FormGroup>
      </Col>
      {formState.isDirty
        && (
        <Col xs={2}>
          <Button color="primary" type="submit" onClick={handleSubmit(onSubmit, (errors, e) => console.log(errors, e))}>
            Save
          </Button>
        </Col>
        )}
    </Row>
  );
};

export default CollectionsEditInput;
