import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Icon, UserIsInRole } from 'components';
import { Alert } from 'lib/utils';
import { Product, useDeleteProductsMutation } from 'graphql/codegen';

interface Props {
  product: Product;
  refetch?: () => void;
}

const DeleteProductButton: React.FC<Props> = ({ product, refetch }) => {
  const [deleteProducts] = useDeleteProductsMutation();
  return (
    <UserIsInRole roles="admin">
      <Button
        onClick={async (): Promise<void> => {
          const confirmed = await Alert({
            title: 'Are you sure?',
            text: "There's no going back!",
            icon: 'warning',
            dangerMode: true,
            buttons: {
              cancel: true,
              confirm: 'Do it!'
            }
          });

          if (confirmed) {
            // get variant ID's if any exist
            const productIds = product?.variants?.length
              ? [product.id, ...product.variants.map((v) => v.id)]
              : [product.id];

            // delete product and any variants it may have
            await deleteProducts({ variables: { where: { id: { in: productIds } } } });

            // optional refetch of product catalog query
            if (refetch) {
              refetch();
            }
          }
        }}
        color="danger"
        size="sm"
        icon
      >
        <Icon name={faTrash} />
      </Button>
    </UserIsInRole>
  );
};

export default DeleteProductButton;
