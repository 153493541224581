import gql from 'graphql-tag';

export default gql`
  query diamond ($slugOrId: String!) {
    clarities {
      _id: id
      id
      label
      order
    }
    colors {
      _id: id
      id
      label
      order
    }
    culets {
      _id: id
      id
      label
      order
    }
    cuts {
      _id: id
      id
      label
      order
    }
    fluorescences {
      _id: id
      id
      label
      order
    }
    girdles {
      _id: id
      id
      label
      order
    }
    lightPerformances {
      _id: id
      id
      label
      order
    }
    polishes {
      _id: id
      id
      label
      order
    }
    diamondShapes {
      _id: id
      id
      label
      order
    }
    symmetries {
      _id: id
      id
      label
      order
    }

    diamond(slugOrId: $slugOrId) {
      _id: id
      id
      autopopulateDescription
      autopopulateMetaDescription
      autopopulateMetaTitle
      carat
      clarityId
      clarity {
        id
        label
        value
      }
      colorId
      color {
        id
        label
        value
      }
      cost
      createdAt
      crownAngle
      culetId
      culet {
        id
        label
        value
      }
      cutId
      cut {
        id
        label
        value
      }
      description
      depthMM
      depthPercent
      enabled
      fluorescenceId
      fluorescence {
        id
        label
        value
      }
      girdles {
        id
        label
        value
      }
      images {
        id
        category
        filename
        path
        storageUrl
        url
      }
      images360IFrameUrl
      lab {
        name
        certificate
        reportNumber
      }
      lengthMM
      lightPerformanceId
      lightPerformance {
        id
        label
        value
      }
      lowerGirdleFacetPercent
      meta {
        title
        description
        keywords
      }
      name
      isLabGrown
      notes
      onSale
      pavilionAngle
      polishId
      polish {
        id
        label
        value
      }
      price
      quantity
      salePrice
      shapeId
      shape {
        id
        label
        value
      }
      sku
      slug
      starLengthPercent
      symmetryId
      symmetry {
        id
        label
        value
      }
      tablePercent
      vendorId
      vendor {
        name
      }
      widthMM
      updatedAt
    }
  }
`;
