import gql from 'graphql-tag';

export default gql`
  query invoice($_id: ObjID!) {
    invoice(_id: $_id) {
      _id
      balance
      body
      emails {
        _id
        body
        from
        hasEmail
        html
        invoiceId
        message
        orderId
        paymentType
        shippingCompanyId
        subject
        template
        text
        to
        trackingNumber
        type
        userId
        workOrderId
        sentAt
        createdAt
        updatedAt
      }
      items {
        title
        unitPrice
        quantity
        price
      }
      note
      number
      subject
      subtotal
      tax
      terms
      to
      total
      totalPayments
      type
      userId
      user {
        firstName
        lastName
        email
        phone
      }
      createdByUser {
        firstName
        lastName
      }
      orderId
      order {
        _id
        tax
        shipping
        total
      }
      workOrderId
      workOrder {
        _id
        completedAt
        quoteId
        subtotal
        tax
        shipping
        total
      }
      sentAt
      createdAt
      updatedAt
    }
  }
`;
