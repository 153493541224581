import React from 'react';
import { Row, Col } from 'reactstrap';
import { BulkImageUpload, Button } from 'components';
import { useRemoveImagesMutation } from 'graphql/codegen';
import { notify } from 'lib/utils';

// interface Props {
//   jewelry: any;
//   refetch: () => void;
// }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Jewelry360Images = ({ jewelry, refetch }) => {
  const [removeImages] = useRemoveImagesMutation();
  return (
    <div>
      <BulkImageUpload productType="jewelry" productId={jewelry.id} category="360" refetch={refetch} />
      <hr />
      <Row>
        <Col md={12}>
          {!!jewelry.images.filter((img) => img.category === '360').length && (
            <div>
              <h4>Uploaded Images</h4>

              <Button
                color="danger"
                onClick={() => {
                  const imageIds = jewelry.images.filter((img) => img.category === '360').map((img) => img.id);

                  removeImages({ variables: { input: imageIds } })
                    .then(async (result) => {
                      refetch();
                      notify('success', `${result.data?.deleteImages?.length} images deleted!`);
                    })
                    .catch((error) => notify('error', `Upload failed! \n ${error}`));
                }}
              >
                Delete all images
              </Button>

              {jewelry.images
                .filter((img) => img.category === '360')
                .map((image) => (
                  <p key={image.id} style={{ marginBottom: '.5rem' }}>
                    <a key={image.id} href={image.url} target="_blank" rel="noopener noreferrer">
                      {image.filename}
                    </a>
                  </p>
                ))}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Jewelry360Images;
