import gql from 'graphql-tag';

export default gql`
  query vendor($where: VendorWhereUniqueInput!) {
    vendor(where: $where) {
      id
      name
      slug
      source
      enabled
      markups(orderBy: { from: asc }) {
        id
        from
        to
        markupPercent
      }
      diamondsCount
      leadTimeFrom
      leadTimeTo
      createdAt
      updatedAt
    }
  }
`;
