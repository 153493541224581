/* eslint-disable no-restricted-globals */
import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { useQueryParams, StringParam } from 'use-query-params';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { FormInputs, FlexSpaceBetween } from 'components';
import { notify } from 'lib/utils';
import { useJewelryQuery, useUpdateProductMutation, useUpdateProductsMutation } from 'graphql/codegen';
import JewelryImages from 'pages/Jewelry/JewelryImages';
import Jewelry360Images from 'pages/Jewelry/Jewelry360Images';

interface FormData {
  isLabGrown: boolean;
  sku: string;
  quantity: number;
  price: number;
}

const OptionsList = styled.div`
  padding-top: 0.7rem;
  padding-bottom: 2rem;
`;

const DefaultVariantControl = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;

  svg {
    margin-right: 0.5rem;
  }
`;

const VariantItemEdit: React.FC = () => {
  // form handlers
  const { handleSubmit, register, control } = useForm<FormData>();

  const [query, setQueryParams] = useQueryParams({
    tab: StringParam,
    variant: StringParam
  });

  // graphql query
  const { data, refetch } = useJewelryQuery({ variables: { where: { sku: query.variant || '' } } });
  const { refetch: refetchParent } = useJewelryQuery({ variables: { where: { id: data?.jewelry?.parentId || '' } } });

  // update mutation
  const [updateProduct, { error: updateProductError }] = useUpdateProductMutation();
  const [updateProducts] = useUpdateProductsMutation();

  const product = data?.jewelry;

  if (!product) {
    return (
      <div>
        <h5>Variant Edit - Product SKU Not Found!</h5>
        <Button onClick={(): void => setQueryParams({ tab: 'options', variant: undefined })}>Go Back</Button>
      </div>
    );
  }

  // onSubmit handler
  const onSubmit = async (formData: FormData): Promise<void> => {
    // run mutation
    await updateProduct({
      variables: {
        where: { id: product.id },
        data: {
          isLabGrown: { set: formData.isLabGrown },
          sku: { set: formData.sku },
          quantity: { set: isNaN(Number(formData.quantity)) ? 0 : Number(formData.quantity) },
          price: { set: Math.round(formData.price * 100) }
        }
      }
    });
    // make sure the SKU param gets updated if the SKU changed
    setQueryParams({ variant: formData.sku });
    // refetch query
    await refetch();

    notify('success', 'Variant updated!');
  };

  // set default variant and unset previous default (if exists)
  const setDefaultVariant = async (): Promise<void> => {
    // unset previous
    await updateProducts({
      variables: {
        where: { parentId: { equals: product.parentId } },
        data: {
          isDefaultVariant: { set: false }
        }
      }
    });

    // set this product as default
    await updateProduct({
      variables: {
        where: { id: product.id },
        data: {
          isDefaultVariant: { set: true }
        }
      }
    });

    // refetch query
    await refetchParent();
    await refetch();

    notify('success', 'Variant set as default!');
  };

  return (
    <Row>
      <Col xs={12}>
        <h2>Variant Edit</h2>
        <Row>
          <Col xs={12} md={4}>
            <DefaultVariantControl>
              {product.isDefaultVariant ? (
                <>
                  <Icon icon={faStar} /> Default Variant
                </>
              ) : (
                <Button color="primary" onClick={(): Promise<void> => setDefaultVariant()}>
                  <Icon icon={faStar} /> Set as Default{' '}
                </Button>
              )}
            </DefaultVariantControl>
            <OptionsList>
              <div>
                <strong>SKU</strong>: {product.sku}
              </div>
              {product.options.map((o) => (
                <div key={o.id}>
                  <strong>{o.optionSet.name}</strong>: {o.name}
                </div>
              ))}
            </OptionsList>

            <FormInputs
              inputs={[
                {
                  label: 'Lab Grown Diamond?',
                  colProps: { xs: 12, style: { marginBottom: '1.3rem' } },
                  inputProps: {
                    type: 'checkbox',
                    name: 'isLabGrown',
                    defaultChecked: product.isLabGrown,
                    innerRef: register()
                  }
                },
                {
                  label: 'SKU',
                  colProps: { xs: 12 },
                  inputProps: {
                    type: 'text',
                    name: 'sku',
                    innerRef: register({ required: true }),
                    defaultValue: product.sku
                  }
                },
                {
                  label: 'Quantity',
                  colProps: { xs: 12 },
                  inputProps: {
                    type: 'number',
                    name: 'quantity',
                    innerRef: register({ required: true }),
                    control,
                    defaultValue: product.quantity
                  }
                },
                {
                  label: 'Price',
                  colProps: { xs: 12 },
                  inputProps: {
                    type: 'currency',
                    name: 'price',
                    defaultValue: product.price / 100,
                    control
                  }
                }
              ]}
            />
            {updateProductError && <div>{updateProductError}</div>}
            <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />
            <Row>
              <Col xs={{ size: 12 }}>
                <FlexSpaceBetween>
                  <Button onClick={(): void => setQueryParams({ tab: 'options', variant: undefined })}>Back</Button>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={handleSubmit(onSubmit, (errors, e) => console.log(errors, e))}
                  >
                    Save
                  </Button>
                </FlexSpaceBetween>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={8}>
            <h3>Images</h3>
            <JewelryImages jewelry={product} refetch={refetch} />
            <h3>360 Images</h3>
            <Jewelry360Images jewelry={product} refetch={refetch} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default VariantItemEdit;
