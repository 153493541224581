import { graphql } from '@apollo/react-hoc';
import gql from 'graphql-tag';

const sendWorkOrderFollowupEmail = gql`
  mutation sendWorkOrderFollowupEmail($input: WorkOrderEmailInput!) {
    sendWorkOrderFollowupEmail(input: $input) {
      result
    }
  }
`;

export default (Component) => graphql(sendWorkOrderFollowupEmail, {
  props: ({ mutate }) => ({
    sendWorkOrderFollowupEmail: (input) => mutate({ variables: { input } })
  })
})(Component);
