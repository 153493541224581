/* eslint-disable no-restricted-globals */
// @ts-nocheck
import React from 'react';
import {
  Form,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane
} from 'reactstrap';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import _ from 'lodash';
import NotificationAlert from 'react-notification-alert';
import { PanelHeader, FormInputsLegacy, Button, Loading, UserIsInRole } from 'components';
import ProductVariants from 'components/Variants/VariantSettings';
import CollectionsEditInput from 'components/Products/CollectionsEditInput';
import LeadTimeRange from 'components/Vendors/LeadTimeRange';
import withJewelry from 'containers/withJewelry';
import jewelryQuery from 'graphql/jewelryQuery';
import jewelryUpdate from 'graphql/jewelryUpdate';
import { Product as IProduct, MutationUpdateProductArgs } from 'graphql/codegen';
import JewelryImages from './JewelryImages';
import JewelryVideo from './JewelryVideo';

class Jewelry extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    saveJewelry: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'basicInfo',
      ...props.data.jewelry
    };
  }

  handleStateChange = (e): void => {
    const t = e.target;
    let val = t.value;
    if (t.type === 'number') {
      val = parseInt(t.value, 10);
    }
    this.setState((state) => _.setWith(_.clone(state), t.name, t.type === 'checkbox' ? t.checked : val, _.clone));
  };

  handleSubmit = (e): void => {
    e.preventDefault();

    const product: IProduct = this.state;

    product.meta = _.omit(product.meta, ['__typename']);

    const { allowCheckout, leadTimeFrom, leadTimeTo } = product;

    if (allowCheckout && (!leadTimeFrom || !leadTimeTo)) {
      return this.notify('danger', 'Lead time range is required if "Allow Checkout" is checked!');
    }

    const variables: MutationUpdateProductArgs = {
      where: { id: product.id },
      data: {
        allowCheckout: { set: !!product.allowCheckout },
        bandType: product.bandTypeId ? { connect: { id: product.bandTypeId } } : undefined,
        // diamondShape: product.diamondShapeId ? { connect: { id: product.diamondShapeId } } : undefined,
        cost: { set: product.cost },
        description: { set: product.description },
        enabled: { set: product.enabled },
        fingerSize: { set: product.fingerSize },
        isBand: { set: product.isBand },
        isEngagementRing: { set: product.isEngagementRing },
        isHandFabricated: { set: product.isHandFabricated },
        isRightHandRing: { set: product.isRightHandRing },
        leadTimeFrom: product.leadTimeFrom ? { set: product.leadTimeFrom } : undefined,
        leadTimeTo: product.leadTimeTo ? { set: product.leadTimeTo } : undefined,
        meta: {
          upsert: {
            create: product.meta,
            update: {
              title: { set: product.meta.title },
              description: { set: product.meta.description },
              keywords: { set: product.meta.keywords }
            }
          }
        },
        // metal: product.metalId ? { connect: { id: product.metalId } } : undefined,
        modelName: { set: product.modelName },
        modelNumber: { set: product.modelNumber },
        name: { set: product.name },
        notes: { set: product.notes },
        onSale: { set: typeof product.onSale === 'boolean' ? product.onSale : false },
        pave: product.paveId ? { connect: { id: product.paveId } } : undefined,
        paveDiamondType: product.paveDiamondTypeId ? { connect: { id: product.paveDiamondTypeId } } : undefined,
        price: { set: isNaN(Number(product.price)) ? 0 : Number(product.price) },
        productType: product.productTypeId ? { connect: { id: product.productTypeId } } : undefined,
        prong: product.prongId ? { connect: { id: product.prongId } } : undefined,
        quantity: { set: isNaN(Number(product.quantity)) ? 0 : Number(product.quantity) },
        ringType: product.ringTypeId ? { connect: { id: product.ringTypeId } } : undefined,
        salePrice: { set: isNaN(Number(product.salePrice)) ? 0 : Number(product.salePrice) },
        shank: product.shankId ? { connect: { id: product.shankId } } : undefined,
        shankThickness: product.shankThicknessId ? { connect: { id: product.shankThicknessId } } : undefined,
        shortDescription: { set: product.shortDescription },
        sku: { set: product.sku },
        slug: { set: product.slug },
        type: { set: product.type },
        variants: {
          // make sure all variants inherit the state of parent
          updateMany: [
            {
              where: {},
              data: {
                description: { set: product.description },
                enabled: { set: product.enabled },
                fingerSize: { set: product.fingerSize },
                isBand: { set: product.isBand },
                isEngagementRing: { set: product.isEngagementRing },
                isHandFabricated: { set: product.isHandFabricated },
                isRightHandRing: { set: product.isRightHandRing },
                modelName: { set: product.modelName },
                modelNumber: { set: product.modelNumber },
                notes: { set: product.notes }
              }
            }
          ]
        }
      }
    };

    this.props
      .saveJewelry(variables)
      .then(() => this.notify('primary', 'Product updated!'))
      .catch(() => this.notify('danger', 'Save failed!'));
  };

  createSelectOptions = (items: { _id: string; label: string }[]): { _id: string; label: string; value: string }[] => {
    if (!items || !Array.isArray(items) || !items.length) {
      return [];
    }
    return items.map(({ _id, label }) => ({ _id, label, value: _id }));
  };

  notify(type: string, message: string): void {
    this.refs.notificationAlert.notificationAlert({
      place: 'tc',
      message,
      type, // success|danger
      autoDismiss: 5
    });
  }

  renderTabNav(tabName: string, label: string): any {
    return (
      <NavItem>
        <NavLink
          className={this.state.currentTab === tabName ? 'active' : ''}
          onClick={(): void => this.setState({ currentTab: tabName })}
        >
          {label}
        </NavLink>
      </NavItem>
    );
  }

  render(): JSX.Element {
    const { loading, error, data, refetch } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <div>
          <p>Error :(</p>
        </div>
      );
    }

    const { jewelry } = this.props.data;

    if (!jewelry) {
      return <div>No product found!</div>;
    }

    const { allowCheckout, leadTimeFrom, leadTimeTo } = this.state;

    return (
      <div>
        <NotificationAlert ref="notificationAlert" />
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h5>JEWELRY DETAIL</h5>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={2}>
                      <Nav pills className="nav-pills-primary flex-column">
                        {this.renderTabNav('basicInfo', 'Basic Info')}
                        {this.renderTabNav('options', 'Options')}
                        <UserIsInRole roles="admin">{this.renderTabNav('costs', "Med'o Gawonoyo")}</UserIsInRole>
                        {this.renderTabNav('metaData', 'Meta Data')}
                        {this.renderTabNav('images', 'Images')}
                        {this.renderTabNav('video', 'Video')}
                        {this.renderTabNav('360', '360')}
                      </Nav>
                    </Col>
                    <Col md={10}>
                      <TabContent activeTab={this.state.currentTab}>
                        <TabPane tabId="basicInfo">
                          <Form onSubmit={this.handleSubmit}>
                            <Row>
                              <Col sm={{ size: 6, offset: 1 }}>
                                <CollectionsEditInput product={jewelry} refetch={refetch} />
                              </Col>
                            </Row>
                            <FormInputsLegacy
                              inputs={[
                                {
                                  label: 'Product Type',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'productTypeId',
                                    options: [
                                      {
                                        label: 'Bracelet',
                                        value: 'Bracelet'
                                      },
                                      {
                                        label: 'Earrings',
                                        value: 'Earrings'
                                      },
                                      {
                                        label: 'Necklace',
                                        value: 'Necklace'
                                      },
                                      {
                                        label: 'Ring',
                                        value: 'Ring'
                                      }
                                    ],
                                    defaultValue: { label: jewelry.type, value: jewelry.type },
                                    onChange: ({ value }) => this.setState({ type: value })
                                  }
                                },
                                {
                                  label: 'Product Name',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'name',
                                    defaultValue: jewelry.name,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'SKU',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'sku',
                                    defaultValue: jewelry.sku,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Slug',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'slug',
                                    defaultValue: jewelry.slug,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Model Name',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'modelName',
                                    defaultValue: jewelry.modelName,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Model Number',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'modelNumber',
                                    defaultValue: jewelry.modelNumber,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Short Description',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'textarea',
                                    rows: 3,
                                    name: 'shortDescription',
                                    defaultValue: jewelry.shortDescription,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Full Description',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'wysiwyg',
                                    name: 'description',
                                    defaultValue: jewelry.description,
                                    onChange: (val) => {
                                      this.setState((state) => _.setWith(_.clone(state), 'description', val, _.clone));
                                    }
                                  }
                                },
                                {
                                  label: 'Price',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'currency',
                                    name: 'price',
                                    value: jewelry.price / 100,
                                    onChange: (e) => {
                                      const { name, rawValue } = e.target;
                                      let value = parseFloat(rawValue, 10) * 100;
                                      if (isNaN(value)) {
                                        value = 0;
                                      }
                                      this.setState((state) => _.setWith(_.clone(state), name, value, _.clone));
                                    }
                                  }
                                },
                                {
                                  label: 'Sale Price',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'currency',
                                    name: 'salePrice',
                                    value: jewelry.salePrice / 100,
                                    onChange: (e) => {
                                      const { name, rawValue } = e.target;
                                      let value = parseFloat(rawValue, 10) * 100;
                                      if (isNaN(value)) {
                                        value = 0;
                                      }
                                      this.setState((state) => _.setWith(_.clone(state), name, value, _.clone));
                                    }
                                  }
                                },
                                {
                                  label: 'On Sale?',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'checkbox',
                                    name: 'onSale',
                                    defaultChecked: jewelry.onSale,
                                    onChange: this.handleStateChange
                                  }
                                },
                                // {
                                //   label: 'Metal',
                                //   colProps: {
                                //     sm: { size: 6, offset: 1 }
                                //   },
                                //   inputProps: {
                                //     type: 'select',
                                //     name: 'metalId',
                                //     options: this.createSelectOptions(data.metals),
                                //     defaultValue: this.state.metal,
                                //     onChange: (metal) => this.setState({ metalId: metal.value, metal })
                                //   }
                                // },
                                {
                                  label: 'Pave',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'paveId',
                                    options: this.createSelectOptions(data.paves),
                                    defaultValue: this.state.pave,
                                    onChange: (pave) => this.setState({ paveId: pave.value, pave })
                                  }
                                },
                                {
                                  label: 'Pave Diamond Type',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'select',
                                    name: 'shapeId',
                                    options: this.createSelectOptions(data.paveDiamondTypes),
                                    defaultValue: this.state.paveDiamondType,
                                    onChange: (paveDiamondType) =>
                                      this.setState({ paveDiamondTypeId: paveDiamondType.value, paveDiamondType })
                                  }
                                },
                                {
                                  label: 'Hand Fabricated?',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'checkbox',
                                    name: 'isHandFabricated',
                                    defaultChecked: this.state.isHandFabricated,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Quantity in Stock',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'number',
                                    name: 'quantity',
                                    defaultValue: jewelry.quantity,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Enabled',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'checkbox',
                                    name: 'enabled',
                                    defaultChecked: jewelry.enabled,
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Allow checkout when quantity is 0?',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'checkbox',
                                    name: 'allowCheckout',
                                    defaultChecked: jewelry.allowCheckout,
                                    onChange: this.handleStateChange
                                  }
                                }
                              ]}
                            />
                            <br />
                            {allowCheckout && (
                              <Col sm={{ size: 8, offset: 1 }}>
                                <LeadTimeRange
                                  setLeadTimeRange={(range): void => this.setState(range)}
                                  leadTimeRange={{ leadTimeFrom, leadTimeTo }}
                                />
                              </Col>
                            )}
                          </Form>
                        </TabPane>
                        <TabPane tabId="options">
                          <ProductVariants />
                        </TabPane>
                        <TabPane tabId="costs">
                          <Form onSubmit={this.handleSubmit}>
                            <FormInputsLegacy
                              inputs={[
                                {
                                  label: 'Nefaqto',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'currency',
                                    name: 'cost',
                                    value: jewelry.cost / 100,
                                    onChange: (e) => {
                                      const { name, rawValue } = e.target;
                                      let value = parseFloat(rawValue, 10) * 100;
                                      if (isNaN(value)) {
                                        value = 0;
                                      }
                                      this.setState((state) => _.setWith(_.clone(state), name, value, _.clone));
                                    }
                                  }
                                },
                                {
                                  label: 'Notes',
                                  colProps: {
                                    md: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'textarea',
                                    name: 'notes',
                                    rows: 6,
                                    defaultValue: jewelry.notes,
                                    onChange: this.handleStateChange
                                  }
                                }
                              ]}
                            />
                          </Form>
                        </TabPane>
                        <TabPane tabId="metaData">
                          <Form onSubmit={this.handleSubmit}>
                            <FormInputsLegacy
                              inputs={[
                                {
                                  label: 'Meta Title',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'meta.title',
                                    defaultValue: _.get(jewelry, 'meta.title'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Meta Description',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'textarea',
                                    name: 'meta.description',
                                    defaultValue: _.get(jewelry, 'meta.description'),
                                    onChange: this.handleStateChange
                                  }
                                },
                                {
                                  label: 'Meta Keywords',
                                  colProps: {
                                    sm: { size: 6, offset: 1 }
                                  },
                                  inputProps: {
                                    type: 'text',
                                    name: 'meta.keywords',
                                    defaultValue: _.get(jewelry, 'meta.keywords'),
                                    onChange: this.handleStateChange
                                  }
                                }
                              ]}
                            />
                          </Form>
                        </TabPane>
                        <TabPane tabId="images">
                          <JewelryImages jewelry={jewelry} refetch={refetch} />
                        </TabPane>
                        <TabPane tabId="video">
                          <JewelryVideo product={jewelry} refetch={refetch} />
                        </TabPane>
                        <TabPane tabId="360">TODO...</TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  {!['options', 'video'].includes(this.state.currentTab) ? (
                    <Button color="primary" type="submit" onClick={this.handleSubmit}>
                      Save
                    </Button>
                  ) : null}
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(jewelryUpdate, {
    props: ({ mutate }) => ({
      saveJewelry: (args: MutationUpdateProductArgs): Promise<void> =>
        mutate({
          variables: args,
          refetchQueries: [
            {
              query: jewelryQuery,
              variables: { where: args.where }
            }
          ]
        })
    })
  }),
  withJewelry
)(Jewelry);
