import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-v6';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Form, Label } from 'reactstrap';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useQueryParams, ArrayParam, NumberParam, StringParam, BooleanParam } from 'use-query-params';

import { PanelHeader, Button, Icon, Loading, FormInputsLegacy, FlexSpaceBetween } from '../components';
import { formatPrice, multiply } from '../lib/utils';
import {
  Diamond,
  QueryDiamondsArgs,
  useDiamondsQuery,
  useProductAttributesQuery,
  useVendorsQuery
} from '../graphql/codegen';

interface VirtualProductData {
  virtualProducts: Diamond[];
}

interface SelectFieldOption {
  key: string;
  label: string;
  value: string;
}

// format the select input options for the filter form inputs
const makeOptions = (arr: string[] | undefined): SelectFieldOption[] =>
  Array.isArray(arr) ? arr.map((v) => ({ key: v, label: v, value: v })) : [];

const VirtualProducts: React.FC = () => {
  // define query param filter schema
  // https://github.com/pbeshai/use-query-params
  const [query, setQueryParams] = useQueryParams({
    priceFrom: NumberParam,
    priceTo: NumberParam,
    caratFrom: NumberParam,
    caratTo: NumberParam,
    color: ArrayParam,
    shape: ArrayParam,
    lab: ArrayParam,
    labGrown: BooleanParam,
    purity: ArrayParam,
    search: StringParam,
    vendors: ArrayParam
  });

  // Filter state from inputs.
  // Any time query params in the URL change, it triggers a GraphQL fetch
  // and a re-render of the component. We use the state handlers below
  // to manage a separate state object to keep track of UI filter state so that you can update
  // multipe filters without changing the query params immediately and triggering API calls.
  // This state object then gets merged into the URL query params on submit
  // button press in the filter UI area.  That triggers query string update in the URL,
  // and then that triggers a new GraphQL query and a page render with the results.
  const [filtersUpdated, setFiltersUpdated] = useState(false);
  const [filters, setFilters] = useState(query); // default value is set to query params at page load

  // multi-select filter input change handling
  const handleSelectChange = (name: string) => (val: SelectFieldOption[] | undefined): void => {
    setFilters({
      ...filters,
      [name]: Array.isArray(val) ? val.map((v) => v.value) : []
    });
    setFiltersUpdated(true);
  };

  // price range filter input change handling
  const handlePriceRangeChange = (name: string) => (e): void => {
    setFilters({
      ...filters,
      // eslint-disable-next-line no-restricted-globals
      [name]: !isNaN(e.target.rawValue) ? e.target.rawValue : undefined
    });
    setFiltersUpdated(true);
  };

  // multi-select filter input change handling
  const handleCaratRangeChange = (name: string) => (e): void => {
    setFilters({
      ...filters,
      // eslint-disable-next-line no-restricted-globals
      [name]: !isNaN(e.target.value) ? e.target.value : filters[name]
    });
    setFiltersUpdated(true);
  };

  const { data: vendorData } = useVendorsQuery();
  const { data: attrData } = useProductAttributesQuery();

  const isLabGrown = typeof query.labGrown === 'boolean' ? query.labGrown : undefined;

  // map the query params from the URL to the GraphQL query variables
  const variables: QueryDiamondsArgs = {
    where: {
      AND: [
        { vendorId: { not: null } },
        { vendor: query.vendors && query.vendors.length ? { slug: { in: query.vendors } } : undefined },
        { carat: { gte: query.caratFrom, lte: query.caratTo } },
        {
          price: {
            gt: multiply(query.priceFrom || 0, 100),
            lte: query.priceTo ? multiply(query.priceTo, 100) : undefined
          }
        },
        { color: query.color ? { value: { in: query.color } } : undefined },
        { shape: query.shape ? { value: { in: query.shape } } : undefined },
        { isLabGrown: isLabGrown ? { equals: isLabGrown } : undefined }
      ],
      OR: query.search
        ? [
            { id: { contains: query.search } },
            { sku: { contains: query.search } },
            { lab: { reportNumber: { contains: query.search } } }
          ]
        : undefined
    },
    first: 100
  };

  const { loading, error, data } = useDiamondsQuery({ variables });

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  // define which table columns to show
  const columns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      maxWidth: 140
    },
    {
      Header: 'Lab Report',
      accessor: 'lab.reportNumber',
      maxWidth: 140
    },
    {
      Header: 'Carat',
      accessor: 'carat',
      maxWidth: 80
    },
    {
      Header: 'Color',
      accessor: 'color',
      Cell: ({ value }): string | null => value?.label,
      maxWidth: 70
    },
    {
      Header: 'Shape',
      accessor: 'shape',
      Cell: ({ value }): string | null => value?.label,
      maxWidth: 150
    },
    {
      Header: 'Cost (vendor price)',
      accessor: 'cost',
      Cell: ({ value }): number | void => value && formatPrice(value),
      sortMethod: (a: number, b: number): number => (a > b ? 1 : -1),
      maxWidth: 200
    },
    {
      Header: 'Price (our price)',
      accessor: 'price',
      Cell: ({ value }): number | void => value && formatPrice(value),
      sortMethod: (a: number, b: number): number => (a > b ? 1 : -1),
      maxWidth: 200
    },
    {
      Header: '',
      accessor: 'actions',
      sortable: false,
      filterable: false,
      maxWidth: 100
    }
  ];

  const tableData =
    data &&
    data.diamonds.map((item) => ({
      ...item,
      actions: (
        <div className="actions-right">
          <Link to={`/products/virtual/${item?.id}`}>
            <Button color="primary" size="sm" icon>
              <Icon name={faArrowRight} />
            </Button>
          </Link>
        </div>
      )
    }));

  const totalResults = data?.count;

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={3}>
            <Card style={{ height: '100%' }}>
              <CardBody>
                <Form>
                  <FormInputsLegacy
                    inputs={[
                      {
                        label: 'Search',
                        colProps: { xs: 12 },
                        inputProps: {
                          type: 'text',
                          placeholder: 'Search by ID, SKU, or lab report number...',
                          name: 'search',
                          value: filters.search,
                          onChange(e): void {
                            setFilters({ ...filters, search: e.target.value });
                            setFiltersUpdated(true);
                          }
                        }
                      },
                      {
                        label: 'Vendor',
                        colProps: { xs: 12 },
                        inputProps: {
                          type: 'select',
                          isMulti: true,
                          placeholder: 'Filter by vendor(s)...',
                          name: 'vendor',
                          options:
                            vendorData?.vendors?.map((v) => ({ label: v.name, value: v.slug, key: v.slug })) || [],
                          value: makeOptions(filters.vendors),
                          onChange: handleSelectChange('vendors')
                        }
                      },
                      {
                        label: 'Colors',
                        colProps: { xs: 12 },
                        inputProps: {
                          type: 'select',
                          isMulti: true,
                          isSearchable: true,
                          placeholder: 'Select filters...',
                          name: 'color',
                          options: makeOptions(attrData?.colors.map((v) => v.value)),
                          value: makeOptions(filters.color),
                          onChange: handleSelectChange('color')
                        }
                      },
                      {
                        label: 'Shapes',
                        colProps: { xs: 12 },
                        inputProps: {
                          type: 'select',
                          isMulti: true,
                          isSearchable: true,
                          placeholder: 'Select filters...',
                          name: 'shape',
                          options: makeOptions(attrData?.diamondShapes.map((v) => v.value)),
                          value: makeOptions(filters.shape),
                          onChange: handleSelectChange('shape')
                        }
                      }
                      // {
                      //   label: 'Purity',
                      //   colProps: { xs: 12 },
                      //   inputProps: {
                      //     type: 'select',
                      //     isMulti: true,
                      //     isSearchable: true,
                      //     placeholder: 'Select filters...',
                      //     name: 'purity',
                      //     // options: makeOptions(attrData?.map((v) => v.label)),
                      //     value: makeOptions(filters.purity),
                      //     onChange: handleSelectChange('purity')
                      //   }
                      // }
                    ]}
                  />
                  <hr />
                  <Label>Price</Label>
                  <FormInputsLegacy
                    inputs={[
                      {
                        label: 'min',
                        colProps: { xs: 6 },
                        inputProps: {
                          type: 'currency',
                          placeholder: 'min',
                          name: 'price-min',
                          value: filters.priceFrom,
                          onChange: handlePriceRangeChange('priceFrom')
                        }
                      },
                      {
                        label: 'max',
                        colProps: { xs: 6 },
                        inputProps: {
                          type: 'currency',
                          placeholder: 'max',
                          name: 'price-max',
                          value: filters.priceTo,
                          onChange: handlePriceRangeChange('priceTo')
                        }
                      }
                    ]}
                  />
                  <hr />
                  <Label>Carat</Label>
                  <FormInputsLegacy
                    inputs={[
                      {
                        colProps: { xs: 6 },
                        inputProps: {
                          type: 'number',
                          placeholder: 'min',
                          name: 'carat-min',
                          defaultValue: filters.caratFrom,
                          onChange: handleCaratRangeChange('caratFrom')
                        }
                      },
                      {
                        colProps: { xs: 6 },
                        inputProps: {
                          type: 'number',
                          placeholder: 'max',
                          name: 'carat-max',
                          defaultValue: filters.caratTo,
                          onChange: handleCaratRangeChange('caratTo')
                        }
                      }
                    ]}
                  />
                  <hr />
                  <div style={{ marginTop: '1rem' }} />
                  <Label>Lab Grown</Label>
                  <FormInputsLegacy
                    inputs={[
                      {
                        colProps: { xs: 12 },
                        inputProps: {
                          type: 'checkbox',
                          name: 'isLabGrown',
                          label: 'Lab Grown',
                          defaultChecked: filters.labGrown,
                          onChange: (e): void => {
                            setFilters({
                              ...filters,
                              labGrown: e.target.checked
                            });
                            setFiltersUpdated(true);
                          }
                        }
                      }
                    ]}
                  />
                  {filtersUpdated && (
                    <Col xs={12} style={{ marginTop: '2rem' }}>
                      <Button
                        type="submit"
                        color="primary"
                        onClick={(): void => {
                          setQueryParams(filters);
                          setFiltersUpdated(false);
                        }}
                      >
                        Update Filters
                      </Button>
                    </Col>
                  )}
                </Form>
              </CardBody>
            </Card>
            {/* <Card>
              <CardBody>
                <ExportVirtualProductsButton />
              </CardBody>
            </Card> */}
          </Col>
          <Col xs={9}>
            <Card>
              <CardHeader>
                <CardTitle>Virtual Products {!loading && <small>({totalResults} results found)</small>}</CardTitle>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <Loading />
                ) : (
                  <div>
                    <FlexSpaceBetween>
                      {/* {data?.virtualProducts.previous
                          && (
                          <Button
                            color="primary"
                            onClick={(): void => {
                              setQueryParams({
                                ...filters,
                                c: data.virtualProducts.previous || undefined
                              });
                            }}
                          >
                            Previous Page
                          </Button>
                        )} */}
                      {/* {data?.diamonds.hasNext
                          && (
                          <Button
                            color="primary"
                            onClick={(): void => {
                              setQueryParams({
                                ...filters,
                                c: data.diamonds.next || undefined
                              });
                            }}
                          >
                            Next Page
                          </Button>
                        )} */}
                    </FlexSpaceBetween>
                    <hr />
                    <ReactTable
                      data={tableData}
                      columns={columns}
                      defaultPageSize={totalResults && totalResults < 50 ? totalResults : 50}
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default VirtualProducts;
